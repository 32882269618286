/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

import styles from './styles.module.scss';

interface LinkWithUnderlineProps {
  text: string;
  id: number;
  activeLink: number | null;
  setActiveLink: (id: number | null) => void;
}

const LinkWithUnderline: React.FC<LinkWithUnderlineProps> = ({ text, id, activeLink, setActiveLink }) => {
  const handleClick = (): void => {
    if (id !== activeLink) {
      setActiveLink(id);
      window.location.hash = text.toLowerCase().replace(/\s+/g, '-');
    }
  };

  return (
    <a className={`fs-unmask ${styles.linkWithUnderline} ${id === activeLink ? styles.active : ''}`} onClick={handleClick}>
      {text}
    </a>
  );
};

export default LinkWithUnderline;
