/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
import Sidenav from '@brands/Dashboard/Sidenav/Sidenav';
import { selectAuth } from '@brands/store/selectors/auth';
import { selectVideoCallCredentials } from '@brands/store/selectors/videoCallCredentials';
import { isSupported } from '@twilio/video-processors';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { BiMessageAltDetail } from 'react-icons/bi';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { LocalTrack } from 'twilio-video';

import Button from '../../Components/Button/Button';
import ProviderProfileCardModal from '../../Dashboard/ProfileCards/Dentist/ProfileCards';
import { useSubscribe, useVideoContext, useWindowSize } from '../../hooks';
import { useAppSelector } from '../../hooks/useReduxHook';
import { Subscriber } from '../../hooks/useSubscribe';
import { getActiveVideoRoom } from '../../services/cases/getActiveVideoRoom';
import { getOneCase } from '../../services/cases/getCaseById';
import { joinVideoRoom } from '../../services/cases/joinVideoRoom';
import { CaseType, ICase } from '../../services/cases/types/ICase';
import { getUserAvatar } from '../../services/identity/getUserAvatar';
import { UserRoleName } from '../../services/identity/types/UserProfile';
import { displayErrorDetails } from '../../Utils/displayError';
import rightImage from '../assets/waiting-room.svg';
import ToggleAudioButton from '../Buttons/ToggleAudioButton/ToggleAudioButton';
import ToggleVideoButton from '../Buttons/ToggleVideoButton/ToggleVideoButton';
import VirtualBackgroundButton from '../Buttons/VirtualBackgroundButton/VirtualBackgroundButton';
import LocalVideoPreview from '../LocalVideoPreview/LocalVideoPreview';
import styles from './styles.module.scss';

interface WaitingRoomProps {
  startVideoRoom: (videoSid: string, localTracks: LocalTrack[], dependentId?: string) => Promise<void>;
  setIsJoined: (value: boolean) => void;
}

const WaitingRoom: React.FC<WaitingRoomProps> = ({ startVideoRoom, setIsJoined }) => {
  const { userInfo } = useAppSelector(selectAuth);
  const { vc_case_id: vcCaseID, is_visible: isVisible } = useAppSelector(selectVideoCallCredentials);
  const p = useParams();
  const screenSize = useWindowSize();
  const [providerProfileCardModal, setProviderProfileCardModal] = useState(false);
  const { getAudioAndVideoTracks, localTracks } = useVideoContext();
  const [nonPatientParticipantIds, setNonPatientParticipantIds] = useState<number[]>([]);
  const [currentCase, setCurrentCase] = useState<ICase>();
  const { subscribe, unsubscribe } = useSubscribe();
  const [waitForTracks, setWaitForTracks] = useState<boolean>(false);
  const [disableBtn, setDisableBtn] = useState<boolean>(false);

  const isMobile = screenSize.width < 768;

  const checkPatientStatus = useCallback(
    (type = 'join'): boolean => {
      if (userInfo?.role?.name === UserRoleName.Patient) {
        switch (currentCase?.status) {
          case 'new':
            return type !== 'notification';
          case 'accepted_care_advisor':
            return true;
          case 'accepted_provider':
            return true;
          case 'in_progress_provider':
            return true;
          case 'in_progress_care_advisor':
            return true;
          case 'completed':
            return false;
          case 'canceled':
            return false;
          default:
            return false;
        }
      }
      return false;
    },
    [userInfo, currentCase],
  );

  const dispatchSuccessEvent = (event: string, video_room_id: string): void => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event,
      userId: userInfo.id,
      video_room_id,
    });
  };

  const stopCamera = useCallback(() => {
    localTracks.forEach((track) => {
      if ('stop' in track && typeof track.stop === 'function') {
        track.stop();
      }
    });
  }, [localTracks]);

  useEffect(() => {
    if (!isVisible) {
      stopCamera();
    }
  }, [isVisible]);

  const [isPatientAbleToJoin, setIsPatientAbleToJoin] = useState<boolean>(false);

  useEffect(() => {
    const getRemainingTimeForPatient = (): number => {
      if (
        userInfo?.role?.name === UserRoleName.Patient &&
        (currentCase?.type === CaseType.video_call_scheduled || currentCase?.type === CaseType.oral_health_coaching)
      ) {
        const utcTime = moment().utc().format('YYYY-MM-DDTHH:mm:ss');

        const scheduledTimeUTC = moment(currentCase.video_conference_date).format('YYYY-MM-DDTHH:mm:ss');

        const dueDateObject = new Date(scheduledTimeUTC);
        const currentTime = new Date(utcTime);
        const remainingTime = dueDateObject.getTime() - currentTime.getTime();

        const remainingMinutes = Math.ceil(remainingTime / (1000 * 60));

        return remainingMinutes;
      }
      return 0;
    };

    const updateIsPatientAbleToJoin = (): void => {
      const remainingTime = getRemainingTimeForPatient();
      setIsPatientAbleToJoin(remainingTime <= 10);
    };

    updateIsPatientAbleToJoin();

    const intervalId = setInterval(updateIsPatientAbleToJoin, 60000);

    return () => clearInterval(intervalId);
  }, [currentCase]);

  const subscriber: Subscriber = {
    onMessage: (message: Record<string, any>) => {
      if (
        message.case_id === Number(userInfo?.role?.name === UserRoleName.Patient ? p.caseId : vcCaseID) &&
        message.participant_role !== UserRoleName.Patient
      ) {
        if (message.command === 'participant_connected') {
          setNonPatientParticipantIds([
            ...nonPatientParticipantIds.filter((id) => id !== message.participant_identity),
            message.participant_identity,
          ]);
        } else {
          setNonPatientParticipantIds(nonPatientParticipantIds.filter((id) => id !== message.participant_identity));
        }
      }
    },
    commands: ['participant_connected', 'participant_disconnected'],
  };

  const start = async (): Promise<void> => {
    try {
      setDisableBtn(true);
      const videoRoom = await joinVideoRoom(currentCase?.id || 0);
      const checkDependentParticipant = videoRoom.participants.find((participant) => {
        return participant.user.role.name === 'Dependent';
      });
      await startVideoRoom(videoRoom.sid, localTracks, checkDependentParticipant?.identity || '');
      dispatchSuccessEvent('video-room-connected', videoRoom.sid);
    } catch (error) {
      setDisableBtn(false);
      toast.error('Something went wrong while joining the video room. Please try again later.');
    }
  };

  const getLocalTracks = async (ctrl: AbortController): Promise<void> => {
    try {
      await getAudioAndVideoTracks(ctrl);
    } catch (error) {
      toast.error('Something went wrong while getting local tracks. Please try again later.');
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    (async () => {
      try {
        const curCase = await getOneCase(Number(userInfo?.role?.name === UserRoleName.Patient ? p.caseId : vcCaseID));
        setCurrentCase(curCase);
        if (curCase) {
          const activeVideoRoom = await getActiveVideoRoom(
            Number(userInfo?.role?.name === UserRoleName.Patient ? p.caseId : vcCaseID),
          );

          if (activeVideoRoom) {
            const connectedParticipants = activeVideoRoom.participants
              .filter((participant) => {
                return participant.status === 'connected' && participant.user.role.name !== UserRoleName.Patient;
              })
              .map((participant) => participant.user.id);
            setNonPatientParticipantIds([...nonPatientParticipantIds, ...connectedParticipants]);
            dispatchSuccessEvent('video-room-joined', activeVideoRoom.sid);
          }
          if (localTracks.length === 0) {
            await getLocalTracks(controller);
            await setTimeout(() => setWaitForTracks(true), 2000);
          }
        }
      } catch (error: unknown) {
        displayErrorDetails(error);
      }
      return () => {
        setCurrentCase(undefined);
      };
    })();
    const subscriptionId = subscribe(subscriber);
    window.addEventListener('beforeunload', () => {
      setNonPatientParticipantIds([]);
    });
    return () => {
      unsubscribe(subscriptionId);
      controller.abort();
      window.removeEventListener('beforeunload', () => {
        setNonPatientParticipantIds([]);
      });
    };
  }, []);

  return (
    <>
      {userInfo?.role.name === UserRoleName.Patient && (
        <Sidenav isVisibleOnMobile isProfileCard={false}>
          {false && (
            <div className={styles.sidenavContainer}>
              <div className={styles.sidenavToggleText}>Chat with Care Advisor</div>
              <div className={styles.sidenavToggle}>
                <BiMessageAltDetail />
              </div>
            </div>
          )}
        </Sidenav>
      )}
      <div
        className={styles.videoBody}
        style={{
          paddingTop: isVisible ? '20px' : '',
          paddingBottom: isVisible ? '20px' : '',
          backgroundColor: isVisible ? 'unset' : '',
          alignItems: isVisible ? 'flex-start' : '',
        }}
      >
        <div className={styles.leftSection}>
          <div
            className={styles.videoContainer}
            style={{
              width: userInfo.role.name !== UserRoleName.Patient ? 'max-content' : '400px',
              minWidth: userInfo.role.name !== UserRoleName.Patient ? '100%' : 'inherit',
            }}
          >
            {checkPatientStatus('notification') && currentCase?.provider && (
              <div className={styles.providerInfo}>
                <div onClick={() => setProviderProfileCardModal(true)}>
                  <img
                    alt="Avatar"
                    onError={(event: any) => {
                      event.target.src =
                        'https://media.istockphoto.com/id/1130884625/tr/vekt%C3%B6r/kullan%C4%B1c%C4%B1-%C3%BCyesi-vekt%C3%B6r-simgesi-ui-kullan%C4%B1c%C4%B1-arabirimi-veya-profil-face-avatar-uygulamas%C4%B1.jpg?s=612x612&w=0&k=20&c=jAf5nq1ebnZo8TJbjaB9dMHMvgff7uOk67NkF5CpgB0=';
                      event.target.onError = null;
                    }}
                    className={`fs.exclude ${styles.profileImage}`}
                    src={getUserAvatar(Number(currentCase?.provider?.id))}
                  />
                </div>
                <div className={styles.providerContent}>
                  <div className={`fs.exclude ${styles.providerName}`}>
                    {currentCase?.provider?.full_name}
                    {currentCase?.provider.provider_profile?.credentials ? ', ' : ''}
                    {currentCase?.provider.provider_profile?.credentials || ''}
                  </div>
                  <div className={styles.providerNotification}>will join you shortly</div>
                </div>
              </div>
            )}
            {currentCase?.provider === null &&
              userInfo?.role.name === UserRoleName.Patient &&
              checkPatientStatus('notification') && (
                <div className={styles.providerInfo}>
                  <div className={styles.providerContent}>
                    <div className={styles.providerNotification}>Your dentist will join shortly.</div>
                  </div>
                </div>
              )}
            {userInfo?.role.name === UserRoleName.Patient && !checkPatientStatus('notification') && (
              <div className={styles.providerInfo}>
                <div className={styles.providerContent}>
                  <div className={styles.providerNotification}>Your dentist will join shortly.</div>
                </div>
              </div>
            )}
            <div
              className={styles.videoTrackContainer}
              style={{
                width: isMobile && userInfo?.role.name !== UserRoleName.Patient ? '90%' : '',
                height: isMobile && userInfo?.role.name !== UserRoleName.Patient ? '90%' : '',
              }}
            >
              <LocalVideoPreview />
              <div className={styles.videoIconsContainer}>
                <ToggleAudioButton />
                <ToggleVideoButton />
                {isSupported && <VirtualBackgroundButton />}
              </div>
            </div>
            {userInfo?.role.name === UserRoleName.Patient && (
              <div className={`fs-unmask ${styles.notificationMessage}`}>
                {currentCase?.type === CaseType.video_call_scheduled ||
                currentCase?.type === CaseType.oral_health_coaching
                  ? 'You can join the video call 10 minutes before your appointment.'
                  : currentCase?.type === CaseType.video_call_instant
                  ? 'You can close this window if you’d prefer not to wait and we’ll send an alert (SMS and/or email) as soon as your dentist arrives.'
                  : ''}
              </div>
            )}
            <Button
              className={`fs-unmask ${styles.joinButton}`}
              type="button"
              onClick={async () => {
                await start();
                setIsJoined(true);
              }}
              disabled={
                (userInfo?.role.name === UserRoleName.Patient && !isPatientAbleToJoin) ||
                localTracks.length === 0 ||
                !waitForTracks ||
                disableBtn
              }
            >
              Join Now
            </Button>
          </div>
        </div>
        {userInfo?.role.name === UserRoleName.Patient && (
          <div className={styles.rightImage}>
            <div
              className={`${styles.cardImage} ${styles.onlineLearning}`}
              style={{ backgroundImage: `url(${rightImage})` }}
            />
          </div>
        )}
      </div>
      {providerProfileCardModal && (
        <ProviderProfileCardModal
          isOpen={providerProfileCardModal}
          toggleModal={setProviderProfileCardModal}
          providerIdentity={currentCase?.provider || null}
          patientCurrentStateValue={currentCase?.patient_current_state}
          caseId={currentCase?.id}
        />
      )}
    </>
  );
};

export default React.memo(WaitingRoom);
