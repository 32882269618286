import { IListCasesParams } from '@brands/services/cases/listAllCases';
import { debounce } from 'lodash';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Container from '../../../Components/Container/Container';
import { Loading } from '../../../Components/LoadingSpinner/Loading';
import { useIdentityContext, useLocalStorageState } from '../../../hooks';
import useCases from '../../../hooks/useCases';
import { CaseType, ICase } from '../../../services/cases/types/ICase';
import { EnforceCAAssignModal } from '../../../services/forms/types/ImageNote';
import { displayErrorDetails } from '../../../Utils/displayError';
import EnforceAssignCAModal from '../../ConsultationPage/Modals/EnforceAssignCAModal/EnforceAssignCAModal';
import AllCases from './AllCases/AllCases';
import styles from './careAdvisorDashboard.module.scss';
import MyCases from './MyCases/MyCases';
import OpenCases from './OpenCases/OpenCases';

const CareConciergeDashboard = (): JSX.Element => {
  const [activeTab, setActiveTab] = useLocalStorageState<string>('careAdvisorDashboardActiveTab', 'myCases');

  const setTab = (targetTab: string): void => {
    setActiveTab(targetTab);
  };

  return (
    <Container
      isBGEclipse={false}
      childClassName={styles.ccDashboard}
      containerStyle={{ justifyContent: 'start' }}
      scrollableDashboard
    >
      <div className={`p-2 px-5 ${styles.mobileV} ${styles.scrollableDiv}`}>
        <div className={styles.container}>
          <span className={styles.mobileHeader}>Upcoming Appointments</span>
          <div className={styles.dashboardHeader}>
            <div
              className={`${styles.dashboardTab} ${activeTab === 'myCases' ? styles.activeTab : styles.inActiveTab}`}
              onClick={() => setTab('myCases')}
            >
              <span>My Cases</span>
            </div>
            <div
              className={`${styles.dashboardTab} ${activeTab === 'allCases' ? styles.activeTab : styles.inActiveTab}`}
              onClick={() => setTab('allCases')}
            >
              <span>All Cases</span>
            </div>
          </div>
          {activeTab === 'myCases' && <MyCases />}
          {activeTab === 'allCases' && <AllCases />}
        </div>
      </div>
    </Container>
  );
};

export default CareConciergeDashboard;
