/* eslint-disable no-case-declarations */
import { Loading } from '@brands/Components/LoadingSpinner/Loading';
import { useAppSelector } from '@brands/hooks/useReduxHook';
import { getCurrentOrganization } from '@brands/services/identity/getCurrentOrganization';
import { getMyOrganizations } from '@brands/services/identity/getMyOrganizations';
import { Organization } from '@brands/services/identity/types/UserProfile';
import { selectAuth } from '@brands/store/selectors/auth';
import { displayErrorDetails } from '@brands/Utils/displayError';
import React, { useCallback, useEffect, useMemo } from 'react';

import styles from '../../../styles.module.scss';

const ReadOnlyOrganizations = (): JSX.Element => {
  const { userInfo } = useAppSelector(selectAuth);
  const userRole = useMemo(() => {
    if (!userInfo) {
      return null;
    }

    return userInfo.role.name;
  }, [userInfo]);
  const [organizations, setOrganizations] = React.useState<Organization[]>([]);
  const [currentOrganization, setCurrentOrganization] = React.useState<Organization | null>(null);

  const getOrganizationValues = useMemo(async () => {
    try {
      await getMyOrganizations()
        .then((res) => {
          setOrganizations(res);
        })
        .catch((err) => {
          displayErrorDetails(err);
        });
      await getCurrentOrganization()
        .then((res) => {
          setCurrentOrganization(res);
        })
        .catch((err) => {
          displayErrorDetails(err);
        });
    } catch (err) {
      displayErrorDetails(err);
    }
  }, []);

  useEffect(() => {
    (async () => {
      await getOrganizationValues;
    })();
  }, []);

  const switchOrganization = (organization: Organization): void => {
    const { id: organizationId, divisions, practices } = organization;
    const currentPath = window.location.pathname; // Declare it once here
    let newURL = '';

    if (organizationId && divisions.length > 0) {
      newURL = `https://${divisions[0].domain}${currentPath}`;
    } else if (organizationId && practices.length > 0) {
      newURL = `https://${practices[0].domain}${currentPath}`;
    }

    if (newURL) {
      window.location.href = newURL;
    }
  };

  const mapOrganizationToGetAvailableServicesForCurrentState = useCallback(
    (organization: Organization): string[] => {
      const availableServices: string[] = [];
      const matchingCorporation = organization?.professional_corporations?.find(
        (pc) => pc.state === userInfo.address?.state,
      );
      if (!matchingCorporation) {
        return ['-'];
      }

      if (matchingCorporation.video_case_enabled) {
        availableServices.push('Live Consultation');
      }

      if (matchingCorporation.phone_case_enabled) {
        availableServices.push('Phone Consultation');
      }

      if (matchingCorporation.async_case_enabled) {
        availableServices.push('Photo Review');
      }

      if (matchingCorporation.second_opinion_case_enabled) {
        availableServices.push('Second Opinion');
      }

      return availableServices;
    },
    [userInfo.address?.state],
  );

  if (!currentOrganization) {
    return <Loading dotted isHeightAuto />;
  }

  return (
    <div className={styles.readOnlyProfileContainer}>
      <div className={styles.titleContainer}>
        <span className={`fs-unmask ${styles.title}`}>Organizations</span>
      </div>
      <div className={styles.detailsContainer}>
        <div className={styles.readOnlyItem}>
          {organizations.map((organization) => (
            <div key={organization.id}>
              <div className={styles.value}>
                {organization.name}{' '}
                {currentOrganization?.id !== organization.id && userRole === 'Patient' && (
                  <span className={styles.switchButton} onClick={() => switchOrganization(organization)}>
                    Switch
                  </span>
                )}
              </div>
              <div className={styles.caption}>
                <span className='fs-unmask'>Services available:</span>
                <span className={`fs-exclude  ${styles.value}`}>
                  {' '}
                  {mapOrganizationToGetAvailableServicesForCurrentState(organization).join(', ')}
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ReadOnlyOrganizations;
