/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unsafe-optional-chaining */
import { addOneReportObject } from '@brands/services/oneReport/addOneReportObject';
import { deleteOneReportObject } from '@brands/services/oneReport/deleteOneReportObject';
import { ObjectType, updateOneReportObject } from '@brands/services/oneReport/updateOneReportObject';
import { checkAndSetPrescriptionDate } from '@brands/Utils/checkAndSetDate';
import React, { useEffect, useState } from 'react';
import { FieldErrors, UseFormRegister, UseFormSetValue, UseFormTrigger } from 'react-hook-form';
import { AiFillPlusCircle } from 'react-icons/ai';
import { IoTrashOutline } from 'react-icons/io5';

import TextArea from '../../../../Components/Inputs/TextArea/TextArea';
import { useWindowSize } from '../../../../hooks';
import { ICase } from '../../../../services/cases/types/ICase';
import { UserProfile } from '../../../../services/identity/types/UserProfile';
import { IOneReport } from '../../../../services/oneReport/types/IOneReport';
import {
  ConvertedPrescription,
  IPrescription,
  PrescriptionPayload,
} from '../../../../services/oneReport/types/ITreatments';
import { displayErrorDetails } from '../../../../Utils/displayError';
import { checkCollapsibleHeaderColor } from '../../../../Utils/getCollapsibleHeader';
import { OralHealthReportSubmit, Prescription } from '../../assets/types';
import styles from '../../styles.module.scss';

type PrescriptionSectionProps = {
  consultationForm: OralHealthReportSubmit;
  setConsultationForm: React.Dispatch<React.SetStateAction<OralHealthReportSubmit>>;
  thisCase: ICase;
  setCase: React.Dispatch<React.SetStateAction<ICase>>;
  userInfo: UserProfile;
  register: UseFormRegister<OralHealthReportSubmit>;
  setValue: UseFormSetValue<OralHealthReportSubmit>;
  trigger: UseFormTrigger<OralHealthReportSubmit>;
  errors: FieldErrors<OralHealthReportSubmit>;
};

export const convertPrescriptionType = (iPrescription: IPrescription[]): Prescription[] => {
  return iPrescription.map((prescription) => ({
    drug: prescription.drug ? prescription.drug?.charAt(0).toUpperCase() + prescription.drug?.slice(1) : '',
    sig: prescription.sig ? prescription.sig.charAt(0).toUpperCase() + prescription.sig?.slice(1) : '',
    prescription_date: prescription.prescription_date ? prescription.prescription_date : '',
    dispense: prescription.dispense
      ? prescription.dispense?.charAt(0).toUpperCase() + prescription.dispense?.slice(1)
      : '',
    refills: prescription.refills ? prescription.refills?.charAt(0).toUpperCase() + prescription.refills?.slice(1) : '',
    id: prescription.id,
  }));
};

const PrescriptionSection = ({
  consultationForm,
  setConsultationForm,
  thisCase,
  setCase,
  userInfo,
  register,
  setValue,
  trigger,
  errors,
}: PrescriptionSectionProps): JSX.Element => {
  const screenSize = useWindowSize();
  const [prescriptions, setPrescriptions] = useState<Prescription[]>(thisCase.one_report.prescriptions || []);
  const checkAtLeastOneFieldIsFilled = (prescription: Prescription): boolean => {
    if (prescription) {
      const { drug, sig, prescription_date, dispense, refills } = prescription;
      if (
        drug?.trim() !== '' ||
        sig?.trim() !== '' ||
        prescription_date?.trim() !== '' ||
        dispense?.trim() !== '' ||
        refills?.trim() !== ''
      ) {
        return true;
      }
    }
    return false;
  };
  const getConvertedPrescriptions = (res: IOneReport): ConvertedPrescription[] => {
    return convertPrescriptionType(res?.prescriptions ?? []).map((currentPrescription: Prescription) => ({
      ...currentPrescription,
      drug: currentPrescription.drug || '',
      sig: currentPrescription.sig || '',
      prescription_date: currentPrescription.prescription_date,
      dispense: currentPrescription.dispense || '',
      refills: currentPrescription.refills || '',
    }));
  };

  const addEmptyPrescriptionRow = async (): Promise<void> => {
    try {
      // Add a new prescription row
      const prescriptionResponse = await addOneReportObject(thisCase?.one_report.id || '', ObjectType.Prescription, {
        drug: '',
        sig: '',
        prescription_date: '',
        dispense: '',
        refills: '',
      });

      // Sort prescriptions by ID and update the form and case states
      const sortedPrescriptions = prescriptionResponse.prescriptions.sort(
        (a, b) => Number(a.id?.valueOf() ?? Infinity) - Number(b.id?.valueOf() ?? Infinity),
      );
      setConsultationForm({
        ...consultationForm,
        prescriptions: [...convertPrescriptionType(sortedPrescriptions)],
      });

      setCase({
        ...thisCase,
        one_report: {
          ...thisCase.one_report,
          prescriptions: [...convertPrescriptionType(sortedPrescriptions)],
        },
      });

      // If pharmacies are missing, add a default pharmacy entry
      if (prescriptionResponse.pharmacies === null) {
        const pharmacyResponse = await addOneReportObject(thisCase?.one_report.id || '', ObjectType.Pharmacy, {
          pharmacy_name: '',
          pharmacy_street_address: '',
          pharmacy_city: '',
          pharmacy_state: '',
          pharmacy_zip_code: '',
          pharmacy_phone_number: '',
        });

        // Update the form and case states with the pharmacy data
        setConsultationForm({
          ...consultationForm,
          pharmacies: pharmacyResponse.pharmacies,
          prescriptions: [...convertPrescriptionType(sortedPrescriptions)],
        });

        setCase({
          ...thisCase,
          one_report: {
            ...thisCase.one_report,
            pharmacies: pharmacyResponse.pharmacies,
            prescriptions: [...convertPrescriptionType(sortedPrescriptions)],
          },
        });
      }
    } catch (error) {
      displayErrorDetails(`Error adding new prescription or pharmacy: ${error}`);
    }
  };

  const addPrescription = async (nonEmptyPayload: IPrescription): Promise<void> => {
    try {
      const res = await addOneReportObject(thisCase?.one_report.id || '', ObjectType.Prescription, nonEmptyPayload);
      const convertedPrescriptions: ConvertedPrescription[] = getConvertedPrescriptions(res);
      setConsultationForm({
        ...consultationForm,
        prescriptions: [...convertedPrescriptions],
      });
    } catch (error) {
      displayErrorDetails(error);
    }
  };

  const updatePrescription = async (id: string, nonEmptyPayload: PrescriptionPayload): Promise<void> => {
    try {
      const res = await updateOneReportObject(thisCase?.one_report.id || '', ObjectType.Prescription, {
        ...nonEmptyPayload,
        id,
      });
      const convertedPrescriptions: ConvertedPrescription[] = getConvertedPrescriptions(res);
      setConsultationForm({
        ...consultationForm,
        prescriptions: [...convertedPrescriptions],
      });
    } catch (error) {
      displayErrorDetails(error);
    }
  };

  const deletePrescription = async (index: number, id?: string): Promise<void> => {
    const oneReport = thisCase?.one_report;
    const prescriptionExists = thisCase?.one_report.prescriptions.some((pres) => pres.id === id);
    try {
      if (id && oneReport?.id && prescriptionExists) {
        const res = await deleteOneReportObject(thisCase?.one_report.id || '', id, ObjectType.Prescription);
        const sortedRes = res.prescriptions.sort(
          (a, b) => Number(a.id?.valueOf() ?? Infinity) - Number(b.id?.valueOf() ?? Infinity),
        );
        if (res.prescriptions.length === 0) {
          setValue(`prescriptions`, []);
          trigger('prescriptions');
          setValue('pharmacies', {
            pharmacy_name: '',
            pharmacy_street_address: '',
            pharmacy_city: '',
            pharmacy_state: '',
            pharmacy_zip_code: '',
            pharmacy_phone_number: '',
          });
          trigger('prescriptions');
          if (res.pharmacies.id !== null) {
            const removePharmacy = await deleteOneReportObject(
              thisCase?.one_report.id || '',
              res.pharmacies.id as string,
              ObjectType.Pharmacy,
            );
            setConsultationForm({
              ...consultationForm,
              pharmacies: removePharmacy.pharmacies,
              prescriptions: [],
            });
            setCase({
              ...thisCase,
              one_report: removePharmacy,
            });
          }
        }
        setConsultationForm({
          ...consultationForm,
          prescriptions: [...convertPrescriptionType(sortedRes)],
        });
      } else {
        setConsultationForm({
          ...consultationForm,
          prescriptions: consultationForm.prescriptions.filter((prescription, i) => i !== index),
        });
      }
    } catch (error) {
      displayErrorDetails(error);
    }
  };

  const handlePrescriptionOnBlur = async (prescription: Prescription): Promise<void> => {
    const { id } = prescription;
    const nonEmptyPayload = {
      ...(prescription.drug ? { drug: prescription.drug } : { drug: '' }),
      ...(prescription.sig ? { sig: prescription.sig } : { sig: '' }),
      ...(prescription.prescription_date
        ? { prescription_date: prescription.prescription_date }
        : { prescription_date: '' }),
      ...(prescription.dispense ? { dispense: prescription.dispense } : { dispense: '' }),
      ...(prescription.refills ? { refills: prescription.refills } : { refills: '' }),
    };

    if (id !== undefined && checkAtLeastOneFieldIsFilled(prescription)) {
      await updatePrescription(id, nonEmptyPayload);
    } else if (id === undefined && checkAtLeastOneFieldIsFilled(prescription)) {
      await addPrescription(nonEmptyPayload);
    }
  };

  useEffect(() => {
    const sortedPrescriptions = consultationForm?.prescriptions?.sort(
      (a, b) => Number(a.id?.valueOf() ?? Infinity) - Number(b.id?.valueOf() ?? Infinity),
    );
    const filteredPrescriptions = sortedPrescriptions?.filter((prescription) => {
      if (
        prescription?.drug?.trim() !== '' ||
        prescription?.sig?.trim() !== '' ||
        prescription?.prescription_date?.trim() !== '' ||
        prescription?.dispense?.trim() !== '' ||
        prescription?.refills?.trim() !== ''
      ) {
        return true;
      }
      return false;
    });
    if (checkCollapsibleHeaderColor(thisCase || '', userInfo, 'editPrescriptions') !== 'edit') {
      setPrescriptions(filteredPrescriptions);
    } else {
      setPrescriptions(consultationForm.prescriptions);
    }
  }, [consultationForm.prescriptions, thisCase]);

  return (
    <>
      {prescriptions?.map((prescription, index) => (
        <div className={styles.descriptionInputs} style={{ marginTop: index !== 0 ? '2rem' : '' }} key={index}>
          <div className={styles.descriptionInput}>
            <div className={`fs-unmask ${styles.title}`}>Drug*</div>
            <TextArea
              {...register(`prescriptions.${index}.drug`)}
              wrapperStyle={{
                marginTop: '0',
                borderBottom: 'none',
                minHeight: '30px',
                height: 'auto',
              }}
              inputClass={`fs-exclude ${styles.input}`}
              placeholder=""
              isUnderlined={false}
              defaultValue={prescription?.drug ?? ''}
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                const updatedDrug = e.target.value;
                setConsultationForm((prevForm) => ({
                  ...prevForm,
                  prescriptions: [
                    ...prevForm.prescriptions.slice(0, index),
                    {
                      ...prevForm.prescriptions[index],
                      drug: updatedDrug,
                    },
                    ...prevForm.prescriptions.slice(index + 1),
                  ],
                }));
                setValue(`prescriptions.${index}.drug`, updatedDrug, { shouldValidate: true });
                if (errors.prescriptions) trigger('prescriptions');
              }}
              onBlur={(e: any) =>
                handlePrescriptionOnBlur({
                  ...prescription,
                  drug: e.target.value,
                })
              }
              disabled={checkCollapsibleHeaderColor(thisCase || '', userInfo, '') !== 'edit'}
            />
          </div>
          <div className={styles.descriptionInput}>
            <div className={`fs-unmask ${styles.title}`}>Sig*</div>
            <TextArea
              {...register(`prescriptions.${index}.sig`)}
              inputClass={`${styles.subjectiveInput}`}
              wrapperStyle={{
                borderBottom: 'none',
                minHeight: '35px',
                height: 'auto',
              }}
              placeholder=""
              isUnderlined={false}
              defaultValue={prescription?.sig ?? ''}
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                setConsultationForm({
                  ...consultationForm,
                  prescriptions: [
                    ...consultationForm.prescriptions.slice(0, index),
                    {
                      ...consultationForm.prescriptions[index],
                      sig: e.target.value,
                    },
                    ...consultationForm.prescriptions.slice(index + 1),
                  ],
                });
                setValue(`prescriptions.${index}.sig`, e.target.value, { shouldValidate: true });
                if (errors.prescriptions) trigger('prescriptions');
              }}
              onBlur={(e: any) =>
                handlePrescriptionOnBlur({
                  ...prescription,
                  sig: e.target.value,
                })
              }
              disabled={checkCollapsibleHeaderColor(thisCase || '', userInfo, '') !== 'edit'}
            />
          </div>
          <div className={styles.descriptionInputsFirstGroup}>
            <div className={`${styles.selectInput}`}>
              <div className={`fs-unmask ${styles.title}`}>Prescription Date*</div>
              <div className={styles.input} style={{ borderBottom: 'none' }}>
                <TextArea
                  {...register(`prescriptions.${index}.prescription_date`)}
                  wrapperStyle={{
                    marginTop: '0',
                    borderBottom: 'none',
                    minHeight: '30px',
                    height: 'auto',
                  }}
                  inputClass={`fs-exclude ${styles.input}`}
                  placeholder=""
                  isUnderlined={false}
                  defaultValue={prescription?.prescription_date ?? ''}
                  onChange={(value: any) => {
                    const updatedDrug = value.target.value;
                    setConsultationForm((prevForm) => ({
                      ...prevForm,
                      prescriptions: [
                        ...prevForm.prescriptions.slice(0, index),
                        {
                          ...prevForm.prescriptions[index],
                          prescription_date: checkAndSetPrescriptionDate(updatedDrug),
                        },
                        ...prevForm.prescriptions.slice(index + 1),
                      ],
                    }));
                    setValue(`prescriptions.${index}.prescription_date`, checkAndSetPrescriptionDate(value!), {
                      shouldValidate: true,
                    });
                    if (errors.prescriptions) trigger('prescriptions');
                  }}
                  onBlur={(e: any) =>
                    handlePrescriptionOnBlur({
                      ...prescription,
                      prescription_date: checkAndSetPrescriptionDate(e.target.value),
                    })
                  }
                  disabled={checkCollapsibleHeaderColor(thisCase || '', userInfo, '') !== 'edit'}
                />
              </div>
            </div>
            <div className={`${styles.selectInput}`}>
              <div className={`fs-unmask ${styles.title}`}>Dispense*</div>
              <div className={styles.input}>
                <TextArea
                  {...register(`prescriptions.${index}.dispense`)}
                  wrapperStyle={{
                    marginTop: '0',
                    borderBottom: 'none',
                    minHeight: '30px',
                    height: 'auto',
                  }}
                  inputClass={`fs-exclude ${styles.input}`}
                  placeholder=""
                  isUnderlined={false}
                  defaultValue={prescription?.dispense ?? ''}
                  onChange={(value: any) => {
                    const updatedDrug = value.target.value;
                    setConsultationForm((prevForm) => ({
                      ...prevForm,
                      prescriptions: [
                        ...prevForm.prescriptions.slice(0, index),
                        {
                          ...prevForm.prescriptions[index],
                          dispense: updatedDrug,
                        },
                        ...prevForm.prescriptions.slice(index + 1),
                      ],
                    }));
                    setValue(`prescriptions.${index}.dispense`, value!, { shouldValidate: true });
                    if (errors.prescriptions) trigger('prescriptions');
                  }}
                  onBlur={(e: any) =>
                    handlePrescriptionOnBlur({
                      ...prescription,
                      dispense: e.target.value,
                    })
                  }
                  disabled={checkCollapsibleHeaderColor(thisCase || '', userInfo, '') !== 'edit'}
                />
              </div>
            </div>
            <div className={`${styles.selectInput}`}>
              <div className={`fs-unmask ${styles.title}`}>Refills*</div>
              <div className={styles.input}>
                <TextArea
                  {...register(`prescriptions.${index}.refills`)}
                  wrapperStyle={{
                    marginTop: '0',
                    borderBottom: 'none',
                    minHeight: '30px',
                    height: 'auto',
                  }}
                  inputClass={`fs-exclude ${styles.input}`}
                  placeholder=""
                  isUnderlined={false}
                  defaultValue={prescription?.refills ?? ''}
                  onChange={(value: any) => {
                    const updatedDrug = value.target.value;
                    setConsultationForm((prevForm) => ({
                      ...prevForm,
                      prescriptions: [
                        ...prevForm.prescriptions.slice(0, index),
                        {
                          ...prevForm.prescriptions[index],
                          refills: updatedDrug,
                        },
                        ...prevForm.prescriptions.slice(index + 1),
                      ],
                    }));
                    setValue(`prescriptions.${index}.refills`, value!, { shouldValidate: true });
                    if (errors.prescriptions) trigger('prescriptions');
                  }}
                  onBlur={(e: any) =>
                    handlePrescriptionOnBlur({
                      ...prescription,
                      refills: e.target.value,
                    })
                  }
                  disabled={checkCollapsibleHeaderColor(thisCase || '', userInfo, '') !== 'edit'}
                />
              </div>
            </div>
          </div>
          {checkCollapsibleHeaderColor(thisCase || '', userInfo, '') === 'edit' && (
            <div
              className={styles.deleteDescription}
              onClick={() => deletePrescription(index, prescription.id)}
              style={{ marginTop: index !== 0 && screenSize.width >= 992 ? '0' : '' }}
            >
              <IoTrashOutline className="w-100 h-100" />
            </div>
          )}
        </div>
      ))}
      {checkCollapsibleHeaderColor(thisCase || '', userInfo, '') === 'edit' && (
        <div className={styles.addTreatment}>
          <div className={styles.addTreatmentContent} onClick={addEmptyPrescriptionRow}>
            <span className='fs-unmask'>Add Additional Prescription</span>
            <span className={`fs-unmask ${styles.addTreatmentIcon}`}>
              <AiFillPlusCircle />
            </span>
          </div>
        </div>
      )}
    </>
  );
};

export default React.memo(PrescriptionSection);
