import Button from '@brands/Components/Button/Button';
import { useAppSelector } from '@brands/hooks/useReduxHook';
import { UserRoleName } from '@brands/services/identity/types/UserProfile';
import { selectAuth } from '@brands/store/selectors/auth';
import { selectPatient } from '@brands/store/selectors/selectedPatient';
import React, { useCallback } from 'react';
import { AiFillCloseCircle } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';

import styles from './dashboardModal.module.scss';

type UnavailableTimeSlotModalType = {
  setDisplayUnavailableTimeSlotModal: React.Dispatch<React.SetStateAction<boolean>>;
};

const UnavailableTimeSlotModal: React.FC<UnavailableTimeSlotModalType> = ({ setDisplayUnavailableTimeSlotModal }) => {
  const navigate = useNavigate();
  const { selectedPatient: currentPatient } = useAppSelector(selectPatient);
  const { userInfo } = useAppSelector(selectAuth);
  const goToDashboard = useCallback(() => {
    if (
      userInfo.role.name === UserRoleName.Admin ||
      userInfo.role.name === UserRoleName.SuperAdmin ||
      userInfo.role.name === UserRoleName.OrganizationAdmin
    ) {
      navigate(`/admin/review-profile/Patient/${currentPatient.id}`);
    } else {
      navigate('/dashboard');
    }
  }, [userInfo.role.name, navigate]);
  return (
    <div className={styles.modalBackground} onClick={() => setDisplayUnavailableTimeSlotModal(false)}>
      <div
        className={`${styles.modalContainer} ${styles.submitConfirmationModal} ${styles.unavailableTimeSlot} fs-unmask`}
        onClick={(e) => e.stopPropagation()}
      >
        <button
          className={styles.modalClose}
          type="button"
          onClick={() => setDisplayUnavailableTimeSlotModal(false)}
          aria-label="Close Modal"
        >
          <AiFillCloseCircle /> Close
        </button>
        <div className={styles.body}>
          <p>Time Slot Unavailable</p>
          <span>
            It looks like the time slot you selected is no longer available. Please choose a new time to complete your
            appointment.
          </span>
          <Button
            className={styles.selectNewTimeSlot}
            onClick={() =>
              navigate('/select-date-time', {
                state: {
                  isFromUnavailableTimeSlotModal: true,
                },
              })
            }
          >
            Select New Timeslot
          </Button>
          <button
            type="button"
            className={styles.cancelBtn}
            onClick={() => {
              goToDashboard();
            }}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};
export default UnavailableTimeSlotModal;
