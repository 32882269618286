/* eslint-disable react/no-array-index-key */
/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
import { selectAuth } from '@brands/store/selectors/auth';
import React, { useEffect, useState } from 'react';
import { CgProfile } from 'react-icons/cg';
import { IoIosArrowDroprightCircle } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';

import { useAppSelector } from '../../hooks/useReduxHook';
import { ICase } from '../../services/cases/types/ICase';
import { IGMConversation } from '../../services/communication/types/IGMConversation';
import { IGMConversationParticipant } from '../../services/communication/types/IGMConversationParticipant';
import { getUserAvatar } from '../../services/identity/getUserAvatar';
import { UserProfile, UserRoleName } from '../../services/identity/types/UserProfile';
import {
  hasUnreadMessageInPatientCA,
  hasUnreadMessageInPatientProvider,
  hasUnreadMessageInPrivate,
} from '../../Utils/getUnreadMessageNotification';
import styles from './case-styles.module.scss';

interface IModal {
  currentCase: ICase | undefined;
  item: IGMConversation | null;
  isSelected: boolean;
  conversationType: string;
  isMobile?: boolean;
}

const CaseMessageInfo = ({ currentCase, item, isSelected, conversationType, isMobile }: IModal): JSX.Element => {
  const [remoteParticipant, setRemoteParticipant] = useState<UserProfile[]>();
  const { userInfo } = useAppSelector(selectAuth);
  const navigate = useNavigate();
  const isUserParticipant = item?.participants?.some((participant) => participant.identity === userInfo.id);
  const getRemoteParticipants = (): UserProfile[] | undefined => {
    let remoteParticipants: IGMConversationParticipant[];
    let participants: UserProfile[] = [];
    if (currentCase?.patient) {
      participants.push(currentCase.patient);
    }
    if (currentCase?.care_advisor) {
      participants.push(currentCase.care_advisor);
    }
    if (currentCase?.provider) {
      participants.push(currentCase.provider);
    }

    if (item?.participants) {
      participants = participants.filter((participant) => participant.id !== userInfo?.id);
    }

    if (item?.participants) {
      remoteParticipants = item.participants.filter((participant) => participant.identity !== userInfo?.id);
      participants = participants?.filter((object1) =>
        remoteParticipants.some((object2) =>
          object1.guardian_id ? +object1.guardian_id === +object2.identity : +object1.id === +object2.identity,
        ),
      );
    }

    return participants;
  };

  useEffect(() => {
    if (item) {
      const remoteParticipants = getRemoteParticipants();
      setRemoteParticipant(remoteParticipants);
    }
  }, [item, currentCase]);

  if (!remoteParticipant) {
    return (
      <div className={`${styles.participantDiv} ${styles.participantMessageDiv}`}>
        <span className={styles.profileImageDate}>
          <CgProfile className={styles.emptyAvatar} />
        </span>
        <div className={styles.details}>
          <div className={styles.participantRole}>Care Advisor</div>
          <div className={styles.detailsNameImage}>
            <span className={`${styles.participantName} ${!isSelected ? styles.primaryColor : ''}`}>{}</span>
          </div>
        </div>
        <div
          className={styles.detailsIcon}
          style={{ display: isSelected ? 'none' : 'block' }}
          onClick={() => {
            navigate(`/case-chat/${currentCase?.id}/${item?.sid}`);
          }}
        >
          <IoIosArrowDroprightCircle />
        </div>
      </div>
    );
  }
  return (
    <div
      className={`${styles.participantDiv} ${styles.participantMessageDiv}`}
      onClick={() => {
        navigate(`/case-chat/${currentCase?.id}/${item?.sid}`);
      }}
    >
      <div className={styles.wrapDiv}>
        {isUserParticipant ? (
          <span className={`fs-unmask ${styles.conversationType}`}>
            {remoteParticipant?.map((participant, index) => (
              <React.Fragment key={index}>
                {participant.role.name !== UserRoleName.Dependent ? participant.role.name : 'Guardian'}
                {index < (remoteParticipant as UserProfile[]).length - 1 && '/'}{' '}
              </React.Fragment>
            ))}
            {isMobile && (
              <div
                className={styles.detailsIcon}
                style={{ display: isSelected ? 'none' : 'block' }}
                onClick={() => {
                  navigate(`/case-chat/${currentCase?.id}/${item?.sid}`);
                }}
              >
                <IoIosArrowDroprightCircle />
              </div>
            )}
          </span>
        ) : (
          <span className={`fs-unmask ${styles.conversationType}`}>
            Read Only
            {isMobile && (
              <div
                className={styles.detailsIcon}
                style={{ display: isSelected ? 'none' : 'block' }}
                onClick={() => {
                  navigate(`/case-chat/${currentCase?.id}/${item?.sid}`);
                }}
              >
                <IoIosArrowDroprightCircle />
              </div>
            )}
          </span>
        )}
        {remoteParticipant?.map((remoteParticipantSingle) => (
          <div
            className={`${styles.participantDivInfo} ${
              remoteParticipantSingle.status !== 'Active' ? styles.inactiveParticipant : ''
            }`}
          >
            <span className={`${styles.profileImageDate} ${styles.imageContainer}`}>
              {userInfo.role.name === UserRoleName.CareAdvisor &&
                conversationType === `${UserRoleName.Provider}_${UserRoleName.CareAdvisor}` &&
                remoteParticipantSingle.role.name === UserRoleName.Provider &&
                currentCase &&
                hasUnreadMessageInPrivate(currentCase, currentCase?.provider_id) && (
                  <span className={styles.unreasMessage} />
                )}
              {userInfo.role.name === UserRoleName.CareAdvisor &&
                conversationType === `${UserRoleName.Patient}_${UserRoleName.CareAdvisor}` &&
                (remoteParticipantSingle.role.name === UserRoleName.Patient ||
                  remoteParticipantSingle.role.name === UserRoleName.Dependent) &&
                currentCase &&
                hasUnreadMessageInPatientCA(
                  currentCase,
                  currentCase.patient.guardian_relationship === null
                    ? currentCase.patient_id
                    : currentCase.patient.guardian_id!,
                ) && <span className={styles.unreasMessage} />}
              {userInfo.role.name === UserRoleName.CareAdvisor &&
                conversationType === `${UserRoleName.Patient}_${UserRoleName.Provider}` &&
                (remoteParticipantSingle.role.name === UserRoleName.Patient ||
                  remoteParticipantSingle.role.name === UserRoleName.Dependent) &&
                currentCase &&
                hasUnreadMessageInPatientProvider(
                  currentCase,
                  currentCase.patient.guardian_relationship === null
                    ? currentCase.patient_id
                    : currentCase.patient.guardian_id!,
                ) && <span className={styles.unreasMessage} />}
              {userInfo.role.name === UserRoleName.CareAdvisor &&
                conversationType === `${UserRoleName.Patient}_${UserRoleName.CareAdvisor}` &&
                remoteParticipantSingle.role.name === UserRoleName.Provider &&
                currentCase &&
                hasUnreadMessageInPatientCA(currentCase, currentCase?.provider_id) && (
                  <span className={styles.unreasMessage} />
                )}
              {userInfo.role.name === UserRoleName.CareAdvisor &&
                conversationType === `${UserRoleName.Patient}_${UserRoleName.Provider}` &&
                remoteParticipantSingle.role.name === UserRoleName.Provider &&
                currentCase &&
                hasUnreadMessageInPatientProvider(currentCase, currentCase?.provider_id) && (
                  <span className={styles.unreasMessage} />
                )}
              {userInfo.role.name === UserRoleName.Provider &&
                conversationType === `${UserRoleName.Patient}_${UserRoleName.CareAdvisor}` &&
                (remoteParticipantSingle.role.name === UserRoleName.Patient ||
                  remoteParticipantSingle.role.name === UserRoleName.Dependent) &&
                currentCase &&
                hasUnreadMessageInPatientCA(
                  currentCase,
                  currentCase.patient.guardian_relationship === null
                    ? currentCase.patient_id
                    : currentCase.patient.guardian_id!,
                ) && <span className={styles.unreasMessage} />}

              {userInfo.role.name === UserRoleName.Provider &&
                conversationType === `${UserRoleName.Patient}_${UserRoleName.Provider}` &&
                (remoteParticipantSingle.role.name === UserRoleName.Patient ||
                  remoteParticipantSingle.role.name === UserRoleName.Dependent) &&
                currentCase &&
                hasUnreadMessageInPatientProvider(
                  currentCase,
                  currentCase.patient.guardian_relationship === null
                    ? currentCase.patient_id
                    : currentCase.patient.guardian_id!,
                ) && <span className={styles.unreasMessage} />}

              {userInfo.role.name === UserRoleName.Provider &&
                conversationType === `${UserRoleName.Patient}_${UserRoleName.CareAdvisor}` &&
                remoteParticipantSingle.role.name === UserRoleName.CareAdvisor &&
                currentCase &&
                hasUnreadMessageInPatientCA(currentCase, currentCase?.care_advisor_id) && (
                  <span className={styles.unreasMessage} />
                )}
              {userInfo.role.name === UserRoleName.Provider &&
                conversationType === `${UserRoleName.Patient}_${UserRoleName.Provider}` &&
                remoteParticipantSingle.role.name === UserRoleName.CareAdvisor &&
                currentCase &&
                hasUnreadMessageInPatientProvider(currentCase, currentCase?.care_advisor_id) && (
                  <span className={styles.unreasMessage} />
                )}
              {userInfo.role.name === UserRoleName.Provider &&
                conversationType === `${UserRoleName.Provider}_${UserRoleName.CareAdvisor}` &&
                remoteParticipantSingle.role.name === UserRoleName.CareAdvisor &&
                currentCase &&
                hasUnreadMessageInPrivate(currentCase, currentCase?.care_advisor_id) && (
                  <span className={styles.unreasMessage} />
                )}
              {userInfo.role.name === UserRoleName.Patient &&
                remoteParticipantSingle.role.name === UserRoleName.Provider &&
                currentCase &&
                hasUnreadMessageInPatientProvider(currentCase, currentCase?.provider_id) && (
                  <span className={styles.unreasMessage} />
                )}

              {userInfo.role.name === UserRoleName.Patient &&
                remoteParticipantSingle.role.name === UserRoleName.CareAdvisor &&
                currentCase &&
                hasUnreadMessageInPatientCA(currentCase, currentCase?.care_advisor_id) && (
                  <span className={styles.unreasMessage} />
                )}
              <img
                alt="Avatar"
                onError={(event: any) => {
                  event.target.src =
                    'https://media.istockphoto.com/id/1130884625/tr/vekt%C3%B6r/kullan%C4%B1c%C4%B1-%C3%BCyesi-vekt%C3%B6r-simgesi-ui-kullan%C4%B1c%C4%B1-arabirimi-veya-profil-face-avatar-uygulamas%C4%B1.jpg?s=612x612&w=0&k=20&c=jAf5nq1ebnZo8TJbjaB9dMHMvgff7uOk67NkF5CpgB0=';
                  event.target.onError = null;
                }}
                className={`fs.exclude ${styles.profileImage}`}
                src={getUserAvatar(
                  Number(
                    remoteParticipantSingle.role.name !== UserRoleName.Dependent
                      ? remoteParticipantSingle?.id
                      : remoteParticipantSingle.guardian_id,
                  ),
                )}
              />
            </span>
            <div className={`${styles.details} ${isMobile && styles.mobileCaseMessageInfo}`}>
              <div className={styles.detailsNameImage}>
                <span className={`${styles.participantName} fs.exclude ${!isSelected ? styles.primaryColor : ''}`}>
                  {remoteParticipantSingle.role.name === 'Provider' && <span style={{ paddingRight: '4px' }}>Dr.</span>}
                  {remoteParticipantSingle.role.name !== UserRoleName.Dependent
                    ? remoteParticipantSingle.full_name
                    : remoteParticipantSingle.guardian.full_name}
                </span>
              </div>
              <div className={styles.relation}>
                {remoteParticipantSingle.role.name === UserRoleName.Dependent && (
                  <span className='fs.exclude'>
                    {`${remoteParticipantSingle.guardian_relationship} of `}
                    <span className={styles.relationSpan}>{remoteParticipantSingle.full_name}</span>
                  </span>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

CaseMessageInfo.defaultProps = {
  isMobile: false,
};
export default CaseMessageInfo;
