/* eslint-disable react/require-default-props */
import { confirmResetPassword } from '@brands/services/auth/confirmResetPassword';
import { LoginResponse } from '@brands/services/auth/login';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { TbEye } from 'react-icons/tb';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import closeModalIcon from '../../assets/icons/close.svg';
import PWDRequisite from '../../Components/PasswordValidation/PasswordValidation';
import { customStylesForgotPassword } from '../../Utils/customStyles';
import { displayErrorDetails } from '../../Utils/displayError';
import { getSecondaryColor } from '../../Utils/getPrimaryColor';
import { hasCapsLetter, hasNumber, hasSpecialChar } from '../../Utils/validation';
import styles from './styles.module.scss';

type ForgotPasswordCredentials = {
  username?: string;
  code: string;
  new_password: string;
  reset_password_hash?: string;
};

interface ForgotPasswordModalStepThreeProps {
  forgotPasswordStep: number;
  setForgotPasswordStep: (num: number) => void;
  credentials: ForgotPasswordCredentials;
  setCredentials: (credentials: ForgotPasswordCredentials) => void;
  resetFlag: boolean;
  triggerLogin?: (response: LoginResponse) => Promise<void>;
}

type ForgotPasswordModalStepThreeSubmitForm = {
  email?: string;
  phoneNumber?: string;
  password?: string;
  confirmPassword?: string;
};

const ForgotPasswordModalStepThree: FC<ForgotPasswordModalStepThreeProps> = ({
  forgotPasswordStep,
  setForgotPasswordStep,
  credentials,
  setCredentials,
  resetFlag,
  triggerLogin,
}) => {
  const [passwordShown, setPasswordShown] = useState(false);
  const [repeatPasswordShown, setRepeatPasswordShown] = useState(false);
  const [passwordStr, setPasswordStr] = useState('');
  const [confPasswordStr, setConfPasswordStr] = useState('');
  const [PWDRequisiteFlag, setPWDRequisiteFlag] = useState(false);
  const [checks, setChecks] = useState({
    capsLetterCheck: false,
    numberCheck: false,
    pwdLengthCheck: false,
    specialFlag: false,
  });

  const closeModal = (): void => {
    setForgotPasswordStep(0);
  };

  useEffect(() => {
    if (forgotPasswordStep === 3) {
      document.body.classList.add('modal-open');
    } else if (document.body.classList.contains('modal-open') && forgotPasswordStep === 0) {
      document.body.classList.remove('modal-open');
    }
  }, [forgotPasswordStep]);

  const onSubmit = async (data: ForgotPasswordModalStepThreeSubmitForm): Promise<void> => {
    if (!credentials.reset_password_hash && (!credentials.code || !credentials.username)) {
      toast.error('Something went wrong. Please contact support.');
      return;
    }
    try {
      await confirmResetPassword({
        reset_password_hash: credentials.reset_password_hash,
        email: credentials.username,
        verification_code: credentials.code,
        new_password: data.password as string,
      })
        .then(async (res) => {
          if (triggerLogin) {
            await triggerLogin(res as LoginResponse);
          } else {
            setForgotPasswordStep(forgotPasswordStep + 1);
          }
          setCredentials({
            username: '',
            code: '',
            new_password: '',
          });
        })
        .catch((error) => {
          displayErrorDetails(error);
          setCredentials({
            username: credentials.username,
            code: '',
            new_password: '',
          });
          setForgotPasswordStep(0);
        });
    } catch (error: unknown) {
      displayErrorDetails(error);
      setCredentials({
        username: credentials.username,
        code: '',
        new_password: '',
      });
      setForgotPasswordStep(0);
    }
  };

  const togglePassword = (): void => {
    setPasswordShown(!passwordShown);
  };

  const toggleRepeatPassword = (): void => {
    setRepeatPasswordShown(!repeatPasswordShown);
  };

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required()
      .min(8)
      .matches(/^(?=.*[A-Z])(?=.*[0-9])(?=.*[><?@_+'`~^%&\*\[\]\{\}.!#|\\\"$';,:;=/\(\),\-])(?=.{8,})/),
    confirmPassword: Yup.string()
      .required('Confirm Password is required')
      .oneOf([Yup.ref('password'), null], 'Passwords do not match'),
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    getValues,
  } = useForm<ForgotPasswordModalStepThreeSubmitForm>({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
  });

  const handleOnFocus = (): void => {
    if (passwordStr.trim().length !== 0) {
      setPWDRequisiteFlag(true);
    } else {
      setPWDRequisiteFlag(false);
    }
  };

  const handleOnBlur = (): void => {
    if (passwordStr.trim().length !== 0) {
      setPWDRequisiteFlag(true);
    } else {
      setPWDRequisiteFlag(false);
    }
  };
  const handleOnChangeConfirm = (e: React.ChangeEvent<HTMLInputElement>): void => {
    errors.confirmPassword = undefined;
    const { value } = e.target;
    setConfPasswordStr(value);
    setValue('confirmPassword', value, { shouldValidate: false });
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    errors.confirmPassword = undefined;
    setPWDRequisiteFlag(true);
    const { value } = e.target;
    setValue('password', value, { shouldValidate: true });
    const capsLetterCheck = hasCapsLetter(value);
    const numberCheck = hasNumber(value);
    const specialFlag = hasSpecialChar(value);
    const pwdLengthCheck = value.length >= 8;
    setChecks({
      capsLetterCheck,
      numberCheck,
      pwdLengthCheck,
      specialFlag,
    });
    setPasswordStr(value);
    if (confPasswordStr !== '') setValue('confirmPassword', confPasswordStr, { shouldValidate: false });
  };

  return (
    <div className={styles.container}>
      <Modal
        isOpen={forgotPasswordStep === 3}
        onRequestClose={closeModal}
        style={customStylesForgotPassword}
        contentLabel="Modal"
        ariaHideApp={false}
        className={`${styles.wrapper} ${styles.forgotPasswordModal}`}
        shouldCloseOnOverlayClick={false}
      >
        <button
          type="button"
          className={styles.modalClose}
          aria-label="Close modal"
          onClick={closeModal}
          style={{ backgroundImage: `url(${closeModalIcon})` }}
        />
        <form className={styles.formContainer} onSubmit={handleSubmit(onSubmit)}>
          <span className={`${styles.formTitle} p-b-26 flex flex-column justify-content-center align-items-center`}>
            <span className={styles.formTitleForgot}>{resetFlag ? 'Reset Password' : 'Forgot Password'}</span>
            <div className={`${styles.formTitleLink} pt-1`}>
              <p className={`${styles.txt1} txt1 mb-0`}>Create your strong password</p>
            </div>
          </span>
          <div>
            <div className={styles.inputWrapper} data-validate="Enter password">
              <span className={styles.btnShowPass}>
                {passwordShown ? (
                  <TbEye
                    className={styles.eyeIcon}
                    style={{ color: '#276fe7' }}
                    title="Hide Password"
                    onClick={togglePassword}
                  />
                ) : (
                  <TbEye
                    className={styles.eyeIcon}
                    style={{ color: getSecondaryColor() }}
                    title="Show Password"
                    onClick={togglePassword}
                  />
                )}
              </span>
              <input
                {...register('password')}
                className={`${styles.input100} ${errors.password ? 'is-invalid' : ''}`}
                type={passwordShown ? 'text' : 'password'}
                name="password"
                placeholder="New Password"
                onChange={handleOnChange}
                onFocus={handleOnFocus}
                onBlur={handleOnBlur}
                style={{ paddingRight: '24px' }}
              />
              <span className={`${styles.focusInput100} ${styles.password}`} />
              {PWDRequisiteFlag ? (
                <PWDRequisite
                  capsLetterFlag={checks.capsLetterCheck ? 'valid' : 'invalid'}
                  numberFlag={checks.numberCheck ? 'valid' : 'invalid'}
                  pwdLengthFlag={checks.pwdLengthCheck ? 'valid' : 'invalid'}
                  specialFlag={checks.specialFlag ? 'valid' : 'invalid'}
                />
              ) : (
                <>
                  <div className={`${styles.invalidFeedback} invalid-feedback`}>Password is required</div>
                  {!errors.password?.message && (
                    <p className={styles.passwordHint}>
                      ● 8 characters &nbsp;● 1 uppercase &nbsp;● 1 number &nbsp;● 1 special character
                    </p>
                  )}
                </>
              )}
            </div>
          </div>
          <div className={styles.inputWrapper} data-validate="Enter password" style={{ marginTop: '40px' }}>
            <span className={styles.btnShowPass}>
              {repeatPasswordShown ? (
                <TbEye
                  className={styles.eyeIcon}
                  style={{ color: '#276fe7' }}
                  title="Hide Password"
                  onClick={toggleRepeatPassword}
                />
              ) : (
                <TbEye
                  className={styles.eyeIcon}
                  style={{ color: getSecondaryColor() }}
                  title="Show Password"
                  onClick={toggleRepeatPassword}
                />
              )}
            </span>
            <input
              {...register('confirmPassword')}
              className={`${styles.input100} ${errors.confirmPassword ? 'is-invalid' : ''}`}
              type={repeatPasswordShown ? 'text' : 'password'}
              name="confirmPassword"
              placeholder="Confirm Password"
              onChange={handleOnChangeConfirm}
              value={getValues('confirmPassword')}
              style={{ paddingRight: '24px' }}
            />
            {errors.confirmPassword && (
              <div className={`${styles.invalidFeedback} invalid-feedback`}>{errors.confirmPassword.message}</div>
            )}
          </div>
          <div className={styles.buttonContainer}>
            <div className={styles.buttonWrapper}>
              <div className={styles.buttonBG} />
              <button
                type="submit"
                className={`${styles.formBtn} ${styles.signIn}`}
                disabled={
                  errors.confirmPassword !== undefined ||
                  errors.password !== undefined ||
                  passwordStr === '' ||
                  confPasswordStr === '' ||
                  passwordStr !== confPasswordStr
                }
              >
                Submit
              </button>
            </div>
          </div>
        </form>
      </Modal>
    </div>
  );
};

Modal.setAppElement('#root');

export default ForgotPasswordModalStepThree;
