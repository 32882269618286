import { FollowUp, PharmacySubmit } from '@brands/Dashboard/ConsultationPage/assets/types';

import { client } from '../client';
import { IEducation } from './types/IEducation';
import { IOneReport } from './types/IOneReport';
import { IPrescriptionOptoinal, ITreatmentsOptoinal, ObjectType } from './updateOneReportObject';

export const addOneReportObject = async (
  one_report_id: string,
  object_type: ObjectType,
  payload: ITreatmentsOptoinal | IPrescriptionOptoinal | IEducation | FollowUp | PharmacySubmit,
): Promise<IOneReport> => {
  const { data } = await client.post<IOneReport>(`/one_reports/${one_report_id}/${object_type}`, payload);

  return data;
};
