import Button from '@brands/Components/Button/Button';
import { ObjectType } from '@brands/services/careCoordinationReport/types/types';
import React from 'react';
import { FieldErrors, UseFormRegister, UseFormSetValue, UseFormTrigger } from 'react-hook-form';

import { ICase } from '../../../../services/cases/types/ICase';
import { UserProfile } from '../../../../services/identity/types/UserProfile';
import { formatDate } from '../../../../Utils/formatDate';
import { checkCollapsibleHeaderColor } from '../../../../Utils/getCollapsibleHeader';
import { getUserRoleName } from '../../../../Utils/getFriendlyName';
import { OralHealthReportSubmit } from '../../assets/types';
import styles from '../../styles.module.scss';

type InternalCasesNotesSectionProps = {
  consultationForm: OralHealthReportSubmit;
  setConsultationForm: React.Dispatch<React.SetStateAction<OralHealthReportSubmit>>;
  thisCase: ICase;
  userInfo: UserProfile;
  register: UseFormRegister<OralHealthReportSubmit>;
  setValue: UseFormSetValue<OralHealthReportSubmit>;
  trigger: UseFormTrigger<OralHealthReportSubmit>;
  errors: FieldErrors<OralHealthReportSubmit>;
  saveObjectType: (note: string, objectType: ObjectType) => Promise<void>;
};

const InternalCasesNotesSection = ({
  consultationForm,
  setConsultationForm,
  thisCase,
  userInfo,
  register,
  setValue,
  trigger,
  errors,
  saveObjectType,
}: InternalCasesNotesSectionProps): JSX.Element => {
  const saveNote = (caseId: number, note: string): void => {
    const notes = JSON.parse(localStorage.getItem('internal_note') || '{}');
    notes[caseId] = note;
    localStorage.setItem('internal_note', JSON.stringify(notes));
  };

  const handleChangeInternalNote = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
    const caseId = thisCase.id;
    const note = event.target.value;
    saveNote(caseId, note);
  };

  const submitInternalNote = (note: string, objectType: ObjectType): void => {
    saveObjectType(note, objectType);
  };
  return (
    <>
      <div className={styles.caseNotes}>
        <div className={styles.caseNotesTitle}>
          <span className='fs-unmask'>Internal case notes are not shared with the patient</span>
        </div>
        {thisCase?.care_coordination_report?.internal_notes &&
          thisCase?.care_coordination_report?.internal_notes.length > 0 &&
          thisCase?.care_coordination_report?.internal_notes
            .sort((a, b) => {
              const dateA = new Date(a.created_at);
              const dateB = new Date(b.created_at);

              // Check for invalid date strings
              if (isNaN(dateA.getTime()) || isNaN(dateB.getTime())) {
                return 0;
              }

              return dateA.getTime() - dateB.getTime();
            })
            .map((note) => (
              <div className={`fs-exclude ${styles.caseNotesContent}`} key={note.id}>
                <div className={styles.caseNotesContentMeta}>
                  <b>{note.created_by.full_name} </b>
                  <b> ({getUserRoleName(note.created_by.role.name)}) </b>
                  {note.last_edited_at === null
                    ? formatDate(note.created_at)
                    : formatDate(note.last_edited_at as string)}
                  <br />
                </div>
                <span className={styles.caseNotesContentText}>{note.note}</span>
                <hr className={styles.ohrHr} />
              </div>
            ))}
      </div>
      {checkCollapsibleHeaderColor(thisCase || '', userInfo, 'careCoordination') !== 'readOnly' && (
        <>
          <textarea
            {...register('internal_note')}
            readOnly={checkCollapsibleHeaderColor(thisCase || '', userInfo, 'careCoordination') === 'readOnly'}
            className={`fs-exclude ${styles.caseNotesInput}`}
            placeholder="Add internal case notes here"
            value={consultationForm.internal_note}
            onChange={(e) => {
              setConsultationForm({ ...consultationForm, internal_note: e.target.value });
              setValue('internal_note', e.target.value);
              handleChangeInternalNote(e);
              if (errors?.internal_note) {
                trigger('internal_note');
              }
            }}
          />

          <Button
            type="button"
            className={`${styles.proviverListBtn} fs-unmask ${styles.assignAprovider} ${styles.caseNotesButton}`}
            onClick={() => submitInternalNote(consultationForm?.internal_note as string, 'internal_note')}
            disabled={consultationForm?.internal_note?.trim() === ''}
          >
            Save Internal Note
          </Button>
        </>
      )}
    </>
  );
};

export default React.memo(InternalCasesNotesSection);
