import Container from '@brands/Components/Container/Container';
import ProgressBar from '@brands/Components/ProgressBar/ProgressBar';
import { config } from '@brands/config/config';
import { useAppSelector } from '@brands/hooks/useReduxHook';
import useWindowSize from '@brands/hooks/useWindowSize';
import { selectSmileScanCaseId } from '@brands/store/selectors/smileScanCase';
import React, { useEffect, useState } from 'react';
import QRCode from 'react-qr-code';
import { useNavigate } from 'react-router-dom';

import styles from './styles.module.scss';

const SmileScanWeb = (): JSX.Element => {
  const screenSize = useWindowSize();
  const navigate = useNavigate();
  const smileScanCaseId = useAppSelector(selectSmileScanCaseId);
  const [qrCodeImageUrl, setQrCodeImageUrl] = useState<string | null>(null);

  useEffect(() => {
    if (smileScanCaseId) {
      let prefix = '';
      if (config.iot.prefix === 'develop') {
        prefix = 'dev-';
      } else if (config.iot.prefix === 'qa') {
        prefix = 'qa-';
      }
      const url = `https://selfcheck.toothlens.com/tpscan/done/${prefix}${smileScanCaseId.case_id}`;
      setQrCodeImageUrl(url);
    }
  }, [smileScanCaseId]);

  return (
    <Container
      isBGEclipse={false}
      patientDashboard
      childClassName={styles.dashboard}
      containerStyle={{
        justifyContent: screenSize.width >= 1289 ? 'center' : 'start',
        overflowY: screenSize.width >= 1289 ? 'hidden' : 'scroll',
      }}
    >
      <div className={styles.smileScanWeb}>
        <ProgressBar currentStep={0} totalSteps={0} hideStepNumbers onBack={() => navigate(-1)} />
        <span className={`fs-unmask ${styles.smileScanWebHeader}`}>AI SmileScan </span>
        <div className={styles.smileScanWebContent}>
          {qrCodeImageUrl ? <QRCode value={qrCodeImageUrl} size={200} /> : <p>Loading QR Code...</p>}
          <div className='fs-unmask'>
            <span className={`${styles.smileScanWebText}`}>To complete the AI SmileScan you will </span>
            <span className={styles.smileScanWebText}>need to continue from a phone.</span>
          </div>
          <span className={`fs-unmask ${styles.smileScanWebSub}`}>Scan the QR code or use the unique URL below to get started.</span>
          <span className='fs-unmask'>{qrCodeImageUrl}</span>
          <span className='fs-unmask'>
            When you've finished submitting your pictures{' '}
            <a className={styles.backToDashboardLink} onClick={() => navigate('/dashboard')}>
              click here
            </a>{' '}
            to go back to the dashboard
          </span>
        </div>
      </div>
    </Container>
  );
};
export default React.memo(SmileScanWeb);
