import PhoneInput from '@brands/Components/Inputs/PhoneInput/PhoneInput';
import SelectInput from '@brands/Components/Inputs/SelectInput/SelectInput';
import TextField from '@brands/Components/Inputs/TextField/TextField';
import useInsuranceCarriers from '@brands/hooks/useInsuranceCarriers';
import { checkAndSetDate } from '@brands/Utils/checkAndSetDate';
import { profileSelectStyles, selectCommonCustomStylesLarge } from '@brands/Utils/customStyles';
import { getFriendlyNameInsuredBy } from '@brands/Utils/getFriendlyName';
import { genderIdentity } from '@brands/Utils/selectButtonOptions';
import { insuranceThroughOptions, stateOptions } from '@brands/Utils/selectOptions';
import React, { useMemo, useRef } from 'react';
import { FieldErrors, UseFormGetValues, UseFormRegister } from 'react-hook-form';

// import { PatientFormFiveSubmit } from '../../../PatientForms/PatientForm/utils/types';
import styles from './styles.module.scss';

interface DashboardHeaderProps {
  getValues: UseFormGetValues<any>;
  register: UseFormRegister<any>;
  errors: FieldErrors<any>;
  onChange: (key: string, value: string | number) => void;
}

const SecondaryInsuranceFields: React.FC<DashboardHeaderProps> = ({ getValues, register, errors, onChange }) => {
  const insuranceCarriers = useInsuranceCarriers();
  const secondaryPhoneInputRef = useRef<HTMLInputElement>(null);
  const insuranceCarrierOptions = useMemo(() => {
    return insuranceCarriers.map((insuranceCarrier) => ({
      value: insuranceCarrier.id,
      label: insuranceCarrier.name,
    }));
  }, [insuranceCarriers]);
  return (
    <div className={styles.insuranceContainer}>
      <div className={styles.formContainer}>
        <div className={styles.wrapper}>
          <div className={styles.formContent}>
            <div className="col col-md-6">
              <div className={styles.inputWrap}>
                <div>
                  <span className='fs-unmask'>
                    I’m insured by{' '}
                    {getValues('secondary_insurance_through') !== 'none' &&
                    getValues('secondary_insurance_through') !== undefined
                      ? getFriendlyNameInsuredBy(getValues('secondary_insurance_through'))
                      : ''}
                  </span>
                  <span className={styles.redText}> *</span>
                </div>
                <SelectInput
                  {...register('secondary_insurance_through')}
                  containerClass={`fs-exclude ${styles.inputWrapper}`}
                  selectorClass={styles.selector}
                  name="secondary_insurance_through"
                  options={insuranceThroughOptions}
                  style={profileSelectStyles}
                  errors={errors}
                  selectedValue={getValues('secondary_insurance_through') || ''}
                  showNoInsuranceOption={false}
                  onChange={(value) => {
                    onChange('secondary_insurance_through', value?.value as string);
                  }}
                />
              </div>
            </div>
            {getValues('secondary_insurance_through') !== 'none' &&
              getValues('secondary_insurance_through') !== undefined && (
                <>
                  <div className="row">
                    <div className="col">
                      <div className={styles.inputWrap}>
                        <div>
                          <span className='fs-unmask'>Insurance company</span>
                          <span className={styles.redText}> *</span>
                        </div>
                        <SelectInput
                          {...register('secondary_insurance_carrier_id')}
                          name="secondary_insurance_carrier_id"
                          options={insuranceCarrierOptions}
                          containerClass={`fs-exclude ${styles.inputWrapper}`}
                          selectorClass={styles.selector}
                          style={profileSelectStyles}
                          errors={errors}
                          selectedValue={getValues('secondary_insurance_carrier_id') || ''}
                          onChange={(value) => {
                            if (value) {
                              onChange('secondary_insurance_carrier_id', value?.value as number);
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <div className={styles.inputWrap}>
                        <div>
                          <span className='fs-unmask'>Member ID</span>
                          <span className={styles.redText}> *</span>
                        </div>
                        <TextField
                          {...register('secondary_member_id')}
                          errors={errors}
                          name="secondary_member_id"
                          value={getValues('secondary_member_id')}
                          onChange={(e) => {
                            onChange('secondary_member_id', e.target.value);
                          }}
                          wrapperStyle={{ marginTop: '0', height: '100%', borderBottom: 'none' }}
                          wrapperClassName={`fs-exclude`}
                          inputClass={styles.profileInputClass}
                          isUnderlined={false}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row" style={{ paddingBottom: '20px' }}>
                    <div className="col">
                      <div className={styles.inputWrap}>
                        <div>
                          <span className='fs-unmask'>Group Number</span> <span className={styles.redText}> *</span>
                        </div>
                        <TextField
                          {...register('secondary_group_number')}
                          errors={errors}
                          name="secondary_group_number"
                          value={getValues('secondary_group_number')}
                          onChange={(e) => {
                            onChange('secondary_group_number', e.target.value);
                          }}
                          wrapperStyle={{ marginTop: '0', height: '100%', borderBottom: 'none' }}
                          wrapperClassName={`fs-exclude`}
                          inputClass={styles.profileInputClass}
                          isUnderlined={false}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row" style={{ paddingBottom: '20px' }}>
                    <div className="col">
                      <div className={styles.inputWrap}>
                        <div>
                          <span className='fs-unmask'>
                            Phone number at the back of your insurance card (if multiple, use the provider number)
                          </span>
                          <span className={styles.redText}> *</span>
                        </div>
                        <PhoneInput
                          {...register('secondary_iPhoneNumber')}
                          value={getValues('secondary_iPhoneNumber')}
                          id="secondary_iPhoneNumber"
                          onChange={(e) => {
                            onChange('secondary_iPhoneNumber', e as string);
                          }}
                          inputClass={styles.input}
                          errors={errors}
                          wrapperClassName={`fs-exclude ${styles.inputWrapper}`}
                          ref={secondaryPhoneInputRef}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <div className={styles.inputWrap}>
                        <div>
                          <span className='fs-unmask'>Insurance Address</span>
                          <span className={styles.redText}> *</span>
                        </div>
                        <TextField
                          {...register('secondary_insurance_address')}
                          errors={errors}
                          name="secondary_insurance_address"
                          value={getValues('secondary_insurance_address')}
                          onChange={(e) => {
                            const { value } = e.target;
                            onChange('secondary_insurance_address', value);
                          }}
                          wrapperClassName={`fs-exclude ${styles.inputWrapper}`}
                          inputClass={styles.input}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <div className={styles.inputWrap}>
                        <div>
                          <span className='fs-unmask'>Insurance City</span>
                          <span className={styles.redText}> *</span>
                        </div>
                        <TextField
                          {...register('secondary_insurance_city')}
                          errors={errors}
                          name="secondary_insurance_city"
                          value={getValues('secondary_insurance_city')}
                          onChange={(e) => {
                            const { value } = e.target;
                            onChange('secondary_insurance_city', value);
                          }}
                          wrapperClassName={`fs-exclude ${styles.inputWrapper}`}
                          inputClass={styles.input}
                        />
                      </div>
                    </div>
                    <div className="col">
                      <div className={styles.inputWrap}>
                        <div>
                          <span className='fs-unmask'>Insurance State</span>
                          <span className={styles.redText}> *</span>
                        </div>
                        <SelectInput
                          {...register('secondary_insurance_state')}
                          name="secondary_insurance_state"
                          options={stateOptions}
                          containerClass={`fs-exclude ${styles.inputWrapper}`}
                          selectorClass={styles.selector}
                          style={selectCommonCustomStylesLarge}
                          errors={errors}
                          selectedValue={getValues('secondary_insurance_state')}
                          onChange={(value) => {
                            if (value) {
                              onChange('secondary_insurance_state', value.value);
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div className="col">
                      <div className={styles.inputWrap}>
                        <div>
                          <span className='fs-unmask'>Insurance Zip Code</span>
                          <span className={styles.redText}> *</span>
                        </div>
                        <TextField
                          {...register('secondary_insurance_zip_code')}
                          errors={errors}
                          name="secondary_insurance_zip_code"
                          value={getValues('secondary_insurance_zip_code')}
                          onChange={(e) => {
                            const { value } = e.target;
                            onChange('secondary_insurance_zip_code', value);
                          }}
                          wrapperClassName={`fs-exclude ${styles.inputWrapper}`}
                          inputClass={styles.input}
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}
            {getValues('secondary_insurance_through') &&
              getValues('secondary_insurance_through') !== 'myself' &&
              getValues('secondary_insurance_through') !== 'none' &&
              getValues('secondary_insurance_through') !== undefined && (
                <div className={styles.insuranceThroughContainer}>
                  <div className="row">
                    <div className="col-12 col-md-4">
                      <div className={styles.inputWrap}>
                        <div>
                          <span className='fs-unmask'>Insurance Holder’s First Name</span>
                          <span className={styles.redText}> *</span>
                        </div>
                        <TextField
                          {...register('secondary_iFirstName')}
                          errors={errors}
                          name="secondary_iFirstName"
                          value={getValues('secondary_iFirstName')}
                          onChange={(e) => {
                            onChange('secondary_iFirstName', e.target.value);
                          }}
                          wrapperClassName={`fs-exclude`}
                          wrapperStyle={{ marginTop: '0', height: '100%', borderBottom: 'none' }}
                          inputClass={styles.profileInputClass}
                          isUnderlined={false}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-4">
                      <div className={styles.inputWrap}>
                        <div>
                          <span className='fs-unmask'>Insurance Holder’s Middle Name</span>
                        </div>
                        <TextField
                          {...register('secondary_iMiddleName')}
                          errors={errors}
                          name="secondary_iMiddleName"
                          value={getValues('secondary_iMiddleName')}
                          onChange={(e) => {
                            onChange('secondary_iMiddleName', e.target.value);
                          }}
                          wrapperStyle={{ marginTop: '0', height: '100%', borderBottom: 'none' }}
                          wrapperClassName={`fs-exclude`}
                          inputClass={styles.profileInputClass}
                          isUnderlined={false}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-4">
                      <div className={styles.inputWrap}>
                        <div>
                          <span className='fs-unmask'>Insurance Holder’s Last Name</span>
                          <span className={styles.redText}> *</span>
                        </div>
                        <TextField
                          {...register('secondary_iLastName')}
                          errors={errors}
                          name="secondary_iLastName"
                          value={getValues('secondary_iLastName')}
                          onChange={(e) => {
                            onChange('secondary_iLastName', e.target.value);
                          }}
                          wrapperStyle={{ marginTop: '0', height: '100%', borderBottom: 'none' }}
                          wrapperClassName={`fs-exclude`}
                          inputClass={styles.profileInputClass}
                          isUnderlined={false}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className={styles.inputWrap}>
                        <div>
                          <span className='fs-unmask'>Insurance Holder’s Date of Birth</span>
                          <span className={styles.redText}> *</span>
                        </div>
                        <TextField
                          {...register('secondary_iDateOfBirth')}
                          errors={errors}
                          name="secondary_iDateOfBirth"
                          placeholder="MM / DD / YYYY"
                          value={getValues('secondary_iDateOfBirth')}
                          onChange={(e) => {
                            onChange('secondary_iDateOfBirth', checkAndSetDate(e));
                          }}
                          maxLength={10}
                          wrapperStyle={{ marginTop: '0', height: '100%', borderBottom: 'none' }}
                          wrapperClassName={`fs-exclude`}
                          inputClass={styles.profileInputClass}
                          isUnderlined={false}
                          enableCursorPos={false}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className={styles.inputWrap}>
                        <div>
                          <span className='fs-unmask'>Insurance Holder’s Gender Identity</span>
                        </div>
                        <SelectInput
                          {...register('secondary_iGenderIdentity')}
                          name="secondary_iGenderIdentity"
                          options={genderIdentity}
                          containerClass={`fs-exclude ${styles.inputWrapper}`}
                          selectorClass={styles.selector}
                          style={selectCommonCustomStylesLarge}
                          errors={errors}
                          selectedValue={getValues('secondary_iGenderIdentity')}
                          onChange={(value) => {
                            if (value) {
                              onChange('secondary_iGenderIdentity', value.value);
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className={styles.inputWrap}>
                        <div>
                          <span className='fs-unmask'>Insurance Holder’s Street Address</span>
                          <span className={styles.redText}> *</span>
                        </div>
                        <TextField
                          {...register('secondary_iAddress1')}
                          errors={errors}
                          name="secondary_iAddress1"
                          value={getValues('secondary_iAddress1')}
                          onChange={(e) => {
                            onChange('secondary_iAddress1', e.target.value);
                          }}
                          wrapperStyle={{ marginTop: '0', height: '100%', borderBottom: 'none' }}
                          wrapperClassName={`fs-exclude`}
                          inputClass={styles.profileInputClass}
                          isUnderlined={false}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className={styles.inputWrap}>
                        <span className='fs-unmask'>Apt, suite, building (optional)</span>
                        <TextField
                          {...register('secondary_iAddress2')}
                          errors={errors}
                          name="secondary_iAddress2"
                          value={getValues('secondary_iAddress2')}
                          onChange={(e) => {
                            onChange('secondary_iAddress2', e.target.value);
                          }}
                          wrapperStyle={{ marginTop: '0', height: '100%', borderBottom: 'none' }}
                          wrapperClassName={`fs-exclude`}
                          inputClass={styles.profileInputClass}
                          isUnderlined={false}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-4">
                      <div className={styles.inputWrap}>
                        <div>
                          <span className='fs-unmask'>City</span>
                          <span className={styles.redText}> *</span>
                        </div>
                        <TextField
                          {...register('secondary_iCity')}
                          errors={errors}
                          name="secondary_iCity"
                          value={getValues('secondary_iCity')}
                          onChange={(e) => {
                            onChange('secondary_iCity', e.target.value);
                          }}
                          wrapperStyle={{ marginTop: '0', height: '100%', borderBottom: 'none' }}
                          wrapperClassName={`fs-exclude`}
                          inputClass={styles.profileInputClass}
                          isUnderlined={false}
                        />
                      </div>
                    </div>
                    <div className="col-4">
                      <div className={styles.inputWrap} style={{ fontWeight: '400' }}>
                        <div>
                          <span className='fs-unmask'>State</span>
                          <span className={styles.redText}> *</span>
                        </div>
                        <SelectInput
                          {...register('secondary_iState')}
                          containerClass="mt-0 fs-exclude"
                          name="secondary_iState"
                          options={stateOptions}
                          style={profileSelectStyles}
                          errors={errors}
                          selectedValue={getValues('secondary_iState')}
                          onChange={(value) => {
                            onChange('secondary_iState', value?.value as string);
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-4">
                      <div className={styles.inputWrap}>
                        <div>
                          <span className='fs-unmask'>Zip Code</span>
                          <span className={styles.redText}> *</span>
                        </div>
                        <TextField
                          {...register('secondary_iZipCode')}
                          errors={errors}
                          name="secondary_iZipCode"
                          value={getValues('secondary_iZipCode')}
                          onChange={(e) => {
                            onChange('secondary_iZipCode', e.target.value);
                          }}
                          wrapperStyle={{ marginTop: '0', height: '100%', borderBottom: 'none' }}
                          wrapperClassName={`fs-exclude`}
                          inputClass={styles.profileInputClass}
                          isUnderlined={false}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SecondaryInsuranceFields;
