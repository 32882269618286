/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react/no-array-index-key */
import { useAppSelector } from '@brands/hooks/useReduxHook';
import { updateUserOrganization } from '@brands/services/identity/updateUserOrganization';
import { selectAuth } from '@brands/store/selectors/auth';
import { trimStringFields } from '@brands/Utils/trimStringFields';
import { UnifiedProfileSubmit } from '@brands/Utils/types';
import { validateAddress, ValidationModalInterface } from '@brands/Utils/validateAddress';
import { yupResolver } from '@hookform/resolvers/yup';
import cn from 'classnames';
import { parsePhoneNumber } from 'libphonenumber-js';
import moment from 'moment';
import React, { FC, KeyboardEventHandler, useEffect, useState } from 'react';
import { useForm, UseFormGetValues } from 'react-hook-form';
import { IoIosAddCircle } from 'react-icons/io';
import { TiDelete } from 'react-icons/ti';
import Modal, { Styles } from 'react-modal';
import { MultiValue } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { toast } from 'react-toastify';

import closeModalIcon from '../../../../../assets/icons/close.svg';
import SuccessAlert from '../../../../../Components/Alert/SuccessAlert';
import Button from '../../../../../Components/Button/Button';
import CustomCheckbox from '../../../../../Components/Inputs/CustomCheckbox/CustomCheckbox';
import MultiselectInput from '../../../../../Components/Inputs/MultiselectInput/MultiselectInput';
import PhoneInput from '../../../../../Components/Inputs/PhoneInput/PhoneInput';
import SelectInput from '../../../../../Components/Inputs/SelectInput/SelectInput';
import TextField from '../../../../../Components/Inputs/TextField/TextField';
import ProfileImage from '../../../../../Components/ProfileImage/ProfileImage';
import SelectButtons from '../../../../../Components/ProfileSelectButtons/SelectButtons';
import { RegisterMediaPayload } from '../../../../../services/communication/registersConversationMedia';
import { confirmAvatarMedia } from '../../../../../services/identity/confirmAvatarMedia';
import { createBasicInformation } from '../../../../../services/identity/createBasicInformation';
import { createNewUser } from '../../../../../services/identity/createNewUser';
import { createProviderLicense } from '../../../../../services/identity/createProviderLicense';
import { createProviderProfile } from '../../../../../services/identity/createProviderProfile';
import { deleteAvatar } from '../../../../../services/identity/deleteAvatar';
import { listAllLanguages } from '../../../../../services/identity/listAllLanguages';
import { listAllOrganizations } from '../../../../../services/identity/listAllOrganizations';
import { listInsuranceCarriers } from '../../../../../services/identity/listInsuranceCarriers';
import { registerAvatarMedia } from '../../../../../services/identity/registerAvatarMedia';
import {
  Address,
  Language,
  Organization,
  ProviderProfile,
  UserProfile,
  UserRoleName,
} from '../../../../../services/identity/types/UserProfile';
import { checkAndSetDate } from '../../../../../Utils/checkAndSetDate';
import {
  customStylesDashboard,
  multiSelectCommonCustomStylesLarge,
  selectCommonCustomStylesLarge,
} from '../../../../../Utils/customStyles';
import { displayErrorDetails } from '../../../../../Utils/displayError';
import { genderIdentity, genderOptions, pronounsOptions } from '../../../../../Utils/selectButtonOptions';
import {
  licenseOptions,
  Option,
  OptionInsurance,
  OptionLanguage,
  specialtyOptions,
  stateOptions,
} from '../../../../../Utils/selectOptions';
import { defaultFormValues } from '../../../../MyProfile/Provider/utils/defaultFromValues';
import { ProviderMyProfileSubmit } from '../../../../MyProfile/Provider/utils/types';
import AddressSection from '../../Components/AddressSection/AddressSection';
import styles from '../styles.module.scss';
import { validationSchema } from './validationSchema';

const components = {
  DropdownIndicator: null,
};

interface CustomStylesDashboard {
  overlay: Styles['overlay'];
  content: Styles['content'];
}

interface ProfileCardModalProps {
  isOpen: boolean;
  toggleModal: (bool: boolean) => void;
}

const AddProviderUserModal: FC<ProfileCardModalProps> = ({ isOpen, toggleModal }) => {
  const { userInfo } = useAppSelector(selectAuth);
  const [myProfileForm, setMyProfileForm] = useState<ProviderMyProfileSubmit>(
    defaultFormValues as ProviderMyProfileSubmit,
  );
  const [insurancePlanName, setInsurancePlanName] = useState<OptionInsurance[]>([]);
  const [currentAvatarURL, setCurrentAvatarURL] = useState<string>('');
  const [avatarFile, setAvatarFile] = useState<File | null>(null);
  const [isProfileSaved] = useState(false);
  const [avatarPayload, setAvatarPayload] = useState<RegisterMediaPayload | null>(null);
  const [selection, setSelection] = React.useState<[number | null, number | null] | null>(null);
  const ref = React.useRef<HTMLInputElement>(null);
  const [languageOptions, setLanguageOptions] = useState<OptionLanguage[]>([]);
  const [organizationOptions, setOrganizationOptions] = useState<Option[]>([]);
  const [avatarAction, setAvatarAction] = useState<string>('');
  const [image, setImage] = React.useState<string>();
  const [isSavingProfile, setIsSavingProfile] = useState(false);
  const [inputValue, setInputValue] = React.useState('');
  const [isSendingInvitation, setIsSendingInvitation] = useState(false);
  const [allOrganization, setAllOrganization] = useState<Organization[]>([]);
  const [validationResponseId, setValidationResponseId] = useState('');
  const [showValidationAddressModal, setShowValidationAddressModal] = useState<ValidationModalInterface>({
    isOpen: false,
    title: '',
    whatYouEntered: '',
    recommended: false,
    recommendedAddress: '',
    onClose: undefined,
    onConfirmChange: undefined,
    onKeepCurrentAddress: undefined,
  });
  const userRoleCustomStylesDashboard: CustomStylesDashboard = {
    ...customStylesDashboard,
    overlay: {
      ...customStylesDashboard.overlay,
      backgroundColor: '#1f2b38f0',
      overflowY: 'scroll',
    },
    content: {
      ...customStylesDashboard.content,
      width: '1166px',
      height: 'auto',
      backgroundColor: '#F0F0F0',
      borderRadius: '11px',
      opacity: '1',
      padding: '40px 100px',
      transform: 'translate(-50%, -16%)',
    },
  };
  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('modal-open');
    } else if (document.body.classList.contains('modal-open') && !isOpen) {
      document.body.classList.remove('modal-open');
    }
  }, [isOpen]);
  const closeModal = (): void => {
    toggleModal(false);
  };

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    getValues,
  } = useForm<ProviderMyProfileSubmit>({
    mode: 'onChange',
    resolver: yupResolver(validationSchema()),
  });

  const getNewID = (id: number): number => {
    const newId = id + 1;
    if (myProfileForm.licences.find((licence) => licence.id === newId)) {
      return getNewID(newId);
    }
    return newId;
  };

  const addLicenseRow = (): void => {
    const newId = getNewID(
      myProfileForm.licences.length > 0 ? myProfileForm.licences[myProfileForm.licences.length - 1].id! : 0,
    );

    const newLicense = {
      id: newId,
      state: '',
      number: '',
      expiry_date: '',
      insurance_carriers: [],
    };

    setMyProfileForm((prev) => ({
      ...getValues(),
      licences: [...prev.licences, newLicense],
    }));
  };

  const handleRemoveRow = (id: number): void => {
    const updatedLicences = myProfileForm.licences.filter((licence) => licence.id !== id);
    setMyProfileForm(() => ({
      ...getValues(),
      licences: updatedLicences,
    }));
  };

  const handleRemoveInsurancePlanRow = (id: number, index: number): void => {
    const updatedLicences = myProfileForm.licences.map((licence) => {
      if (licence.id === id) {
        const updatedInsurancePlans = licence.insurance_carriers.filter((_, i) => i !== index);
        return {
          ...licence,
          insurance_carriers: updatedInsurancePlans,
        };
      }
      return licence;
    });
    setMyProfileForm(() => ({
      ...getValues(),
      licences: updatedLicences,
    }));
  };

  const triggerProfileAvatar = React.useCallback((avatarUrl: string) => {
    setValue('profile_img', avatarUrl, { shouldValidate: true });
    setMyProfileForm({
      ...getValues(),
      profile_img: avatarUrl,
    });
  }, []);

  const onCloseConfirmationModal = (): void => {
    setShowValidationAddressModal({
      isOpen: false,
      title: '',
      whatYouEntered: '',
      recommended: false,
      recommendedAddress: '',
      onClose: undefined,
      onConfirmChange: undefined,
      onKeepCurrentAddress: undefined,
    });
  };

  const fetchLanguageOptions = async (): Promise<void> => {
    try {
      const data = await listAllLanguages();
      const transformedData = data.map((language) => ({
        value: language.locale,
        label: language.name,
        id: language.id,
      }));
      setLanguageOptions(transformedData);
    } catch (error: unknown) {
      displayErrorDetails(error);
    }
  };

  const fetchOrganizationOptions = async (): Promise<void> => {
    try {
      const data = await listAllOrganizations();
      if (userInfo.role.name === UserRoleName.OrganizationAdmin) {
        data.nodes = data.nodes.filter((org) => org.id === userInfo.organizations[0].id);
      }
      setAllOrganization(data.nodes);
      const transformedData = data.nodes.map((organization) => ({
        value: organization.id,
        label: organization.name,
        id: organization.id,
      }));
      setOrganizationOptions(transformedData);
    } catch (error: unknown) {
      displayErrorDetails(error);
    }
  };

  const fetchInsurancePlanOptions = async (): Promise<void> => {
    try {
      const data = await listInsuranceCarriers();
      const transformedData = data.map((insurancePlan) => ({
        id: insurancePlan.id,
        label: insurancePlan.name,
        value: insurancePlan.id,
      }));
      setInsurancePlanName(transformedData);
    } catch (error: unknown) {
      displayErrorDetails(error);
    }
  };

  useEffect(() => {
    fetchLanguageOptions();
    fetchOrganizationOptions();
    fetchInsurancePlanOptions();
  }, []);

  const setTextValue = (e: React.ChangeEvent<HTMLInputElement>, name: string): void => {
    const { value } = e.target;
    setValue(name as keyof ProviderMyProfileSubmit, value, { shouldValidate: true });
    setSelection([e.target.selectionStart, e.target.selectionEnd]);
  };

  React.useLayoutEffect(() => {
    if (selection && ref.current) {
      [ref.current.selectionStart, ref.current.selectionEnd] = selection;
    }
  }, [selection]);

  useEffect(() => {
    if (Object.keys(myProfileForm).length > 0) {
      Object.keys(myProfileForm).forEach((key: unknown) => {
        setValue(key as keyof ProviderMyProfileSubmit, myProfileForm[key as keyof ProviderMyProfileSubmit]);
      });
    }
  }, [myProfileForm, setValue]);

  const onInvalid = (errorsList: any): void => {
    const error = Object.values(errorsList)[0] as any;
    if (error) {
      toast.error(error.message as string);
    }
  };

  const onCancelEditProfile = (): void => {
    toggleModal(false);
    setImage(currentAvatarURL);
  };

  const getInvitationDomain = (): string => {
    const selectedOrganization = getValues('organization');
    if (selectedOrganization) {
      const matchedOrganization = allOrganization.find((selectedOrg) => selectedOrg.id === selectedOrganization[0].id);
      return (
        (matchedOrganization?.divisions?.length
          ? matchedOrganization?.divisions[0]!.domain
          : matchedOrganization?.practices[0]!.domain) || ''
      );
    }
    return '';
  };

  const callSaveProfile = async (profileData: UnifiedProfileSubmit): Promise<void> => {
    setIsSavingProfile(true);
    let newUser: UserProfile | undefined;
    try {
      newUser = await createNewUser({
        email: profileData.email?.toLowerCase(),
        ...((profileData as ProviderMyProfileSubmit).phone_number && {
          phone_number: parsePhoneNumber((profileData as ProviderMyProfileSubmit).phone_number, 'US')?.format(
            'E.164',
          ) as string,
        }),
        ...(profileData.address1 !== '' ||
        profileData.address2 !== '' ||
        profileData.city !== '' ||
        profileData.state !== '' ||
        profileData.zip_code !== ''
          ? {
              address: {
                ...(profileData.address1 !== '' && { address1: profileData.address1 }),
                ...(profileData.address2 !== '' && { address2: profileData.address2 }),
                ...(profileData.city !== '' && { city: profileData.city }),
                ...(profileData.state !== '' && { state: profileData.state }),
                ...(profileData.zip_code !== '' && { zip_code: profileData.zip_code }),
              } as Address,
            }
          : {}),
        type: UserRoleName.Provider,
        ...((profileData as ProviderMyProfileSubmit).organization && {
          organization_ids: (profileData as ProviderMyProfileSubmit).organization
            ?.map((org) => org.id)
            .filter((id) => id !== undefined) as string[],
        }),
        send_invitation: isSendingInvitation,
        full_name: isSendingInvitation ? `${profileData.first_name} ${profileData.last_name}` : undefined,
        frontend_website: `https://${getInvitationDomain()}`,
      });
    } catch (error: unknown) {
      newUser = undefined;
      displayErrorDetails(error);
      setIsSavingProfile(false);
    }
    if (newUser) {
      try {
        if (
          profileData.first_name !== '' ||
          (profileData as ProviderMyProfileSubmit).middle_name !== '' ||
          profileData.last_name !== '' ||
          (profileData as ProviderMyProfileSubmit).gender !== '' ||
          (profileData as ProviderMyProfileSubmit).pronouns !== '' ||
          (Array.isArray((profileData as ProviderMyProfileSubmit).languages) &&
            (profileData as ProviderMyProfileSubmit).languages.length > 0 &&
            ((profileData as ProviderMyProfileSubmit).languages[0] as Language).name !== '')
        ) {
          await createBasicInformation(
            {
              ...(profileData.first_name && { first_name: profileData.first_name }),
              ...((profileData as ProviderMyProfileSubmit).middle_name && {
                middle_name: (profileData as ProviderMyProfileSubmit).middle_name,
              }),
              ...(profileData.last_name && { last_name: profileData.last_name }),
              ...((profileData as ProviderMyProfileSubmit).gender && {
                gender: (profileData as ProviderMyProfileSubmit).gender,
              }),
              ...((profileData as ProviderMyProfileSubmit).pronouns && {
                pronouns: (profileData as ProviderMyProfileSubmit).pronouns,
              }),
              ...((profileData as ProviderMyProfileSubmit).gender_identity && {
                gender_identity: (profileData as ProviderMyProfileSubmit).gender_identity,
              }),
              ...((profileData as ProviderMyProfileSubmit).languages && {
                languages: (profileData as ProviderMyProfileSubmit).language?.map((language) => language.id),
              }),
            },
            newUser.id,
          );
        }
        const updateProfileData: ProviderProfile = {
          ...((profileData as ProviderMyProfileSubmit).education && {
            education: (profileData as ProviderMyProfileSubmit).education,
          }),
          primary_specialty: ((profileData as ProviderMyProfileSubmit).primary_specialty || [])
            .map((option) => option.value)
            .filter(Boolean),
          secondary_specialty: ((profileData as ProviderMyProfileSubmit).secondary_specialty || [])
            .map((option) => option.value)
            .filter(Boolean),
          ...((profileData as ProviderMyProfileSubmit).biography && {
            biography: (profileData as ProviderMyProfileSubmit).biography,
          }),
          ...((profileData as ProviderMyProfileSubmit).additional_credentials && {
            additional_credentials: (profileData as ProviderMyProfileSubmit).additional_credentials,
          }),
          availability: [...(newUser?.provider_profile?.availability || [])],
          ...((profileData as ProviderMyProfileSubmit).national_provider_id && {
            national_provider_id: (profileData as ProviderMyProfileSubmit).national_provider_id,
          }),
          ...((profileData as ProviderMyProfileSubmit).credentials && {
            credentials: (profileData as ProviderMyProfileSubmit).credentials,
          }),
        };
        if (
          profileData.first_name !== '' ||
          (profileData as ProviderMyProfileSubmit).middle_name !== '' ||
          profileData.last_name !== '' ||
          (profileData as ProviderMyProfileSubmit).gender !== '' ||
          (profileData as ProviderMyProfileSubmit).pronouns !== '' ||
          (profileData as ProviderMyProfileSubmit).national_provider_id !== '' ||
          (profileData as ProviderMyProfileSubmit).credentials !== '' ||
          (Array.isArray((profileData as ProviderMyProfileSubmit).languages) &&
            (profileData as ProviderMyProfileSubmit).languages.length > 0 &&
            ((profileData as ProviderMyProfileSubmit).languages[0] as Language).name !== '')
        ) {
          await createProviderProfile(updateProfileData, newUser.id);
        }
        if (
          newUser !== undefined &&
          myProfileForm.licences.length > 0 &&
          (profileData as ProviderMyProfileSubmit).licences.length > 0
        ) {
          await Promise.all(
            myProfileForm.licences.map((licence) =>
              createProviderLicense(
                {
                  state: licence.state,
                  number: licence.number,
                  expiry_date: moment(licence.expiry_date).format('YYYY-MM-DD'),
                  insurance_carriers: licence?.insurance_carriers.map((plan) => {
                    return {
                      ...plan,
                      insurance_carrier_id: Number(plan.insurance_carrier_id),
                      effective_date: moment(plan.effective_date).format('YYYY-MM-DD'),
                      recredential_date: moment(plan.recredential_date).format('YYYY-MM-DD'),
                    };
                  }),
                },
                newUser?.id ?? 0,
              ),
            ),
          );
        }
        if ((profileData as ProviderMyProfileSubmit).organization) {
          const updatedOrganizations = (profileData as ProviderMyProfileSubmit)?.organization;
          const organizationIdList: string[] = [];
          if (updatedOrganizations) {
            for (const updatedOrganization of updatedOrganizations) {
              organizationIdList.push(updatedOrganization.id as string);
            }
          }
          if (organizationIdList.length === 0) {
            organizationIdList.push('dentistry-one');
          }
          await updateUserOrganization(newUser.id, {
            organization_ids: organizationIdList,
          });
        }
        if (avatarPayload && avatarFile && avatarAction === 'Upload') {
          const res = await registerAvatarMedia(avatarPayload);
          const mediaId = res.files[0].upload_id;
          await fetch(res.files[0].upload_url, {
            method: 'PUT',
            body: avatarFile,
            headers: { 'content-type': avatarFile.type },
          });
          await confirmAvatarMedia(mediaId, newUser.id);
          triggerProfileAvatar(res.files[0].upload_url);
        } else if (avatarAction === 'Remove') {
          await deleteAvatar(newUser.id);
          triggerProfileAvatar('');
        }
        toggleModal(false);
      } catch (error: unknown) {
        displayErrorDetails(error);
        setIsSavingProfile(false);
      }
    }
  };

  const updateCurrentAddress = (standardizedAddress: any, postalAddress: any, addressComponents: any): void => {
    const streetNumber = addressComponents.find(
      (addressComponent: any) => addressComponent.componentType === 'street_number',
    );
    const address1 = addressComponents.find((addressComponent: any) => addressComponent.componentType === 'route');
    if (postalAddress) setValue('city', postalAddress.locality);
    if (streetNumber || address1) {
      setValue('address1', `${streetNumber.componentName.text} ${address1.componentName.text}`);
    }
    if (standardizedAddress?.state) setValue('state', standardizedAddress.state);
    if (postalAddress) setValue('zip_code', postalAddress.postalCode);
  };

  const onSubmitProfile = async (profileData: ProviderMyProfileSubmit): Promise<void> => {
    profileData = trimStringFields(profileData);
    if (
      profileData.address1 !== '' ||
      profileData.address2 !== '' ||
      profileData.city !== '' ||
      profileData.state !== '' ||
      profileData.zip_code !== ''
    ) {
      await validateAddress(
        profileData,
        getValues as UseFormGetValues<UnifiedProfileSubmit>,
        validationResponseId,
        callSaveProfile,
        setValidationResponseId,
        setShowValidationAddressModal,
        onCloseConfirmationModal,
        updateCurrentAddress,
      );
    } else callSaveProfile(profileData);
  };

  const handleKeyDown: KeyboardEventHandler = (event) => {
    if (!inputValue) return;
    switch (event.key) {
      case 'Enter':
        setValue('education', [...getValues('education'), inputValue], { shouldValidate: true });
        setInputValue('');
        event.preventDefault();
        return;
      case 'Tab':
        setValue('education', [...getValues('education'), inputValue], { shouldValidate: true });
        setInputValue('');
        event.preventDefault();
    }
  };
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      style={userRoleCustomStylesDashboard}
      contentLabel="Modal"
      ariaHideApp={false}
      shouldCloseOnOverlayClick={false}
    >
      <button
        type="button"
        className={styles.modalClose}
        aria-label="Close modal"
        onClick={closeModal}
        style={{ backgroundImage: `url(${closeModalIcon})` }}
      />
      <div className={styles.profileContainer}>
        <div className={styles.contentContainer}>
          <div className={styles.contentContainerInner}>
            <div className={styles.editProfileContainer}>
              <div className={styles.titleContainer}>
                <span className={styles.title}>Create Provider Profile</span>
              </div>
              <div className={styles.editContainer}>
                <form className={styles.formContainer} onSubmit={handleSubmit(onSubmitProfile, onInvalid)}>
                  <div className={styles.headerActionDiv}>
                    <ProfileImage
                      reviewProfile
                      setCurrentAvatarURL={setCurrentAvatarURL}
                      setAvatarFile={setAvatarFile}
                      setAvatarPayload={setAvatarPayload}
                      setAvatarAction={setAvatarAction}
                      triggerProfileAvatar={triggerProfileAvatar}
                      setImage={setImage}
                      image={image}
                      errors={errors}
                      size="80px"
                      isNewDependent
                    />
                    {isProfileSaved && <SuccessAlert />}
                  </div>
                  <p className={styles.requiredIndicator}>
                    Required <span>*</span>
                  </p>
                  <div className={styles.row}>
                    <div className={cn(styles.rowItem, styles.details)}>
                      <div className={styles.caption}>
                        First Name <span>*</span>
                      </div>
                      <TextField
                        {...register('first_name')}
                        errors={errors}
                        name="first_name"
                        value={getValues('first_name')}
                        onChange={(e) => setTextValue(e, 'first_name')}
                        wrapperClassName={styles.inputWrapper}
                        inputClass={styles.input}
                      />
                    </div>
                    <div className={cn(styles.rowItem, styles.details)}>
                      <div className={styles.caption}>Middle Name</div>
                      <TextField
                        {...register('middle_name')}
                        errors={errors}
                        name="middle_name"
                        value={getValues('middle_name')}
                        onChange={(e) => setTextValue(e, 'middle_name')}
                        wrapperClassName={styles.inputWrapper}
                        inputClass={styles.input}
                      />
                    </div>
                    <div className={cn(styles.rowItem, styles.details)}>
                      <div className={styles.caption}>
                        Last Name <span>*</span>
                      </div>
                      <TextField
                        {...register('last_name')}
                        errors={errors}
                        name="last_name"
                        value={getValues('last_name')}
                        onChange={(e) => setTextValue(e, 'last_name')}
                        wrapperClassName={styles.inputWrapper}
                        inputClass={styles.input}
                      />
                    </div>
                  </div>
                  <div className={styles.row}>
                    <div className={cn(styles.rowItem, styles.gender)}>
                      <div className={styles.caption}>Gender assigned at birth</div>
                      <SelectButtons
                        errors={errors}
                        setValue={setValue}
                        form={myProfileForm}
                        value="gender"
                        options={genderOptions}
                      />
                    </div>
                  </div>
                  <div className={styles.row}>
                    <div className={cn('col-md-6', styles.rowItem, styles.details, styles.rowItemHalf)}>
                      <div className={styles.caption}>Gender identity</div>
                      <SelectInput
                        {...register('gender_identity')}
                        name="gender_identity"
                        options={genderIdentity}
                        containerClass={styles.inputWrapper}
                        selectorClass={styles.selector}
                        style={selectCommonCustomStylesLarge}
                        errors={errors}
                        selectedValue={getValues('gender_identity')}
                        onChange={(value) => {
                          if (value) {
                            setValue('gender_identity', value.value, { shouldValidate: true });
                          }
                        }}
                      />
                    </div>
                    <div className={cn('col-md-6', styles.rowItem, styles.details, styles.rowItemHalf)}>
                      <div className={styles.caption}>Pronouns</div>
                      <SelectInput
                        {...register('pronouns')}
                        name="pronouns"
                        options={pronounsOptions}
                        containerClass={styles.inputWrapper}
                        selectorClass={styles.selector}
                        style={selectCommonCustomStylesLarge}
                        errors={errors}
                        selectedValue={getValues('pronouns')}
                        onChange={(value) => {
                          if (value) {
                            setValue('pronouns', value.value, { shouldValidate: true });
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className={styles.row}>
                    <div className={cn(styles.rowItem, styles.details, styles.big, styles.languages)}>
                      <div className={styles.caption}>Languages</div>
                      <MultiselectInput
                        {...register('language')}
                        name="language"
                        placeholder="Please add any languages you are comfortable speaking during the consult"
                        options={languageOptions}
                        style={multiSelectCommonCustomStylesLarge}
                        containerClass={styles.inputWrapper}
                        selectorClass={styles.selector}
                        errors={errors}
                        selectedValue={getValues('language')}
                        onChange={(value) => {
                          if (value) {
                            setValue('language', value as MultiValue<OptionLanguage>, {
                              shouldValidate: true,
                            });
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className={styles.separator} />
                  <AddressSection
                    register={register}
                    errors={errors}
                    getValues={getValues}
                    setTextValue={setTextValue}
                    setValue={setValue}
                    showValidationAddressModal={showValidationAddressModal}
                    required={false}
                  />
                  <div className={styles.separator} />
                  <div className={styles.row}>
                    <div className={styles.rowItem}>
                      <div className={styles.caption}>
                        Email Address <span>*</span>
                      </div>
                      <TextField
                        {...register('email')}
                        errors={errors}
                        name="email"
                        value={getValues('email')}
                        onChange={(e) => setTextValue(e, 'email')}
                        wrapperClassName={styles.inputWrapper}
                        inputClass={styles.input}
                      />
                    </div>
                    <div className={styles.rowItem}>
                      <div className={styles.caption}>Phone Number</div>
                      <div className={styles.inputWrapper}>
                        <PhoneInput
                          {...register('phone_number')}
                          value={getValues('phone_number')}
                          id="phone_number"
                          onChange={(val) => {
                            setValue('phone_number', val as string, {
                              shouldValidate: true,
                            });
                          }}
                          inputClass={styles.input}
                          errors={errors}
                          wrapperClassName={styles.inputWrapper}
                        />
                      </div>
                    </div>
                  </div>
                  <div className={styles.separator} />
                  <div className={`${styles.row} ${styles.licenceHeader}`}>
                    <div className={styles.licenceInfo}>
                      <div className={cn(styles.rowItem, styles.details)}>
                        <div className={styles.caption}>
                          Provider NPI # <span>*</span>
                        </div>
                        <TextField
                          {...register('national_provider_id')}
                          errors={errors}
                          name="national_provider_id"
                          value={getValues('national_provider_id')}
                          onChange={(e) => setTextValue(e, 'national_provider_id')}
                          wrapperClassName={styles.inputWrapper}
                          inputClass={styles.input}
                        />
                      </div>
                      <div className={cn(styles.rowItem, styles.details)}>
                        <div className={styles.caption}>
                          Credentials (DDS/DMD) <span>*</span>
                        </div>
                        <SelectInput
                          {...register('credentials')}
                          name="credentials"
                          options={licenseOptions}
                          containerClass={styles.inputWrapper}
                          selectorClass={styles.selector}
                          style={selectCommonCustomStylesLarge}
                          errors={errors}
                          selectedValue={getValues('credentials')}
                          onChange={(value) => {
                            if (value) {
                              setValue('credentials', value.value, {
                                shouldValidate: true,
                              });
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className={`${styles.row} ${styles.licencesRow}`}>
                    <div className={styles.licencesRowHeader}>
                      Licensure
                      <button
                        type="button"
                        onClick={addLicenseRow}
                        style={{ color: '#3498db', marginRight: '15px', textAlign: 'center' }}
                      >
                        <IoIosAddCircle /> Add License
                      </button>
                    </div>
                    {errors.licences && <div className={`${styles.invalidFeedback}`}>{errors.licences.message}</div>}
                    {myProfileForm.licences
                      .sort((a, b) => Number(a.id?.valueOf() ?? Infinity) - Number(b.id?.valueOf() ?? Infinity))
                      .map((licence, index) => (
                        <div className={styles.rowItems}>
                          <div className={cn(styles.rowItem, styles.details)}>
                            <div className={styles.caption} style={{ display: index === 0 ? 'block' : 'none' }}>
                              State of License <span>*</span>
                            </div>
                            <SelectInput
                              {...register(`licences.${licence.id!}.state`)}
                              name="statesOfLicense"
                              options={stateOptions}
                              containerClass={styles.inputWrapper}
                              selectorClass={styles.selector}
                              style={selectCommonCustomStylesLarge}
                              errors={errors}
                              selectedValue={
                                stateOptions.find((option) => option.label.split(' - ')[1] === licence.state) ||
                                licence.state
                              }
                              onChange={(value) => {
                                if (value) {
                                  setMyProfileForm((prev) => ({
                                    ...getValues(),
                                    licences: [
                                      ...prev.licences.map((l) => {
                                        if (l.id === licence.id) {
                                          return {
                                            ...l,
                                            state: value.value,
                                          };
                                        }
                                        return l;
                                      }),
                                    ],
                                  }));
                                }
                              }}
                            />
                          </div>
                          <div className={cn(styles.rowItem, styles.details)}>
                            <div className={styles.caption} style={{ display: index === 0 ? 'block' : 'none' }}>
                              License # <span>*</span>
                            </div>
                            <TextField
                              {...register(`licences.${licence.id!}.number`)}
                              errors={errors}
                              name="license_number"
                              value={licence.number}
                              onChange={(e) => {
                                const { value } = e.target;
                                setMyProfileForm((prev) => ({
                                  ...getValues(),
                                  licences: [
                                    ...prev.licences.map((l) => {
                                      if (l.id === licence.id) {
                                        return {
                                          ...l,
                                          number: value,
                                        };
                                      }
                                      return l;
                                    }),
                                  ],
                                }));
                              }}
                              wrapperClassName={styles.inputWrapper}
                              inputClass={styles.input}
                            />
                          </div>
                          <div className={cn(styles.rowItem, styles.details, styles.dobContainer)}>
                            <div className={styles.caption} style={{ display: index === 0 ? 'block' : 'none' }}>
                              Expiry date <span>*</span>
                            </div>
                            <TextField
                              {...register(`licences.${licence.id!}.expiry_date`)}
                              errors={errors}
                              name="expiry_date"
                              placeholder="MM / DD / YYYY"
                              onChange={(e) => {
                                setMyProfileForm((prev) => ({
                                  ...getValues(),
                                  licences: [
                                    ...prev.licences.map((l) => {
                                      if (l.id === licence.id) {
                                        return {
                                          ...l,
                                          expiry_date: checkAndSetDate(e),
                                        };
                                      }
                                      return l;
                                    }),
                                  ],
                                }));
                              }}
                              value={licence.expiry_date}
                              maxLength={10}
                              wrapperClassName={styles.inputWrapper}
                              inputClass={styles.input}
                              enableCursorPos={false}
                            />
                          </div>
                          {myProfileForm.licences.length > 1 && (
                            <button
                              className={styles.removeBtm}
                              type="button"
                              onClick={() => handleRemoveRow(licence.id!)}
                              aria-label="Remove license"
                            >
                              <TiDelete style={{ marginTop: index === 0 ? '' : '0' }} />
                            </button>
                          )}
                        </div>
                      ))}
                  </div>
                  <div className={`${styles.row} ${styles.licencesRow}`}>
                    <div className={styles.licencesRowHeader}>Enrollments</div>
                    <div className={styles.rowItemsContainer}>
                      {myProfileForm.licences.filter((licence) => licence.state).length > 0 ? (
                        myProfileForm.licences
                          .sort((a, b) => Number(a.id?.valueOf() ?? Infinity) - Number(b.id?.valueOf() ?? Infinity))
                          .map((licence) => (
                            <>
                              <div className={styles.licencesRowHeader} style={{ fontSize: '14px' }}>
                                {stateOptions.find((option) => option.value === licence.state)?.label.split(' - ')[1]}
                                <button
                                  type="button"
                                  style={{
                                    color: '#3498db',
                                    marginRight: '15px',
                                    textAlign: 'center',
                                  }}
                                  onClick={() => {
                                    const newInsurancePlan = {
                                      effective_date: '',
                                      recredential_date: '',
                                    };
                                    setMyProfileForm((prev) => ({
                                      ...getValues(),
                                      licences: [
                                        ...prev.licences.map((l) => {
                                          if (l.id === licence.id) {
                                            return {
                                              ...l,
                                              insurance_carriers: [...l.insurance_carriers, newInsurancePlan],
                                            };
                                          }
                                          return l;
                                        }),
                                      ],
                                    }));
                                  }}
                                >
                                  <IoIosAddCircle /> Add Insurance
                                </button>
                              </div>
                              {licence.insurance_carriers?.map((insurancePlan, insurancePlanIndex) => (
                                <div className={styles.rowItems}>
                                  <div className={cn(styles.rowItem, styles.details)}>
                                    <div
                                      className={styles.caption}
                                      style={{ fontSize: '14px', display: insurancePlanIndex === 0 ? 'block' : 'none' }}
                                    >
                                      Insurance Carrier <span>*</span>
                                    </div>
                                    <SelectInput
                                      {...register(
                                        `licences.${licence.id!}.insurance_carriers.${insurancePlanIndex}.insurance_carrier_id`,
                                      )}
                                      name={`licences.${licence.id!}.insurance_carriers.${insurancePlanIndex}.insurance_carrier_id`}
                                      options={insurancePlanName}
                                      containerClass={styles.inputWrapper}
                                      selectorClass={styles.selector}
                                      style={selectCommonCustomStylesLarge}
                                      errors={errors}
                                      selectedValue={
                                        insurancePlanName.find(
                                          (option) => Number(option.value) === insurancePlan.insurance_carrier_id,
                                        ) || insurancePlan.insurance_carrier_id
                                      }
                                      onChange={(value) => {
                                        if (value) {
                                          setMyProfileForm((prev) => ({
                                            ...getValues(),
                                            licences: [
                                              ...prev.licences.map((l) => {
                                                if (l.id === licence.id) {
                                                  return {
                                                    ...l,
                                                    insurance_carriers: [
                                                      ...l.insurance_carriers.map((plan, planIndex) => {
                                                        if (planIndex === insurancePlanIndex) {
                                                          return {
                                                            ...plan,
                                                            insurance_carrier_id: value.value,
                                                          };
                                                        }
                                                        return plan;
                                                      }),
                                                    ],
                                                  };
                                                }
                                                return l;
                                              }),
                                            ],
                                          }));
                                        }
                                      }}
                                    />
                                  </div>
                                  <div className={cn(styles.rowItem, styles.details)}>
                                    <div
                                      className={styles.caption}
                                      style={{ fontSize: '14px', display: insurancePlanIndex === 0 ? 'block' : 'none' }}
                                    >
                                      Effective Date <span>*</span>
                                    </div>
                                    <TextField
                                      {...register(
                                        `licences.${licence.id!}.insurance_carriers.${insurancePlanIndex}.effective_date`,
                                      )}
                                      errors={errors}
                                      name="effectiveDateOfLicence"
                                      placeholder="MM / DD / YYYY"
                                      onChange={(e) => {
                                        setMyProfileForm((prev) => ({
                                          ...getValues(),
                                          licences: [
                                            ...prev.licences.map((l) => {
                                              if (l.id === licence.id) {
                                                return {
                                                  ...l,
                                                  insurance_carriers: [
                                                    ...l.insurance_carriers.map((plan, planIndex) => {
                                                      if (planIndex === insurancePlanIndex) {
                                                        return {
                                                          ...plan,
                                                          effective_date: checkAndSetDate(e),
                                                        };
                                                      }
                                                      return plan;
                                                    }),
                                                  ],
                                                };
                                              }
                                              return l;
                                            }),
                                          ],
                                        }));
                                      }}
                                      value={insurancePlan.effective_date}
                                      maxLength={10}
                                      wrapperClassName={styles.inputWrapper}
                                      inputClass={styles.input}
                                      enableCursorPos={false}
                                    />
                                  </div>
                                  <div className={cn(styles.rowItem, styles.details, styles.dobContainer)}>
                                    <div
                                      className={styles.caption}
                                      style={{ fontSize: '14px', display: insurancePlanIndex === 0 ? 'block' : 'none' }}
                                    >
                                      Re-credentialing Date <span>*</span>
                                    </div>
                                    <TextField
                                      {...register(
                                        `licences.${licence.id!}.insurance_carriers.${insurancePlanIndex}.recredential_date`,
                                      )}
                                      errors={errors}
                                      name="recredentialDateOfLicence"
                                      placeholder="MM / DD / YYYY"
                                      onChange={(e) => {
                                        setMyProfileForm((prev) => ({
                                          ...getValues(),
                                          licences: [
                                            ...prev.licences.map((l) => {
                                              if (l.id === licence.id) {
                                                return {
                                                  ...l,
                                                  insurance_carriers: [
                                                    ...l.insurance_carriers.map((plan, planIndex) => {
                                                      if (planIndex === insurancePlanIndex) {
                                                        return {
                                                          ...plan,
                                                          recredential_date: checkAndSetDate(e),
                                                        };
                                                      }
                                                      return plan;
                                                    }),
                                                  ],
                                                };
                                              }
                                              return l;
                                            }),
                                          ],
                                        }));
                                      }}
                                      value={insurancePlan.recredential_date}
                                      maxLength={10}
                                      wrapperClassName={styles.inputWrapper}
                                      inputClass={styles.input}
                                      enableCursorPos={false}
                                    />
                                  </div>
                                  <button
                                    className={styles.removeBtm}
                                    type="button"
                                    onClick={() => handleRemoveInsurancePlanRow(licence.id!, insurancePlanIndex)}
                                    aria-label="Remove insurance plan"
                                  >
                                    <TiDelete style={{ marginTop: insurancePlanIndex === 0 ? '' : '0' }} />
                                  </button>
                                </div>
                              ))}
                            </>
                          ))
                      ) : (
                        <div className={styles.rowItems}>-</div>
                      )}
                    </div>
                  </div>
                  <div className={styles.separator} />
                  <div className={styles.row}>
                    <div className={cn(styles.rowItem, styles.details)}>
                      <div className={styles.caption}>Primary Specialty</div>
                      <MultiselectInput
                        {...register('primary_specialty')}
                        name="primary_specialty"
                        placeholder="Select up to 3"
                        options={specialtyOptions as { value: string; label: string }[]}
                        style={multiSelectCommonCustomStylesLarge}
                        containerClass={styles.inputWrapper}
                        selectorClass={styles.selector}
                        errors={errors}
                        selectedValue={getValues('primary_specialty')}
                        onChange={(value) => {
                          if (value) {
                            setValue('primary_specialty', value as MultiValue<Option>, {
                              shouldValidate: true,
                            });
                          }
                        }}
                      />
                    </div>
                    <div className={cn(styles.rowItem, styles.details)}>
                      <div className={styles.caption}>Secondary Specialty</div>
                      <MultiselectInput
                        {...register('secondary_specialty')}
                        name="secondary_specialty"
                        placeholder="Select up to 3"
                        options={specialtyOptions as { value: string; label: string }[]}
                        style={multiSelectCommonCustomStylesLarge}
                        containerClass={styles.inputWrapper}
                        selectorClass={styles.selector}
                        errors={errors}
                        selectedValue={getValues('secondary_specialty')}
                        onChange={(value) => {
                          if (value) {
                            setValue('secondary_specialty', value as MultiValue<Option>, {
                              shouldValidate: true,
                            });
                          }
                        }}
                      />
                    </div>
                    <div className={cn(styles.rowItem, styles.details)}>
                      <div className={styles.caption}>Additional Credentials</div>
                      <TextField
                        {...register('additional_credentials')}
                        errors={errors}
                        name="additional_credentials"
                        value={getValues('additional_credentials')}
                        onChange={(e) => setTextValue(e, 'additional_credentials')}
                        wrapperClassName={styles.inputWrapper}
                        inputClass={styles.input}
                      />
                    </div>
                  </div>
                  <div className={styles.row}>
                    <div className={cn(styles.rowItem, styles.details, styles.rowItemHalf)}>
                      <div className={styles.caption}>Education / Universities</div>
                      <div className={styles.inputWrapper}>
                        <CreatableSelect
                          {...register('education')}
                          components={components}
                          inputValue={inputValue} // Update inputValue prop
                          isMulti
                          className={errors.education ? 'is-invalid' : 'is-valid'}
                          menuIsOpen={false}
                          onKeyDown={handleKeyDown}
                          value={getValues('education')?.map((item) => ({ label: item, value: item }))}
                          styles={multiSelectCommonCustomStylesLarge}
                          onInputChange={(newValue) => setInputValue(newValue)} // Update onInputChange prop
                          onChange={(value) => {
                            if (value) {
                              setValue(
                                'education',
                                value.map((v) => v.value),
                                { shouldValidate: true },
                              );
                            }
                          }}
                          onBlur={() => {
                            if (inputValue) {
                              // Use educationInputValue instead of inputValue
                              setValue('education', [...getValues('education'), inputValue], {
                                shouldValidate: true,
                              }); // Use educationInputValue instead of inputValue
                            }
                          }}
                        />
                        <div
                          className={`${styles.invalidFeedback} invalid-feedback`}
                          style={{ display: errors.education?.message ? 'block' : 'none' }}
                        >
                          {errors.education?.message}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.row}>
                    <div className={styles.rowItem}>
                      <div className={styles.caption}>Biography</div>
                      <textarea
                        {...register('biography')}
                        className={styles.textarea}
                        onChange={(e) => {
                          setValue('biography', e.target.value, { shouldValidate: true });
                          setSelection([e.target.selectionStart, e.target.selectionEnd]);
                        }}
                        value={getValues('biography')}
                        rows={6}
                      />
                      <div
                        className={`${styles.invalidFeedback} invalid-feedback`}
                        style={{ display: errors.biography?.message ? 'block' : 'none' }}
                      >
                        {errors.biography?.message}
                      </div>
                    </div>
                  </div>
                  <div className={styles.separator} />
                  <div className={styles.editProfileContainer}>
                    <div className={styles.editContainer}>
                      <div className={styles.row}>
                        <div className={cn(styles.rowItem, styles.details, styles.big)}>
                          <div className={styles.caption}>
                            Organization <span>*</span>
                          </div>
                          <MultiselectInput
                            {...register('organization')}
                            name="organization"
                            options={organizationOptions as { value: string; label: string }[]}
                            containerClass={styles.inputWrapper}
                            selectorClass={styles.selector}
                            style={selectCommonCustomStylesLarge}
                            selectedValue={getValues('organization')}
                            errors={errors}
                            onChange={(value) => {
                              if (value) {
                                setValue('organization', value as MultiValue<Option>, { shouldValidate: true });
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {isProfileSaved && <SuccessAlert />}
                  <div className={styles.actionBtnContainer} style={{ marginTop: '20px' }}>
                    <CustomCheckbox
                      label="Invite user"
                      checked={isSendingInvitation}
                      onChange={(e) => {
                        setIsSendingInvitation(e.target.checked);
                      }}
                      containerClassName={styles.checkboxContainer}
                    />
                    <div className={styles.btnContainer}>
                      <Button type="button" className={styles.cancel} onClick={onCancelEditProfile}>
                        Cancel
                      </Button>
                      <Button type="submit" className={styles.submit} disabled={isSavingProfile}>
                        Save
                      </Button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

Modal.setAppElement('#root');

export default AddProviderUserModal;
