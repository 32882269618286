import Sidenav from '@brands/Dashboard/Sidenav/Sidenav';
import { LoginResponse } from '@brands/services/auth/login';
import { verifyMfaCode } from '@brands/services/auth/verifyMfaCode';
import { formatEmail } from '@brands/Utils/formatDate';
import { toastError } from '@brands/Utils/toastError';
import React, { FC, SyntheticEvent, useState } from 'react';
import { AiOutlineQuestionCircle } from 'react-icons/ai';

import styles from './styles.module.scss';

interface MultiFactorAuthenticationProps {
  loginResponse: LoginResponse;
  setShowTwoFactorAuth: React.Dispatch<
    React.SetStateAction<{
      trigger: boolean;
      response: LoginResponse | null;
    }>
  >;
  userCredentials: {
    email: string;
    password: string;
  };
  triggerLogin: (response: LoginResponse) => Promise<void>;
  endLogin: (data: any, error: any) => void;
}

const MultiFactorAuthentication: FC<MultiFactorAuthenticationProps> = ({
  loginResponse,
  setShowTwoFactorAuth,
  userCredentials,
  triggerLogin,
  endLogin,
}) => {
  const [code, setCode] = useState('');
  const [loading, setLoading] = useState(false);

  const onSubmit = async (event: SyntheticEvent): Promise<void> => {
    event.preventDefault();
    setLoading(true);

    await verifyMfaCode({
      method: loginResponse.mfa_method as string,
      user_credentials: userCredentials,
      mfa_code: code,
      session: loginResponse.session_id as string,
    })
      .then(async (res) => {
        await triggerLogin(res);
      })
      .catch((error) => {
        toastError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const clickCancel = (): void => {
    setShowTwoFactorAuth({ trigger: false, response: null });
    endLogin(null, null);
  };

  return (
    <>
      <Sidenav isVisibleOnMobile hideDefaultOptions>
        <a
          href="https://dentistryone.zendesk.com/hc/en-us/articles/11914427760151-How-to-change-your-password"
          target="_blank"
          className={styles.sidenavContainer}
          rel="noreferrer"
          style={{ textDecoration: 'none' }}
        >
          <div className={styles.sidenavToggleText}>Help</div>
          <div className={styles.sidenavToggle}>
            <AiOutlineQuestionCircle />
          </div>
        </a>
      </Sidenav>
      <form className={styles.formContainer} onSubmit={onSubmit}>
        <div className={`${styles.body}`}>
          <div className={`${styles.twoFactorBody}`}>
            <h1 className='fs-unmask'>Let’s make sure it’s you</h1>
            <div className={styles.verificationCode}>
              <span className='fs-unmask'>{`We sent a verification code to ${formatEmail(userCredentials.email)}`}</span>
              <div className={`${styles.inputWrapper} ${styles.inputWrap}`} data-validate="Valid code is: xxxxxx">
                <span className='fs-unmask'>Enter Code</span>
                <input
                  className={`${styles.input100} ${styles.profileInputClass} fs-exclude`}
                  type="text"
                  name="code"
                  placeholder=""
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                />
                <span className={styles.focusInput100} />
                <div className={`${styles.invalidFeedback} invalid-feedback`}>error</div>
              </div>
            </div>
            <div className={styles.buttonContainer}>
              <div className={styles.buttonWrapper}>
                <div className={styles.buttonBG} />
                <button
                  type="submit"
                  className={`${styles.formBtn} ${styles.signIn} fs-unmask`}
                  disabled={loading || !/^\d{6}$/.test(code)}
                >
                  Continue
                </button>
                <button
                  type="button"
                  className={`${styles.formBtn} ${styles.cancel} fs-unmask`}
                  disabled={loading}
                  onClick={() => clickCancel()}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default MultiFactorAuthentication;
