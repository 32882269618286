import React from 'react';
import { useNavigate } from 'react-router-dom';

import { ICase } from '../../../../services/cases/types/ICase';
import { addAdditionalInformationAvailability } from '../../../../Utils/getFriendlyName';
import styles from '../appointments.module.scss';

interface Props {
  appointment: ICase;
}

const AddAdditionalInformationButton = ({ appointment }: Props): JSX.Element => {
  const navigate = useNavigate();

  return (
    <span>
      {addAdditionalInformationAvailability(appointment) && (
        <button
          type="button"
          className={`fs-unmask ${styles.cancelBtn}`}
          onClick={() => navigate(`/add-additional-information/${appointment.id}`)}
        >
          Add Additional Information
        </button>
      )}
    </span>
  );
};

export default AddAdditionalInformationButton;
