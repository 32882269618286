/* eslint-disable no-nested-ternary */
import { Loading } from '@brands/Components/LoadingSpinner/Loading';
import { useAppSelector } from '@brands/hooks/useReduxHook';
import { getMfaStatus } from '@brands/services/auth/getMfaStatus';
import { selectAuth } from '@brands/store/selectors/auth';
import React, { useEffect, useMemo } from 'react';
import { FiEdit } from 'react-icons/fi';

import styles from '../../../styles.module.scss';

type ReadOnlyProfileProps = {
  setIsEditSecurity: React.Dispatch<React.SetStateAction<boolean>>;
};

const ReadOnlyAccountSecurity = ({ setIsEditSecurity }: ReadOnlyProfileProps): JSX.Element => {
  const { userInfo } = useAppSelector(selectAuth);

  const [preferredMethod, setPreferredMethod] = React.useState<string[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const mfaStatus = await getMfaStatus(userInfo.id);
      setPreferredMethod(mfaStatus.mfa_method);
      setLoading(false);
    })();
  }, [userInfo.id]);

  const friendlyMfaMethodName = useMemo(() => {
    if (preferredMethod.includes('EMAIL_MFA')) {
      return 'Email';
    }
    if (preferredMethod.includes('SMS_MFA')) {
      return 'SMS';
    }
    return '';
  }, [preferredMethod]);

  if (loading) {
    return <Loading dotted isHeightAuto />;
  }

  return (
    <div className={styles.readOnlyProfileContainer}>
      <div className={styles.titleContainer}>
        <span className={`fs-unmask ${styles.title}`}>Account Security</span>
        <button className={`fs-unmask ${styles.editBtn}`} type="button" onClick={() => setIsEditSecurity(true)}>
          Edit
          <FiEdit className={`fs-unmask ${styles.editIcon}`} />
        </button>
      </div>
      <div className={styles.detailsContainer} style={{ flexDirection: 'column', alignItems: 'flex-start' }}>
        <div className={styles.readOnlyItem}>
          <div className={`fs-unmask ${styles.caption}`}>Multi-factor Authentication</div>
          <div className={`fs-exclude ${styles.value}`}>
            {userInfo.role.name === 'Patient' ? (preferredMethod.length > 0 ? 'On' : 'Off') : 'On'}
          </div>
        </div>
        {preferredMethod.length > 0 && (
          <div className={styles.readOnlyItem}>
            <div className={`fs-unmask ${styles.caption}`}>Preferred Delivery Method</div>
            <div className={`fs-exclude ${styles.value}`}>{friendlyMfaMethodName || 'Unknown'}</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ReadOnlyAccountSecurity;
