/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-cycle */
/* eslint-disable @typescript-eslint/naming-convention */
import PhoneInput from '@brands/Components/Inputs/PhoneInput/PhoneInput';
import SecondaryInsuranceFields from '@brands/Dashboard/Dashboard/Components/InsuranceFields/SecondaryInsuranceFields';
import AddressSection from '@brands/Dashboard/Dashboard/SuperAdmin/Components/AddressSection/AddressSection';
import { stateFundedProgramOptions } from '@brands/Dashboard/PatientForms/PatientForm/utils/types';
import { useInsuranceCarriers } from '@brands/hooks';
import { useAppDispatch, useAppSelector } from '@brands/hooks/useReduxHook';
import { CaseType } from '@brands/services/cases/types/ICase';
import { createPatientInsurance } from '@brands/services/identity/createPatientInsurance';
import { getPatientInsurance } from '@brands/services/identity/getPatientInsurance';
import { Pricings } from '@brands/services/identity/getPricings';
import { getUserById } from '@brands/services/identity/getUserById';
import { UpdateMe, updateMe } from '@brands/services/identity/updateMe';
import { updateUserClientTag } from '@brands/services/identity/updateUserClientTag';
import { selectAuth } from '@brands/store/selectors/auth';
import { selectOrganization } from '@brands/store/selectors/organization';
import { selectOrganizationPrices } from '@brands/store/selectors/organizationPrices';
import { selectPageState } from '@brands/store/selectors/pageState';
import { selectPatientForm } from '@brands/store/selectors/patientForm';
import { selectPatient } from '@brands/store/selectors/selectedPatient';
import { addDependent, updateDependent as editDependent } from '@brands/store/slices/dependentsSlice';
import { setDependentId, setIsFromSelectPatient } from '@brands/store/slices/pageStateSlice';
import { setExternalForms, setSelectedPatient } from '@brands/store/slices/patientFormSlice';
import { setSelectedPatientByAdmin } from '@brands/store/slices/selectedPatientSlice';
import { checkExternalFormAnswers } from '@brands/Utils/checkExternalForms';
import { displayErrorDetails } from '@brands/Utils/displayError';
import { UnifiedProfileSubmit } from '@brands/Utils/types';
import { validateAddress, ValidationModalInterface } from '@brands/Utils/validateAddress';
import { yupResolver } from '@hookform/resolvers/yup';
import cn from 'classnames';
import { E164Number } from 'libphonenumber-js';
import { isEqual } from 'lodash';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm, UseFormGetValues } from 'react-hook-form';
import { MdKeyboardArrowLeft } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { MultiValue } from 'react-select';
import { toast } from 'react-toastify';

import Button from '../../../../../Components/Button/Button';
import MultiselectInput from '../../../../../Components/Inputs/MultiselectInput/MultiselectInput';
import SelectInput from '../../../../../Components/Inputs/SelectInput/SelectInput';
import TextField from '../../../../../Components/Inputs/TextField/TextField';
import ConfirmationModal from '../../../../../Components/Modals/ConfirmationModal/ConfirmationModal';
import ProfileImage from '../../../../../Components/ProfileImage/ProfileImage';
import SelectButtons from '../../../../../Components/ProfileSelectButtons/SelectButtons';
import { RegisterMediaPayload } from '../../../../../services/communication/registersConversationMedia';
import { confirmAvatarMedia } from '../../../../../services/identity/confirmAvatarMedia';
import { createBasicInformation } from '../../../../../services/identity/createBasicInformation';
import { createDependent } from '../../../../../services/identity/createDependent';
import { deleteAvatar } from '../../../../../services/identity/deleteAvatar';
import { deleteDependent } from '../../../../../services/identity/deleteDependent';
import { getDependentById } from '../../../../../services/identity/getDependentById';
import { registerAvatarMedia } from '../../../../../services/identity/registerAvatarMedia';
import { saveBasicInformation } from '../../../../../services/identity/saveBasicInformation';
import { Dependent } from '../../../../../services/identity/types/Dependent';
import {
  Address,
  BasicInfo,
  Insurance,
  Marker,
  UserProfile,
  UserRoleName,
} from '../../../../../services/identity/types/UserProfile';
import { updateDependent } from '../../../../../services/identity/updateDependent';
import { checkAndSetDate } from '../../../../../Utils/checkAndSetDate';
import {
  multiSelectCommonCustomStylesLarge,
  profileSelectStyles,
  selectCommonCustomStylesLarge,
} from '../../../../../Utils/customStyles';
import { genderIdentity, genderOptions, pronounsOptions } from '../../../../../Utils/selectButtonOptions';
import { insuranceThroughOptions, Option, OptionLanguage, stateOptions } from '../../../../../Utils/selectOptions';
import styles from '../../../styles.module.scss';
import { areArraysEqual, updateDataInsurance, updateDataSecondaryInsurance } from '../../utils/utilsFunctions';
import { dependentAddValidationSchema, dependentUpdateValidationSchema } from '../../utils/validationSchema';
import EditInsurance from '../Insurance/EditInsurance';
import ReadOnlyInsurance from '../Insurance/ReadOnlyInsurance';

export type DependentSubmit = {
  profile_img: string | null;
  first_name: string;
  middle_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  zip_code: string;
  state: string;
  date_of_birth: string;
  pronouns: string;
  gender: string;
  gender_identity: string;
  address1: string;
  address2: string;
  city: string;
  country: string;
  dependent_clientTag?: MultiValue<Option>;
  profileAddress: string;
  profileCity: string;
  language: MultiValue<OptionLanguage>;
  id: number;
  patientId: number;
  guardian_relationship: string;
  dependent_email: string;
  member_id: string;
  group_number: string;
  insurance_through: string;
  insurance_carrier_id?: number;
  state_funded_coverage_status?: string;
  secondary_group_number: string;
  secondary_member_id: string;
  secondary_iPhoneNumber: string;
  secondary_insurance_through: string;
  secondary_insurance_carrier_id?: number;
  insurance_address?: string;
  insurance_city?: string;
  insurance_state?: string;
  insurance_zip_code?: string;
  iFirstName: string;
  iMiddleName: string;
  iDateOfBirth: string;
  iGenderIdentity: string;
  iLastName: string;
  iAddress1: string;
  iAddress2: string;
  iZipCode: string;
  iState: string;
  iCity: string;
  secondary_iFirstName: string;
  secondary_iMiddleName: string;
  secondary_iDateOfBirth: string;
  secondary_iGenderIdentity: string;
  secondary_iLastName: string;
  secondary_iAddress1: string;
  secondary_iAddress2: string;
  secondary_iZipCode: string;
  secondary_iState: string;
  secondary_iCity: string;
  secondary_insurance_address: string;
  secondary_insurance_city: string;
  secondary_insurance_state: string;
  secondary_insurance_zip_code: string;
  iPhoneNumber: string;
  markers: Marker[];
};

type EditDependentProps = {
  setIsEditDependent: React.Dispatch<React.SetStateAction<boolean>>;
  setSelection: React.Dispatch<React.SetStateAction<[number | null, number | null] | null>>;
  setThisUser: React.Dispatch<React.SetStateAction<UserProfile>>;
  dependentId?: number | undefined;
  languageOptions: OptionLanguage[];
  isModal?: boolean;
  reviewProfile?: boolean;
  validationResponseId: string;
  setValidationResponseId: React.Dispatch<React.SetStateAction<string>>;
  isFromSelectNeeds: boolean | undefined;
  showDeleteDependentConfirmationModal?: boolean;
  setShowDeleteDependentConfirmationModal?: React.Dispatch<React.SetStateAction<boolean>>;
  clientTagOptions: Option[];
  roleName: string;
};

export const relationshipOptions = [
  { value: 'parent', label: 'Parent' },
  { value: 'stepparent', label: 'Stepparent' },
  { value: 'sibling', label: 'Sibling' },
  { value: 'aunt_uncle', label: 'Aunt/Uncle' },
  { value: 'grandparent', label: 'Grandparent' },
  { value: 'appointed_guardian', label: 'Appointed Guardian' },
  { value: 'other', label: 'Other' },
];

const EditDependent = ({
  setIsEditDependent,
  setSelection,
  setThisUser,
  dependentId,
  languageOptions,
  isModal,
  reviewProfile,
  validationResponseId,
  setValidationResponseId,
  isFromSelectNeeds,
  showDeleteDependentConfirmationModal,
  setShowDeleteDependentConfirmationModal,
  clientTagOptions,
  roleName,
}: EditDependentProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const { selectedPatient } = useAppSelector(selectPatient);
  const { values } = useAppSelector(selectPatientForm);
  const [avatarPayload, setAvatarPayload] = useState<RegisterMediaPayload | null>(null);
  const { userInfo } = useAppSelector(selectAuth);
  const [fetchLoading, setFetchLoading] = useState(false);
  const { currentOrganization } = useAppSelector(selectOrganization);
  const organizationPrices = useAppSelector(selectOrganizationPrices);
  const { isFromSelectPatient, isEditInsuranceFlag } = useAppSelector(selectPageState);
  const [guardianPatient, setGuardianPatient] = useState<UserProfile>();
  const [avatarFile, setAvatarFile] = useState<File | null>(null);
  const [isSavingProfile, setIsSavingProfile] = useState(false);
  const [image, setImage] = useState<string | undefined>(undefined);
  const [avatarAction, setAvatarAction] = useState<string>('');
  const [patientInsurance, setPatientInsurance] = useState<Insurance[]>([]);
  const [isEditInsurance, setIsEditInsurance] = useState(false);
  const [addSecondaryInsurance, setAddSecondaryInsurance] = useState(patientInsurance.length > 1);
  const navigate = useNavigate();
  const [key, setKey] = useState(0);
  const [isSelected, setIsSelected] = React.useState<number | null>(null);
  const [dependentProfileForm, setDependentProfileForm] = useState<DependentSubmit>({
    profile_img: null,
    first_name: '',
    middle_name: '',
    last_name: '',
    email: '',
    phone_number: '',
    zip_code: '',
    state: '',
    date_of_birth: '',
    pronouns: '',
    gender_identity: '',
    gender: '',
    address1: '',
    address2: '',
    city: '',
    country: '',
    profileAddress: '',
    profileCity: '',
    language: [],
    id: 0,
    patientId: 0,
    guardian_relationship: '',
    dependent_email: '',
    group_number: '',
    insurance_through: '',
    insurance_carrier_id: 0,
    secondary_group_number: '',
    insurance_address: '',
    insurance_city: '',
    insurance_state: '',
    insurance_zip_code: '',
    secondary_member_id: '',
    secondary_iPhoneNumber: '',
    secondary_insurance_through: '',
    secondary_insurance_carrier_id: 0,
    iFirstName: '',
    iMiddleName: '',
    iDateOfBirth: '',
    iGenderIdentity: '',
    iLastName: '',
    iAddress1: '',
    iAddress2: '',
    iZipCode: '',
    state_funded_coverage_status: '',
    iState: '',
    iCity: '',
    secondary_iFirstName: '',
    secondary_iMiddleName: '',
    secondary_iDateOfBirth: '',
    secondary_iGenderIdentity: '',
    secondary_iLastName: '',
    secondary_iAddress1: '',
    secondary_iAddress2: '',
    secondary_iZipCode: '',
    secondary_iState: '',
    secondary_iCity: '',
    secondary_insurance_address: '',
    secondary_insurance_city: '',
    secondary_insurance_state: '',
    secondary_insurance_zip_code: '',
    iPhoneNumber: '',
    member_id: '',
    markers: [],
  });
  const [showValidationAddressModal, setShowValidationAddressModal] = useState<ValidationModalInterface>({
    isOpen: false,
    title: '',
    whatYouEntered: '',
    recommended: false,
    recommendedAddress: '',
    onClose: undefined,
    onConfirmChange: undefined,
    onKeepCurrentAddress: undefined,
  });

  useEffect(() => {
    const getGuardianPAtient = async (): Promise<void> => {
      if (
        userInfo.role.name === UserRoleName.Admin ||
        userInfo.role.name === UserRoleName.SuperAdmin ||
        userInfo.role.name === UserRoleName.OrganizationAdmin
      ) {
        if (selectedPatient.guardian_id !== null) {
          const guardianResponse = await getUserById(selectedPatient?.guardian_id as number);
          setGuardianPatient(guardianResponse);
        }
      } else if (userInfo.role.name === UserRoleName.Patient) {
        setGuardianPatient(userInfo);
      }
    };
    if (isFromSelectPatient || userInfo.role.name === UserRoleName.Patient || reviewProfile) {
      getGuardianPAtient();
    }
  }, [selectedPatient]);

  const isAdminRole = useMemo(
    () =>
      userInfo.role.name === UserRoleName.Admin ||
      userInfo.role.name === UserRoleName.SuperAdmin ||
      userInfo.role.name === UserRoleName.OrganizationAdmin,
    [userInfo.role.name],
  );

  let currentOrganizationPrices: any;
  let isPaymentRequired: boolean;

  if (isFromSelectPatient) {
    currentOrganizationPrices = organizationPrices?.currentOrganizationPrices?.find(
      (currentOrganizationPrise: Pricings) =>
        currentOrganizationPrise.case_types.includes(
          values.find((item) => item.form_question_id === 20)?.value.caseType as string,
        ),
    )?.amount;
    isPaymentRequired = currentOrganization?.payment_options?.length > 0;
  }

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    getValues,
  } = useForm<DependentSubmit>({
    mode: 'onChange',
    resolver: yupResolver(
      dependentId?.toString() === '' || dependentId === undefined || dependentId === 0
        ? dependentAddValidationSchema(addSecondaryInsurance, isAdminRole)
        : dependentUpdateValidationSchema(undefined, isAdminRole, isFromSelectPatient),
    ),
  });

  useEffect(() => {
    if (getValues('state_funded_coverage_status') !== '') {
      setIsSelected(
        Number(
          stateFundedProgramOptions.find((answer) => answer.value === getValues('state_funded_coverage_status'))?.id,
        ),
      );
    }
  }, [getValues('state_funded_coverage_status')]);

  const onChange = (k: any, value: string | number): void => {
    setValue(k, value, { shouldValidate: true });
  };
  const insuranceCarriers = useInsuranceCarriers();
  const insuranceCarrierOptions = useMemo(() => {
    return insuranceCarriers.map((insuranceCarrier) => ({
      value: insuranceCarrier.id,
      label: insuranceCarrier.name,
    }));
  }, [insuranceCarriers]);
  const selectSameAsGuardian = (isSelect = true): void => {
    const inputList = ['address1', 'address2', 'city', 'state', 'zip_code'];
    if (isSelect) {
      inputList.forEach((input) => {
        setValue(
          input as keyof DependentSubmit,
          guardianPatient?.address?.[input as keyof Address]
            ? (guardianPatient?.address?.[input as keyof Address] as string)
            : '',
          {
            shouldValidate: true,
          },
        );
      });
    } else {
      inputList.forEach((input) => {
        setValue(input as keyof DependentSubmit, input === 'state' ? undefined : '', {
          shouldValidate: true,
        });
        setKey((prev) => prev + 1);
      });
    }
  };

  const triggerProfileAvatar = React.useCallback((avatarUrl: string) => {
    setValue('profile_img', avatarUrl, { shouldValidate: true });
    setDependentProfileForm({
      ...getValues(),
      profile_img: avatarUrl,
    });
  }, []);

  const deleteSelectedDependent = async (): Promise<void> => {
    try {
      await deleteDependent(
        userInfo.role.name === UserRoleName.Admin ||
          userInfo.role.name === UserRoleName.SuperAdmin ||
          userInfo.role.name === UserRoleName.OrganizationAdmin
          ? selectedPatient.guardian_id !== null
            ? selectedPatient.guardian_id
            : selectedPatient.id
          : userInfo.id,
        dependentId as number,
      );
      setIsEditDependent(false);
      dispatch(
        editDependent({
          value: dependentId?.toString(),
          label: `${dependentProfileForm.first_name} ${dependentProfileForm.last_name}`,
          status: 'Deactivated',
        }),
      );
      if (userInfo.id) {
        dispatch(
          setSelectedPatient({
            value: userInfo.id.toString(),
            label: 'Myself',
          }),
        );
      }
    } catch (error: any) {
      const { status, data } = error.response;
      if (status === 400 || status === 422) {
        toast.error(data.message);
      }
    }
  };

  const onCloseConfirmationModal = (): void => {
    setShowValidationAddressModal({
      isOpen: false,
      title: '',
      whatYouEntered: '',
      recommended: false,
      recommendedAddress: '',
      onClose: undefined,
      onConfirmChange: undefined,
      onKeepCurrentAddress: undefined,
    });
    setShowDeleteDependentConfirmationModal && setShowDeleteDependentConfirmationModal(false);
  };

  const onContinueConfirmationModal = (): void => {
    setShowDeleteDependentConfirmationModal && setShowDeleteDependentConfirmationModal(false);
    deleteSelectedDependent();
  };

  const checkExternalForms = async (selectedPatientId: number, updatedUser: UserProfile): Promise<string[]> => {
    const [checkProfile, checkMedicalHistory, checkMedicalConditionsFormAnswer, checkDentalHistoryFormAnswer] =
      await checkExternalFormAnswers(
        userInfo.role.name === UserRoleName.Admin ||
          userInfo.role.name === UserRoleName.SuperAdmin ||
          userInfo.role.name === UserRoleName.OrganizationAdmin
          ? guardianPatient ?? (updatedUser as UserProfile)
          : userInfo,
        updatedUser,
      );

    const formsToSet: string[] = [];

    if (!checkProfile) {
      formsToSet.push('patient_profile');
    }
    if (!checkMedicalHistory) {
      formsToSet.push('patient_medical_history');
    }
    if (!checkMedicalConditionsFormAnswer) {
      formsToSet.push('patient_medical_conditions');
    }
    if (!checkDentalHistoryFormAnswer) {
      formsToSet.push('patient_dental_history');
    }

    dispatch(setExternalForms(formsToSet));
    return formsToSet;
  };

  const fetchData = (selectedPatientId: number, updatedUser: UserProfile): Promise<string[]> => {
    setFetchLoading(true);
    return checkExternalForms(selectedPatientId, updatedUser);
  };

  const callSaveProfile = async (profileData: UnifiedProfileSubmit): Promise<void> => {
    setIsSavingProfile(true);
    let id;
    let updatedUser: UserProfile | undefined;
    try {
      if (!dependentId) {
        const res = await createDependent(
          userInfo.role.name === UserRoleName.Admin ||
            userInfo.role.name === UserRoleName.SuperAdmin ||
            userInfo.role.name === UserRoleName.OrganizationAdmin
            ? selectedPatient.guardian_id !== null
              ? selectedPatient.guardian_id
              : selectedPatient.id
            : userInfo.id,
          {
            guardian_relationship: (profileData as DependentSubmit).guardian_relationship!,
            email: (profileData as DependentSubmit).dependent_email || null,
          },
        );
        id = res.id;
        if (isFromSelectNeeds) {
          dispatch(
            setSelectedPatient({
              value: res.id.toString(),
              label: res.full_name,
            }),
          );
          dispatch(setSelectedPatientByAdmin({ selectedPatient: res }));
        }
      } else {
        id = dependentId;
        const res = await updateDependent(
          userInfo.role.name === UserRoleName.Admin ||
            userInfo.role.name === UserRoleName.SuperAdmin ||
            userInfo.role.name === UserRoleName.OrganizationAdmin
            ? selectedPatient.guardian_id !== null
              ? selectedPatient.guardian_id
              : selectedPatient.id
            : userInfo.id,
          id,
          {
            guardian_relationship: (profileData as DependentSubmit).guardian_relationship,
            email: (profileData as DependentSubmit).dependent_email || null,
          },
        );
        if (isFromSelectNeeds) {
          dispatch(
            setSelectedPatient({
              value: res.id.toString(),
              label: res.full_name,
            }),
          );
        }
      }
      if (avatarPayload && avatarFile && avatarAction === 'Upload') {
        const res = await registerAvatarMedia(avatarPayload);
        const mediaId = res.files[0].upload_id;
        await fetch(res.files[0].upload_url, {
          method: 'PUT',
          body: avatarFile,
          headers: { 'content-type': avatarFile.type },
        });
        await confirmAvatarMedia(mediaId, id);
        triggerProfileAvatar(res.files[0].upload_url);
      } else if (avatarAction === 'Remove') {
        await deleteAvatar(id);
        triggerProfileAvatar('');
      }

      updatedUser = await updateMe(
        {
          ...(profileData as UpdateMe),
          address: {
            address1: profileData.address1,
            address2: profileData.address2,
            city: profileData.city,
            state: profileData.state,
            zip_code: profileData.zip_code,
          },
        },
        id || 0,
      );

      const setPayload = (data: DependentSubmit): BasicInfo => {
        const { date_of_birth, first_name, last_name, middle_name, gender, pronouns, gender_identity } = data;

        const basicInfoPayload: BasicInfo = {
          date_of_birth: moment(date_of_birth).format('YYYY-MM-DD'),
          first_name: first_name as string,
          last_name: last_name as string,
          middle_name: middle_name as string,
          gender: gender as string,
          ...(pronouns && { pronouns }),
          gender_identity: gender_identity as string,
          languages: (profileData as DependentSubmit).language?.map((language) => language.id.toString()) || [],
        };
        return basicInfoPayload;
      };

      if (dependentId) {
        updatedUser = await saveBasicInformation(setPayload(profileData as DependentSubmit), id);
      } else {
        updatedUser = await createBasicInformation(setPayload(profileData as DependentSubmit), id);
      }

      if (
        reviewProfile &&
        getValues('dependent_clientTag') &&
        !areArraysEqual(selectedPatient.markers as Marker[], getValues('dependent_clientTag')!) &&
        userInfo.role.name !== UserRoleName.OrganizationAdmin
      ) {
        const updatedClientTags = getValues('dependent_clientTag');
        const clientTagIdList: number[] = [];

        if (updatedClientTags) {
          for (const updatedOrganization of updatedClientTags) {
            clientTagIdList.push(updatedOrganization.id as number);
          }
        }

        if (clientTagIdList.length > 0) {
          updatedUser = await updateUserClientTag(selectedPatient.id, {
            client_ids: clientTagIdList,
          });
        } else {
          updatedUser = await updateUserClientTag(selectedPatient.id, {
            client_ids: [],
          });
        }
      }

      if (
        (dependentId === undefined || dependentId === 0 || dependentId.toString() === '') &&
        (profileData as DependentSubmit).insurance_through !== 'none' &&
        (profileData as DependentSubmit).insurance_through !== '' &&
        (profileData as DependentSubmit).insurance_through !== undefined
      ) {
        const dataWithUpdatedInsurance = updateDataInsurance(profileData as DependentSubmit);
        const insuranceArray: Insurance[] = [];
        if (
          dataWithUpdatedInsurance.insured_by !== '' ||
          dataWithUpdatedInsurance.insurance_membership !== undefined ||
          dataWithUpdatedInsurance.insurance_through !== undefined
        )
          insuranceArray.push(dataWithUpdatedInsurance);
        if (addSecondaryInsurance) {
          const dataWithUpdatedSecondaryInsurance = updateDataSecondaryInsurance(profileData as DependentSubmit);
          insuranceArray.push(dataWithUpdatedSecondaryInsurance);
        }
        await createPatientInsurance({
          user_id: id,
          insurance: insuranceArray,
        });
      }

      if (!dependentId) {
        dispatch(
          addDependent({
            value: id.toString(),
            label: `${profileData.first_name} ${profileData.last_name}`,
            status: 'Active',
          }),
        );
      } else {
        dispatch(
          editDependent({
            value: id.toString(),
            label: `${profileData.first_name} ${profileData.last_name}`,
            status: 'Active',
          }),
        );
      }
      if (setIsEditDependent) setIsEditDependent(false);
    } catch (error: unknown) {
      displayErrorDetails(error);
    } finally {
      setIsSavingProfile(false);
      dispatch(setSelectedPatientByAdmin({ selectedPatient: updatedUser as UserProfile }));
      if (roleName === UserRoleName.Dependent) setThisUser(updatedUser as UserProfile);
      if (isFromSelectPatient) {
        fetchData(id || 0, updatedUser as UserProfile);
        dispatch(setIsFromSelectPatient(false));
        dispatch(setDependentId(null));

        if (isPaymentRequired && currentOrganizationPrices.toString() !== '0') {
          navigate('/payment-information', {
            state: { caseType: values.find((item) => item.form_question_id === 20)?.value.caseType as string },
          });
        } else if (
          (values.find((item) => item.form_question_id === 20)?.value.caseType as string) === 'video_call_scheduled' ||
          (values.find((item) => item.form_question_id === 20)?.value.caseType as string) ===
            CaseType.phone_call_scheduled ||
          (values.find((item) => item.form_question_id === 20)?.value.caseType as string) ===
            CaseType.oral_health_coaching
        ) {
          navigate('/select-date-time', {
            state: {
              caseType: values.find((item) => item.form_question_id === 20)?.value.caseType as string as string,
              insuranceCarrierId: '',
              customInsuranceCarrier: '',
              userOrganization: currentOrganization.id,
              patientId: userInfo.id,
            },
          });
        } else if (
          currentOrganizationPrices.toString() === '0' &&
          (values.find((item) => item.form_question_id === 20)?.value.caseType as string) === 'second_opinion'
        ) {
          navigate('/upload-image', {
            state: {
              caseType: values.find((item) => item.form_question_id === 20)?.value.caseType as string,
              patientState: userInfo.address?.state,
              userOrganization: currentOrganization.id,
              patientId: userInfo.id,
            },
          });
        } else if (
          (values.find((item) => item.form_question_id === 20)?.value.caseType as string) ===
            CaseType.care_coordination &&
          currentOrganizationPrices?.toString() === '0'
        ) {
          navigate('/select-patient', {
            state: { caseType: values.find((item) => item.form_question_id === 20)?.value.caseType as string },
          });
        } else {
          navigate('/select-needs', {
            state: {
              caseType: values.find((item) => item.form_question_id === 20)?.value.caseType as string,
              patientState: userInfo.address?.state,
              userOrganization: currentOrganization.id,
              patientId: userInfo.id,
            },
          });
        }
      }
    }
  };

  const updateCurrentAddress = (standardizedAddress: any, postalAddress: any, addressComponents: any): void => {
    const streetNumber = addressComponents.find(
      (addressComponent: any) => addressComponent.componentType === 'street_number',
    );
    const address1 = addressComponents.find((addressComponent: any) => addressComponent.componentType === 'route');
    if (postalAddress) setValue('city', postalAddress.locality, { shouldValidate: true });
    if (streetNumber || address1) {
      setValue('address1', `${streetNumber.componentName.text} ${address1.componentName.text}`, {
        shouldValidate: true,
      });
    }
    if (standardizedAddress?.state) setValue('state', standardizedAddress.state, { shouldValidate: true });
    if (postalAddress) setValue('zip_code', postalAddress.postalCode, { shouldValidate: true });
  };

  const onSubmit = async (profileData: DependentSubmit): Promise<void> => {
    await validateAddress(
      profileData,
      getValues as UseFormGetValues<UnifiedProfileSubmit>,
      validationResponseId,
      callSaveProfile,
      setValidationResponseId,
      setShowValidationAddressModal,
      onCloseConfirmationModal,
      updateCurrentAddress,
    );
  };

  const onInvalid = (errorsList: any): void => {
    const error = Object.values(errorsList)[0] as any;
    if (error) {
      toast.error(error.message as string);
    }
  };

  const onCancelEditDependent = (): void => {
    setIsEditDependent(false);
    if (isFromSelectPatient) {
      dispatch(setIsFromSelectPatient(false));
      dispatch(setDependentId(null));
      if (!isAdminRole) {
        navigate('/select-patient', {
          state: { caseType: values.find((item) => item.form_question_id === 20)?.value.caseType as string },
        });
      } else {
        navigate(`/admin/review-profile/Dependent/${selectedPatient.id}`, {
          state: { caseType: values.find((item) => item.form_question_id === 20)?.value.caseType as string },
        });
      }
    }
  };

  useEffect(() => {
    if (getValues('insurance_through') === 'none') {
      setAddSecondaryInsurance(false);
    }
  }, [getValues('insurance_through')]);

  useEffect(() => {
    if (image) triggerProfileAvatar(image);
  }, [image]);

  const convertResponseToFormData = (data: Dependent): DependentSubmit => {
    const { id, guardian_relationship, basic_info, picture_media_id, patient_profile, ...rest } = data;
    const dependentData: DependentSubmit = {
      ...rest,
      profile_img: '',
      first_name: basic_info?.first_name,
      middle_name: basic_info?.middle_name || '',
      last_name: basic_info?.last_name,
      email: rest.email || '',
      phone_number: rest.phone_number,
      zip_code: data?.address?.zip_code,
      state: data?.address?.state,
      date_of_birth: moment(basic_info?.date_of_birth).format('MM/DD/YYYY'),
      pronouns: basic_info?.pronouns || '',
      gender_identity: basic_info?.gender_identity || '',
      gender: basic_info?.gender,
      address1: data?.address?.address1,
      address2: data?.address?.address2 || '',
      city: data?.address?.city,
      country: data?.address?.country,
      profileAddress: data?.address?.address1,
      profileCity: data?.address?.city,
      id,
      patientId: id,
      guardian_relationship: guardian_relationship || '',
      dependent_email: rest.email || '',
      language:
        (basic_info?.languages?.map(
          (language) => languageOptions.find((option) => option.label === language.name) || null,
        ) as MultiValue<OptionLanguage>) || ([] as MultiValue<OptionLanguage>),
      state_funded_coverage_status:
        patientInsurance && patientInsurance[0] ? patientInsurance[0].state_funded_coverage_status : '',
      insurance_through: patientInsurance && patientInsurance[0]?.insured_by ? patientInsurance[0].insured_by : 'none',
      member_id:
        patientInsurance && patientInsurance[0]?.insurance_membership
          ? patientInsurance[0]?.insurance_membership.member_id
          : '',
      group_number:
        patientInsurance && patientInsurance[0]?.insurance_membership
          ? patientInsurance[0]?.insurance_membership.group_number
          : '',
      insurance_address:
        patientInsurance && patientInsurance[0]?.insurance_membership
          ? patientInsurance[0]?.insurance_membership.address?.address1
          : '',
      insurance_city:
        patientInsurance && patientInsurance[0]?.insurance_membership
          ? patientInsurance[0]?.insurance_membership.address?.city
          : '',
      insurance_state:
        patientInsurance && patientInsurance[0]?.insurance_membership
          ? patientInsurance[0]?.insurance_membership.address?.state
          : '',
      insurance_zip_code:
        patientInsurance && patientInsurance[0]?.insurance_membership
          ? patientInsurance[0]?.insurance_membership.address?.zip_code
          : '',
      iFirstName: (patientInsurance && patientInsurance[0]?.insurance_through?.first_name) || '',
      iMiddleName: (patientInsurance && patientInsurance[0]?.insurance_through?.middle_name) || '',
      iLastName: (patientInsurance && patientInsurance[0]?.insurance_through?.last_name) || '',
      iDateOfBirth:
        patientInsurance && patientInsurance[0]?.insurance_through
          ? moment(patientInsurance[0]?.insurance_through?.date_of_birth).format('MM/DD/YYYY')
          : '',
      iGenderIdentity: (patientInsurance && patientInsurance[0]?.insurance_through?.gender_identity) || '',
      iAddress1: (patientInsurance && patientInsurance[0]?.insurance_through?.address?.address1) || '',
      iAddress2: (patientInsurance && patientInsurance[0]?.insurance_through?.address?.address2) || '',
      iZipCode: (patientInsurance && patientInsurance[0]?.insurance_through?.address?.zip_code) || '',
      iState: (patientInsurance && patientInsurance[0]?.insurance_through?.address?.state) || '',
      iCity: (patientInsurance && patientInsurance[0]?.insurance_through?.address?.city) || '',
      iPhoneNumber:
        patientInsurance && patientInsurance[0]?.insurance_membership
          ? patientInsurance[0]?.insurance_membership.phone_number
          : '',
      insurance_carrier_id: patientInsurance && patientInsurance[0]?.insurance_membership?.insurance_carrier_id,

      secondary_insurance_through:
        patientInsurance && patientInsurance[1]?.insured_by ? patientInsurance[1].insured_by : 'none',
      secondary_member_id:
        patientInsurance && patientInsurance[1]?.insurance_membership
          ? patientInsurance[1].insurance_membership.member_id
          : '',
      secondary_group_number:
        patientInsurance && patientInsurance[1]?.insurance_membership
          ? patientInsurance[1].insurance_membership.group_number
          : '',
      secondary_insurance_address:
        patientInsurance && patientInsurance[1]?.insurance_membership
          ? patientInsurance[1].insurance_membership?.address?.address1 || ''
          : '',
      secondary_insurance_city:
        patientInsurance && patientInsurance[1]?.insurance_membership
          ? patientInsurance[1].insurance_membership?.address?.city || ''
          : '',
      secondary_insurance_state:
        patientInsurance && patientInsurance[1]?.insurance_membership
          ? patientInsurance[1].insurance_membership?.address?.state || ''
          : '',
      secondary_insurance_zip_code:
        patientInsurance && patientInsurance[1]?.insurance_membership
          ? patientInsurance[1].insurance_membership?.address?.zip_code || ''
          : '',
      secondary_iFirstName: (patientInsurance && patientInsurance[1]?.insurance_through?.first_name) || '',
      secondary_iMiddleName: (patientInsurance && patientInsurance[1]?.insurance_through?.middle_name) || '',
      secondary_iLastName: (patientInsurance && patientInsurance[1]?.insurance_through?.last_name) || '',
      secondary_iDateOfBirth:
        patientInsurance && patientInsurance[1]?.insurance_through
          ? moment(patientInsurance[1]?.insurance_through?.date_of_birth).format('MM/DD/YYYY')
          : '',
      secondary_iGenderIdentity: (patientInsurance && patientInsurance[1]?.insurance_through?.gender_identity) || '',
      secondary_iAddress1: (patientInsurance && patientInsurance[1]?.insurance_through?.address?.address1) || '',
      secondary_iAddress2: (patientInsurance && patientInsurance[1]?.insurance_through?.address?.address2) || '',
      secondary_iZipCode: (patientInsurance && patientInsurance[1]?.insurance_through?.address?.zip_code) || '',
      secondary_iState: (patientInsurance && patientInsurance[1]?.insurance_through?.address?.state) || '',
      secondary_iCity: (patientInsurance && patientInsurance[1]?.insurance_through?.address?.city) || '',
      secondary_iPhoneNumber:
        patientInsurance && patientInsurance[1]?.insurance_membership
          ? patientInsurance[1].insurance_membership.phone_number
          : '',
      secondary_insurance_carrier_id:
        patientInsurance && patientInsurance[1]?.insurance_membership?.insurance_carrier_id,
    };

    return dependentData;
  };

  const setInitialValues = (data: Dependent): void => {
    const dependentData = convertResponseToFormData(data);
    if (Object.keys(dependentData).length > 0) {
      Object.keys(dependentData).forEach((k: unknown) => {
        setValue(k as keyof DependentSubmit, dependentData[k as keyof DependentSubmit]);
      });
    }
    let selectedOptions: MultiValue<Option> = [];
    if (selectedPatient?.markers) {
      selectedOptions = (selectedPatient.markers as Marker[])
        .map((marker) => clientTagOptions.find((option): option is Option => option?.value === marker.value))
        .filter((option): option is Option => option !== undefined);
    }
    if (selectedOptions && selectedOptions?.length > 0) {
      setValue('dependent_clientTag', selectedOptions);
    }
    setDependentProfileForm(dependentData);
  };

  useEffect(() => {
    if (dependentId) {
      (async () => {
        const dependent = await getDependentById(
          userInfo.role.name === UserRoleName.Admin ||
            userInfo.role.name === UserRoleName.SuperAdmin ||
            userInfo.role.name === UserRoleName.OrganizationAdmin
            ? selectedPatient.guardian_id !== null
              ? selectedPatient.guardian_id
              : selectedPatient.id
            : userInfo.id,
          dependentId,
        );
        if (dependent) {
          setInitialValues(dependent);

          dispatch(setSelectedPatientByAdmin({ selectedPatient: dependent }));
        }
      })();
    }
  }, [dependentId, patientInsurance]);

  const fetchUserInsurance = async (): Promise<void> => {
    if (dependentId) {
      const userInsurance = await getPatientInsurance(dependentId);
      setPatientInsurance(userInsurance);
    }
  };

  useEffect(() => {
    if (selectedPatient.address === null) {
      selectSameAsGuardian();
    }
    if (
      userInfo.role.name === UserRoleName.Patient ||
      userInfo.role.name === UserRoleName.Admin ||
      userInfo.role.name === UserRoleName.SuperAdmin ||
      userInfo.role.name === UserRoleName.OrganizationAdmin
    )
      fetchUserInsurance();
  }, []);

  const setTextValue = (e: React.ChangeEvent<HTMLInputElement>, name: string): void => {
    const { value } = e.target;
    setValue(name as keyof DependentSubmit, value, { shouldValidate: true });
    setSelection([e.target.selectionStart, e.target.selectionEnd]);
  };

  return (
    <>
      {!isModal && !isFromSelectPatient && (
        <div className={styles.backToPrimaryProfileBtn}>
          <button type="button" className={styles.backBtn} onClick={onCancelEditDependent}>
            <MdKeyboardArrowLeft />
            Back to Primary Profile
          </button>
        </div>
      )}
      <div className={styles.editProfileContainer}>
        <div className={styles.titleContainer}>
          <span className={styles.title}>Dependent Profile</span>
        </div>
        <div className={styles.editContainer}>
          <div className={`d-flex justify-content-between ${styles.mobileFlexCol}`}>
            <ProfileImage
              id={dependentId ? dependentId.toString() : undefined}
              setAvatarFile={setAvatarFile}
              setAvatarPayload={setAvatarPayload}
              setAvatarAction={setAvatarAction}
              setImage={setImage}
              image={image}
              size="80px"
              isNewDependent={!dependentId}
            />
            <div className={styles.relationshipToGuardian}>
              <div className={styles.caption}>
                Guardian's relationship to Dependent <span>*</span>
              </div>
              <SelectInput
                {...register('guardian_relationship')}
                name="guardian_relationship"
                options={relationshipOptions}
                containerClass={`${styles.inputWrapper} mt-1`}
                selectorClass={styles.selector}
                style={selectCommonCustomStylesLarge}
                errors={errors}
                selectedValue={
                  relationshipOptions.find((option) => option.label === getValues('guardian_relationship'))?.value
                }
                onChange={(value) => {
                  if (value) {
                    setValue('guardian_relationship', value.label, { shouldValidate: true });
                  }
                }}
              />
            </div>
          </div>
          <p className={styles.requiredIndicator}>
            Required <span>*</span>
          </p>
          <form className={styles.formContainer} onSubmit={handleSubmit(onSubmit, onInvalid)}>
            <div className={styles.row}>
              <div className={cn(styles.rowItem, styles.details)}>
                <div className={styles.caption}>
                  First Name <span>*</span>
                </div>
                <TextField
                  {...register('first_name')}
                  errors={errors}
                  name="first_name"
                  value={getValues('first_name')}
                  onChange={(e) => setTextValue(e, 'first_name')}
                  wrapperClassName={styles.inputWrapper}
                  inputClass={styles.input}
                />
              </div>
              <div className={cn(styles.rowItem, styles.details)}>
                <div className={styles.caption}>Middle Name</div>
                <TextField
                  {...register('middle_name')}
                  errors={errors}
                  name="middle_name"
                  value={getValues('middle_name')}
                  onChange={(e) => setTextValue(e, 'middle_name')}
                  wrapperClassName={styles.inputWrapper}
                  inputClass={styles.input}
                />
              </div>
              <div className={cn(styles.rowItem, styles.details)}>
                <div className={styles.caption}>
                  Last Name <span>*</span>
                </div>
                <TextField
                  {...register('last_name')}
                  errors={errors}
                  name="last_name"
                  value={getValues('last_name')}
                  onChange={(e) => setTextValue(e, 'last_name')}
                  wrapperClassName={styles.inputWrapper}
                  inputClass={styles.input}
                />
              </div>
            </div>
            <div className={styles.row}>
              <div className={cn(styles.rowItem, styles.gender)}>
                <div className={styles.caption}>
                  Gender assigned at birth <span>*</span>
                </div>
                <SelectButtons
                  errors={errors}
                  setValue={setValue}
                  form={dependentProfileForm}
                  value="gender"
                  options={genderOptions}
                />
              </div>
            </div>
            <div className={styles.row}>
              <div className={cn('col-md-6', styles.rowItem, styles.details, styles.half)}>
                <div className={styles.caption}>
                  Gender identity <span>{isAdminRole && !isFromSelectPatient ? '' : '*'}</span>
                </div>
                <SelectInput
                  {...register('gender_identity')}
                  name="gender_identity"
                  options={genderIdentity}
                  containerClass={styles.inputWrapper}
                  selectorClass={styles.selector}
                  style={selectCommonCustomStylesLarge}
                  errors={errors}
                  selectedValue={getValues('gender_identity')}
                  onChange={(value) => {
                    if (value) {
                      setValue('gender_identity', value.value, { shouldValidate: true });
                    }
                  }}
                />
              </div>
              <div className={cn('col-md-6', styles.rowItem, styles.details, styles.half)}>
                <div className={styles.caption}>Pronouns</div>
                <SelectInput
                  {...register('pronouns')}
                  name="pronouns"
                  options={pronounsOptions}
                  containerClass={styles.inputWrapper}
                  selectorClass={styles.selector}
                  style={selectCommonCustomStylesLarge}
                  errors={errors}
                  selectedValue={getValues('pronouns')}
                  onChange={(value) => {
                    if (value) {
                      setValue('pronouns', value.value, { shouldValidate: true });
                    }
                  }}
                />
              </div>
            </div>
            <div className={styles.row}>
              <div className={cn(styles.rowItem, styles.details, styles.dobContainer)}>
                <div className={styles.caption}>
                  Date of Birth <span>*</span>
                </div>
                <TextField
                  {...register('date_of_birth')}
                  errors={errors}
                  name="date_of_birth"
                  placeholder="MM / DD / YYYY"
                  value={getValues('date_of_birth') as string}
                  onChange={(e) => {
                    setValue('date_of_birth', checkAndSetDate(e), { shouldValidate: true });
                    setSelection([e.target.selectionStart, e.target.selectionEnd]);
                  }}
                  maxLength={10}
                  wrapperClassName={styles.inputWrapper}
                  inputClass={styles.input}
                  enableCursorPos={false}
                />
              </div>
              <div className={cn(styles.rowItem, styles.details, styles.big, styles.languages)}>
                <div className={styles.caption}>
                  Languages <span>*</span>
                </div>
                <MultiselectInput
                  {...register('language')}
                  name="language"
                  placeholder="Please add any languages you are comfortable speaking during the consult"
                  options={languageOptions}
                  style={multiSelectCommonCustomStylesLarge}
                  containerClass={styles.inputWrapper}
                  selectorClass={styles.selector}
                  errors={errors}
                  selectedValue={getValues('language')}
                  onChange={(value) => {
                    if (value) {
                      setValue('language', value as MultiValue<OptionLanguage>, {
                        shouldValidate: true,
                      });
                    }
                  }}
                />
              </div>
            </div>
            <div className={styles.separator} />
            <div key={key}>
              <div className={styles.optional}>
                <div className={`${styles.checkInput} ${styles.deoendentCheckInput}`}>
                  <div className="form-check m-0">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      checked={
                        getValues('address1') === guardianPatient?.address?.address1 &&
                        getValues('address2') === guardianPatient?.address?.address2 &&
                        getValues('city') === guardianPatient?.address?.city &&
                        guardianPatient?.address?.state !== undefined &&
                        getValues('state') !== undefined &&
                        getValues('state') === guardianPatient?.address?.state &&
                        getValues('zip_code') === guardianPatient?.address?.zip_code
                      }
                      onChange={(e) => selectSameAsGuardian(e.target.checked)}
                      id="optionalInput"
                      disabled={!guardianPatient?.address?.address1}
                    />
                    <label className={styles.optionalLabel} htmlFor="optionalInput">
                      Same as Guardian
                    </label>
                  </div>
                </div>
              </div>
              <div className={styles.row}>
                <AddressSection
                  register={register}
                  errors={errors}
                  getValues={getValues}
                  setTextValue={setTextValue}
                  setValue={setValue}
                  showValidationAddressModal={showValidationAddressModal}
                  required
                />
              </div>
            </div>
            <div className={styles.separator} />
            <div className={styles.row}>
              <div className={styles.rowItem}>
                <div className={styles.caption}>Email Address</div>
                <TextField
                  {...register('dependent_email')}
                  errors={errors}
                  name="dependent_email"
                  value={getValues('dependent_email')}
                  onChange={(e) => setTextValue(e, 'dependent_email')}
                  wrapperClassName={styles.inputWrapper}
                  inputClass={styles.input}
                />
              </div>
            </div>
            {reviewProfile &&
              userInfo.role.name !== UserRoleName.OrganizationAdmin &&
              selectedPatient?.organizations?.some((org: any) => org.id === 'dentistry-one') && (
                <div className={styles.row}>
                  <div className={cn(styles.rowItem, styles.details, styles.big)}>
                    <div className={styles.caption}>Client Tags</div>
                    <MultiselectInput
                      {...register('dependent_clientTag')}
                      name="dependent_clientTag"
                      options={clientTagOptions as { id: string; value: string; label: string }[]}
                      containerClass={styles.inputWrapper}
                      selectorClass={styles.selector}
                      style={selectCommonCustomStylesLarge}
                      selectedValue={getValues('dependent_clientTag')}
                      errors={errors}
                      onChange={(selectedOptions) => {
                        if (selectedOptions) {
                          const selectedClientTags = selectedOptions as MultiValue<Option>;

                          setValue('dependent_clientTag', selectedClientTags, { shouldValidate: true });
                        }
                      }}
                    />
                  </div>
                </div>
              )}
            {(dependentId === undefined || dependentId === 0 || dependentId.toString() === '') && (
              <>
                <div className={styles.separator} />
                <div className={styles.editProfileContainer}>
                  <div className={styles.titleContainer}>
                    <span className={styles.title}>Insurance Information</span>
                  </div>
                  <div className={styles.editContainer}>
                    <div className={styles.row}>
                      <div className={styles.rowItem}>
                        <div className={styles.caption}>I'm Insured By</div>
                        <SelectInput
                          {...register('insurance_through')}
                          name="insurance_through"
                          options={insuranceThroughOptions}
                          containerClass={styles.inputWrapper}
                          selectorClass={styles.selector}
                          style={selectCommonCustomStylesLarge}
                          errors={errors}
                          selectedValue={getValues('insurance_through')}
                          onChange={(value) => {
                            if (value) {
                              setValue('insurance_through', value.value, { shouldValidate: true });
                            }
                          }}
                        />
                      </div>
                    </div>
                    {getValues('insurance_through') &&
                      getValues('insurance_through') !== 'none' &&
                      getValues('insurance_through') !== undefined && (
                        <>
                          <div className={styles.row}>
                            <div className={styles.rowItem}>
                              <div className={styles.caption}>
                                Insurance company <span>*</span>
                              </div>
                              <SelectInput
                                {...register('insurance_carrier_id')}
                                name="insurance_carrier_id"
                                options={insuranceCarrierOptions}
                                containerClass={styles.inputWrapper}
                                selectorClass={styles.selector}
                                style={selectCommonCustomStylesLarge}
                                errors={errors}
                                selectedValue={getValues('insurance_carrier_id')}
                                onChange={(value) => {
                                  if (value) {
                                    setValue('insurance_carrier_id', value.value, { shouldValidate: true });
                                  }
                                }}
                              />
                            </div>
                          </div>
                          <div className={styles.row}>
                            <div className={styles.rowItem}>
                              <div className={styles.caption}>
                                Member ID <span>*</span>
                              </div>
                              <TextField
                                {...register('member_id')}
                                errors={errors}
                                name="member_id"
                                value={getValues('member_id')}
                                onChange={(e) => {
                                  setValue('member_id', e.target.value, { shouldValidate: true });
                                  setSelection([e.target.selectionStart, e.target.selectionEnd]);
                                }}
                                wrapperClassName={styles.inputWrapper}
                                inputClass={styles.input}
                              />
                            </div>
                          </div>
                          <div className={styles.row}>
                            <div className={styles.rowItem}>
                              <div className={styles.caption}>
                                Group Number <span>*</span>
                              </div>
                              <TextField
                                {...register('group_number')}
                                errors={errors}
                                name="group_number"
                                value={getValues('group_number')}
                                onChange={(e) => {
                                  setValue('group_number', e.target.value, { shouldValidate: true });
                                  setSelection([e.target.selectionStart, e.target.selectionEnd]);
                                }}
                                wrapperClassName={styles.inputWrapper}
                                inputClass={styles.input}
                              />
                            </div>
                          </div>
                          <div className={styles.row}>
                            <div className={styles.rowItem}>
                              <div className={styles.caption}>
                                Phone number at the back of your insurance card (if multiple, use the provider number)
                                <span> *</span>
                              </div>
                              <PhoneInput
                                {...register('iPhoneNumber')}
                                value={getValues('iPhoneNumber') as E164Number}
                                id="iPhoneNumber"
                                onChange={(val) => {
                                  setValue('iPhoneNumber', val as string, {
                                    shouldValidate: true,
                                  });
                                }}
                                inputClass={styles.input}
                                errors={errors}
                                wrapperClassName={styles.inputWrapper}
                              />
                            </div>
                          </div>
                          <div className={styles.row}>
                            <div className={styles.rowItem}>
                              <div className={styles.caption}>
                                Insurance Address
                                <span className={styles.redText}> *</span>
                              </div>
                              <TextField
                                {...register('insurance_address')}
                                errors={errors}
                                name="insurance_address"
                                value={getValues('insurance_address')}
                                onChange={(e) => {
                                  const { value } = e.target;
                                  onChange('insurance_address', value);
                                }}
                                wrapperClassName={styles.inputWrapper}
                                inputClass={styles.input}
                              />
                            </div>
                          </div>
                          <div className={styles.row}>
                            <div className={styles.rowItem}>
                              <div className={styles.caption}>
                                Insurance City
                                <span className={styles.redText}> *</span>
                              </div>
                              <TextField
                                {...register('insurance_city')}
                                errors={errors}
                                name="insurance_city"
                                value={getValues('insurance_city')}
                                onChange={(e) => {
                                  const { value } = e.target;
                                  onChange('insurance_city', value);
                                }}
                                wrapperClassName={styles.inputWrapper}
                                inputClass={styles.input}
                              />
                            </div>
                            <div className={styles.rowItem}>
                              <div className={styles.caption}>
                                Insurance State
                                <span className={styles.redText}> *</span>
                              </div>
                              <SelectInput
                                {...register('insurance_state')}
                                name="insurance_state"
                                options={stateOptions}
                                containerClass={styles.inputWrapper}
                                selectorClass={styles.selector}
                                style={selectCommonCustomStylesLarge}
                                errors={errors}
                                selectedValue={getValues('insurance_state')}
                                onChange={(value) => {
                                  if (value) {
                                    onChange('insurance_state', value.value);
                                  }
                                }}
                              />
                            </div>
                            <div className={styles.rowItem}>
                              <div className={styles.caption}>
                                Insurance Zip Code
                                <span className={styles.redText}> *</span>
                              </div>
                              <TextField
                                {...register('insurance_zip_code')}
                                errors={errors}
                                name="insurance_zip_code"
                                value={getValues('insurance_zip_code')}
                                onChange={(e) => {
                                  const { value } = e.target;
                                  onChange('insurance_zip_code', value);
                                }}
                                wrapperClassName={styles.inputWrapper}
                                inputClass={styles.input}
                              />
                            </div>
                          </div>
                        </>
                      )}
                    {getValues('insurance_through') &&
                      getValues('insurance_through') !== 'myself' &&
                      getValues('insurance_through') !== 'none' &&
                      getValues('insurance_through') !== undefined && (
                        <div className={styles.insuranceThroughContainer}>
                          <div className={styles.row}>
                            <div className={`${styles.rowItem} ${styles.midRow}`}>
                              <div className={styles.caption}>Insurance Holder’s First Name</div>
                              <TextField
                                {...register('iFirstName')}
                                errors={errors}
                                name="iFirstName"
                                value={getValues('iFirstName')}
                                onChange={(e) => {
                                  setValue('iFirstName', e.target.value, { shouldValidate: true });
                                  setSelection([e.target.selectionStart, e.target.selectionEnd]);
                                }}
                                wrapperClassName={styles.inputWrapper}
                                inputClass={styles.input}
                              />
                            </div>
                            <div className={`${styles.rowItem} ${styles.midRow}`}>
                              <div className={styles.caption}>Insurance Holder’s Middle Name</div>
                              <TextField
                                {...register('iMiddleName')}
                                errors={errors}
                                name="iMiddleName"
                                value={getValues('iMiddleName')}
                                onChange={(e) => {
                                  setValue('iMiddleName', e.target.value, { shouldValidate: true });
                                  setSelection([e.target.selectionStart, e.target.selectionEnd]);
                                }}
                                wrapperClassName={styles.inputWrapper}
                                inputClass={styles.input}
                              />
                            </div>
                            <div className={`${styles.rowItem} ${styles.midRow}`}>
                              <div className={styles.caption}>Insurance Holder’s Last Name</div>
                              <TextField
                                {...register('iLastName')}
                                errors={errors}
                                name="iLastName"
                                value={getValues('iLastName')}
                                onChange={(e) => {
                                  setValue('iLastName', e.target.value, { shouldValidate: true });
                                  setSelection([e.target.selectionStart, e.target.selectionEnd]);
                                }}
                                wrapperClassName={styles.inputWrapper}
                                inputClass={styles.input}
                              />
                            </div>
                          </div>
                          <div className={styles.row}>
                            <div className={styles.rowItem}>
                              <div className={styles.caption}>Insurance Holder’s Date of Birth</div>
                              <TextField
                                {...register('iDateOfBirth')}
                                errors={errors}
                                name="iDateOfBirth"
                                value={getValues('iDateOfBirth')}
                                onChange={(e) => {
                                  setValue('iDateOfBirth', checkAndSetDate(e), {
                                    shouldValidate: true,
                                  });
                                  setSelection([e.target.selectionStart, e.target.selectionEnd]);
                                }}
                                maxLength={10}
                                wrapperClassName={styles.inputWrapper}
                                inputClass={styles.input}
                                isUnderlined={false}
                                enableCursorPos={false}
                              />
                            </div>
                          </div>
                          <div className={styles.row}>
                            <div className={styles.rowItem}>
                              <div className={styles.caption}>Insurance Holder’s Gender Identity</div>
                              <SelectInput
                                {...register('iGenderIdentity')}
                                name="iGenderIdentity"
                                options={genderIdentity}
                                containerClass={styles.inputWrapper}
                                selectorClass={styles.selector}
                                style={selectCommonCustomStylesLarge}
                                errors={errors}
                                selectedValue={getValues('iGenderIdentity')}
                                onChange={(value) => {
                                  if (value) {
                                    setValue('iGenderIdentity', value.value, {
                                      shouldValidate: true,
                                    });
                                  }
                                }}
                              />
                            </div>
                          </div>
                          <div className={styles.row}>
                            <div className={styles.rowItem}>
                              <div className={styles.caption}>Insurance Holder’s Street Address</div>
                              <TextField
                                {...register('iAddress1')}
                                errors={errors}
                                name="iAddress1"
                                value={getValues('iAddress1')}
                                onChange={(e) => {
                                  setValue('iAddress1', e.target.value, { shouldValidate: true });
                                  setSelection([e.target.selectionStart, e.target.selectionEnd]);
                                }}
                                wrapperClassName={styles.inputWrapper}
                                inputClass={styles.input}
                                isUnderlined={false}
                              />
                            </div>
                          </div>
                          <div className={styles.row}>
                            <div className={styles.rowItem}>
                              <div className={styles.caption}>Apt, suite, building (optional)</div>
                              <TextField
                                {...register('iAddress2')}
                                errors={errors}
                                name="iAddress2"
                                value={getValues('iAddress2')}
                                onChange={(e) => {
                                  setValue('iAddress2', e.target.value, { shouldValidate: true });
                                  setSelection([e.target.selectionStart, e.target.selectionEnd]);
                                }}
                                wrapperClassName={styles.inputWrapper}
                                inputClass={styles.input}
                                isUnderlined={false}
                              />
                            </div>
                          </div>
                          <div className={styles.row}>
                            <div className={`${styles.rowItem} ${styles.trimesterRow}`}>
                              <div className={styles.caption}>City</div>
                              <TextField
                                {...register('iCity')}
                                errors={errors}
                                name="iCity"
                                value={getValues('iCity')}
                                onChange={(e) => {
                                  setValue('iCity', e.target.value, { shouldValidate: true });
                                  setSelection([e.target.selectionStart, e.target.selectionEnd]);
                                }}
                                wrapperClassName={styles.inputWrapper}
                                inputClass={styles.input}
                                isUnderlined={false}
                              />
                            </div>
                            <div className={`${styles.rowItem} ${styles.trimesterRow}`}>
                              <div className={styles.caption}>State</div>
                              <SelectInput
                                {...register('iState')}
                                containerClass="mt-0"
                                name="iState"
                                options={stateOptions}
                                style={profileSelectStyles}
                                errors={errors}
                                selectedValue={getValues('iState')}
                                onChange={(value) => {
                                  setValue('iState', value ? value.value : '', { shouldValidate: true });
                                }}
                              />
                            </div>
                            <div className={`${styles.rowItem} ${styles.trimesterRow}`}>
                              <div className={styles.caption}>Zip Code</div>
                              <TextField
                                {...register('iZipCode')}
                                errors={errors}
                                name="iZipCode"
                                value={getValues('iZipCode')}
                                onChange={(e) => {
                                  setValue('iZipCode', e.target.value, { shouldValidate: true });
                                  setSelection([e.target.selectionStart, e.target.selectionEnd]);
                                }}
                                wrapperClassName={styles.inputWrapper}
                                inputClass={styles.input}
                                isUnderlined={false}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    {getValues('insurance_through') !== 'none' &&
                      getValues('insurance_through') !== '' &&
                      getValues('insurance_through') !== undefined && (
                        <div className={`${styles.row}`} style={{ flexDirection: 'row' }}>
                          <div className={`${styles.rowItem}`}>
                            <div className={styles.caption}>
                              Do you currently have insurance coverage through a state-funded program such as Medicaid?
                              <span> *</span>
                            </div>
                            <div className={styles.optionsDiv}>
                              {stateFundedProgramOptions.map((selection) => (
                                <button
                                  className={
                                    isSelected === selection.id
                                      ? `${styles.multiSelectorInsuranceCoverage} ${styles.buttonSelectedInsuranceCoverage}`
                                      : `${styles.multiSelectorInsuranceCoverage}`
                                  }
                                  value={getValues('state_funded_coverage_status')}
                                  {...register('state_funded_coverage_status')}
                                  type="button"
                                  onClick={() => {
                                    setIsSelected(selection.id);
                                    setValue('state_funded_coverage_status', selection.value, { shouldValidate: true });
                                  }}
                                  key={selection.id}
                                >
                                  <span>{selection.label}</span>
                                  <div className={styles.checkInput} />
                                </button>
                              ))}
                            </div>
                          </div>
                        </div>
                      )}
                    {getValues('insurance_through') !== 'none' &&
                      getValues('insurance_through') !== '' &&
                      getValues('insurance_through') !== undefined && (
                        <div className={`${styles.addSecondaryInsurance} form-check`}>
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="isAdditionalInsurance"
                            checked={addSecondaryInsurance}
                            onChange={(event) => {
                              setAddSecondaryInsurance(event.target.checked);
                            }}
                          />
                          <label
                            className={`form-check-label ${styles.additionalPeopleCheckLabel}`}
                            htmlFor="isAdditionalInsurance"
                          >
                            Add Secondary Insurance
                          </label>
                        </div>
                      )}
                    {addSecondaryInsurance &&
                      getValues('insurance_through') !== 'none' &&
                      getValues('insurance_through') !== undefined && (
                        <SecondaryInsuranceFields
                          register={register}
                          errors={errors}
                          getValues={getValues}
                          onChange={onChange}
                        />
                      )}
                  </div>
                </div>
              </>
            )}
            <div className={styles.actionBtnContainer}>
              <Button type="button" className={styles.cancel} onClick={onCancelEditDependent}>
                Cancel
              </Button>
              <Button
                type="submit"
                className={styles.submit}
                disabled={
                  (isEqual(dependentProfileForm, getValues()) && isEqual(avatarAction, '')) ||
                  isSavingProfile ||
                  fetchLoading
                }
              >
                {isFromSelectPatient && !isEditInsuranceFlag
                  ? 'Continue'
                  : isFromSelectPatient && isEditInsuranceFlag && !isEqual(dependentProfileForm, getValues())
                  ? (values.find((item) => item.form_question_id === 20)?.value.caseType as string) ===
                      CaseType.care_coordination && currentOrganizationPrices?.toString() === '0'
                    ? 'Save'
                    : 'Continue'
                  : 'Save'}
              </Button>
            </div>
          </form>
          {dependentId !== undefined &&
            dependentId !== null &&
            dependentId !== 0 &&
            dependentId.toString() !== '' &&
            [
              UserRoleName.Patient,
              UserRoleName.Admin,
              UserRoleName.SuperAdmin,
              UserRoleName.OrganizationAdmin,
            ].includes(userInfo.role.name) && (
              <>
                {!isFromSelectPatient || (isEditInsuranceFlag && <div className={styles.separator} />)}
                <div className={styles.row}>
                  <div className={styles.rowItem}>
                    {isEditInsurance || (isFromSelectPatient && isEditInsuranceFlag) ? (
                      <EditInsurance
                        myProfileForm={dependentProfileForm}
                        patientInsurance={patientInsurance!}
                        setPatientInsurance={setPatientInsurance}
                        setIsEditInsurance={setIsEditInsurance}
                        setSelection={setSelection}
                        loading={false}
                        thisUser={selectedPatient as UserProfile}
                        dependentId={dependentId}
                      />
                    ) : !isFromSelectPatient || isEditInsuranceFlag ? (
                      <ReadOnlyInsurance
                        onEditInsurance={() => {
                          setIsEditInsurance(true);
                        }}
                        patientInsurance={patientInsurance!}
                      />
                    ) : null}
                  </div>
                </div>
              </>
            )}
        </div>
      </div>
      <ConfirmationModal
        title="Confirmation"
        content="Are you sure you want to delete dependent?"
        isOpen={showDeleteDependentConfirmationModal || false}
        onClose={onCloseConfirmationModal}
        onConfirm={onContinueConfirmationModal}
        isSubmitDisabled={isSavingProfile}
      />
    </>
  );
};

EditDependent.defaultProps = {
  dependentId: '',
  isModal: false,
  reviewProfile: false,
  showDeleteDependentConfirmationModal: false,
  setShowDeleteDependentConfirmationModal: () => {},
};

export default EditDependent;
