/* eslint-disable no-nested-ternary */
import { selectAuth } from '@brands/store/selectors/auth';
import axios from 'axios';
import cn from 'classnames';
import React, { useEffect, useState } from 'react';
import { FieldErrorsImpl } from 'react-hook-form';
import { BiEdit } from 'react-icons/bi';
import { BsPerson } from 'react-icons/bs';
import { FaSpinner } from 'react-icons/fa';
import { IoMdClose } from 'react-icons/io';

import { CAMyProfileSubmit } from '../../Dashboard/MyProfile/CareAdvisor/utils/types';
import { useAppSelector } from '../../hooks/useReduxHook';
import { RegisterMediaPayload } from '../../services/communication/registersConversationMedia';
import { getUserAvatar } from '../../services/identity/getUserAvatar';
import styles from './styles.module.scss';
import { heicTo } from 'heic-to';
import { displayErrorDetails } from '@brands/Utils/displayError';

type ProfileImageProps = {
  triggerProfileAvatar?: ((avatarUrl: string) => void) | null;
  className?: string;
  id?: string;
  isUpload?: boolean;
  size?: string;
  errors?: Partial<FieldErrorsImpl<CAMyProfileSubmit>> | null;
  disableDelete?: boolean;
  setAvatarPayload?: React.Dispatch<React.SetStateAction<RegisterMediaPayload | null>> | null;
  setAvatarFile?: React.Dispatch<React.SetStateAction<File | null>> | null;
  setAvatarAction?: React.Dispatch<React.SetStateAction<string>> | null;
  setCurrentAvatarURL?: React.Dispatch<React.SetStateAction<string>> | null;
  setImage?: React.Dispatch<React.SetStateAction<string | undefined>> | null;
  image?: string | undefined | null;
  reviewProfile?: boolean;
  isNewDependent?: boolean;
};

const ProfileImage: React.FC<ProfileImageProps> = ({
  triggerProfileAvatar,
  className,
  disableDelete,
  id,
  isUpload,
  size,
  errors,
  setAvatarPayload,
  setAvatarFile,
  setAvatarAction,
  setCurrentAvatarURL,
  setImage,
  image,
  reviewProfile,
  isNewDependent,
}) => {
  const inputElm = React.useRef<HTMLInputElement>(null);
  const { userInfo: userInfoInContext } = useAppSelector(selectAuth);
  const [loading, setLoading] = useState(true);
  const [thisImage, setThisImage] = useState<string | undefined | null>(image);

  useEffect(() => {
    if (!isNewDependent) {
      const getAvatar = async (): Promise<void> => {
        if (id || userInfoInContext) {
          try {
            const response = await axios.get(getUserAvatar(id || userInfoInContext?.id));
            const url = await response.config.url;
            if (setImage) setImage(url);
            if (setCurrentAvatarURL && url) setCurrentAvatarURL(url);
            setThisImage(url);
            setLoading(false); // Set loading to false after image is loaded
          } catch (e: any) {
            if (setImage) setImage(undefined);
            if (setCurrentAvatarURL) setCurrentAvatarURL('');
            setThisImage(undefined);
            setLoading(false); // Set loading to false if there's an error
            // toast.error(e.message);
          }
        }
      };
      const getAvatarById = async (): Promise<void> => {
        if (id) {
          try {
            const response = await axios.get(getUserAvatar(Number(id)));
            const url = await response.config.url;
            if (setImage) setImage(url);
            if (setCurrentAvatarURL && url) setCurrentAvatarURL(url);
            setThisImage(url);
            setLoading(false);
          } catch (e: any) {
            if (setImage) setImage(undefined);
            if (setCurrentAvatarURL) setCurrentAvatarURL('');
            setThisImage(undefined);
            setLoading(false);
          }
        }
      };
      if (!reviewProfile) getAvatar();
      else if (reviewProfile && id) getAvatarById();
    } else {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (setCurrentAvatarURL && image) setCurrentAvatarURL(image);
  }, [setCurrentAvatarURL]);

  useEffect(() => {
    if (triggerProfileAvatar && image) triggerProfileAvatar(image);
  }, [triggerProfileAvatar]);

  const onEdit = (): void => {
    if (inputElm.current) {
      inputElm.current.click();
    }
  };

  const onDelete = (): void => {
    if (setAvatarAction) setAvatarAction('Remove');
    if (setImage) setImage(undefined);
    setThisImage(undefined);
    if (triggerProfileAvatar) triggerProfileAvatar('');
  };

  const uploadImage = async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    if (setAvatarAction) setAvatarAction('Upload');

    const selectedFiles = event.target.files;
    if (!selectedFiles || selectedFiles.length === 0) return;

    const file = selectedFiles[0];
    let convertedFile = file;

    try {
      if (file.type === 'image/heic' || file.name.endsWith('.heic')) {
        try {
          const jpegBlob = await heicTo({
            blob: file,
            type: 'image/jpeg',
            quality: 0.5,
          });

          convertedFile = new File([jpegBlob], file.name.replace(/\.heic$/i, '.jpeg'), {
            type: 'image/jpeg',
          });
        } catch (error) {
          console.error('Error converting HEIC to JPEG:', error);
          displayErrorDetails(`Error converting HEIC to JPEG: ${error}`);
          return;
        }
      }

      const uploadedPic = URL.createObjectURL(convertedFile);
      const payload: RegisterMediaPayload = {
        files: [
          {
            filename: convertedFile.name,
            mimetype: convertedFile.type,
          },
        ],
      };

      if (setImage) setImage(uploadedPic);
      setThisImage(uploadedPic);
      if (setAvatarPayload) setAvatarPayload(payload);
      if (setAvatarFile) setAvatarFile(convertedFile);
      if (triggerProfileAvatar && uploadedPic) triggerProfileAvatar(uploadedPic);
    } catch (error) {
      console.error('Error in uploadImage function:', error);
    }
  };

  return (
    <div className={cn(styles.img, className)}>
      <div className={`fs-exclude ${styles.profileImage}`} style={{ width: size, height: size }}>
        {loading ? (
          <FaSpinner className={styles.spinner} />
        ) : image || thisImage ? (
          <img className={`fs-exclude ${styles.avatar}`} src={image ? (image as string) : (thisImage as string)} alt="avatar" />
        ) : (
          <BsPerson className={`fs-exclude ${styles.personIcon}`} />
        )}
        {image && isUpload && !disableDelete && (
          <div className={styles.deleteContainer} onClick={onDelete}>
            <IoMdClose />
          </div>
        )}
      </div>
      {isUpload && (
        <>
          <input
            ref={inputElm}
            type="file"
            id="formId"
            onChange={uploadImage}
            accept=".png, .jpg , .jpeg , .jfif , .pjpeg , .pjp, .heic"
            hidden
          />
          <div className={`${styles.editContainer}`} onClick={onEdit}>
            <span className='fs-unmask'>Edit</span>
            <BiEdit className='fs-unmask' />
          </div>
        </>
      )}
      <div className={`${styles.invalidFeedback} invalid-feedback`}>{errors?.profile_img?.message}</div>
    </div>
  );
};

ProfileImage.defaultProps = {
  triggerProfileAvatar: null,
  className: '',
  id: '',
  isUpload: true,
  size: '62px',
  errors: null,
  disableDelete: false,
  setAvatarPayload: null,
  setAvatarFile: null,
  setAvatarAction: null,
  setCurrentAvatarURL: null,
  setImage: null,
  image: null,
  reviewProfile: false,
  isNewDependent: false,
};

export default ProfileImage;
