import { selectAuth } from '@brands/store/selectors/auth';
import { displayErrorDetails } from '@brands/Utils/displayError';
import React from 'react';
import { FieldErrors, UseFormRegister, UseFormSetValue } from 'react-hook-form';

import TextField from '../../../../Components/Inputs/TextField/TextField';
import { useAppSelector } from '../../../../hooks/useReduxHook';
import { CasePayload } from '../../../../services/cases/createNewCase';
import { ICase } from '../../../../services/cases/types/ICase';
import { checkCollapsibleHeaderColor } from '../../../../Utils/getCollapsibleHeader';
import { OralHealthReportSubmit } from '../../assets/types';
import styles from '../../styles.module.scss';

type ConsultationAdditionalPeopleProps = {
  thisCase: ICase;
  setCase: React.Dispatch<React.SetStateAction<ICase>>;
  consultationForm: OralHealthReportSubmit;
  setConsultationForm: React.Dispatch<React.SetStateAction<OralHealthReportSubmit>>;
  isAdditionalPeople: boolean;
  setIsAdditionalPeople: React.Dispatch<React.SetStateAction<boolean>>;
  setValue: UseFormSetValue<OralHealthReportSubmit>;
  register: UseFormRegister<OralHealthReportSubmit>;
  errors: FieldErrors<OralHealthReportSubmit>;
  editCase: (case_id: number, body: CasePayload) => Promise<ICase | null>;
};

const ConsultationAdditionalPeople = ({
  thisCase,
  setCase,
  consultationForm,
  setConsultationForm,
  isAdditionalPeople,
  setIsAdditionalPeople,
  setValue,
  register,
  errors,
  editCase,
}: ConsultationAdditionalPeopleProps): JSX.Element => {
  const { userInfo } = useAppSelector(selectAuth);
  const addAdditionalPeople = async (additionalPersonName: string): Promise<void> => {
    if (additionalPersonName.trim() !== '') {
      const payload: CasePayload = {
        video_conference_other_participants: additionalPersonName,
      };

      try {
        setValue('additionalPeople', additionalPersonName, { shouldValidate: true });
        const updatedCase = await editCase(thisCase.id, payload);
        if (updatedCase) {
          setCase(updatedCase);
        }
      } catch (error: unknown) {
        displayErrorDetails(error);
      }
    }
    if (additionalPersonName !== '' && additionalPersonName.trim() !== '') {
      setConsultationForm({
        ...consultationForm,
        isAdditionalPeople: true,
        additionalPeople: additionalPersonName,
      });
    } else {
      setConsultationForm((prevState) => ({
        ...prevState,
        isAdditionalPeople: false,
        additionalPeople: '',
      }));
    }
  };

  return (
    <div className={styles.additionalPeople}>
      <div className={styles.additionalPeopleCheck}>
        <div className="form-check m-0">
          {checkCollapsibleHeaderColor(thisCase || '', userInfo, 'APPDC') === 'edit' ? (
            <input
              {...register('isAdditionalPeople')}
              className="form-check-input fs-exclude"
              type="checkbox"
              id="isAdditionalPeople"
              checked={consultationForm.isAdditionalPeople}
              onChange={() => {
                const isChecked = !isAdditionalPeople;

                setValue('isAdditionalPeople', isChecked);
                setIsAdditionalPeople(isChecked);
                setConsultationForm((prevState) => ({
                  ...prevState,
                  isAdditionalPeople: isChecked,
                }));

                if (!isChecked) {
                  setValue('isAdditionalPeople', false, { shouldValidate: true });
                  addAdditionalPeople('');
                }
              }}
            />
          ) : (
            <input
              className="form-check-input fs-exclude"
              type="checkbox"
              id="isAdditionalPeople"
              checked={!!isAdditionalPeople}
              disabled
            />
          )}
          <label className={`form-check-label fs-unmask ${styles.additionalPeopleCheckLabel}`} htmlFor="isAdditionalPeople">
            Additional people present during call?
          </label>
        </div>
      </div>
      {checkCollapsibleHeaderColor(thisCase || '', userInfo, 'APPDC') === 'edit' ? (
        <TextField
          {...register('additionalPeople', { required: isAdditionalPeople })}
          name="additionalPeople"
          wrapperStyle={{ marginTop: '0', borderBottom: 'none', height: '30px' }}
          inputClass={`fs-exclude ${styles.input}`}
          placeholder="Who?"
          isUnderlined={false}
          errors={errors}
          defaultValue={
            consultationForm.additionalPeople !== undefined && consultationForm.additionalPeople !== null
              ? thisCase.video_conference_other_participants || consultationForm.additionalPeople
              : ''
          }
          onBlur={(e) => addAdditionalPeople(e.target.value)}
          onChange={(e) => {
            setConsultationForm((prevState) => ({
              ...prevState,
              isAdditionalPeople,
              additionalPeople: e.target.value,
            }));
            setValue('additionalPeople', e.target.value, { shouldValidate: true });
          }}
          disabled={!isAdditionalPeople}
        />
      ) : (
        <TextField
          register={register}
          name="additionalPeople"
          wrapperStyle={{ marginTop: '0', borderBottom: 'none', height: '30px' }}
          inputClass={`fs-exclude ${styles.input}`}
          placeholder="Who?"
          disabled
        />
      )}
    </div>
  );
};
export default ConsultationAdditionalPeople;
