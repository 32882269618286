import React, { useMemo } from 'react';

import styles from './styles.module.scss';

interface Props {
  date: Date;
  setFormattedDate: React.Dispatch<React.SetStateAction<string>>;
}

export const formatDate = (date: Date): string => {
  const options: Intl.DateTimeFormatOptions = {
    weekday: 'long',
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  };

  return date.toLocaleString('en-US', options);
};

const DisplaySelectedDates: React.FC<Props> = ({ date, setFormattedDate }) => {
  const formattedDate = formatDate(date);
  setFormattedDate(formattedDate);

  const getCurrentTimezone = useMemo(() => {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return timezone;
  }, []);

  return (
    <div className={`fs-unmask ${styles.dateHeader}`}>
      <span>{formattedDate}</span>
      <span>Time Zone: {getCurrentTimezone}</span>
    </div>
  );
};

export default DisplaySelectedDates;
