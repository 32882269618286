/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-cycle */
import ResetPasswordModal from '@brands/Components/Modals/ResetPasswordModal/ResetPasswordModal';
import { listClinicalData } from '@brands/services/clinical_data/listClinicalData';
import { MedicalHistory } from '@brands/services/clinical_data/types/types';
import { listAllClientTags } from '@brands/services/identity/listAllClientTags';
import { mapProfileToMyProfileSubmit } from '@brands/store/actions/patientAction';
import { selectAuth } from '@brands/store/selectors/auth';
import { selectPageState } from '@brands/store/selectors/pageState';
import { selectPatient } from '@brands/store/selectors/selectedPatient';
import { setPatientMyProfile } from '@brands/store/slices/patientMyProfileSlice';
import { setSelectedPatientByAdmin } from '@brands/store/slices/selectedPatientSlice';
import { parsePhoneNumber } from 'libphonenumber-js';
import React, { useCallback, useEffect, useState } from 'react';
import { RiDeleteBin6Line, RiRestartLine } from 'react-icons/ri';
import { useParams } from 'react-router-dom';
import { MultiValue } from 'react-select';

import BlueCircle from '../../../assets/icons/light-blue-circle.svg';
import GrayCircle from '../../../assets/icons/light-gray-circle.svg';
import { Loading } from '../../../Components/LoadingSpinner/Loading';
import { useAppDispatch, useAppSelector } from '../../../hooks/useReduxHook';
import { getCurrentProfile } from '../../../services/identity/getCurrentProfile';
import { getPatientInsurance } from '../../../services/identity/getPatientInsurance';
import { getUserById } from '../../../services/identity/getUserById';
import { listAllLanguages } from '../../../services/identity/listAllLanguages';
import { listAllOrganizations } from '../../../services/identity/listAllOrganizations';
import {
  ClientTag,
  Insurance,
  Language,
  Organization,
  UserProfile,
  UserRoleName,
} from '../../../services/identity/types/UserProfile';
import { displayErrorDetails } from '../../../Utils/displayError';
import { Option, OptionLanguage } from '../../../Utils/selectOptions';
import Sidenav from '../../Sidenav/Sidenav';
import DeleteAccount from '../../Sidenav/SidenavModals/DeleteAccount';
import styles from '../styles.module.scss';
import ReviewPatientCases from './ReviewPatientCases';
import EditAccountSecurity from './Sections/AccountSecurity/EditAccountSecurity';
import ReadOnlyAccountSecurity from './Sections/AccountSecurity/ReadOnlyAccountSecurity';
import EditDependent from './Sections/Dependent/EditDependent';
import ReadOnlyDependent from './Sections/Dependent/ReadOnlyDependent';
import EditInsurance from './Sections/Insurance/EditInsurance';
import ReadOnlyInsurance from './Sections/Insurance/ReadOnlyInsurance';
import ReadOnlyOrganizations from './Sections/Organizations/ReadOnlyOrganizations';
import EditPharmacy from './Sections/Pharmacy/EditPharmacy';
import ReadOnlyPharmacy from './Sections/Pharmacy/ReadOnlyPharmacy';
import EditProfile from './Sections/Profile/EditProfile';
import ReadOnlyProfile from './Sections/Profile/ReadOnlyProfile';
import { MyProfileSubmit } from './utils/types';

type ReviewMyProfileProps = {
  reviewProfile?: boolean;
};

export const isValidPhoneNumberFormat = (phoneNumber: string): boolean => {
  const phoneNumberPattern = /^\+?1?[-.\s]?\(?(\d{3})\)?[-.\s]?(\d{3})[-.\s]?(\d{4})$/;

  return phoneNumberPattern.test(phoneNumber);
};

export const displayPhoneNumber = (pharmacyPhoneNumber: string): string => {
  const phoneNumber = parsePhoneNumber(pharmacyPhoneNumber, 'US');
  if (phoneNumber) {
    return phoneNumber.formatNational();
  }
  return '';
};

const MyProfile = ({ reviewProfile }: ReviewMyProfileProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const { selectedPatient: currentPatient } = useAppSelector(selectPatient);
  const [medicalHistory, setMedicalHistory] = useState<MedicalHistory | null>(null);
  const userId = useParams().userId as string;
  const roleName = useParams().role as string;
  const { userInfo } = useAppSelector(selectAuth);
  const [thisUser, setThisUser] = useState<UserProfile>();
  const [loading, setLoading] = useState(true);
  const [myProfileForm, setMyProfileForm] = useState<MyProfileSubmit>({} as MyProfileSubmit);
  const [isEditProfile, setIsEditProfile] = useState(false);
  const [isEditInsurance, setIsEditInsurance] = useState(false);
  const [isEditDependent, setIsEditDependent] = useState(false);
  const [isEditSecurity, setIsEditSecurity] = useState(false);
  const [isEditPharmacy, setIsEditPharmacy] = useState(false);
  const [closeAccountModal, setCloseAccountModal] = useState(false);
  const [resetPasswordModal, setResetPasswordModal] = useState(false);
  const [avatarAction, setAvatarAction] = useState<string>('');
  const [languageOptions, setLanguageOptions] = useState<OptionLanguage[]>([]);
  const [organizationOptions, setOrganizationOptions] = useState<Option[]>([]);
  const [clientTagOptions, setClientTagOptions] = useState<Option[]>([]);
  const [allOrganization, setAllOrganization] = useState<Organization[]>([]);
  const [allClientTags, setAllClientTags] = useState<ClientTag[]>([]);
  const [currentAvatarURL, setCurrentAvatarURL] = useState<string>('');
  const [validationResponseId, setValidationResponseId] = useState('');
  const [image, setImage] = React.useState<string>();
  const [patientInsurance, setPatientInsurance] = useState<Insurance[]>([]);
  const [pharmacyName, setPharmacyName] = useState('');
  const [pharmacyAddress, setPharmacyAddress] = useState('');
  const [pharmacyPhoneNumber, setPharmacyPhoneNumber] = useState('');
  const [dependentId, setDependentId] = useState<number | undefined>(undefined);
  const [selection, setSelection] = React.useState<[number | null, number | null] | null>(null);
  const { isFromSelectPatient, isEditInsuranceFlag, selectedDependentId } = useAppSelector(selectPageState);
  const [showDeleteDependentConfirmationModal, setShowDeleteDependentConfirmationModal] = useState(false);

  const getFormAnswers = useCallback(async (createdByPatientId: number | undefined) => {
    setLoading(true);
    const { medical_history } = await listClinicalData(createdByPatientId ?? 0);

    setMedicalHistory(medical_history);

    setPharmacyName(medical_history?.pharmacy_name || '');

    setPharmacyAddress(
      `${medical_history?.pharmacy_street_address || ''} ${medical_history?.pharmacy_city || ''}, ${
        medical_history?.pharmacy_state || ''
      }, ${medical_history?.pharmacy_zip_code || ''}`,
    );

    setPharmacyPhoneNumber(
      isValidPhoneNumberFormat(medical_history?.pharmacy_phone_number || '')
        ? displayPhoneNumber(medical_history?.pharmacy_phone_number || '')
        : medical_history?.pharmacy_phone_number || '',
    );

    setLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      setLoading(true);
      setPharmacyAddress('');
      setPharmacyPhoneNumber('');
      await getFormAnswers(isEditDependent && dependentId ? dependentId : Number(currentPatient?.id));
      setLoading(false);
    })();
  }, [thisUser, dependentId, isEditDependent]);

  const ref = React.useRef<HTMLInputElement>(null);
  const addDependent = (): void => {
    setDependentId(undefined);
    setIsEditProfile(false);
    setIsEditDependent(true);
  };

  const onEditProfile = (): void => {
    setAvatarAction('');
    setIsEditDependent(false);
    setIsEditProfile(true);
  };

  const onEditInsurance = (): void => {
    setIsEditInsurance(true);
  };

  const onEditDependent = (id: number): void => {
    setDependentId(id);
    setIsEditProfile(false);
    setIsEditDependent(true);
  };

  const onEditPharmacy = (): void => {
    setIsEditPharmacy(true);
  };

  const getCurrentUser = async (id: string): Promise<void> => {
    try {
      setLoading(true);
      if (reviewProfile && userId) {
        const res = await getUserById(Number(id));
        dispatch(setSelectedPatientByAdmin({ selectedPatient: res }));
        setThisUser(res);
      }
    } catch (error: unknown) {
      displayErrorDetails(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchUserDetails = async (): Promise<void> => {
    setLoading(true);
    const userDetails = await getCurrentProfile();
    setThisUser(userDetails);
    setLoading(false);
  };

  const fetchUserInsurance = async (): Promise<void> => {
    if (!reviewProfile) {
      const userInsurance = await getPatientInsurance(
        Number(currentPatient?.id) !== Number(userInfo.id) ? Number(currentPatient?.id) : undefined,
      );
      setPatientInsurance(userInsurance);
    } else {
      const userInsurance = await getPatientInsurance(thisUser?.id);
      setPatientInsurance(userInsurance);
    }
  };

  const fetchLanguageOptions = async (): Promise<void> => {
    const data = await listAllLanguages();
    const transformedData = data.map((language) => ({
      value: language.locale,
      label: language.name,
      id: language.id,
    }));
    setLanguageOptions(transformedData);
  };

  const fetchOrganizationOptions = async (): Promise<void> => {
    try {
      const data = await listAllOrganizations();
      setAllOrganization(data.nodes);
      if (userInfo.role.name === UserRoleName.OrganizationAdmin) {
        data.nodes = data.nodes.filter((org) => org.id === userInfo.organizations[0].id);
      }
      const transformedData = data.nodes.map((organization) => ({
        value: organization.id,
        label: organization.name,
        id: organization.id,
      }));
      setOrganizationOptions(transformedData);
    } catch (error: unknown) {
      displayErrorDetails(error);
    }
  };

  const fetchClientTagsOptions = async (): Promise<void> => {
    try {
      const data = await listAllClientTags();
      setAllClientTags(data.nodes);
      const transformedData = data.nodes.map((clientTag) => ({
        value: clientTag.code,
        label: clientTag.name,
        id: clientTag.id,
      }));
      setClientTagOptions(transformedData);
    } catch (error: unknown) {
      displayErrorDetails(error);
    }
  };

  useEffect(() => {
    fetchLanguageOptions();
    if (
      reviewProfile ||
      userInfo.role.name === UserRoleName.Admin ||
      userInfo.role.name === UserRoleName.SuperAdmin ||
      userInfo.role.name === UserRoleName.OrganizationAdmin
    ) {
      fetchOrganizationOptions();
      fetchClientTagsOptions();
    }
  }, []);

  useEffect(() => {
    if (userId) {
      getCurrentUser(userId);
    } else {
      fetchUserDetails();
    }
  }, [userId]);

  useEffect(() => {
    fetchUserInsurance();
  }, [thisUser]);

  useEffect(() => {
    if (isFromSelectPatient && selectedDependentId) {
      onEditDependent(selectedDependentId);
    } else if (isFromSelectPatient && selectedDependentId === null) {
      onEditProfile();
    }
  }, [isFromSelectPatient, selectedDependentId]);

  const fetchProfile = (): void => {
    const mappedProfile = mapProfileToMyProfileSubmit(thisUser, patientInsurance);

    function isLanguageArray(value: Language[] | string[] | Organization[]): value is Language[] {
      return Array.isArray(value) && value.length > 0 && value[0] instanceof Object && 'id' in value[0];
    }
    let selectedLanguage: MultiValue<OptionLanguage> = [];
    if (isLanguageArray(mappedProfile.languages)) {
      for (let i = 0; i < languageOptions.length; i++) {
        for (let j = 0; j < mappedProfile.languages.length; j++) {
          if (Number(languageOptions[i].id) === Number(mappedProfile.languages[j].id)) {
            selectedLanguage = [...selectedLanguage, languageOptions[i]];
          }
        }
      }
    }

    let selectedOrganizations: MultiValue<Option> = [];
    if (
      mappedProfile.organizations &&
      (mappedProfile.organizations as Organization[]) &&
      isLanguageArray(mappedProfile.organizations)
    ) {
      for (let i = 0; i < organizationOptions.length; i++) {
        for (let j = 0; j < mappedProfile.organizations.length; j++) {
          if (organizationOptions[i].id === mappedProfile.organizations[j].id) {
            selectedOrganizations = [...selectedOrganizations, organizationOptions[i]];
          }
        }
      }
    }

    let selectedOptions: MultiValue<Option> = [];

    if (thisUser?.markers) {
      selectedOptions = thisUser.markers
        .map((marker) => clientTagOptions.find((option): option is Option => option?.value === marker.value))
        .filter((option): option is Option => option !== undefined);
    }

    dispatch(
      setPatientMyProfile({
        ...(mappedProfile as MyProfileSubmit),
        language: selectedLanguage,
        profile_img: currentAvatarURL,
        organization: selectedOrganizations,
      }),
    );
    setMyProfileForm({
      ...(mappedProfile as MyProfileSubmit),
      language: selectedLanguage,
      profile_img: currentAvatarURL,
      organization: selectedOrganizations,
      organizations: mappedProfile.organizations,
      clientTag: selectedOptions,
    });
  };
  useEffect(() => {
    if (thisUser) {
      fetchProfile();
    }
  }, [thisUser, languageOptions, patientInsurance]);

  React.useLayoutEffect(() => {
    if (selection && ref.current) {
      [ref.current.selectionStart, ref.current.selectionEnd] = selection;
    }
  }, [selection]);

  useEffect(() => {
    if (thisUser) {
      fetchProfile();
      setLoading(false);
    }
  }, [userInfo, languageOptions, patientInsurance]);

  if (loading) {
    return <Loading fullScreen />;
  }

  const renderProfile = (): React.ReactNode => {
    let componentToRender: React.JSX.Element;

    if (isEditProfile) {
      componentToRender = (
        <EditProfile
          reviewProfile={reviewProfile}
          id={userId}
          setImage={setImage}
          image={image as string}
          myProfileForm={myProfileForm}
          setMyProfileForm={setMyProfileForm}
          setIsEditProfile={setIsEditProfile}
          setSelection={setSelection}
          setCurrentAvatarURL={setCurrentAvatarURL}
          currentAvatarURL={currentAvatarURL}
          thisUser={thisUser as UserProfile}
          setThisUser={setThisUser as React.Dispatch<React.SetStateAction<UserProfile>>}
          avatarAction={avatarAction}
          setAvatarAction={setAvatarAction}
          languageOptions={languageOptions}
          organizationOptions={organizationOptions}
          allOrganization={allOrganization}
          clientTagOptions={clientTagOptions}
          allClientTags={allClientTags}
          validationResponseId={validationResponseId}
          setValidationResponseId={setValidationResponseId}
        />
      );
    } else if (isEditDependent) {
      componentToRender = (
        <EditDependent
          setIsEditDependent={setIsEditDependent}
          setSelection={setSelection}
          setThisUser={setThisUser as React.Dispatch<React.SetStateAction<UserProfile>>}
          dependentId={dependentId}
          languageOptions={languageOptions}
          reviewProfile={reviewProfile}
          validationResponseId={validationResponseId}
          setValidationResponseId={setValidationResponseId}
          isFromSelectNeeds={false}
          showDeleteDependentConfirmationModal={showDeleteDependentConfirmationModal}
          setShowDeleteDependentConfirmationModal={setShowDeleteDependentConfirmationModal}
          clientTagOptions={clientTagOptions}
          roleName={roleName}
        />
      );
    } else {
      componentToRender = (
        <ReadOnlyProfile
          id={userId}
          onEditProfile={onEditProfile}
          onEditDependent={onEditDependent}
          setImage={setImage}
          image={image!}
          thisUser={thisUser as UserProfile}
          setThisUser={setThisUser as React.Dispatch<React.SetStateAction<UserProfile>>}
          reviewProfile={reviewProfile}
          roleName={roleName}
          organizationOptions={organizationOptions}
          clientTagOptions={clientTagOptions}
          setDependentId={setDependentId}
        />
      );
    }
    return componentToRender;
  };
  return (
    <div className={styles.profileContainer}>
      <img className={styles.bkgTopImage} src={BlueCircle} alt="profile" />
      <img className={styles.bkgBottomImage} src={GrayCircle} alt="profile" />
      <Sidenav isVisibleOnMobile />
      <div className={styles.contentContainer}>
        <div className={styles.parentContainer}>
          {renderProfile()}
          {(!isFromSelectPatient || isEditInsuranceFlag) && !isEditDependent && (
            <>
              {userInfo.role.name === UserRoleName.Patient && <div className={styles.separator} />}
              {userInfo.role.name === UserRoleName.Patient && <ReadOnlyOrganizations />}
              {userInfo.role.name === UserRoleName.Patient && <div className={styles.separator} />}
              {userInfo.role.name === UserRoleName.Patient &&
                (isEditSecurity ? (
                  <EditAccountSecurity setIsEditSecurity={setIsEditSecurity} />
                ) : (
                  <ReadOnlyAccountSecurity setIsEditSecurity={setIsEditSecurity} />
                ))}
              {(!isFromSelectPatient || isEditInsuranceFlag) && <div className={styles.separator} />}
              {isEditInsurance || (isFromSelectPatient && isEditInsuranceFlag) ? (
                <EditInsurance
                  myProfileForm={myProfileForm}
                  patientInsurance={patientInsurance!}
                  setPatientInsurance={setPatientInsurance}
                  setIsEditInsurance={setIsEditInsurance}
                  setSelection={setSelection}
                  loading={loading}
                  thisUser={thisUser as UserProfile}
                />
              ) : !isFromSelectPatient || isEditInsuranceFlag ? (
                <ReadOnlyInsurance onEditInsurance={onEditInsurance} patientInsurance={patientInsurance!} />
              ) : null}
            </>
          )}

          {(!isFromSelectPatient || isEditInsuranceFlag) &&
            (!isEditDependent || (isEditDependent && dependentId !== undefined)) && (
              <>
                {!isEditPharmacy && <div className={styles.separator} />}
                {!isEditPharmacy ? (
                  <ReadOnlyPharmacy
                    onEditPharmacy={onEditPharmacy}
                    pharmacyName={pharmacyName}
                    pharmacyAddress={pharmacyAddress}
                    pharmacyPhoneNumber={pharmacyPhoneNumber}
                  />
                ) : (
                  <EditPharmacy
                    thisUser={thisUser as UserProfile}
                    setThisUser={setThisUser}
                    medicalHistoryForm={medicalHistory as MedicalHistory}
                    setIsEditPharmacy={setIsEditPharmacy}
                    loading={loading}
                    setPharmacyName={setPharmacyName}
                    setPharmacyAddress={setPharmacyAddress}
                    setPharmacyPhoneNumber={setPharmacyPhoneNumber}
                    dependentId={dependentId}
                  />
                )}
              </>
            )}
          {(!isFromSelectPatient || isEditInsuranceFlag) && !isEditDependent && <div className={styles.separator} />}
          {(!isFromSelectPatient || isEditInsuranceFlag) &&
            !isEditDependent &&
            thisUser?.role.name !== UserRoleName.Dependent && (
              <ReadOnlyDependent
                onEditDependent={onEditDependent}
                addDependent={addDependent}
                reviewProfile={reviewProfile as boolean}
                thisUser={thisUser as UserProfile}
              />
            )}
          {(!isFromSelectPatient || isEditInsuranceFlag) && !reviewProfile && !isEditDependent && (
            <div className={styles.closeAccountContainer}>
              <span className={styles.closeAccount} onClick={() => setCloseAccountModal(true)}>
                <RiDeleteBin6Line />
                Delete Account
              </span>
            </div>
          )}
          {(!isFromSelectPatient || isEditInsuranceFlag) &&
          isEditDependent &&
          thisUser &&
          dependentId !== undefined &&
          dependentId !== 0 &&
          dependentId.toString() !== '' ? (
            <div className={`${styles.closeAccountContainer} `}>
              <div
                className={`${styles.closeAccount} ${
                  (thisUser as UserProfile)?.status === 'Active' ? '' : styles.inactiveClass
                }`}
                onClick={() =>
                  (thisUser as UserProfile)?.status === 'Active' ? setShowDeleteDependentConfirmationModal(true) : ''
                }
              >
                <RiDeleteBin6Line />
                Delete Dependent
              </div>
            </div>
          ) : null}
          {!reviewProfile && !isEditDependent && (
            <div className={styles.closeAccountContainer} style={{ width: 'auto' }}>
              <span className={styles.closeAccount} onClick={() => setResetPasswordModal(true)}>
                <RiRestartLine />
                Reset Password
              </span>
            </div>
          )}
          {(!isFromSelectPatient || isEditInsuranceFlag) && reviewProfile && (
            <ReviewPatientCases patientId={Number(userId)} />
          )}
        </div>
      </div>
      {closeAccountModal && <DeleteAccount setOpenModal={setCloseAccountModal} />}
      {resetPasswordModal && (
        <ResetPasswordModal
          isOpen={resetPasswordModal}
          onClose={() => setResetPasswordModal(false)}
          email={thisUser?.email || ''}
        />
      )}
    </div>
  );
};
MyProfile.defaultProps = {
  reviewProfile: false,
};
export default MyProfile;
