import moment from 'moment-timezone';
import { displayErrorDetails } from './displayError';

const abbrs = {
  AKST: 'US/Alaska Time',
  AKDT: 'US/Alaska Time',
  EST: 'US/Eastern Time',
  EDT: 'US/Eastern Time',
  CST: 'US/Central Time',
  CDT: 'US/Central Time',
  MST: 'US/Mountain Time',
  MDT: 'US/Mountain Time',
  PST: 'US/Pacific Time',
  PDT: 'US/Pacific Time',
  HST: 'US/Hawaii-Aleutian Time',
};

export const getParsedTimezoneName = (timezone: string): string => {
  moment.fn.zoneName = () => {
    const abbr = moment.tz(timezone).zoneAbbr();
    return abbrs[abbr as keyof typeof abbrs] || timezone?.replace('_', ' ');
  };
  return moment.tz(timezone).zoneName();
};

export const stateTimezones = {
  AL: 'CST',
  AK: 'AKST',
  AZ: 'MST',
  AR: 'CST',
  CA: 'PST',
  CO: 'MST',
  CT: 'EST',
  DE: 'EST',
  DC: 'EST',
  FL: 'EST',
  GA: 'EST',
  HI: 'HST',
  ID: 'MST',
  IL: 'CST',
  IN: 'EST',
  IA: 'CST',
  KS: 'CST',
  KY: 'EST',
  LA: 'CST',
  ME: 'EST',
  MD: 'EST',
  MA: 'EST',
  MI: 'EST',
  MN: 'CST',
  MS: 'CST',
  MO: 'CST',
  MT: 'MST',
  NE: 'CST',
  NV: 'PST',
  NH: 'EST',
  NJ: 'EST',
  NM: 'MST',
  NY: 'EST',
  NC: 'EST',
  ND: 'CST',
  OH: 'EST',
  OK: 'CST',
  OR: 'PST',
  PA: 'EST',
  RI: 'EST',
  SC: 'EST',
  SD: 'CST',
  TN: 'CST',
  TX: 'CST',
  UT: 'MST',
  VT: 'EST',
  VA: 'EST',
  WA: 'PST',
  WV: 'EST',
  WI: 'CST',
  WY: 'MST',
};

export const getTimezoneAbbreviation = (stateValue: string): string | undefined => {
  return stateTimezones[stateValue as keyof typeof stateTimezones];
};

export const timezoneMap: { [key: string]: string } = {
  AL: 'America/Chicago',
  AK: 'America/Anchorage',
  AZ: 'America/Phoenix',
  AR: 'America/Chicago',
  CA: 'America/Los_Angeles',
  CO: 'America/Denver',
  CT: 'America/New_York',
  DE: 'America/New_York',
  FL: 'America/New_York',
  GA: 'America/New_York',
  HI: 'Pacific/Honolulu',
  ID: 'America/Boise',
  IL: 'America/Chicago',
  IN: 'America/Indiana/Indianapolis',
  IA: 'America/Chicago',
  KS: 'America/Chicago',
  KY: 'America/New_York',
  LA: 'America/Chicago',
  ME: 'America/New_York',
  MD: 'America/New_York',
  MA: 'America/New_York',
  MI: 'America/Detroit',
  MN: 'America/Chicago',
  MS: 'America/Chicago',
  MO: 'America/Chicago',
  MT: 'America/Denver',
  NE: 'America/Chicago',
  NV: 'America/Los_Angeles',
  NH: 'America/New_York',
  NJ: 'America/New_York',
  NM: 'America/Denver',
  NY: 'America/New_York',
  NC: 'America/New_York',
  ND: 'America/Chicago',
  OH: 'America/New_York',
  OK: 'America/Chicago',
  OR: 'America/Los_Angeles',
  PA: 'America/New_York',
  RI: 'America/New_York',
  SC: 'America/New_York',
  SD: 'America/Chicago',
  TN: 'America/Chicago',
  TX: 'America/Chicago',
  UT: 'America/Denver',
  VT: 'America/New_York',
  VA: 'America/New_York',
  WA: 'America/Los_Angeles',
  WV: 'America/New_York',
  WI: 'America/Chicago',
  WY: 'America/Denver',
};

export const getTimezoneAbbrWithTimezone = (timeZone: string): string => {
  try {
    const now = moment().tz(timeZone);
    return now.zoneAbbr();
  } catch (error) {
    displayErrorDetails(error);
    return 'Invalid data';
  }
};

const timezones = {
  AKST: 'America/Anchorage', // Alaska Time
  AKDT: 'America/Anchorage', // Alaska Time
  EST: 'America/New_York', // Eastern Time
  EDT: 'America/New_York', // Eastern Time
  CST: 'America/Chicago', // Central Time
  CDT: 'America/Chicago', // Central Time
  MST: 'America/Denver', // Mountain Time
  MDT: 'America/Denver', // Mountain Time
  PST: 'America/Los_Angeles', // Pacific Time
  PDT: 'America/Los_Angeles', // Pacific Time
  HST: 'Pacific/Honolulu', // Hawaii-Aleutian Time
};

export const convertZoneAbbr = (zoneAbbr: string): string => {
  const parsedTimezoneName = timezones[zoneAbbr as keyof typeof abbrs];
  return parsedTimezoneName;
};
