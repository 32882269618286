import uniqBy from 'lodash/uniqBy';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';

import { assignCareAdvisor, IAssignCACase } from '../services/cases/assignCareAdvisor';
import { assignProvider, IAssignProviderCase } from '../services/cases/assignProvider';
import { CasePayload, createNewCase } from '../services/cases/createNewCase';
import { editCaseById } from '../services/cases/editCase';
import { editCaseType } from '../services/cases/editCaseType';
import { getOneCase } from '../services/cases/getCaseById';
import { IListCasesParams, listAllCases } from '../services/cases/listAllCases';
import { ICase } from '../services/cases/types/ICase';
import { Meta } from '../services/communication/types/Meta';
import { displayErrorDetails } from '../Utils/displayError';

interface IUseConversations {
  cases: ICase[];
  totalNewCases: number;
  reloadAll: () => Promise<void>;
  loadNextPage: () => Promise<void>;
  loadPreviousPage: () => Promise<void>;
  editCase: (case_id: number, body: CasePayload) => Promise<ICase | null>;
  editCaseTypeFn: (case_id: number, body: CasePayload) => Promise<ICase | null>;
  assignToCareAdvisor: (case_id: number, body: IAssignCACase, showError?: boolean) => Promise<ICase | null>;
  assignToProvider: (case_id: number, body: IAssignProviderCase) => Promise<ICase | null>;
  addCase: (payload: CasePayload) => Promise<void>;
  lastPageMeta: Meta | null;
  oneCase: ICase | undefined;
  getCaseById: (case_id: number) => Promise<void>;
  dataLoading: boolean;
  loading: boolean;
}

export default function useCases(
  params: Pick<
    IListCasesParams,
    | 'sort_by'
    | 'sort_direction'
    | 'provider_id'
    | 'patient_id'
    | 'care_advisor_id'
    | 'type'
    | 'case_id'
    | 'consultation_date'
    | 'completed_date'
    | 'canceled_date'
    | 'case_state'
    | 'patient_user_type'
    | 'client_tag'
    | 'patient_full_name'
    | 'care_advisor_full_name'
    | 'provider_full_name'
    | 'payment_method'
    | 'patient_self_pay_cost'
    | 'insurance_carrier'
    | 'limit'
    | 'status'
    | 'dependent_id'
    | 'include_patient_care_advisor_conversation'
    | 'include_patient_provider_conversation'
    | 'include_provider_care_advisor_conversation'
    | 'include_dependents_cases'
    | 'include_vc_stats'
    | 'type'
    | 'provider_email'
    | 'care_advisor_email'
    | 'patient_email'
    | 'follow_up_status'
    | 'consultation_date_start'
    | 'consultation_date_end'
    | 'completed_date_start'
    | 'completed_date_end'
    | 'canceled_date_start'
    | 'canceled_date_end'
    | 'case_intake_questions'
  >,
  shouldLoadAllCases = false,
): IUseConversations {
  const [cases, setCases] = useState<ICase[]>([]);
  const [totalNewCases, setTotalNewCases] = useState<number>(0);
  const [oneCase, setCase] = useState<ICase | undefined>();
  const [dataLoading, setDataLoading] = useState<boolean>(false);
  const [firstPageMeta, setFirstPageMeta] = useState<Meta | null>(null);
  const [lastPageMeta, setLastPageMeta] = useState<Meta | null>(null);
  const [loading, setLoading] = useState(false);

  const queryString = useMemo(
    () =>
      Object.keys(params)
        .map((key) => {
          const value = params[key as keyof typeof params];
          if (Array.isArray(value)) {
            return value.map((v) => `${key}=${encodeURIComponent(v)}`).join('&');
          }
          return `${key}=${encodeURIComponent(value as string | number | boolean)}`;
        })
        .join('&'),
    [params],
  );

  const loadAll = useCallback(async (): Promise<void> => {
    setDataLoading(true);
    try {
      const casesList = await listAllCases(queryString);
      setCases(casesList.nodes);
      setTotalNewCases(casesList.total);
      setFirstPageMeta(casesList.meta);
      setLastPageMeta(casesList.meta);
    } catch (error: unknown) {
      displayErrorDetails(error);
    }
    setDataLoading(false);
  }, [queryString]);

  const reloadAll = useCallback(async (): Promise<void> => {
    setDataLoading(true);
    try {
      const casesList = await listAllCases(queryString);
      setCases(casesList.nodes);
      setTotalNewCases(casesList.total);
      setFirstPageMeta(casesList.meta);
      setLastPageMeta(casesList.meta);
    } catch (error: unknown) {
      displayErrorDetails(error);
    }
    setDataLoading(false);
  }, [queryString]);

  const loadNextPage = async (): Promise<void> => {
    setLoading(true);
    if (!lastPageMeta?.has_next_page) {
      return;
    }
    try {
      const { meta, nodes } = await listAllCases(
        `${queryString}&${`cursor=${lastPageMeta.end_cursor}`}&cursor_direction=${`next`}`,
      );
      setLastPageMeta(meta);
      if (!firstPageMeta) {
        setFirstPageMeta(meta);
      }
      setCases((prevCases) => uniqBy([...prevCases, ...nodes], 'id'));
    } catch (error: unknown) {
      displayErrorDetails(error);
    }
    setLoading(false);
  };

  const loadPreviousPage = async (): Promise<void> => {
    if (!firstPageMeta?.has_previous_page) {
      return;
    }
    try {
      const { meta, nodes } = await listAllCases(
        `${queryString}&cursor=${firstPageMeta.start_cursor}&cursor_direction=${`previous`}`,
      );
      setFirstPageMeta(meta);
      if (!lastPageMeta) {
        setLastPageMeta(meta);
      }
      setCases((prevCases) => uniqBy([...nodes, ...prevCases], 'id'));
    } catch (error: unknown) {
      displayErrorDetails(error);
    }
  };

  const editCase = async (case_id: number, body: CasePayload): Promise<ICase | null> => {
    try {
      const updatedCase = await editCaseById(case_id, body);
      const idx = cases.findIndex((c) => c.id === updatedCase.id);
      if (idx >= 0) {
        cases.splice(idx, 1, updatedCase);
      } else {
        cases.push(updatedCase);
      }
      setCases([...cases]);
      return updatedCase;
    } catch (error: unknown) {
      displayErrorDetails(error);
    }
    return null;
  };

  const editCaseTypeFn = async (case_id: number, body: CasePayload): Promise<ICase | null> => {
    try {
      const updatedCase = await editCaseType(case_id, body);
      const idx = cases.findIndex((c) => c.id === updatedCase.id);
      if (idx >= 0) {
        cases.splice(idx, 1, updatedCase);
      } else {
        cases.push(updatedCase);
      }
      setCases([...cases]);
      return updatedCase;
    } catch (error: any) {
      if (error.response && error.response.data && error.response.data.message) {
        const errorMessage = error.response.data.message;
        toast.error(errorMessage);
      } else {
        toast.error('An error occurred');
      }
    }
    return null;
  };

  const assignToCareAdvisor = async (case_id: number, body: IAssignCACase, showError = true): Promise<ICase | null> => {
    try {
      const updatedCase = await assignCareAdvisor(case_id, body);
      const idx = cases.findIndex((c) => c.id === updatedCase.id);
      if (idx >= 0) {
        cases.splice(idx, 1, updatedCase);
      } else {
        cases.push(updatedCase);
      }
      setCases([...cases]);
      return updatedCase;
    } catch (error: unknown) {
      if (showError) {
        displayErrorDetails(error);
      }
    }
    return null;
  };

  const assignToProvider = async (case_id: number, body: IAssignProviderCase): Promise<ICase | null> => {
    try {
      const updatedCase = await assignProvider(case_id, body);
      const idx = cases.findIndex((c) => c.id === updatedCase.id);
      if (idx >= 0) {
        cases.splice(idx, 1, updatedCase);
      } else {
        cases.push(updatedCase);
      }
      setCases([...cases]);
      return updatedCase;
    } catch (error: any) {
      displayErrorDetails(error);
    }
    return null;
  };

  const addCase = async (payload: CasePayload): Promise<void> => {
    try {
      const newCase = await createNewCase(payload);
      const idx = cases.findIndex((c) => c.id === newCase.id);
      if (idx >= 0) {
        cases.splice(idx, 1, newCase);
      } else {
        cases.push(newCase);
      }
      setCases([...cases]);
    } catch (error: unknown) {
      displayErrorDetails(error);
    }
  };

  const getCaseById = async (caseId: number): Promise<void> => {
    try {
      const singleCase = await getOneCase(caseId);
      setCase(singleCase);
    } catch (error: unknown) {
      displayErrorDetails(error);
    }
  };

  useEffect(() => {
    if (shouldLoadAllCases) {
      loadAll();
    }
  }, [shouldLoadAllCases, loadAll]);

  // useEffect(() => {
  //   loadAll();
  // }, [queryString, loadAll]);

  return {
    cases,
    totalNewCases,
    editCase,
    editCaseTypeFn,
    assignToCareAdvisor,
    assignToProvider,
    addCase,
    lastPageMeta,
    getCaseById,
    oneCase,
    reloadAll,
    loadNextPage,
    loadPreviousPage,
    dataLoading,
    loading,
  };
}
