import { ClinicalDataQuestion } from '@brands/services/clinical_data/types/types';
import React, { Dispatch, FC, useEffect, useState } from 'react';

import happy from '../icons/happy.svg';
import neutral from '../icons/neutral.svg';
import unhappy from '../icons/unhappy.svg';
import very_happy from '../icons/very_happy.svg';
import very_unhappy from '../icons/very_unhappy.svg';
import styles from './styles.module.scss';

interface QuestionAndMultiselectProps {
  element: ClinicalDataQuestion;
  subElement?: ClinicalDataQuestion;
  form: { [key: string]: any };
  setForm: Dispatch<React.SetStateAction<any>>;
  isEmoji?: boolean;
  medicalHistory?: boolean;
  isMulti?: boolean;
}

const QuestionAndMultiselect: FC<QuestionAndMultiselectProps> = React.forwardRef(
  ({ element, subElement, form, setForm, isEmoji, isMulti }, ref) => {
    const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
    const [initialized, setInitialized] = useState(false);
    useEffect(() => {
      setSelectedOptions(element.options && element.options.length > 0 ? element.options : []);
    }, []);

    useEffect(() => {
      if (initialized) {
        if (selectedOptions.length > 0) {
          setForm((prev: any) => ({
            ...prev,
            [element.id]: selectedOptions,
          }));
        } else {
          setForm((prev: any) => ({
            ...prev,
            [element.id]: [],
          }));
        }
      } else {
        setInitialized(true);
      }
    }, [selectedOptions, initialized]);

    const getComputedEmoji = (option: string): string => {
      switch (option) {
        case 'Very Unhappy':
          return `url(${very_unhappy})`;
        case 'Somewhat Unhappy':
          return `url(${unhappy})`;
        case 'Neutral':
          return `url(${neutral})`;
        case 'Somewhat Happy':
          return `url(${happy})`;
        case 'Very Happy':
          return `url(${very_happy})`;
        default:
          return '';
      }
    };

    useEffect(() => {
      if (element.id) {
        setSelectedOptions(
          form[element.id] !== 'no' && Object.keys(form[element.id]).length !== 0 ? form[element.id] : [],
        );
      }
    }, []);

    const handleOptionChange = (option: string): void => {
      if (selectedOptions.includes(option)) {
        if (selectedOptions.length === 1 && subElement) {
          setForm((prev: any) => ({
            ...prev,
            [subElement.id]: undefined,
          }));
        }

        setSelectedOptions(selectedOptions.filter((selectedOption) => selectedOption !== option));
      } else {
        setSelectedOptions([...selectedOptions, option]);
      }
    };

    return (
      <div
        className={`${styles.row} row ${styles.medicalFormRow} ${
          isEmoji && selectedOptions && selectedOptions.length
            ? styles.selectedClass
            : !isEmoji
            ? styles.selectedClass
            : ''
        }`}
      >
        <div
          className={styles.questionAndButtonContainer}
          onClick={() => {
            if (isEmoji) {
              setSelectedOptions([]);
            } else {
              setSelectedOptions([]);
            }
          }}
        >
          <div className={`${styles.question} col-12 col-sm-8 px-0`}>
            <div className={`${styles.label} mb-2 mb-md-0 fs-unmask`}>
              {element.title}
              {element.is_required ? <span className={styles.redText}> *</span> : ''}
            </div>
          </div>
        </div>
        {isEmoji && (
          <div className={styles.selectSelf} onClick={(e) => e.stopPropagation()}>
            <div className={styles.radio}>
              {element.options &&
                element.options.map((option: string) => (
                  <div key={option}>
                    <label
                      className={`${styles.label} fs-unmask ${
                        selectedOptions.length > 0 && selectedOptions?.includes(option) ? styles.selectedOption : ''
                      }`}
                      htmlFor={`${element.id}-${option}`}
                    >
                      <div
                        className={styles.emoji}
                        style={{
                          filter: selectedOptions.includes(option)
                            ? 'brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(29deg) brightness(105%) contrast(102%)'
                            : '',
                          backgroundImage: getComputedEmoji(option),
                        }}
                      />
                      {isEmoji ? option.replace(/\p{Emoji}+/gu, '').trim() : option}
                    </label>
                    <input
                      id={`${element.id}-${option}`}
                      value={option}
                      className='fs-exclude'
                      type={isMulti ? 'checkbox' : 'radio'}
                      onClick={() => handleOptionChange(option)}
                      checked={selectedOptions.includes(option)}
                      ref={ref as any}
                    />
                  </div>
                ))}
            </div>
          </div>
        )}
      </div>
    );
  },
);

QuestionAndMultiselect.defaultProps = {
  isEmoji: false,
  medicalHistory: false,
  isMulti: false,
};

export default QuestionAndMultiselect;
