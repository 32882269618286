/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

import { Loading } from '@brands/Components/LoadingSpinner/Loading';
import useSubscribe, { Subscriber } from '@brands/hooks/useSubscribe';
import { selectAuth } from '@brands/store/selectors/auth';
import React, { useEffect, useMemo, useState } from 'react';
import { AiFillCloseCircle } from 'react-icons/ai';
import { useNavigate, useParams } from 'react-router-dom';

import { useWindowSize } from '../../hooks';
import { useAppSelector } from '../../hooks/useReduxHook';
import { ICase, Status } from '../../services/cases/types/ICase';
import { getConversation } from '../../services/communication/getConversation';
import { IGMConversation } from '../../services/communication/types/IGMConversation';
import { UserRoleName } from '../../services/identity/types/UserProfile';
import styles from './case-styles.module.scss';
import CaseMessageInfo from './CaseMessageInfo';
import CaseMessageInfoCreateNewConversation from './CaseMessageInfoCreateNewConversation';

interface ConversationsListProps {
  activeConversationSid: string | null;
  setActiveConversationSid: (conversationSid: string) => void;
  isProfileCard: boolean;
  thisCase: ICase | undefined;
  setConversations: React.Dispatch<
    React.SetStateAction<
      {
        conversationType: string;
        conversation?: IGMConversation;
        createNewConversation?: boolean;
      }[]
    >
  >;
  conversations: {
    conversationType: string;
    conversation?: IGMConversation;
    createNewConversation?: boolean;
  }[];
  setCase: React.Dispatch<React.SetStateAction<ICase | undefined>>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  changeCaseUnreadIndicator: () => Promise<void>;
}
const CaseConversationList: React.FC<ConversationsListProps> = ({
  activeConversationSid,
  setActiveConversationSid,
  isProfileCard,
  thisCase,
  setConversations,
  conversations,
  setCase,
  setLoading,
  changeCaseUnreadIndicator,
}) => {
  const { userInfo } = useAppSelector(selectAuth);
  const routeConversationSid = useParams().conversationSid as string;
  const routeCaseId = useParams().caseId as string;
  const [noConversations, setNoConversations] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => {
      if (!conversations || conversations.length === 0) {
        setNoConversations(true);
      }
    }, 8000);

    return () => clearTimeout(timer);
  }, [conversations]);

  useEffect(() => {
    if (conversations && conversations.length > 0) {
      setNoConversations(false);
    }
  }, [conversations]);
  const userRole = useMemo(() => {
    if (!userInfo) {
      return null;
    }

    return userInfo.role.name;
  }, [userInfo]);
  const navigate = useNavigate();
  const screenSize = useWindowSize();
  const isMobile = screenSize.width < 768;

  const getConversationsForCase = async (
    currentCase: ICase,
  ): Promise<{ conversationType: string; conversation?: IGMConversation; createNewConversation?: boolean }[]> => {
    const conversationsList: {
      conversationType: string;
      conversation?: IGMConversation;
      createNewConversation?: boolean;
    }[] = [];

    const promises: Array<
      Promise<{ conversationType: string; conversation?: IGMConversation; createNewConversation?: boolean }>
    > = [];

    if (
      userRole === UserRoleName.CareAdvisor ||
      userRole === UserRoleName.Provider ||
      userRole === UserRoleName.Admin ||
      userRole === UserRoleName.SuperAdmin ||
      userRole === UserRoleName.OrganizationAdmin
    ) {
      if (currentCase.patient_care_advisor_conversation_sid) {
        promises.push(
          Promise.resolve({
            conversationType: `${UserRoleName.Patient}_${UserRoleName.CareAdvisor}`,
            conversation: await getConversation(currentCase.patient_care_advisor_conversation_sid),
          }),
        );
      }
      if (currentCase.patient_provider_conversation_sid) {
        promises.push(
          Promise.resolve({
            conversationType: `${UserRoleName.Patient}_${UserRoleName.Provider}`,
            conversation: await getConversation(currentCase.patient_provider_conversation_sid),
          }),
        );
      }
      if (currentCase.provider_care_advisor_conversation_sid) {
        promises.push(
          Promise.resolve({
            conversationType: `${UserRoleName.Provider}_${UserRoleName.CareAdvisor}`,
            conversation: await getConversation(currentCase.provider_care_advisor_conversation_sid),
          }),
        );
      }
    } else if (userRole === UserRoleName.Patient) {
      if (currentCase.patient_care_advisor_conversation_sid) {
        promises.push(
          Promise.resolve({
            conversationType: `${UserRoleName.Patient}_${UserRoleName.CareAdvisor}`,
            conversation: await getConversation(currentCase.patient_care_advisor_conversation_sid),
          }),
        );
      }
      if (currentCase.patient_provider_conversation_sid) {
        promises.push(
          Promise.resolve({
            conversationType: `${UserRoleName.Patient}_${UserRoleName.Provider}`,
            conversation: await getConversation(currentCase.patient_provider_conversation_sid),
          }),
        );
      }
    }

    const allConversations = await Promise.all(promises);

    if (userRole === UserRoleName.CareAdvisor && userInfo.id === Number(currentCase.care_advisor_id)) {
      if (currentCase.patient_care_advisor_conversation_sid === null) {
        allConversations.push({
          conversationType: `${UserRoleName.Patient}_${UserRoleName.CareAdvisor}`,
          createNewConversation: true,
        });
      }
      if (
        currentCase.provider_care_advisor_conversation_sid === null &&
        currentCase.provider !== null &&
        currentCase.provider !== undefined
      ) {
        allConversations.push({
          conversationType: `${UserRoleName.Provider}_${UserRoleName.CareAdvisor}`,
          createNewConversation: true,
        });
      }
    } else if (userRole === UserRoleName.Provider && userInfo.id === Number(currentCase.provider_id)) {
      if (
        currentCase.patient_provider_conversation_sid === null &&
        (currentCase.status === Status.New ||
          currentCase.status === Status.AcceptedProvider ||
          currentCase.status === Status.InProgressProvider)
      ) {
        allConversations.push({
          conversationType: `${UserRoleName.Patient}_${UserRoleName.Provider}`,
          createNewConversation: true,
        });
      }
      if (
        currentCase.provider_care_advisor_conversation_sid === null &&
        currentCase.care_advisor !== null &&
        currentCase.care_advisor !== undefined
      ) {
        allConversations.push({
          conversationType: `${UserRoleName.Provider}_${UserRoleName.CareAdvisor}`,
          createNewConversation: true,
        });
      }
    }

    return conversationsList.concat(allConversations);
  };

  const reloadConversationList = async (): Promise<void> => {
    if (thisCase) {
      await getConversationsForCase(thisCase).then((convList) => {
        setConversations(convList);
        if (activeConversationSid) {
          const activeConv = convList.find((c) => c?.conversation?.sid === activeConversationSid);
          if (!isMobile && !activeConv) {
            navigate(`/case-chat/${routeCaseId}/${activeConversationSid}`);
          }
        }
      });
    }
  };

  useEffect(() => {
    reloadConversationList();
  }, [thisCase, routeConversationSid, activeConversationSid]);

  const subscriber: Subscriber = useMemo(
    () => ({
      onMessage: (message: Record<string, any>) => {
        if (thisCase && Number(message.case_id) === Number(thisCase.id)) {
          if (['case_new_conversation_created', 'case_new_message_created'].includes(message.command)) {
            changeCaseUnreadIndicator();
            reloadConversationList();
          }
        }
      },
      commands: ['case_new_message_created', 'case_new_conversation_created'],
    }),
    [reloadConversationList],
  );
  const { subscribe, unsubscribe } = useSubscribe();

  useEffect(() => {
    const subscriptionId = subscribe(subscriber);

    return () => unsubscribe(subscriptionId);
  }, [subscribe, unsubscribe, subscriber]);

  const handleClick = (item: IGMConversation): void => {
    setActiveConversationSid(item.sid);
  };

  const sortConversations = (
    conversations: {
      conversationType: string;
      conversation?: IGMConversation | undefined;
      createNewConversation?: boolean | undefined;
    }[],
    userId: number,
  ): {
    conversationType: string;
    conversation?: IGMConversation | undefined;
    createNewConversation?: boolean | undefined;
  }[] => {
    return conversations.sort((a, b) => {
      const aIncludesUser = a?.conversation?.participants?.some((p) => Number(p.identity) === Number(userId)) ?? false;
      const bIncludesUser = b?.conversation?.participants?.some((p) => Number(p.identity) === Number(userId)) ?? false;

      const aIsSpecialCase = a.createNewConversation || !a.conversation;
      const bIsSpecialCase = b.createNewConversation || !b.conversation;

      if (aIsSpecialCase && !bIsSpecialCase) return -1;
      if (!aIsSpecialCase && bIsSpecialCase) return 1;

      if (aIncludesUser && !bIncludesUser) return -1;
      if (!aIncludesUser && bIncludesUser) return 1;

      return 0;
    });
  };

  return (
    <div className={styles.vSidenav}>
      <div className={`${styles.closeMessages} ${styles.borderBottom}`}>
        <button
          type="button"
          className={styles.closeMessagesButton}
          onClick={() => {
            if (userRole === UserRoleName.Patient) {
              navigate('/appointments');
            } else if (thisCase?.id) {
              navigate(`/consultation-page/${thisCase?.id}`);
            }
          }}
          disabled={UserRoleName.Patient !== userRole && !thisCase?.id}
        >
          <AiFillCloseCircle className={styles.closeMessagesIcon} />
          <div className={`fs-unmask ${styles.closeMessagesText}`}>Close Messages</div>
        </button>
      </div>
      <div className={styles.participantsDiv}>
        <div className={styles.vParticipantsInfo}>
          <div className={styles.messagesList}>
            {isMobile && <span className={styles.textCenter}>Choose a user below to enter the conversation</span>}
            {conversations && conversations.length > 0 ? (
              sortConversations(conversations, userInfo.id).map((conversation, index) => {
                if (conversation.conversation) {
                  if (!isProfileCard) {
                    return (
                      <li
                        key={conversation.conversation.sid}
                        className={`${activeConversationSid === conversation.conversation.sid ? styles.selected : ''} ${
                          styles.borderBottom
                        }`}
                        onClick={() => {
                          navigate(`/case-chat/${routeCaseId}/${conversation.conversation?.sid}`);
                        }}
                      >
                        <CaseMessageInfo
                          currentCase={thisCase}
                          item={conversation.conversation}
                          isSelected={activeConversationSid === conversation.conversation.sid}
                          conversationType={conversation.conversationType}
                          isMobile={isMobile}
                        />
                      </li>
                    );
                  }
                  return (
                    <li
                      key={conversation.conversation.sid}
                      onClick={() => handleClick(conversation.conversation as IGMConversation)}
                      className={activeConversationSid === conversation.conversation.sid ? styles.selected : ''}
                    >
                      <CaseMessageInfo
                        currentCase={thisCase}
                        item={conversation.conversation}
                        isSelected={activeConversationSid === conversation.conversation.sid}
                        conversationType={conversation.conversationType}
                        isMobile={isMobile}
                      />
                    </li>
                  );
                }
                if (conversation.createNewConversation) {
                  return (
                    <li key={index}>
                      <CaseMessageInfoCreateNewConversation
                        currentCase={thisCase}
                        conversationType={conversation.conversationType}
                        setActiveConversationSid={setActiveConversationSid}
                        setCase={setCase}
                        setLoading={setLoading}
                      />
                    </li>
                  );
                }
                return null;
              })
            ) : !noConversations ? (
              <Loading dotted />
            ) : (
              <span className={styles.noConversationSpan}>No Conversation Found.</span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CaseConversationList;
