import { client } from '../client';
import { ClinicalDataPayload, ClinicalDataResponse, ObjectType } from './types/types';

export const updateClinicalData = async (
  object_type: ObjectType,
  object_id: string,
  payload: ClinicalDataPayload,
): Promise<ClinicalDataResponse> => {
  const { data } = await client.put<ClinicalDataResponse>(`/clinical-data/${object_type}/${object_id}`, payload);

  return data;
};
