/* eslint-disable react/no-array-index-key */
import { addOneReportObject } from '@brands/services/oneReport/addOneReportObject';
import { deleteOneReportObject } from '@brands/services/oneReport/deleteOneReportObject';
import { IEducation } from '@brands/services/oneReport/types/IEducation';
import { ObjectType, updateOneReportObject } from '@brands/services/oneReport/updateOneReportObject';
import React, { useEffect, useState } from 'react';
import { FieldErrors, UseFormRegister, UseFormSetValue, UseFormTrigger } from 'react-hook-form';
import { AiFillPlusCircle } from 'react-icons/ai';
import { IoTrashOutline } from 'react-icons/io5';
import { TbExternalLink } from 'react-icons/tb';

import TextField from '../../../../Components/Inputs/TextField/TextField';
import { ICase } from '../../../../services/cases/types/ICase';
import { UserProfile } from '../../../../services/identity/types/UserProfile';
import { checkCollapsibleHeaderColor } from '../../../../Utils/getCollapsibleHeader';
import { Education, OralHealthReportSubmit } from '../../assets/types';
import styles from '../../styles.module.scss';
import { displayErrorDetails } from '@brands/Utils/displayError';

type EducationSectionProps = {
  consultationForm: OralHealthReportSubmit;
  setConsultationForm: React.Dispatch<React.SetStateAction<OralHealthReportSubmit>>;
  thisCase: ICase;
  setCase: React.Dispatch<React.SetStateAction<ICase>>;
  userInfo: UserProfile;
  register: UseFormRegister<OralHealthReportSubmit>;
  setValue: UseFormSetValue<OralHealthReportSubmit>;
  trigger: UseFormTrigger<OralHealthReportSubmit>;
  errors: FieldErrors<OralHealthReportSubmit>;
};

const EducationSection = ({
  consultationForm,
  setConsultationForm,
  thisCase,
  setCase,
  userInfo,
  register,
  setValue,
  trigger,
  errors,
}: EducationSectionProps): JSX.Element => {
  const [disabled, setDisabled] = useState<boolean>(false);
  const updateEducation = async (
    updatedEducation: Education,
    partialField: keyof IEducation,
    id?: string,
  ): Promise<void> => {
    try {
      const { educations: updatedEducations } = id
        ? await updateOneReportObject(thisCase?.one_report.id || '', ObjectType.Education, {
            ...updatedEducation,
            id,
          })
        : await addOneReportObject(thisCase?.one_report.id || '', ObjectType.Education, updatedEducation as IEducation);

      setCase((prevCase) => ({
        ...prevCase,
        one_report: {
          ...prevCase.one_report,
          educations: prevCase.one_report.educations.map((edu) =>
            edu.id === id
              ? {
                  ...edu,
                  [partialField]: updatedEducations.find((updatedEdu) => updatedEdu.id === id)?.[partialField],
                }
              : edu,
          ),
        },
      }));

      setConsultationForm((prevForm) => ({
        ...prevForm,
        educations: prevForm.educations.map((edu) =>
          edu.id === id
            ? { ...edu, [partialField]: updatedEducations.find((updatedEdu) => updatedEdu.id === id)?.[partialField] }
            : edu,
        ),
      }));
    } catch (error) {
      displayErrorDetails(`Error updating education: ${error}`);
    }
  };

  const addNewResourceField = async (): Promise<void> => {
    try {
      const { educations: res } = await addOneReportObject(thisCase?.one_report.id || '', ObjectType.Education, {
        url: '',
        title: '',
        id: '',
      });
      const sortedRes = res.sort((a, b) => Number(a.id?.valueOf() ?? Infinity) - Number(b.id?.valueOf() ?? Infinity));
      setConsultationForm((prevForm) => ({
        ...prevForm,
        educations: [...sortedRes],
      }));
    } catch (error) {
      displayErrorDetails(`Error adding new resource: ${error}`);
    }
  };

  const deleteEducation = async (id: string): Promise<void> => {
    try {
      if (id) {
        const res = await deleteOneReportObject(thisCase?.one_report.id || '', id, ObjectType.Education);
        if (res.educations.length === 0) {
          setValue(`educations`, []);
          trigger('educations');
        }
      }

      setConsultationForm((prevForm) => ({
        ...prevForm,
        educations: prevForm.educations
          .filter((education) => education.id !== id)
          .sort((a, b) => Number(a.id?.valueOf() ?? Infinity) - Number(b.id?.valueOf() ?? Infinity)),
      }));
    } catch (error) {
      displayErrorDetails(`Error deleting education: ${error}`);
    }
  };

  const handleEducationOnBlur = async (
    education: Education,
    partialEducation: keyof IEducation,
    id?: string,
  ): Promise<void> => {
    await updateEducation(education, partialEducation, id);
  };

  useEffect(() => {
    if (checkCollapsibleHeaderColor(thisCase, userInfo, 'Education') !== 'edit') {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [thisCase, userInfo]);

  return (
    <>
      <div className={styles.viewResources}>
        <div className={styles.viewResourcesContent}>
          <a
            href="https://patienthealthcenter.com/mgkOQvgp/home"
            style={{ textDecoration: 'none', color: 'unset', fontSize: 'unset' }}
            className="d-flex gap-2"
            target="_blank"
            rel="noreferrer"
          >
            <span className='fs-unmask'>View Dentistry.One education resources here</span>
            <span className={`fs-unmask ${styles.viewResourcesIcon}`}>
              <TbExternalLink />
            </span>
          </a>
        </div>
      </div>
      <>
        <div className={`fs-unmask ${styles.inputsLabel}`}>Copy and paste education URL and titles below</div>
        {consultationForm.educations.map((education, index) => (
          <div className={styles.educationInputsContainer} key={education.id}>
            <div className={styles.educationInputs} style={{ marginTop: index !== 0 ? '1.5rem' : '' }}>
              <div className={styles.educationInput}>
                <div className={`fs-unmask ${styles.title}`} style={{ display: index !== 0 ? 'none' : '' }}>
                  URL
                </div>
                <TextField
                  {...register(`educations.${index}.url`)}
                  name={`educations.${index}.url`}
                  wrapperStyle={{ marginTop: '0', borderBottom: 'none', height: '30px' }}
                  className={`fs-exclude ${styles.input}`}
                  placeholder=""
                  isUnderlined={false}
                  defaultValue={education.url}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setConsultationForm({
                      ...consultationForm,
                      educations: [
                        ...consultationForm.educations.slice(0, index),
                        {
                          ...consultationForm.educations[index],
                          url: e.target.value,
                        },
                        ...consultationForm.educations.slice(index + 1),
                      ],
                    });
                    setValue(`educations.${index}.url`, e.target.value);
                    if (errors.educations) trigger('educations');
                  }}
                  onBlur={async (e) => {
                    await handleEducationOnBlur(
                      {
                        ...education,
                        url: e.target.value,
                      },
                      'url',
                      education.id,
                    );
                  }}
                  disabled={disabled}
                />
              </div>
              <div className={styles.educationInput}>
                <div className={`fs-unmask ${styles.title}`} style={{ display: index !== 0 ? 'none' : '' }}>
                  Title
                </div>
                <TextField
                  {...register(`educations.${index}.title`)}
                  name={`educations.${index}.title`}
                  wrapperStyle={{ marginTop: '0', borderBottom: 'none', height: '30px' }}
                  className={`fs-exclude ${styles.input}`}
                  placeholder=""
                  isUnderlined={false}
                  defaultValue={education.title}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setConsultationForm({
                      ...consultationForm,
                      educations: [
                        ...consultationForm.educations.slice(0, index),
                        {
                          ...consultationForm.educations[index],
                          title: e.target.value,
                        },
                        ...consultationForm.educations.slice(index + 1),
                      ],
                    });
                    setValue(`educations.${index}.title`, e.target.value);
                    if (errors.educations) trigger('educations');
                  }}
                  onBlur={async (e) => {
                    await handleEducationOnBlur(
                      {
                        ...education,
                        title: e.target.value,
                      },
                      'title',
                      education.id,
                    );
                  }}
                  disabled={disabled}
                />
              </div>
            </div>
            {checkCollapsibleHeaderColor(thisCase, userInfo, 'Education') === 'edit' && (
              <div className={styles.deleteEducation} onClick={() => deleteEducation(education.id || '0')}>
                <IoTrashOutline className="w-100 h-100" />
              </div>
            )}
          </div>
        ))}
        {!disabled && (
          <div className={styles.addTreatment}>
            <div className={styles.addTreatmentContent} onClick={addNewResourceField}>
              <span className='fs-unmask'>Add Resource</span>
              <span className={`fs-unmask ${styles.addTreatmentIcon}`}>
                <AiFillPlusCircle />
              </span>
            </div>
          </div>
        )}
      </>
    </>
  );
};

export default React.memo(EducationSection);
