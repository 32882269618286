/* eslint-disable react/no-array-index-key */
import React from 'react';
import { FiEdit } from 'react-icons/fi';

import styles from '../../../styles.module.scss';

type ReadOnlyProfileProps = {
  onEditPharmacy: () => void;
  pharmacyName: string;
  pharmacyAddress: string;
  pharmacyPhoneNumber: string;
};

const ReadOnlyPharmacy = ({
  onEditPharmacy,
  pharmacyName,
  pharmacyAddress,
  pharmacyPhoneNumber,
}: ReadOnlyProfileProps): JSX.Element => {
  return (
    <div className={styles.readOnlyProfileContainer}>
      <div className={styles.titleContainer} style={{ marginBottom: '0' }}>
        <span className={`fs-unmask ${styles.title}`}>Pharmacy</span>
        <button className={`fs-unmask ${styles.editBtn}`} type="button" onClick={onEditPharmacy}>
          Edit
          <FiEdit className={`fs-unmask ${styles.editIcon}`} />
        </button>
      </div>
      <div>
        <div className={styles.detailsContainer}>
          <div className={`fs-exclude ${styles.readOnlyItem}`}>
            <div className={styles.sectionContentRowDetails}>{pharmacyName}</div>
            <div className={styles.sectionContentRowDetails}>{pharmacyAddress}</div>
            <div className={styles.sectionContentRowDetails}>{pharmacyPhoneNumber}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReadOnlyPharmacy;
