/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/control-has-associated-label */
import './styles.css';

import lozad from 'lozad';
import React, { createRef, useEffect, useState } from 'react';
import { GoCircleSlash } from 'react-icons/go';
import { ImArrowDown2 } from 'react-icons/im';
import Linkify from 'react-linkify';
import { Document, Page, pdfjs } from 'react-pdf';
import ReactViewer from 'react-viewer';

import useConversationMedia, { IConversationMedia } from '../../../hooks/conversation-hooks/useConversationMedia';
import { IGMMessage } from '../../../services/communication/types/IGMMessage';
import { truncateString } from '../../../Utils/truncateString';
import pdf from '../../assets/pdf.svg';
import styles from './styles.module.scss';
import VideoPlayer from './VideoPlayer';

pdfjs.GlobalWorkerOptions.workerSrc = '/assets/pdf.worker.mjs';

interface Props {
  message: IGMMessage;
  mediaIDs: string[];
  messageIndex: number;
  handleMediaLoad: (index: number) => void;
  direction: 'outgoing' | 'incoming';
}

const MessageContent: React.FC<Props> = ({ message, mediaIDs, messageIndex, handleMediaLoad, direction }) => {
  const uploadedMedias: IConversationMedia[] = useConversationMedia(mediaIDs, message.conversation_sid);

  const linkRef = createRef<HTMLAnchorElement>();
  const [, setNumPages] = useState(0);
  const [download, setDownload] = useState<boolean>(false);

  const [showModal, setShowModal] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState<number>(0);

  const onDocumentLoadSuccess = ({ numPagesNew }: any): void => {
    setNumPages(numPagesNew);
    setDownload(true);
  };

  const MAX_CHARS = 15; // Maximum number of characters before truncating

  const handleView = (mediaUrl: string): void => {
    const newTab = window.open(mediaUrl, '_blank');
    if (newTab) {
      newTab.focus();
    }
  };

  const imageMedias = uploadedMedias.filter((media) => media.mediaType === 'image');

  const handleImageView = (media: IConversationMedia): void => {
    const imageIndex = imageMedias.findIndex((uploadedMedia) => uploadedMedia.url === media.url);
    setSelectedImageIndex(imageIndex);
    setShowModal(true);
  };

  const linkDecorator = (href: string, text: string, key: number): JSX.Element => (
    <a
      href={href}
      key={key}
      target="_blank"
      rel="noopener noreferrer"
      style={{ color: direction === 'incoming' ? '#3c72b8' : 'white' }}
    >
      {text}
    </a>
  );

  useEffect(() => {
    const observer = lozad(`.${styles.lozad}`);
    observer.observe();
  }, []);

  return (
    <>
      {message.body === null || message.attributes.archived_at !== undefined ? (
        <div className={`fs.exclude ${styles.deletedMessage}`}>
          <GoCircleSlash className={styles.deletedMessageIcon} /> This message was deleted
        </div>
      ) : (
        <span className="fs.exclude">
          <Linkify componentDecorator={linkDecorator}>{message.body}</Linkify>
        </span>
      )}
      <div className={styles.mediaWrap}>
        {message.archived_at === null &&
          message.body !== null &&
          message.attributes.archived_at === undefined &&
          uploadedMedias[0] &&
          uploadedMedias.map((uploadedMedia) => (
            <>
              {uploadedMedia.mediaType === 'image' && (
                <img
                  alt="Uploaded"
                  onClick={() => handleImageView(uploadedMedia)}
                  onLoad={() => handleMediaLoad(messageIndex)}
                  className={`fs.exclude ${styles.uploadedImg} ${styles.lozad}`}
                  src={uploadedMedia.url}
                  key={uploadedMedia.url}
                />
              )}
              {uploadedMedia.mediaType === 'video' && <VideoPlayer videoUrl={uploadedMedia.url} />}
              {uploadedMedia.mediaType === 'other' && (
                <div className={styles.pdfDiv} onClick={() => handleView(uploadedMedia.url)}>
                  <Document
                    file={uploadedMedia.url}
                    onLoadSuccess={() => {
                      onDocumentLoadSuccess(1);
                      handleMediaLoad(messageIndex);
                    }}
                    className={`fs.exclude ${styles.lozad} ${styles.pdfDocument}`}
                  >
                    <Page className={styles.pdfPage} pageNumber={1} scale={1} />
                  </Document>
                  {download && (
                    <div className={styles.controls}>
                      <img className={styles.pdfIcon} alt="PDF" src={pdf} />
                      <span className={styles.pdfName}>
                        {truncateString(
                          decodeURIComponent(
                            uploadedMedia.url
                              .split('?')[0]
                              .split('/')
                              .pop()
                              ?.split('-')
                              ?.pop()
                              ?.replace(/%[0-9A-Fa-f]{2}/g, (match) =>
                                String.fromCharCode(parseInt(match.substr(1), 16)),
                              ) ?? '',
                          ),
                          MAX_CHARS,
                        )}
                      </span>
                      <span className={styles.pdfDownload}>
                        <a ref={linkRef}>
                          <ImArrowDown2 />
                        </a>
                      </span>
                    </div>
                  )}
                </div>
              )}
            </>
          ))}
        <ReactViewer
          visible={showModal}
          onClose={() => setShowModal(false)}
          images={imageMedias.map((uploadedMedia) => ({ src: uploadedMedia.url }))}
          activeIndex={selectedImageIndex}
        />
      </div>
    </>
  );
};

export default MessageContent;
