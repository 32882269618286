import TextField from '@brands/Components/Inputs/TextField/TextField';
import { ClinicalDataQuestion } from '@brands/services/clinical_data/types/types';
import React, { Dispatch, FC, useEffect, useState } from 'react';

import styles from './styles.module.scss';

interface QuestionAndTextFieldProps {
  element: ClinicalDataQuestion;
  form: { [key: string]: any };
  setForm: Dispatch<React.SetStateAction<any>>;
  questionTitle?: string;
  medicalCondition?: boolean;
  noneOfTheAboveFlag?: boolean;
  noneOfTheAboveQuestionId?: string;
}

const QuestionAndTextField: FC<QuestionAndTextFieldProps> = React.forwardRef(
  ({ element, form, setForm, questionTitle, medicalCondition, noneOfTheAboveFlag, noneOfTheAboveQuestionId }) => {
    const [enteredValue, setEnteredValue] = useState('');
    useEffect(() => {
      if (!form[element.id] || form[element.id] === 'no') {
        setEnteredValue('');
      } else {
        setEnteredValue(form[element.id]);
      }
    }, []);
    useEffect(() => {
      if (enteredValue && enteredValue.trim() !== '') {
        setForm((prev: any) => ({
          ...prev,
          [element.id]: enteredValue,
          ...(noneOfTheAboveQuestionId && { [noneOfTheAboveQuestionId]: false }),
        }));
      } else {
        setForm((prev: any) => ({
          ...prev,
          [element.id]: null,
        }));
      }
    }, [enteredValue]);

    useEffect(() => {
      if (noneOfTheAboveFlag && noneOfTheAboveQuestionId) {
        setEnteredValue(form[element.id] === false ? '' : form[element.id]);
      }
    }, [form[element.id]]);

    useEffect(() => {
      if (noneOfTheAboveFlag && noneOfTheAboveQuestionId) {
        setForm((prev: any) => {
          const updatedForm = { ...prev };
          Object.keys(updatedForm).forEach((key: string) => {
            if (key) {
              updatedForm[key] = 'no';
            }
          });
          updatedForm[noneOfTheAboveQuestionId] = true;
          return updatedForm;
        });
      }
    }, [noneOfTheAboveFlag]);

    return (
      <div className={`${styles.row} row ${styles.medicalFormRow}`}>
        <div className={styles.inputsDiv}>
          <div className={styles.rowItem}>
            <span
              className={`fs-unmask ${questionTitle && styles.mainTitle}`}
              style={{ opacity: questionTitle !== '' || medicalCondition ? '1' : '0.5' }}
            >
              {element.title.startsWith('Pharmacy') ? element.title.replace(/^Pharmacy\s*/, '') : element.title}
              {element.is_required ? <span className={styles.redText}> *</span> : ''}
            </span>
            {questionTitle !== '' && <span className={`fs-unmask ${styles.subTitle}`}>{questionTitle}</span>}
            <TextField
              name={element.id}
              value={enteredValue || ''}
              onChange={(e) => setEnteredValue(e.target.value)}
              wrapperClassName={`fs-exclude ${styles.inputWrapper}`}
              inputClass={styles.input}
            />
          </div>
        </div>
      </div>
    );
  },
);

QuestionAndTextField.defaultProps = {
  questionTitle: '',
  medicalCondition: false,
  noneOfTheAboveFlag: false,
  noneOfTheAboveQuestionId: undefined,
};

export default QuestionAndTextField;
