import PhoneInput from '@brands/Components/Inputs/PhoneInput/PhoneInput';
import { ClinicalDataQuestion } from '@brands/services/clinical_data/types/types';
import React, { Dispatch, FC, useEffect, useState } from 'react';

import styles from './styles.module.scss';
import { E164Number } from 'libphonenumber-js';

interface QuestionAndPhoneInputProps {
  element: ClinicalDataQuestion;
  form: { [key: string]: any };
  setForm: Dispatch<React.SetStateAction<any>>;
}

const QuestionAndPhoneInput: FC<QuestionAndPhoneInputProps> = React.forwardRef(({ element, form, setForm }) => {
  const [enteredValue, setEnteredValue] = useState('');
  useEffect(() => {
    if (!form[element.id] || form[element.id] === 'no') {
      setEnteredValue('');
    } else {
      setEnteredValue(form[element.id]);
    }
  }, []);
  useEffect(() => {
    if (enteredValue) {
      setForm((prev: any) => ({
        ...prev,
        [element.id]: enteredValue,
      }));
    } else {
      setForm((prev: any) => ({
        ...prev,
        [element.id]: '',
      }));
    }
  }, [enteredValue]);

  return (
    <div className={`${styles.row} row ${styles.medicalFormRow}`}>
      <div className="row" style={{ paddingBottom: '20px' }}>
        <div className="col">
          <div className={styles.inputWrap}>
            <div>
              <span className='fs-unmask'>
                {element.title.startsWith('Pharmacy') ? element.title.replace(/^Pharmacy\s*/, '') : element.title}
                {element.is_required ? <span className={styles.redText}> *</span> : ''}
              </span>
            </div>
            <PhoneInput
              id="iPhoneNumber"
              value={enteredValue as E164Number}
              onChange={(e) => {
                setEnteredValue(e as string);
              }}
              inputClass={styles.input}
              wrapperClassName={`fs-exclude ${styles.inputWrapper}`}
              isUnderlined={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
});

export default QuestionAndPhoneInput;
