export type License = {
  id?: number;
  state: string;
  number: string;
  expiry_date: string;
  insurance_carriers: UpdatedInsuranceCarrier[];
};

export type ProviderListTableLicense = {
  state: string;
  number: string;
  expiry_date: string;
  insurance_carriers: ProviderListTableLicenseInsuranceCarrier[];
};

export type ProviderListTableLicenseInsuranceCarrier = {
  id: number;
  name: string;
  payer_id: string;
};

export enum UserRoleName {
  Admin = 'Admin',
  SuperAdmin = 'SuperAdmin',
  OrganizationAdmin = 'OrganizationAdmin',
  Provider = 'Provider',
  Patient = 'Patient',
  CareAdvisor = 'CareAdvisor',
  Dependent = 'Dependent',
}

export interface ClientTag {
  id: number;
  name: string;
  code?: string;
}

export interface UserRole {
  id: number;
  name: UserRoleName;
  permissions: string;
}

export interface Language {
  id: string;
  name: string;
  locale: string;
}

export interface BasicInfo {
  first_name?: string;
  middle_name?: string;
  last_name?: string;
  gender?: string;
  date_of_birth?: string;
  pronouns?: string;
  languages?: Language[] | string[];
  gender_identity?: string;
}

export interface InsuranceThrough {
  first_name?: string;
  date_of_birth?: string;
  gender_identity?: string;
  middle_name?: string;
  last_name?: string;
  address: Address;
}

export interface Address {
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  zip_code?: string;
  country?: string;
  timezone?: string;
}

export interface UpdatedInsuranceCarrier {
  insurance_carrier?: InsuranceCarrier;
  insurance_carrier_id?: number;
  effective_date: string;
  recredential_date: string;
}

export interface InsuranceCarrier {
  id: number;
  name: string;
  payer_id?: string;
}

export interface InsuranceMembership {
  member_id: string;
  group_number: string;
  insurance_carrier?: InsuranceCarrier;
  insurance_carrier_id?: number;
  phone_number: string;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  zip_code?: string;
  country?: string;
  address?: {
    address1?: string;
    address2?: string;
    city?: string;
    state?: string;
    zip_code?: string;
    country?: string;
  };
}

export interface Insurance {
  id?: number;
  user_id?: number;
  insured_by: string;
  insurance_membership?: InsuranceMembership;
  insurance_through?: InsuranceThrough;
  is_current?: boolean;
  state_funded_coverage_status?: string;
}

export interface PaymentProfile {
  full_name: string;
  expiration_month: number;
  expiration_year: number;
  cc_last_four: number;
  billing_address: Address;
  is_active: boolean;
}

export interface PatientProfile {
  insurances: Insurance[];
  payment_profile: PaymentProfile;
  divisions: Division[];
  practices: Practice[];
}

export interface OrganizationAdminProfile {
  divisions?: Division[];
  practices?: Practice[];
}

export interface ProviderProfile {
  licenses?: License[];
  dea_number?: string;
  national_provider_id?: string;
  credentials?: string;
  education?: string[];
  additional_credentials?: string;
  primary_specialty?: string[];
  secondary_specialty?: string[];
  biography?: string;
  plan?: string[];
  gender?: string;
  availability?: string[];
  npi?: string;
  status?: string;
}
export interface CareAdvisorProfile {
  biography?: string;
}

export interface ProfessionalCorporation {
  id: string;
  state: string;
  video_case_enabled: boolean;
  async_case_enabled: boolean;
  second_opinion_case_enabled: boolean;
  phone_case_enabled: boolean;
}

export interface Division {
  id: string;
  name: string;
  is_default?: boolean;
  organization_id?: string;
  domain?: string;
}

export interface Practice {
  id: string;
  name: string;
  is_default?: boolean;
  organization_id?: string;
  domain?: string;
}
export interface Organization {
  id: string;
  name: string;
  credit_card_required?: boolean;
  divisions: Division[];
  practices: Practice[];
  professional_corporations?: ProfessionalCorporation[];
  payment_options: string[];
  follow_up_enabled?: boolean;
  care_coordination_enabled?: boolean;
  oral_health_coaching_enabled?: boolean;
}

export interface Marker {
  created_at: string;
  id: number;
  type: string;
  user_id: number;
  value: string;
}

export interface UserProfile {
  id: number;
  external_id: string;
  username: string;
  email: string;
  unverified_email?: string;
  status: string;
  full_name: string;
  phone_number: string;
  picture_media_id: string;
  guardian_id?: number;
  guardian_relationship?: string;
  role: Role;
  type?: Role;
  basic_info: BasicInfo;
  organizations: Organization[];
  patient_profile: PatientProfile;
  provider_profile: ProviderProfile;
  care_advisor_profile: CareAdvisorProfile;
  organization_admin_profile?: OrganizationAdminProfile;
  created_at: string;
  updated_at: string;
  state?: string;
  license_states?: string[];
  specialty?: string;
  plan?: string[];
  availability?: string[];
  languages?: Language[];
  insurance?: Insurance[];
  guardian?: any;
  national_provider_id?: string;
  credentials?: string;
  licenses?: License[];
  address?: Address;
  pharmacy_name?: string;
  pharmacy_street_address?: string;
  pharmacy_city?: string;
  pharmacy_state?: string;
  pharmacy_zip_code?: string;
  pharmacy_phone_number?: string;
  markers?: Marker[];
  web_state?: {
    state: object;
  };
}

export interface Role {
  name: string;
}
