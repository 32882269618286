import PhoneInput from '@brands/Components/Inputs/PhoneInput/PhoneInput';
import SelectInput from '@brands/Components/Inputs/SelectInput/SelectInput';
import TextField from '@brands/Components/Inputs/TextField/TextField';
import useInsuranceCarriers from '@brands/hooks/useInsuranceCarriers';
import { useAppSelector } from '@brands/hooks/useReduxHook';
import { selectPatientForm } from '@brands/store/selectors/patientForm';
import { checkAndSetDate } from '@brands/Utils/checkAndSetDate';
import { profileSelectStyles, selectCommonCustomStylesLarge } from '@brands/Utils/customStyles';
import { getFriendlyNameInsuredBy } from '@brands/Utils/getFriendlyName';
import { genderIdentity } from '@brands/Utils/selectButtonOptions';
import { insuranceThroughOptions, stateOptions } from '@brands/Utils/selectOptions';
import React, { useEffect, useMemo, useRef } from 'react';
import { FieldErrors, UseFormRegister } from 'react-hook-form';

// import { PatientFormFiveSubmit } from '../../../PatientForms/PatientForm/utils/types';
import styles from './styles.module.scss';
import { stateFundedProgramOptions } from '@brands/Dashboard/PatientForms/PatientForm/utils/types';

interface DashboardHeaderProps {
  patientForm: any;
  register: UseFormRegister<any>;
  errors: FieldErrors<any>;
  onChange: (key: string, value: string | number) => void;
}

const InsuranceFields: React.FC<DashboardHeaderProps> = ({ patientForm, register, errors, onChange }) => {
  const insuranceCarriers = useInsuranceCarriers();
  const phoneInputRef = useRef<HTMLInputElement>(null);
  const insuranceCarrierOptions = useMemo(() => {
    return insuranceCarriers.map((insuranceCarrier) => ({
      value: insuranceCarrier.id,
      label: insuranceCarrier.name,
    }));
  }, [insuranceCarriers]);
  const { values } = useAppSelector(selectPatientForm);

  const [isSelected, setIsSelected] = React.useState<number | null>(null);

  useEffect(() => {
    if (patientForm.state_funded_coverage_status !== '') {
      setIsSelected(
        Number(
          stateFundedProgramOptions.find((answer) => answer.value === patientForm.state_funded_coverage_status)?.id,
        ),
      );
    }
  }, [patientForm.state_funded_coverage_status]);

  return (
    <div className={styles.insuranceContainer}>
      <div className={styles.formContainer}>
        <div className={styles.wrapper}>
          <div className={styles.formContent}>
            <div className="col col-md-6">
              <div className={styles.inputWrap}>
                <div>
                  <span className='fs-unmask'>I’m insured by {getFriendlyNameInsuredBy(patientForm.insurance_through)}</span>
                  <span className={styles.redText}> *</span>
                </div>
                <SelectInput
                  {...register('insurance_through')}
                  containerClass={`fs-exclude ${styles.inputWrapper}`}
                  selectorClass={styles.selector}
                  name="insurance_through"
                  options={insuranceThroughOptions}
                  style={profileSelectStyles}
                  errors={errors}
                  selectedValue={patientForm.insurance_through}
                  showNoInsuranceOption={false}
                  onChange={(value) => {
                    onChange('insurance_through', value?.value as string);
                  }}
                />
              </div>
            </div>
            {patientForm.insurance_through !== 'none' && (
              <>
                <div className="row">
                  <div className="col">
                    <div className={styles.inputWrap}>
                      <div>
                        <span className='fs-unmask'>Insurance company</span>
                        <span className={styles.redText}> *</span>
                      </div>
                      <SelectInput
                        {...register('insurance_carrier_id')}
                        name="insurance_carrier_id"
                        options={insuranceCarrierOptions}
                        containerClass={`fs-exclude ${styles.inputWrapper}`}
                        selectorClass={styles.selector}
                        style={profileSelectStyles}
                        errors={errors}
                        selectedValue={
                          values.find((item) => item.form_question_id === 14)?.value?.selectedCarrier !== undefined &&
                          Number(values.find((item) => item.form_question_id === 14)?.value?.selectedCarrier) !== 0
                            ? Number(values.find((item) => item.form_question_id === 14)?.value?.selectedCarrier)
                            : patientForm.insurance_carrier_id
                        }
                        onChange={(value) => {
                          if (value) {
                            onChange('insurance_carrier_id', value?.value as number);
                          }
                        }}
                        isDisabled={
                          values.find((item) => item.form_question_id === 14)?.value?.selectedCarrier !== undefined &&
                          Number(values.find((item) => item.form_question_id === 14)?.value?.selectedCarrier) !== 0
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <div className={styles.inputWrap}>
                      <div>
                        <span className='fs-unmask'>Member ID</span>
                        <span className={styles.redText}> *</span>
                      </div>
                      <TextField
                        {...register('member_id')}
                        errors={errors}
                        name="member_id"
                        value={patientForm.member_id}
                        onChange={(e) => {
                          onChange('member_id', e.target.value);
                        }}
                        wrapperStyle={{ marginTop: '0', height: '100%', borderBottom: 'none' }}
                        inputClass={styles.profileInputClass}
                        wrapperClassName={`fs-exclude`}
                        isUnderlined={false}
                      />
                    </div>
                  </div>
                </div>
                <div className="row" style={{ paddingBottom: '20px' }}>
                  <div className="col">
                    <div className={styles.inputWrap}>
                      <div>
                        <span className='fs-unmask'>Group Number</span> <span className={styles.redText}> *</span>
                      </div>
                      <TextField
                        {...register('group_number')}
                        errors={errors}
                        name="group_number"
                        value={patientForm.group_number}
                        onChange={(e) => {
                          onChange('group_number', e.target.value);
                        }}
                        wrapperStyle={{ marginTop: '0', height: '100%', borderBottom: 'none' }}
                        wrapperClassName={`fs-exclude`}
                        inputClass={styles.profileInputClass}
                        isUnderlined={false}
                      />
                    </div>
                  </div>
                </div>
                <div className="row" style={{ paddingBottom: '20px' }}>
                  <div className="col">
                    <div className={styles.inputWrap}>
                      <div>
                        <span className='fs-unmask'>
                          Phone number at the back of your insurance card (if multiple, use the provider number)
                        </span>
                        <span className={styles.redText}> *</span>
                      </div>
                      <PhoneInput
                        {...register('iPhoneNumber')}
                        value={patientForm.iPhoneNumber}
                        id="iPhoneNumber"
                        onChange={(e) => {
                          onChange('iPhoneNumber', e as string);
                        }}
                        inputClass={styles.input}
                        errors={errors}
                        wrapperClassName={`fs-exclude ${styles.inputWrapper}`}
                        ref={phoneInputRef}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <div className={styles.inputWrap}>
                      <div>
                        <span className='fs-unmask'>Insurance Address</span>
                        <span className={styles.redText}> *</span>
                      </div>
                      <TextField
                        {...register('insurance_address')}
                        errors={errors}
                        name="insurance_address"
                        value={patientForm.insurance_address}
                        onChange={(e) => {
                          const { value } = e.target;
                          onChange('insurance_address', value);
                        }}
                        wrapperClassName={`fs-exclude ${styles.inputWrapper}`}
                        inputClass={styles.input}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <div className={styles.inputWrap}>
                      <div>
                        <span className='fs-unmask'>Insurance City</span>
                        <span className={styles.redText}> *</span>
                      </div>
                      <TextField
                        {...register('insurance_city')}
                        errors={errors}
                        name="insurance_city"
                        value={patientForm.insurance_city}
                        onChange={(e) => {
                          const { value } = e.target;
                          onChange('insurance_city', value);
                        }}
                        wrapperClassName={`fs-exclude ${styles.inputWrapper}`}
                        inputClass={styles.input}
                      />
                    </div>
                  </div>
                  <div className="col">
                    <div className={styles.inputWrap}>
                      <div>
                        <span className='fs-unmask'>Insurance State</span>
                        <span className={styles.redText}> *</span>
                      </div>
                      <SelectInput
                        {...register('insurance_state')}
                        name="insurance_state"
                        options={stateOptions}
                        containerClass={`fs-exclude ${styles.inputWrapper}`}
                        selectorClass={styles.selector}
                        style={selectCommonCustomStylesLarge}
                        errors={errors}
                        selectedValue={patientForm.insurance_state}
                        onChange={(value) => {
                          if (value) {
                            onChange('insurance_state', value.value);
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className="col">
                    <div className={styles.inputWrap}>
                      <div>
                        <span className='fs-unmask'>Insurance Zip Code</span>
                        <span className={styles.redText}> *</span>
                      </div>
                      <TextField
                        {...register('insurance_zip_code')}
                        errors={errors}
                        name="insurance_zip_code"
                        value={patientForm.insurance_zip_code}
                        onChange={(e) => {
                          const { value } = e.target;
                          onChange('insurance_zip_code', value);
                        }}
                        wrapperClassName={`fs-exclude ${styles.inputWrapper}`}
                        inputClass={styles.input}
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
            {patientForm.insurance_through &&
              patientForm.insurance_through !== 'myself' &&
              patientForm.insurance_through !== 'none' && (
                <div className={styles.insuranceThroughContainer}>
                  <div className="row">
                    <div className="col-12 col-md-4">
                      <div className={styles.inputWrap}>
                        <div>
                          <span className='fs-unmask'>Insurance Holder’s First Name</span>
                          <span className={styles.redText}> *</span>
                        </div>
                        <TextField
                          {...register('iFirstName')}
                          errors={errors}
                          name="iFirstName"
                          value={patientForm.iFirstName}
                          onChange={(e) => {
                            onChange('iFirstName', e.target.value);
                          }}
                          wrapperStyle={{ marginTop: '0', height: '100%', borderBottom: 'none' }}
                          wrapperClassName={`fs-exclude`}
                          inputClass={styles.profileInputClass}
                          isUnderlined={false}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-4">
                      <div className={styles.inputWrap}>
                        <div>
                          <span className='fs-unmask'>Insurance Holder’s Middle Name</span>
                        </div>
                        <TextField
                          {...register('iMiddleName')}
                          errors={errors}
                          name="iMiddleName"
                          value={patientForm.iMiddleName}
                          onChange={(e) => {
                            onChange('iMiddleName', e.target.value);
                          }}
                          wrapperStyle={{ marginTop: '0', height: '100%', borderBottom: 'none' }}
                          wrapperClassName={`fs-exclude`}
                          inputClass={styles.profileInputClass}
                          isUnderlined={false}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-4">
                      <div className={styles.inputWrap}>
                        <div>
                          <span className='fs-unmask'>Insurance Holder’s Last Name</span>
                          <span className={styles.redText}> *</span>
                        </div>
                        <TextField
                          {...register('iLastName')}
                          errors={errors}
                          name="iLastName"
                          value={patientForm.iLastName}
                          onChange={(e) => {
                            onChange('iLastName', e.target.value);
                          }}
                          wrapperStyle={{ marginTop: '0', height: '100%', borderBottom: 'none' }}
                          wrapperClassName={`fs-exclude`}
                          inputClass={styles.profileInputClass}
                          isUnderlined={false}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className={styles.inputWrap}>
                        <div>
                          <span className='fs-unmask'>Insurance Holder’s Date of Birth</span>
                          <span className={styles.redText}> *</span>
                        </div>
                        <TextField
                          {...register('iDateOfBirth')}
                          errors={errors}
                          name="iDateOfBirth"
                          placeholder="MM / DD / YYYY"
                          value={patientForm.iDateOfBirth}
                          onChange={(e) => {
                            onChange('iDateOfBirth', checkAndSetDate(e));
                          }}
                          maxLength={10}
                          wrapperStyle={{ marginTop: '0', height: '100%', borderBottom: 'none' }}
                          wrapperClassName={`fs-exclude`}
                          inputClass={styles.profileInputClass}
                          isUnderlined={false}
                          enableCursorPos={false}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className={styles.inputWrap}>
                        <div>
                          <span className='fs-unmask'>Insurance Holder's Gender Identity</span>
                        </div>
                        <SelectInput
                          {...register('iGenderIdentity')}
                          name="iGenderIdentity"
                          options={genderIdentity}
                          containerClass={`fs-exclude ${styles.inputWrapper}`}
                          selectorClass={styles.selector}
                          style={selectCommonCustomStylesLarge}
                          errors={errors}
                          selectedValue={patientForm.iGenderIdentity}
                          onChange={(value) => {
                            if (value) {
                              onChange('iGenderIdentity', value.value);
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className={styles.inputWrap}>
                        <div>
                          <span className='fs-unmask'>Insurance Holder’s Street Address</span>
                          <span className={styles.redText}> *</span>
                        </div>
                        <TextField
                          {...register('iAddress1')}
                          errors={errors}
                          name="iAddress1"
                          value={patientForm.iAddress1}
                          onChange={(e) => {
                            onChange('iAddress1', e.target.value);
                          }}
                          wrapperStyle={{ marginTop: '0', height: '100%', borderBottom: 'none' }}
                          wrapperClassName={`fs-exclude`}
                          inputClass={styles.profileInputClass}
                          isUnderlined={false}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className={styles.inputWrap}>
                        <span className='fs-unmask'>Apt, suite, building (optional)</span>
                        <TextField
                          {...register('iAddress2')}
                          errors={errors}
                          name="iAddress2"
                          value={patientForm.iAddress2}
                          onChange={(e) => {
                            onChange('iAddress2', e.target.value);
                          }}
                          wrapperStyle={{ marginTop: '0', height: '100%', borderBottom: 'none' }}
                          wrapperClassName={`fs-exclude`}
                          inputClass={styles.profileInputClass}
                          isUnderlined={false}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4">
                      <div className={styles.inputWrap}>
                        <div>
                          <span className='fs-unmask'>City</span>
                          <span className={styles.redText}> *</span>
                        </div>
                        <TextField
                          {...register('iCity')}
                          errors={errors}
                          name="iCity"
                          value={patientForm.iCity}
                          onChange={(e) => {
                            onChange('iCity', e.target.value);
                          }}
                          wrapperStyle={{ marginTop: '0', height: '100%', borderBottom: 'none' }}
                          wrapperClassName={`fs-exclude`}
                          inputClass={styles.profileInputClass}
                          isUnderlined={false}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className={styles.inputWrap}>
                        <div>
                          <span className='fs-unmask'>State</span>
                          <span className={styles.redText}> *</span>
                        </div>
                        <SelectInput
                          {...register('iState')}
                          containerClass="mt-0 fs-exclude"
                          name="iState"
                          options={stateOptions}
                          style={profileSelectStyles}
                          errors={errors}
                          selectedValue={patientForm.iState}
                          onChange={(value) => {
                            onChange('iState', value?.value as string);
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className={styles.inputWrap}>
                        <div>
                          <span className='fs-unmask'>Zip Code</span>
                          <span className={styles.redText}> *</span>
                        </div>
                        <TextField
                          {...register('iZipCode')}
                          errors={errors}
                          name="iZipCode"
                          value={patientForm.iZipCode}
                          onChange={(e) => {
                            onChange('iZipCode', e.target.value);
                          }}
                          wrapperStyle={{ marginTop: '0', height: '100%', borderBottom: 'none' }}
                          wrapperClassName={`fs-exclude`}
                          inputClass={styles.profileInputClass}
                          isUnderlined={false}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            {patientForm.insurance_through !== 'none' &&
              patientForm.insurance_through !== '' &&
              patientForm.insurance_through !== undefined && (
                <div className="row">
                  <div className={`${styles.rowItem}`}>
                    <div className={`fs-unmask ${styles.caption}`}>
                      Do you currently have insurance coverage through a state-funded program such as Medicaid?
                      <span> *</span>
                    </div>
                    <div className={styles.optionsDiv}>
                      {stateFundedProgramOptions.map((selection) => (
                        <button
                          className={
                            isSelected === selection.id
                              ? `${styles.multiSelectorInsuranceCoverage} ${styles.buttonSelectedInsuranceCoverage} fs-exclude`
                              : `${styles.multiSelectorInsuranceCoverage} fs-exclude`
                          }
                          value={patientForm.state_funded_coverage_status}
                          {...register('state_funded_coverage_status')}
                          type="button"
                          onClick={() => {
                            setIsSelected(selection.id);
                            onChange('state_funded_coverage_status', selection.value);
                          }}
                          key={selection.id}
                        >
                          <span className='fs-unmask'>{selection.label}</span>
                          <div className={styles.checkInput} />
                        </button>
                      ))}
                    </div>
                  </div>
                </div>
              )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InsuranceFields;
