import { ClinicalDataQuestion } from '@brands/services/clinical_data/types/types';
import React, { FC, useEffect, useState } from 'react';

import styles from './styles.module.scss';

interface QuestionAndRadioButtonProps {
  element: ClinicalDataQuestion;
  form: any;
  setForm: any;
  noneOfTheAboveFlag?: boolean;
  noneOfTheAboveQuestionId?: string;
}

const QuestionAndRadioButton: FC<QuestionAndRadioButtonProps> = React.forwardRef(
  ({ element, form, setForm, noneOfTheAboveFlag, noneOfTheAboveQuestionId }, ref) => {
    const [isSelected, setIsSelected] = useState(false);
    const [description, setDescription] = useState('');
    const [isEdit, setIsEdit] = useState(false);

    useEffect(() => {
      if (!form[element.id]) {
        setIsSelected(false);
      } else {
        setIsSelected(true);
        if (element.id) {
          setDescription(form[element.descriptionId as string]);
          setIsEdit(true);
        }
      }
    }, []);

    useEffect(() => {
      if (noneOfTheAboveFlag && noneOfTheAboveQuestionId && element.id && element.id !== noneOfTheAboveQuestionId) {
        const selected = form?.[element.id] || false; // Add optional chaining and default value
        setIsSelected(selected);

        if (form?.[element.id]) {
          setForm((prev: any) => ({
            ...prev,
            [noneOfTheAboveQuestionId]: false,
          }));
        }
      }
    }, [form, noneOfTheAboveFlag, noneOfTheAboveQuestionId, element.id, setForm]);

    useEffect(() => {
      if (noneOfTheAboveFlag && noneOfTheAboveQuestionId) {
        setForm((prev: any) => {
          const updatedForm = { ...prev };
          Object.keys(updatedForm).forEach((key: string) => {
            if (key) {
              updatedForm[key] = false;
            }
          });
          updatedForm[noneOfTheAboveQuestionId] = true;
          return updatedForm;
        });
      }
    }, [noneOfTheAboveFlag]);

    return (
      <div
        className={`${styles.row} row fs-exclude ${styles.medicalFormRow} ${isSelected ? styles.selectClass : ''}`}
        onClick={() => {
          setDescription('');
          setIsEdit(true);
          if (!isSelected) {
            setIsSelected(true);
            setForm((prev: any) => ({
              ...prev,
              [element.id]: true,
              [element.descriptionId as string]: '',
            }));
          } else {
            setIsSelected(false);
            setForm((prev: any) => ({
              ...prev,
              [element.id]: false,
              [element.descriptionId as string]: '',
            }));
          }
        }}
      >
        <div className={styles.questionAndButtonContainer}>
          <div className={`col-6 col-sm-3 px-0 ${styles.select}`}>
            <div className={styles.check}>
              <input
                className={`form-check-input fs-exclude ${styles.checkInput} mt-0 ${isSelected ? styles.primaryColor : ''}`}
                type="checkbox"
                name={element.id}
                id={element.id}
                value={isSelected ? 'true' : 'false'}
                ref={ref as any}
              />
            </div>
          </div>
          <div className={`${styles.question} col-12 col-sm-8 px-0`}>
            <div className={`${styles.label} fs-unmask`}>{element.title}</div>
          </div>
        </div>
        {element.type === 'textarea' && isSelected && isEdit && (
          <div className={styles.selectSelf} onClick={(e) => e.stopPropagation()}>
            <div className={`fs-exclude ${styles.textField}`}>
              <span className={`fs-unmask ${styles.textFieldTitle}`}>{element.description || 'Please tell us more'}</span>
              <input
                name={element.id}
                type="text"
                className={`fs-exclude ${element.description && element.description.length > 32 ? styles.placeholder : null}`}
                value={description}
                onChange={(e) => {
                  setDescription(e.target.value);
                  setForm((prev: any) => ({
                    ...prev,
                    [element.descriptionId as string]: e.target.value,
                  }));
                }}
                ref={ref as any}
              />
            </div>
          </div>
        )}
      </div>
    );
  },
);

QuestionAndRadioButton.defaultProps = {
  noneOfTheAboveFlag: false,
  noneOfTheAboveQuestionId: undefined,
};

export default QuestionAndRadioButton;
