/* eslint-disable @typescript-eslint/naming-convention */
import SelectInput from '@brands/Components/Inputs/SelectInput/SelectInput';
import AddressSection from '@brands/Dashboard/Dashboard/SuperAdmin/Components/AddressSection/AddressSection';
import { updateUserEmail } from '@brands/services/identity/updateUserEmail';
import { selectAuth } from '@brands/store/selectors/auth';
import { setUser } from '@brands/store/slices/authSlice';
import { setCareAdvisorMyProfile } from '@brands/store/slices/careAdvisorMyProfile';
import { UnifiedProfileSubmit } from '@brands/Utils/types';
import { validateAddress, ValidationModalInterface } from '@brands/Utils/validateAddress';
import { yupResolver } from '@hookform/resolvers/yup';
import cn from 'classnames';
import { E164Number, parsePhoneNumber } from 'libphonenumber-js';
import { isEqual } from 'lodash';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useForm, UseFormGetValues } from 'react-hook-form';
import { MultiValue } from 'react-select';
import { toast } from 'react-toastify';

import SuccessAlert from '../../../../Components/Alert/SuccessAlert';
import Button from '../../../../Components/Button/Button';
import MultiselectInput from '../../../../Components/Inputs/MultiselectInput/MultiselectInput';
import PhoneInput from '../../../../Components/Inputs/PhoneInput/PhoneInput';
import TextField from '../../../../Components/Inputs/TextField/TextField';
import ProfileImage from '../../../../Components/ProfileImage/ProfileImage';
import SelectButtons from '../../../../Components/ProfileSelectButtons/SelectButtons';
import { useAppDispatch, useAppSelector } from '../../../../hooks/useReduxHook';
import { RegisterMediaPayload } from '../../../../services/communication/registersConversationMedia';
import { confirmAvatarMedia } from '../../../../services/identity/confirmAvatarMedia';
import { createBasicInformation } from '../../../../services/identity/createBasicInformation';
import { createCareAdvisorProfile } from '../../../../services/identity/createCareAdvisorProfile';
import { deleteAvatar } from '../../../../services/identity/deleteAvatar';
import { registerAvatarMedia } from '../../../../services/identity/registerAvatarMedia';
import { saveBasicInformation } from '../../../../services/identity/saveBasicInformation';
import { saveCareAdvisorProfile } from '../../../../services/identity/saveCareAdvisorProfile';
import { Address, BasicInfo, UserProfile, UserRoleName } from '../../../../services/identity/types/UserProfile';
import { UpdateMe, updateMe } from '../../../../services/identity/updateMe';
import { multiSelectCommonCustomStylesLarge, selectCommonCustomStylesLarge } from '../../../../Utils/customStyles';
import { displayErrorDetails } from '../../../../Utils/displayError';
import { genderIdentity, genderOptions, pronounsOptions } from '../../../../Utils/selectButtonOptions';
import { OptionLanguage } from '../../../../Utils/selectOptions';
import styles from '../../styles.module.scss';
import { CAMyProfileSubmit } from '../utils/types';
import { validationSchema } from '../utils/validationSchema';

type EditProfileProps = {
  myProfileForm: CAMyProfileSubmit;
  setMyProfileForm: React.Dispatch<React.SetStateAction<CAMyProfileSubmit>>;
  setImage: React.Dispatch<React.SetStateAction<string | undefined>>;
  image: string | undefined;
  setIsEditProfile: React.Dispatch<React.SetStateAction<boolean>>;
  setCurrentAvatarURL: React.Dispatch<React.SetStateAction<string>>;
  currentAvatarURL: string;
  thisUser: UserProfile;
  setThisUser: React.Dispatch<React.SetStateAction<UserProfile>>;
  avatarAction: string;
  setAvatarAction: React.Dispatch<React.SetStateAction<string>>;
  reviewProfile: boolean | undefined;
  languageOptions: OptionLanguage[];
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  userId: string;
  validationResponseId: string;
  setValidationResponseId: React.Dispatch<React.SetStateAction<string>>;
};

const EditProfile = ({
  myProfileForm,
  setMyProfileForm,
  setImage,
  image,
  setIsEditProfile,
  setCurrentAvatarURL,
  currentAvatarURL,
  thisUser,
  setThisUser,
  avatarAction,
  setAvatarAction,
  reviewProfile,
  languageOptions,
  setLoading,
  userId,
  validationResponseId,
  setValidationResponseId,
}: EditProfileProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const { userInfo } = useAppSelector(selectAuth);
  const [isInfoSaved, setIsInfoSaved] = useState(false);
  const [avatarPayload, setAvatarPayload] = useState<RegisterMediaPayload | null>(null);
  const [avatarFile, setAvatarFile] = useState<File | null>(null);
  const [isSavingProfile, setIsSavingProfile] = useState(false);
  const [selection, setSelection] = React.useState<[number | null, number | null] | null>(null);
  const ref = React.useRef<HTMLInputElement>(null);
  const [showValidationAddressModal, setShowValidationAddressModal] = useState<ValidationModalInterface>({
    isOpen: false,
    title: '',
    whatYouEntered: '',
    recommended: false,
    recommendedAddress: '',
    onClose: undefined,
    onConfirmChange: undefined,
    onKeepCurrentAddress: undefined,
  });

  React.useLayoutEffect(() => {
    if (selection && ref.current) {
      [ref.current.selectionStart, ref.current.selectionEnd] = selection;
    }
  }, [selection]);

  const isAdminRole = useMemo(
    () =>
      userInfo.role.name === UserRoleName.Admin ||
      userInfo.role.name === UserRoleName.SuperAdmin ||
      userInfo.role.name === UserRoleName.OrganizationAdmin,
    [userInfo.role.name],
  );

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    getValues,
  } = useForm<CAMyProfileSubmit>({
    mode: 'onChange',
    resolver: yupResolver(validationSchema(isAdminRole)),
  });

  const triggerProfileAvatar = React.useCallback((avatarUrl: string) => {
    setValue('profile_img', avatarUrl, { shouldValidate: true });
    setMyProfileForm({
      ...getValues(),
      profile_img: avatarUrl,
    });
  }, []);

  const onCloseConfirmationModal = (): void => {
    setShowValidationAddressModal({
      isOpen: false,
      title: '',
      whatYouEntered: '',
      recommended: false,
      recommendedAddress: '',
      onClose: undefined,
      onConfirmChange: undefined,
      onKeepCurrentAddress: undefined,
    });
  };

  const callSaveProfile = async (profileData: UnifiedProfileSubmit): Promise<void> => {
    setIsSavingProfile(true);
    try {
      if (avatarPayload && avatarFile && avatarAction === 'Upload') {
        const res = await registerAvatarMedia(avatarPayload);
        const mediaId = res.files[0].upload_id;
        await fetch(res.files[0].upload_url, {
          method: 'PUT',
          body: avatarFile,
          headers: { 'content-type': avatarFile.type },
        });
        await confirmAvatarMedia(mediaId, thisUser.id);
        triggerProfileAvatar(res.files[0].upload_url);
      } else if (avatarAction === 'Remove') {
        await deleteAvatar(thisUser.id);
        triggerProfileAvatar('');
      }

      let updatedUser;
      if (thisUser?.email !== (profileData as UnifiedProfileSubmit).email) {
        updatedUser = await updateUserEmail(thisUser?.id || 0, {
          new_email: (profileData as UnifiedProfileSubmit).email as string,
        });
      }
      const { address1, address2, city, state, zip_code } = profileData;
      const userAddress: Address = { address1, address2, city, state, zip_code };
      updatedUser = await updateMe(
        {
          ...(profileData as UpdateMe),
          phone_number: parsePhoneNumber((profileData as CAMyProfileSubmit).phone_number, 'US')?.format(
            'E.164',
          ) as string,
          address: userAddress as Address,
        },
        thisUser.id,
      );

      if (thisUser && thisUser.basic_info !== null) {
        updatedUser = await saveBasicInformation(
          {
            ...(profileData as BasicInfo),
            languages: (profileData as CAMyProfileSubmit).language.map((language) => language.id.toString()),
          },
          thisUser.id,
        );
      } else {
        updatedUser = await createBasicInformation(
          {
            ...(profileData as BasicInfo),
            languages: (profileData as CAMyProfileSubmit).language.map((language) => language.id.toString()),
          },
          thisUser.id,
        );
      }

      if (thisUser && thisUser.care_advisor_profile !== null) {
        updatedUser = await saveCareAdvisorProfile(
          {
            biography: (profileData as CAMyProfileSubmit).biography,
          },
          thisUser.id,
        );
      } else {
        updatedUser = await createCareAdvisorProfile(
          {
            biography: (profileData as CAMyProfileSubmit).biography,
          },
          thisUser.id,
        );
      }
      setThisUser(updatedUser);
      if (!reviewProfile) {
        dispatch(
          setCareAdvisorMyProfile({
            ...(profileData as CAMyProfileSubmit),
          }),
        );
        dispatch(setUser({ userInfo: updatedUser }));
      }
      setIsInfoSaved(true);
      setTimeout(() => {
        setIsEditProfile(false);
        setIsInfoSaved(false);
      }, 500);
    } catch (error: unknown) {
      displayErrorDetails(error);
      setIsEditProfile(false);
    }
    setIsSavingProfile(false);
  };

  const onCancelEditProfile = (): void => {
    setImage(currentAvatarURL);
    setIsEditProfile(false);
  };

  const onInvalid = (errorsList: any): void => {
    const error = Object.values(errorsList)[0] as any;
    if (error) {
      toast.error(error.message as string);
    }
  };

  const updateCurrentAddress = (standardizedAddress: any, postalAddress: any, addressComponents: any): void => {
    const streetNumber = addressComponents.find(
      (addressComponent: any) => addressComponent.componentType === 'street_number',
    );
    const address1 = addressComponents.find((addressComponent: any) => addressComponent.componentType === 'route');
    if (postalAddress) setValue('city', postalAddress.locality);
    if (streetNumber || address1) {
      setValue('address1', `${streetNumber.componentName.text} ${address1.componentName.text}`);
    }
    if (standardizedAddress?.state) setValue('state', standardizedAddress.state);
    if (postalAddress) setValue('zip_code', postalAddress.postalCode);
  };

  const onSubmit = async (profileData: CAMyProfileSubmit): Promise<void> => {
    await validateAddress(
      profileData,
      getValues as UseFormGetValues<UnifiedProfileSubmit>,
      validationResponseId,
      callSaveProfile,
      setValidationResponseId,
      setShowValidationAddressModal,
      onCloseConfirmationModal,
      updateCurrentAddress,
    );
  };

  const setTextValue = (e: React.ChangeEvent<HTMLInputElement>, name: string): void => {
    const { value } = e.target;
    setValue(name as keyof CAMyProfileSubmit, value, { shouldValidate: true });
    setSelection([e.target.selectionStart, e.target.selectionEnd]);
  };

  useEffect(() => {
    if (Object.keys(myProfileForm).length > 1) {
      Object.keys(myProfileForm).forEach((key: unknown) => {
        setValue(key as keyof CAMyProfileSubmit, myProfileForm[key as keyof CAMyProfileSubmit]);
        if (thisUser.picture_media_id) {
          setValue('avatar', thisUser.picture_media_id, { shouldValidate: true });
        }
      });
      setLoading(false);
    }
  }, [myProfileForm]);

  useEffect(() => {
    if (image) triggerProfileAvatar(image);
  }, [image]);

  const phoneInputRef = useRef<HTMLInputElement>(null);
  return (
    <div className={styles.editProfileContainer}>
      <div className={styles.titleContainer}>
        <span className={styles.title}>Profile</span>
      </div>
      <div className={styles.editContainer}>
        <ProfileImage
          reviewProfile={reviewProfile}
          id={userId}
          setCurrentAvatarURL={setCurrentAvatarURL}
          setAvatarFile={setAvatarFile}
          setAvatarPayload={setAvatarPayload}
          setAvatarAction={setAvatarAction}
          triggerProfileAvatar={triggerProfileAvatar}
          setImage={setImage}
          image={image}
          errors={errors}
          size="80px"
        />
        <p className={styles.requiredIndicator}>
          Required <span>*</span>
        </p>
        <form className={styles.formContainer} onSubmit={handleSubmit(onSubmit, onInvalid)}>
          <div className={styles.row}>
            <div className={cn(styles.rowItem, styles.detailsCA)}>
              <div className={styles.caption}>
                First Name <span>*</span>
              </div>
              <TextField
                {...register('first_name')}
                errors={errors}
                name="first_name"
                value={getValues('first_name')}
                onChange={(e) => setTextValue(e, 'first_name')}
                wrapperClassName={styles.inputWrapper}
                inputClass={styles.input}
              />
            </div>
            <div className={cn(styles.rowItem, styles.detailsCA)}>
              <div className={styles.caption}>Middle Name</div>
              <TextField
                {...register('middle_name')}
                errors={errors}
                name="middle_name"
                value={getValues('middle_name')}
                onChange={(e) => setTextValue(e, 'middle_name')}
                wrapperClassName={styles.inputWrapper}
                inputClass={styles.input}
              />
            </div>
            <div className={cn(styles.rowItem, styles.detailsCA)}>
              <div className={styles.caption}>
                Last Name <span>*</span>
              </div>
              <TextField
                {...register('last_name')}
                errors={errors}
                name="last_name"
                value={getValues('last_name')}
                onChange={(e) => setTextValue(e, 'last_name')}
                wrapperClassName={styles.inputWrapper}
                inputClass={styles.input}
              />
            </div>
          </div>
          <div className={styles.row}>
            <div className={cn(styles.rowItem, styles.gender)}>
              <div className={styles.caption}>
                Gender assigned at birth <span>*</span>
              </div>
              <SelectButtons
                errors={errors}
                setValue={setValue}
                form={myProfileForm}
                value="gender"
                options={genderOptions}
              />
            </div>
          </div>
          <div className={styles.row}>
            <div className={cn('col-md-6', styles.rowItem, styles.details, styles.half)}>
              <div className={styles.caption}>
                Gender identity <span>{isAdminRole ? '' : '*'}</span>
              </div>
              <SelectInput
                {...register('gender_identity')}
                name="gender_identity"
                options={genderIdentity}
                containerClass={styles.inputWrapper}
                selectorClass={styles.selector}
                style={selectCommonCustomStylesLarge}
                errors={errors}
                selectedValue={getValues('gender_identity')}
                onChange={(value) => {
                  if (value) {
                    setValue('gender_identity', value.value, { shouldValidate: true });
                  }
                }}
              />
            </div>
            <div className={cn('col-md-6', styles.rowItem, styles.details, styles.half)}>
              <div className={styles.caption}>Pronouns</div>
              <SelectInput
                {...register('pronouns')}
                name="pronouns"
                options={pronounsOptions}
                containerClass={styles.inputWrapper}
                selectorClass={styles.selector}
                style={selectCommonCustomStylesLarge}
                errors={errors}
                selectedValue={getValues('pronouns')}
                onChange={(value) => {
                  if (value) {
                    setValue('pronouns', value.value, { shouldValidate: true });
                  }
                }}
              />
            </div>
          </div>
          <div className={styles.row}>
            <div className={cn(styles.rowItem, styles.detailsCA, styles.big, styles.languages)}>
              <div className={styles.caption}>
                Languages <span>*</span>
              </div>
              <MultiselectInput
                {...register('language')}
                name="language"
                placeholder="Please add any languages you are comfortable speaking during the consult"
                options={languageOptions}
                style={multiSelectCommonCustomStylesLarge}
                containerClass={styles.inputWrapper}
                selectorClass={styles.selector}
                errors={errors}
                selectedValue={getValues('language')}
                onChange={(value) => {
                  if (value) {
                    setValue('language', value as MultiValue<OptionLanguage>, {
                      shouldValidate: true,
                    });
                  }
                }}
              />
            </div>
          </div>
          <div className={styles.separator} />
          <div className={styles.row}>
            <AddressSection
              register={register}
              errors={errors}
              getValues={getValues}
              setTextValue={setTextValue}
              setValue={setValue}
              showValidationAddressModal={showValidationAddressModal}
              required
            />
          </div>
          <div className={styles.separator} />
          <div className={styles.row}>
            {reviewProfile && isAdminRole && (
              <div className={styles.rowItem}>
                <div className={styles.caption}>
                  Email Address <span>*</span>
                </div>
                <TextField
                  {...register('email')}
                  errors={errors}
                  name="email"
                  value={getValues('email')}
                  onChange={(e) => setTextValue(e, 'email')}
                  wrapperClassName={styles.inputWrapper}
                  inputClass={styles.input}
                />
              </div>
            )}
            <div className={styles.rowItem}>
              <div className={styles.caption}>
                Phone Number <span>*</span>
              </div>
              <div className={styles.inputWrapper}>
                <PhoneInput
                  {...register('phone_number')}
                  value={myProfileForm.phone_number as E164Number}
                  id="phone_number"
                  onChange={(val) => {
                    setValue('phone_number', val as string, {
                      shouldValidate: true,
                    });
                  }}
                  inputClass={styles.input}
                  errors={errors}
                  wrapperClassName={styles.inputWrapper}
                  ref={phoneInputRef}
                />
              </div>
            </div>
          </div>
          <div className={styles.separator} />
          <div className={styles.row}>
            <div className={cn(styles.rowItem)}>
              <div className={styles.caption}>
                Biography <span>*</span>
              </div>
              <textarea
                {...register('biography')}
                className={cn(styles.formControl, styles.textareaProfile)}
                style={{ marginLeft: '0px', width: '100%' }}
                placeholder="Write here"
                onChange={(e) => {
                  setValue('biography', e.target.value, { shouldValidate: true });
                  setSelection([e.target.selectionStart, e.target.selectionEnd]);
                }}
                value={getValues('biography')}
              />
              <div
                className={`${styles.invalidFeedback} invalid-feedback`}
                style={{ display: errors.biography?.message ? 'block' : 'none' }}
              >
                {errors.biography?.message}
              </div>
            </div>
          </div>
          {isInfoSaved && <SuccessAlert />}
          <div className={styles.actionBtnContainer}>
            <Button type="button" className={`fs-unmask ${styles.cancel}`} onClick={onCancelEditProfile}>
              Cancel
            </Button>
            <Button
              type="submit"
              className={`fs-unmask ${styles.submit}`}
              disabled={(isEqual(myProfileForm, getValues()) && isEqual(avatarAction, '')) || isSavingProfile}
            >
              Save
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditProfile;
