/* eslint-disable no-nested-ternary */
import Button from '@brands/Components/Button/Button';
import { Loading } from '@brands/Components/LoadingSpinner/Loading';
import AddAdditionalInformationButton from '@brands/Dashboard/Appointments/Patient/AppointmentCard/AddAdditionalInformationButton';
import CancelButton from '@brands/Dashboard/Appointments/Patient/AppointmentCard/CancelButton';
import EditCreditCardButton from '@brands/Dashboard/Appointments/Patient/AppointmentCard/EditCreditCardButton';
import JoinButton from '@brands/Dashboard/Appointments/Patient/AppointmentCard/JoinButton';
import RescheduleButton from '@brands/Dashboard/Appointments/Patient/AppointmentCard/RescheduleButton';
import CancelAppointmentModal from '@brands/Dashboard/Appointments/Patient/CancelAppointmentModal/CancelAppointmentModal';
import EditCreditCardModal from '@brands/Dashboard/Appointments/Patient/EditCreditCardModal/EditCreditCardModal';
import { useAppSelector } from '@brands/hooks/useReduxHook';
import useSubscribe, { Subscriber } from '@brands/hooks/useSubscribe';
import { CaseType, ICase, Status } from '@brands/services/cases/types/ICase';
import { selectDependents } from '@brands/store/selectors/dependents';
import { selectOrganization } from '@brands/store/selectors/organization';
import { getCaseDate, getCaseTime, getFormatDate } from '@brands/Utils/formatDate';
import {
  addAdditionalInformationAvailability,
  cancelCaseAvailability,
  getFriendlyNameOfConsultationType,
  rescheduleCaseAvailability,
  updatePaymentAvailability,
} from '@brands/Utils/getFriendlyName';
import React, { useEffect, useMemo, useState } from 'react';
import { useDetectClickOutside } from 'react-detect-click-outside';
import { FiEdit } from 'react-icons/fi';
import { MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight } from 'react-icons/md';
import { TbMessage2 } from 'react-icons/tb';
import { useNavigate } from 'react-router-dom';

import ProfileComponent from '../../CareAdvisor/Component/ProfileComponent';
import styles from './styles.module.scss';

type AppointmentsProps = {
  displayingCases: ICase[];
  dataLoading: boolean;
  reloadAll: () => Promise<void>;
  scrollToTargetDiv: () => void;
};

const UpcomingServices = ({
  displayingCases,
  dataLoading,
  reloadAll,
  scrollToTargetDiv,
}: AppointmentsProps): JSX.Element => {
  const navigate = useNavigate();
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
  const { dependents } = useAppSelector(selectDependents);
  const { currentOrganization } = useAppSelector(selectOrganization);
  const [casesIndex, setCasesIndex] = useState(0);
  const [thisCase, setCase] = useState<ICase>();
  const [editAppointmentDropDown, setOpenAppointmentDropDown] = useState(0);
  const ref = useDetectClickOutside({ onTriggered: () => setOpenAppointmentDropDown(0) });
  const [cancelCaseModal, setCancelCaseModal] = useState<boolean>(false);
  const [editCreditCardModal, setEditCreditCardModal] = useState<boolean>(false);

  const checkIsDependentActive = (appointment: ICase): boolean => {
    const isDependent = appointment.patient?.guardian_id;
    if (!isDependent) return true;
    const dependentDetails = dependents.find(
      (dependent) => Number(dependent?.value) === Number(appointment.patient?.id),
    );
    const isDeactivated = isDependent && dependentDetails?.status === 'Active';
    return isDeactivated || true;
  };

  const setOpenEditAppointment = (appointmentId: number): void => {
    setOpenAppointmentDropDown(appointmentId);
  };

  const subscriber: Subscriber = useMemo(
    () => ({
      onMessage: (message: Record<string, any>) => {
        if (
          [
            'case_updated',
            'case_new_message_created',
            'case_cancelled',
            'case_new_conversation_created',
            'case_completed',
          ].includes(message.command)
        ) {
          reloadAll();
        }
      },
      commands: [
        'case_updated',
        'case_new_message_created',
        'case_cancelled',
        'case_new_conversation_created',
        'case_completed',
      ],
    }),

    [reloadAll],
  );
  const { subscribe, unsubscribe } = useSubscribe();

  useEffect(() => {
    const subscriptionId = subscribe(subscriber);

    return () => unsubscribe(subscriptionId);
  }, [subscribe, unsubscribe, subscriber]);

  return (
    <div className={`${styles.upcomingServicesContainer} fs-unmask`}>
      <div className={styles.upcomingConsultationsTitle}>
        <span className='fs-unmask'>Upcoming Services</span>
      </div>
      {dataLoading ? (
        <Loading dotted />
      ) : displayingCases.length > 0 ? (
        <div className={styles.upcomingConsultations}>
          <div className={styles.appointmentDivContainer}>
            {displayingCases.length > 1 && (
              <div className={styles.navigationLeftButtons}>
                <div
                  className={styles.navigationButton}
                  onClick={() => {
                    if (casesIndex > 0) {
                      setCasesIndex(casesIndex - 1);
                    } else {
                      setCasesIndex(displayingCases.length - 1);
                    }
                  }}
                >
                  <MdOutlineKeyboardArrowLeft />
                </div>
              </div>
            )}
            <div className={styles.appointmentLeftSide}>
              <div className={styles.appointmentDetails}>
                <div className={styles.providerImage}>
                  {displayingCases[casesIndex].type !== CaseType.care_coordination &&
                  displayingCases[casesIndex].type !== CaseType.oral_health_coaching ? (
                    <ProfileComponent
                      caseRow={displayingCases[casesIndex]}
                      displayRole={false}
                      role="Provider"
                      showName={false}
                    />
                  ) : (
                    <ProfileComponent
                      caseRow={displayingCases[casesIndex]}
                      displayRole={false}
                      role="CA"
                      showName={false}
                    />
                  )}
                </div>
                <div className={styles.appointmentInfo}>
                  <span className={`${styles.appointmentState} fs-unmask`}>
                    {(displayingCases[casesIndex].provider_id ||
                      displayingCases[casesIndex].type === CaseType.care_coordination ||
                      displayingCases[casesIndex].type === CaseType.oral_health_coaching) &&
                      getFriendlyNameOfConsultationType(
                        displayingCases[casesIndex].type,
                        displayingCases[casesIndex].status === Status.Canceled,
                      )}
                  </span>
                  {displayingCases[casesIndex].provider && (
                    <span className={styles.providerName}>
                      <ProfileComponent
                        caseRow={displayingCases[casesIndex]}
                        displayRole={false}
                        role="Provider"
                        showImage={false}
                        maxChars={25}
                      />
                    </span>
                  )}
                  {(displayingCases[casesIndex].type === CaseType.care_coordination ||
                    displayingCases[casesIndex].type === CaseType.oral_health_coaching) && (
                    <span className={styles.providerName}>
                      <ProfileComponent
                        caseRow={displayingCases[casesIndex]}
                        displayRole={false}
                        role="CA"
                        showImage={false}
                        maxChars={25}
                      />
                    </span>
                  )}
                  {(displayingCases[casesIndex].type === CaseType.video_call_scheduled ||
                    displayingCases[casesIndex].type === CaseType.video_call_instant ||
                    displayingCases[casesIndex].type === CaseType.phone_call_scheduled) && (
                    <span className={`${styles.appointmentVideoDate} fs-unmask`}>
                      {displayingCases[casesIndex].video_conference_date !== null ? (
                        <>
                          <span>{getCaseDate(displayingCases[casesIndex].video_conference_date || '', timeZone)}</span>
                          <span>{getCaseTime(displayingCases[casesIndex].video_conference_date || '', timeZone)}</span>
                        </>
                      ) : (
                        <span>TBD</span>
                      )}
                    </span>
                  )}
                  <span className={styles.appointmentDetailsInfo}>
                    <span className='fs-unmask'>Requested</span>
                    <span className='fs-unmask'>{getFormatDate(displayingCases[casesIndex].created_at || '', timeZone)}</span>
                  </span>
                  {displayingCases[casesIndex].type !== CaseType.care_coordination &&
                    displayingCases[casesIndex].type !== CaseType.oral_health_coaching &&
                    displayingCases[casesIndex].organization.care_coordination_enabled && (
                      <span className={`${styles.caNAme} ${styles.appointmentDetailsInfo}`}>
                        <span className='fs-unmask'>Care Advisor</span>
                        <span>
                          <ProfileComponent
                            caseRow={displayingCases[casesIndex]}
                            displayRole={false}
                            role="CA"
                            showImage={false}
                            maxChars={25}
                          />
                        </span>
                      </span>
                    )}
                </div>
              </div>
            </div>
            <div className={styles.appointmentRightSide}>
              <div className={styles.appointmentControls}>
                <div className={styles.controls}>
                  {displayingCases[casesIndex] &&
                    (displayingCases[casesIndex].type === 'video_call_scheduled' ||
                      displayingCases[casesIndex].type === 'video_call_instant' ||
                      displayingCases[casesIndex].type === 'oral_health_coaching') && (
                      <JoinButton appointment={displayingCases[casesIndex]} />
                    )}
                  {(displayingCases[casesIndex].patient_care_advisor_conversation_sid ||
                    displayingCases[casesIndex].patient_provider_conversation_sid) && (
                    <Button
                      className={`${styles.appBtn} fs-unmask`}
                      type="button"
                      onClick={() => navigate(`/case-chat/${displayingCases[casesIndex]?.id}`)}
                    >
                      <TbMessage2 /> Messages
                    </Button>
                  )}
                </div>
                {displayingCases[casesIndex].type !== CaseType.care_coordination &&
                  ((checkIsDependentActive(displayingCases[casesIndex]) &&
                    currentOrganization.payment_options.find(
                      (paymentOption: string) => paymentOption === 'Credit Card',
                    ) &&
                    updatePaymentAvailability(displayingCases[casesIndex])) ||
                    cancelCaseAvailability(displayingCases[casesIndex]) ||
                    rescheduleCaseAvailability(displayingCases[casesIndex]) ||
                    addAdditionalInformationAvailability(displayingCases[casesIndex])) && (
                    <div
                      className={styles.controlsEdit}
                      onClick={() => setOpenEditAppointment(displayingCases[casesIndex].id)}
                      ref={ref}
                    >
                      <button
                        className={`${styles.editBtn} fs-unmask`}
                        type="button"
                        onClick={(e) => {
                          e.stopPropagation();
                          setOpenEditAppointment(displayingCases[casesIndex].id);
                        }}
                      >
                        <FiEdit className={styles.editIcon} />
                        Edit Appointment
                      </button>
                      {editAppointmentDropDown === displayingCases[casesIndex].id && (
                        <div className={`${styles.appointmentDropDown}`}>
                          {addAdditionalInformationAvailability(displayingCases[casesIndex]) && (
                            <span>
                              <AddAdditionalInformationButton appointment={displayingCases[casesIndex]} />
                            </span>
                          )}
                          {checkIsDependentActive(displayingCases[casesIndex]) &&
                            currentOrganization.payment_options.find(
                              (paymentOption: string) => paymentOption === 'Credit Card',
                            ) &&
                            updatePaymentAvailability(displayingCases[casesIndex]) && (
                              <span>
                                <EditCreditCardButton
                                  appointment={displayingCases[casesIndex]}
                                  setEditCreditCardModal={setEditCreditCardModal}
                                  setCase={setCase}
                                  updatePaymentAvailabilityCheck={updatePaymentAvailability(
                                    displayingCases[casesIndex],
                                  )}
                                />
                              </span>
                            )}
                          {rescheduleCaseAvailability(displayingCases[casesIndex]) && (
                            <span>
                              <RescheduleButton
                                appointment={displayingCases[casesIndex]}
                                rescheduleAvailability={rescheduleCaseAvailability(displayingCases[casesIndex])}
                              />
                            </span>
                          )}
                          {cancelCaseAvailability(displayingCases[casesIndex]) && (
                            <span>
                              <CancelButton
                                appointment={displayingCases[casesIndex]}
                                setCancelCaseModal={setCancelCaseModal}
                                setCase={setCase}
                                cancelAvailability={cancelCaseAvailability(displayingCases[casesIndex])}
                              />
                            </span>
                          )}
                        </div>
                      )}
                    </div>
                  )}
              </div>
            </div>
            {displayingCases.length > 1 && (
              <div className={styles.navigationRightButtons}>
                <div
                  className={styles.navigationButton}
                  onClick={() => {
                    if (casesIndex < displayingCases.length - 1) {
                      setCasesIndex(casesIndex + 1);
                    } else {
                      setCasesIndex(0);
                    }
                  }}
                >
                  <MdOutlineKeyboardArrowRight />
                </div>
              </div>
            )}
          </div>

          {cancelCaseModal && (
            <CancelAppointmentModal
              setOpenModal={setCancelCaseModal}
              thisCase={thisCase!}
              reloadAll={reloadAll}
              refreshAll={reloadAll}
            />
          )}
          {editCreditCardModal && (
            <EditCreditCardModal
              setOpenModal={setEditCreditCardModal}
              thisCase={thisCase!}
              reloadAll={reloadAll}
              refreshAll={reloadAll}
            />
          )}
        </div>
      ) : (
        <div className={styles.noCases}>
          <span className='fs-unmask'>You have no upcoming services</span>
          <Button onClick={scrollToTargetDiv} className={`fs-unmask ${styles.dashboardBtn}`} id="schedule_now">
            Schedule Now
          </Button>
        </div>
      )}
      <div className={styles.controlsEdit}>
        <button className={`${styles.editBtn} fs-unmask`} type="button" onClick={() => navigate(`/appointments`)}>
          View Past Appointments
        </button>
      </div>
    </div>
  );
};
export default UpcomingServices;
