/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
import PatientProfileCardModal from '@brands/Dashboard/ProfileCards/Patient/ProfileCards';
import { getOneCase } from '@brands/services/cases/getCaseById';
import { createConversation } from '@brands/services/communication/createConversation';
import { selectAuth } from '@brands/store/selectors/auth';
import { displayErrorDetails } from '@brands/Utils/displayError';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAppSelector } from '../../hooks/useReduxHook';
import { ICase } from '../../services/cases/types/ICase';
import { getUserAvatar } from '../../services/identity/getUserAvatar';
import { UserProfile, UserRoleName } from '../../services/identity/types/UserProfile';
import styles from './case-styles.module.scss';

interface IModal {
  currentCase: ICase | undefined;
  conversationType: string;
  setActiveConversationSid: (conversationSid: string) => void;
  setCase: React.Dispatch<React.SetStateAction<ICase | undefined>>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const CaseMessageInfoCreateNewConversation = ({
  currentCase,
  conversationType,
  setActiveConversationSid,
  setCase,
  setLoading,
}: IModal): JSX.Element => {
  const { userInfo } = useAppSelector(selectAuth);
  const navigate = useNavigate();
  const [profileCardModal, setProfileCardModal] = useState(false);
  const [patientParticipant, setPatientParticipant] = useState<UserProfile | null>(null);
  const newConversationCreation = async (): Promise<void> => {
    try {
      const newConversation = await createConversation({
        friendly_name: conversationType,
        participants: [
          {
            identity: String(
              conversationType === `${UserRoleName.Provider}_${UserRoleName.CareAdvisor}`
                ? userInfo.role.name === UserRoleName.CareAdvisor
                  ? currentCase?.provider_id
                  : currentCase?.care_advisor_id
                : currentCase?.patient.role.name === UserRoleName.Patient
                ? currentCase?.patient_id
                : currentCase?.patient.guardian_id,
            ),
          },
        ],
        conversation_type:
          conversationType === `${UserRoleName.Provider}_${UserRoleName.CareAdvisor}`
            ? 'provider_care_advisor_conversation'
            : conversationType === `${UserRoleName.Patient}_${UserRoleName.CareAdvisor}`
            ? 'patient_care_advisor_conversation'
            : 'patient_provider_conversation',
        case_id: Number(currentCase?.id),
        attributes: {
          case_id: Number(currentCase?.id),
        },
      });
      const updatedCase = await getOneCase(Number(currentCase?.id), {
        include_patient_care_advisor_conversation:
          userInfo.role.name !== UserRoleName.Admin &&
          userInfo.role.name !== UserRoleName.SuperAdmin &&
          userInfo.role.name !== UserRoleName.OrganizationAdmin,
        include_patient_provider_conversation:
          userInfo.role.name !== UserRoleName.Admin &&
          userInfo.role.name !== UserRoleName.SuperAdmin &&
          userInfo.role.name !== UserRoleName.OrganizationAdmin,
        include_provider_care_advisor_conversation:
          userInfo.role.name !== UserRoleName.Patient &&
          userInfo.role.name !== UserRoleName.Admin &&
          userInfo.role.name !== UserRoleName.SuperAdmin &&
          userInfo.role.name !== UserRoleName.OrganizationAdmin,
      });
      setCase(updatedCase);
      if (newConversation.sid) {
        setActiveConversationSid(newConversation.sid);
        navigate(`/case-chat/${currentCase?.id}/${newConversation.sid}`);
      }
    } catch (error: unknown) {
      displayErrorDetails(error);
    }
  };

  const CreateNewConversation = async (): Promise<void> => {
    try {
      setLoading(true);
      const caseCurrent = await getOneCase(Number(currentCase?.id), {
        include_patient_care_advisor_conversation:
          userInfo.role.name !== UserRoleName.Admin &&
          userInfo.role.name !== UserRoleName.SuperAdmin &&
          userInfo.role.name !== UserRoleName.OrganizationAdmin,
        include_patient_provider_conversation:
          userInfo.role.name !== UserRoleName.Admin &&
          userInfo.role.name !== UserRoleName.SuperAdmin &&
          userInfo.role.name !== UserRoleName.OrganizationAdmin,
        include_provider_care_advisor_conversation:
          userInfo.role.name !== UserRoleName.Patient &&
          userInfo.role.name !== UserRoleName.Admin &&
          userInfo.role.name !== UserRoleName.SuperAdmin &&
          userInfo.role.name !== UserRoleName.OrganizationAdmin,
      });
      if (conversationType === `${UserRoleName.Patient}_${UserRoleName.CareAdvisor}`) {
        if (caseCurrent.patient_care_advisor_conversation_sid === null) {
          newConversationCreation();
        }
      } else if (conversationType === `${UserRoleName.Patient}_${UserRoleName.Provider}`) {
        if (caseCurrent.patient_provider_conversation_sid === null) {
          newConversationCreation();
        }
      } else if (conversationType === `${UserRoleName.Provider}_${UserRoleName.CareAdvisor}`) {
        if (caseCurrent.provider_care_advisor_conversation_sid === null) {
          newConversationCreation();
        }
      }
      setCase(caseCurrent);
      const conversationSid =
        conversationType === `${UserRoleName.Patient}_${UserRoleName.CareAdvisor}`
          ? caseCurrent.patient_care_advisor_conversation_sid
          : conversationType === `${UserRoleName.Patient}_${UserRoleName.Provider}`
          ? caseCurrent.patient_provider_conversation_sid
          : caseCurrent.provider_care_advisor_conversation_sid;
      if (conversationSid) {
        setActiveConversationSid(conversationSid);
        navigate(`/case-chat/${currentCase?.id}/${conversationSid}`);
      }
    } catch (error: unknown) {
      displayErrorDetails(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={`${styles.participantDiv} ${styles.participantMessageDiv}`} onClick={() => CreateNewConversation()}>
      <div className={styles.wrapDiv}>
        <span className={`fs-unmask ${styles.conversationType}`}>
          {conversationType === `${UserRoleName.Patient}_${UserRoleName.CareAdvisor}` ||
          conversationType === `${UserRoleName.Patient}_${UserRoleName.Provider}`
            ? currentCase?.patient.guardian_id
              ? 'Guardian'
              : UserRoleName.Patient
            : userInfo.role.name === UserRoleName.CareAdvisor
            ? UserRoleName.Provider
            : UserRoleName.CareAdvisor}
        </span>
        <div className={`${styles.participantDivInfo}`}>
          <span className={`${styles.profileImageDate} ${styles.imageContainer}`}>
            <img
              alt="Avatar"
              onError={(event: any) => {
                event.target.src =
                  'https://media.istockphoto.com/id/1130884625/tr/vekt%C3%B6r/kullan%C4%B1c%C4%B1-%C3%BCyesi-vekt%C3%B6r-simgesi-ui-kullan%C4%B1c%C4%B1-arabirimi-veya-profil-face-avatar-uygulamas%C4%B1.jpg?s=612x612&w=0&k=20&c=jAf5nq1ebnZo8TJbjaB9dMHMvgff7uOk67NkF5CpgB0=';
                event.target.onError = null;
              }}
              className={`fs.exclude ${styles.profileImage}`}
              src={getUserAvatar(
                Number(
                  conversationType === `${UserRoleName.Provider}_${UserRoleName.CareAdvisor}`
                    ? userInfo.role.name === UserRoleName.CareAdvisor
                      ? currentCase?.provider_id
                      : currentCase?.care_advisor_id
                    : currentCase?.patient.guardian_id ?? currentCase?.patient_id,
                ),
              )}
            />
          </span>
          <div className={styles.details}>
            <div className={styles.detailsNameImage}>
              <span className={`${styles.participantName} fs.exclude`}>
                {conversationType === `${UserRoleName.Provider}_${UserRoleName.CareAdvisor}`
                  ? userInfo.role.name === UserRoleName.CareAdvisor
                    ? `Dr. ${currentCase?.provider?.full_name}`
                    : `${currentCase?.care_advisor?.full_name}`
                  : `${
                      currentCase?.patient?.guardian_id
                        ? currentCase?.patient?.guardian?.full_name
                        : currentCase?.patient?.full_name
                    }`}
              </span>
            </div>
            <div className={styles.relation}>
              {currentCase?.patient.role.name === UserRoleName.Dependent &&
                (conversationType === `${UserRoleName.Patient}_${UserRoleName.CareAdvisor}` ||
                  conversationType === `${UserRoleName.Patient}_${UserRoleName.Provider}`) && (
                  <span className='fs.exclude'>
                    {`${currentCase?.patient.guardian_relationship} of `}
                    <span
                      onClick={() => {
                        setPatientParticipant(currentCase?.patient || null);
                        setProfileCardModal(true);
                      }}
                      className={styles.relationSpan}
                    >
                      {currentCase?.patient.full_name}
                    </span>
                  </span>
                )}
            </div>
          </div>
        </div>
      </div>
      {profileCardModal && (
        <PatientProfileCardModal
          patientIdentity={patientParticipant}
          isOpen={profileCardModal}
          toggleModal={setProfileCardModal}
        />
      )}
    </div>
  );
};

export default CaseMessageInfoCreateNewConversation;
