/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-loop-func */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-plusplus */
import 'react-toastify/dist/ReactToastify.css';

import { CaseType } from '@brands/services/cases/types/ICase';
import { selectAuth } from '@brands/store/selectors/auth';
import { displayErrorDetails } from '@brands/Utils/displayError';
import { truncateString } from '@brands/Utils/truncateString';
import React, { useEffect, useRef, useState } from 'react';
import { BsCamera, BsFilePdfFill } from 'react-icons/bs';
import { FaPlay } from 'react-icons/fa';
import { ImAttachment } from 'react-icons/im';
import { IoCloseCircleOutline } from 'react-icons/io5';
import { RiSendPlaneFill } from 'react-icons/ri';
import ReactViewer from 'react-viewer';

import { useAppSelector } from '../../hooks/useReduxHook';
import { confirmMedia } from '../../services/communication/confirmMedia';
import {
  RegisterMediaPayload,
  registersConversationMedia,
} from '../../services/communication/registersConversationMedia';
import { SendMessagePayload } from '../../services/communication/sendConversationMessage';
import { UserRoleName } from '../../services/identity/types/UserProfile';
import VideoPreviewModal from '../ChatModal/VideoPreviewModal';
import WebCam from '../WebCam/WebCam';
import styles from './case-styles.module.scss';
import { heicTo } from 'heic-to';

interface IChatFooterProps {
  conversationSid: string | undefined;
  sendMessage: (payload: SendMessagePayload) => void;
  isVCRoom?: boolean;
  isUploadDialogVisible?: boolean;
  choosenFiles: File[];
  setChoosenFiles: React.Dispatch<React.SetStateAction<File[]>>;
  setDisplayProgressBar: React.Dispatch<React.SetStateAction<boolean>>;
  setProgress: React.Dispatch<React.SetStateAction<number>>;
  displayProgressBar: boolean;
  conversationType: string | undefined;
  caseType: CaseType | string;
}

const CaseChatFooter: React.FC<IChatFooterProps> = ({
  conversationSid,
  sendMessage,
  isVCRoom,
  isUploadDialogVisible,
  choosenFiles,
  setChoosenFiles,
  setDisplayProgressBar,
  setProgress,
  displayProgressBar,
  conversationType,
  caseType,
}) => {
  const { userInfo } = useAppSelector(selectAuth);
  const [newMessage, setNewMessage] = useState('');
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [mediaIds, setMediaIds] = useState<string[]>([]);
  const fileRef = useRef<HTMLInputElement>(null);
  const fileRefMobile = useRef<HTMLInputElement>(null);
  const submitInputRef = useRef<HTMLButtonElement>(null);
  const [webCam, setWebCam] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState<number>(0);
  const [videoReviewModal, setVideoReviewModal] = useState({
    isOpen: false,
    videoSrc: '',
  });
  const MAX_CHARS = 7;
  const [processedFiles, setProcessedFiles] = useState<any[]>([]);

  useEffect(() => {
    const processFiles = async () => {
      if (choosenFiles.length > 10) {
        displayErrorDetails("You can't upload more than 10 files at once.");
        const truncatedFiles = choosenFiles.slice(0, 10);
        setChoosenFiles(truncatedFiles);
      }

      const processed = await Promise.all(
        choosenFiles.map(async (mediaFile) => {
          if (mediaFile.type.startsWith('video/')) {
            const thumbnailURL = await generateThumbnailFromFile(mediaFile);
            return {
              ...mediaFile,
              thumbnailURL,
              name: mediaFile.name,
              size: mediaFile.size,
              type: mediaFile.type,
            };
          }
          return mediaFile;
        }),
      );
      setProcessedFiles(processed);
    };

    processFiles();
  }, [choosenFiles]);

  const showUploadDialog = (): void => {
    if (fileRef.current) {
      fileRef.current.click();
    }
    if (fileRefMobile.current) {
      fileRefMobile.current.click();
    }
  };

  const generateThumbnailFromFile = (videoFile: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const video = document.createElement('video');

      video.addEventListener('loadedmetadata', () => {
        const canvas = document.createElement('canvas');
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;

        const context = canvas.getContext('2d');
        if (!context) {
          reject(new Error('Canvas context is not supported'));
          return;
        }

        // Draw the first frame of the video onto the canvas
        video.currentTime = 0;
      });

      video.addEventListener('error', (error) => {
        reject(error);
      });

      // Set the video source to the File object
      video.src = URL.createObjectURL(videoFile);

      video.addEventListener('seeked', () => {
        const canvas = document.createElement('canvas');
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;

        const context = canvas.getContext('2d');
        if (!context) {
          reject(new Error('Canvas context is not supported'));
          return;
        }

        // Draw the first frame of the video onto the canvas
        context.drawImage(video, 0, 0, canvas.width, canvas.height);

        // Convert the canvas content to a data URL representing the thumbnail
        const thumbnailURL = canvas.toDataURL('image/jpeg');
        resolve(thumbnailURL);
      });

      video.currentTime = 0; // Seek to the beginning to trigger 'seeked'
    });
  };

  const uploadFile = async (file: File, onProgress: (progress: number) => void): Promise<string | undefined> => {
    try {
      const payload: RegisterMediaPayload = {
        files: [
          {
            filename: file.name,
            mimetype: file.type,
          },
        ],
      };

      const res = await registersConversationMedia(conversationSid!, payload);
      const mediaId = res.files[0].upload_id;

      return await new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.open('PUT', res.files[0].upload_url, true);
        xhr.setRequestHeader('Content-Type', file.type);

        xhr.upload.addEventListener('progress', (event) => {
          if (event.lengthComputable) {
            const progress = Math.floor((event.loaded / 1.0 / event.total) * 100);
            onProgress(progress);
          }
        });

        xhr.onreadystatechange = function () {
          if (xhr.readyState === XMLHttpRequest.DONE) {
            if (xhr.status === 200) {
              confirmMedia(conversationSid!, mediaId)
                .then(() => {
                  resolve(mediaId);
                })
                .catch((error) => {
                  reject(error);
                });
            } else {
              reject(new Error('Something went wrong'));
            }
          }
        };

        xhr.send(file);
      });
    } catch (error: unknown) {
      displayErrorDetails(error);
      return undefined;
    }
  };

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    const { files } = event.currentTarget;
    if (!files) {
      return;
    }

    const mediaList: File[] = [];
    const maxFiles = 10 - choosenFiles.length;

    if (files.length > maxFiles) {
      displayErrorDetails("You can't upload more than 10 files at once.");
      return;
    }

    const fileArray = Array.from(files).slice(0, maxFiles);

    const convertedFiles = await Promise.all(
      fileArray.map(async (file) => {
        const fileName = file.name;

        // Check if the file already exists in the chosen files
        if (choosenFiles.some((existingFile) => existingFile.name === fileName)) {
          displayErrorDetails(`File "${fileName}" already exists.`);
          return null;
        }

        // Convert HEIC files to JPEG
        if (file.type === 'image/heic') {
          try {
            const jpegBlob = await heicTo({
              blob: file,
              type: 'image/jpeg',
              quality: 0.85,
            });

            return new File([jpegBlob], fileName.replace(/\.heic$/i, '.jpeg'), {
              type: 'image/jpeg',
            });
          } catch (error) {
            displayErrorDetails(`Error converting HEIC file "${fileName}" to JPEG: ${error}`);
            return null;
          }
        }

        return file;
      }),
    );

    const filteredFiles = convertedFiles.filter((file) => file !== null) as File[];

    setChoosenFiles([...choosenFiles, ...filteredFiles]);

    if (fileRef.current) {
      fileRef.current.value = '';
    }
  };

  const onFormSubmit = async (e: any): Promise<void> => {
    e.preventDefault();
    e.stopPropagation();

    if (newMessage && choosenFiles.length === 0) {
      sendMessage({ body: newMessage, media_ids: mediaIds });
      setNewMessage('');
      setMediaIds([]);
    } else if (choosenFiles.length) {
      setDisplayProgressBar(true);

      try {
        let totalUploaded = 0; // Keep track of total uploaded files
        const uploadedMediaIds: string[] = [];

        // Loop through each file and upload them
        for (const choosenFile of choosenFiles) {
          const mediaId = await uploadFile(choosenFile, (progress) => {
            // Calculate the overall progress based on the number of uploaded files
            const overallProgress = Math.floor((totalUploaded * 100 + progress) / choosenFiles.length);
            setProgress(overallProgress);
          }).catch((error) => {
            displayErrorDetails(`Error uploading file: ${error}`);
          });

          if (mediaId) {
            uploadedMediaIds.push(mediaId);
            totalUploaded++;
          }
        }

        // Set the progress to 100% after all files are uploaded
        setProgress(100);

        // Send message with uploaded media IDs
        sendMessage({ body: newMessage || '', media_ids: uploadedMediaIds });
        setNewMessage('');
        setChoosenFiles([]);
        setMediaIds([]);
      } catch (error: unknown) {
        displayErrorDetails(error);
      } finally {
        setProgress(0);
        setDisplayProgressBar(false);
      }
    }
  };

  const openImageModal = (index: number, media: any) => {
    const isImage = /\.(jpg|jpeg|png|gif|jfif|pjpeg|pjp)$/i.test(media.name);
    const isPdf = /\.pdf$/i.test(media.name);
    const isVideo = /\.(mp4|mov)$/i.test(media.name);

    if (isImage) {
      const imageMedias = choosenFiles.filter((image) => /\.(jpg|jpeg|png|gif|jfif|pjpeg|pjp)$/i.test(image.name));
      const imageIndex = imageMedias.findIndex((processedFiles) => processedFiles.name === media.name);
      setSelectedImageIndex(imageIndex);
      setShowModal(true);
    } else if (isPdf) {
      // Handle opening PDF in a new tab
      window.open(URL.createObjectURL(choosenFiles[index]), '_blank');
    } else if (isVideo) {
      setVideoReviewModal({ isOpen: true, videoSrc: URL.createObjectURL(choosenFiles[index]) });
    }
  };

  const deleteMedia = (mediaName: string): void => {
    const newChosenList = choosenFiles.filter((item) => item.name !== mediaName);
    const newProcessedList = processedFiles.filter((item) => item.name !== mediaName);

    setChoosenFiles(newChosenList);
    setProcessedFiles(newProcessedList);
  };

  useEffect(() => {
    if (isUploadDialogVisible) {
      showUploadDialog();
    }
  }, [isUploadDialogVisible]);

  const autoResize = (): void => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = 'auto';
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };

  const onTextareaKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>): void => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      e.stopPropagation();
      if (submitInputRef.current) {
        submitInputRef.current.click();
      }
    }
  };

  return (
    <>
      {(userInfo.role.name === UserRoleName.CareAdvisor || userInfo.role.name === UserRoleName.Provider) &&
        conversationType &&
        (conversationType === `${UserRoleName.Patient}_${UserRoleName.CareAdvisor}` ||
          conversationType === `${UserRoleName.Patient}_${UserRoleName.Provider}`) && (
          <div>
            <div className={styles.warningMessage}>
              <span>Warning</span>
              <span>
                {caseType === CaseType.phone_call_scheduled
                  ? 'Warning Messages sent to this conversation are viewable by the patient. This patient may not have internet access. Please don’t rely on this communication channel'
                  : 'Messages sent to this conversation are viewable by the patient'}
              </span>
            </div>
          </div>
        )}
      <form style={{ width: '100%' }} onSubmit={onFormSubmit}>
        <div className={`fs-unmask ${styles.inputIcons}`}>
          <div className={styles.mediaIcons} style={{ display: isVCRoom ? 'none' : '' }}>
            <label htmlFor="formId" className={styles.chatUploadImg} aria-label="Upload Image">
              <input
                ref={fileRef}
                onChange={handleFileChange}
                multiple
                type="file"
                hidden
                accept=".png, .mov, .jpg , .jpeg , .jfif , .pjpeg , .pjp, .pdf, .mp4, .heic"
              />
              <button type="button" onClick={showUploadDialog} aria-label="Upload Image">
                <ImAttachment className={styles.imageIcon} />
              </button>
            </label>
            <BsCamera
              className={styles.cameraIcon}
              style={{ display: isVCRoom ? 'none' : '' }}
              onClick={() => setWebCam(true)}
            />
          </div>
          <div className={styles.chatInputDiv}>
            <textarea
              className={`fs.exclude ${styles.chatInput}`}
              placeholder="Type your message"
              value={newMessage}
              ref={textareaRef}
              onChange={(e) => setNewMessage(e.target.value)}
              onInput={autoResize}
              onKeyDown={onTextareaKeyDown}
            />
            <div className={styles.uploadMediaDiv} style={{ display: processedFiles.length === 0 ? 'none' : 'block' }}>
              <ul>
                {processedFiles.map((mediaFile, index) => (
                  <li key={`${mediaFile.name}-${index}`} className={styles.thumbnailDiv}>
                    <span className={styles.participantSpan} onClick={() => openImageModal(index, mediaFile)}>
                      {mediaFile.type.startsWith('image/') ? (
                        <>
                          <img src={URL.createObjectURL(mediaFile)} alt={mediaFile.name} className={`fs.exclude ${styles.thumbnail}`} />
                          <div className={styles.grayOverlay} />
                        </>
                      ) : mediaFile.type.startsWith('video/') ? (
                        <div className={styles.videoThumbnail}>
                          <img src={mediaFile.thumbnailURL} alt={mediaFile.name} className={`fs.exclude ${styles.thumbnail}`} />
                          <div className={styles.videoOverlay}>
                            <FaPlay />
                          </div>
                          <div className={styles.grayOverlay} />
                        </div>
                      ) : (
                        <div className={`fs.exclude ${styles.pdfThumbnail}`}>
                          <BsFilePdfFill />
                          <span>
                            {truncateString(
                              decodeURIComponent(
                                mediaFile.name
                                  .split('?')[0]
                                  .split('/')
                                  .pop()
                                  ?.split('-')
                                  ?.pop()
                                  ?.replace(/%[0-9A-Fa-f]{2}/g, (match: string) =>
                                    String.fromCharCode(parseInt(match.substr(1), 16)),
                                  ) ?? '',
                              ),
                              MAX_CHARS,
                            )}
                          </span>
                          <div className={styles.grayOverlay} />
                        </div>
                      )}
                    </span>
                    <IoCloseCircleOutline className={styles.deleteIcon} onClick={() => deleteMedia(mediaFile.name)} />
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <button
            ref={submitInputRef}
            type="submit"
            style={{ width: '0px' }}
            aria-label="Send Message"
            disabled={displayProgressBar}
          >
            <div className={styles.sendIconContainer}>
              <RiSendPlaneFill className={styles.sendIcon}>
                <input type="submit" name="submit" />
              </RiSendPlaneFill>
            </div>
          </button>
        </div>
      </form>
      {webCam && <WebCam setOpenModal={setWebCam} setChoosenFiles={setChoosenFiles} choosenFiles={choosenFiles} />}
      <ReactViewer
        visible={showModal}
        onClose={() => setShowModal(false)}
        images={choosenFiles
          .filter((image) => /\.(jpg|jpeg|png|gif|jfif|pjpeg|pjp)$/i.test(image.name))
          .map((file) => ({ src: URL.createObjectURL(file) }))}
        activeIndex={selectedImageIndex}
      />
      {videoReviewModal.isOpen && (
        <VideoPreviewModal setOpenModal={setVideoReviewModal} videoReviewModal={videoReviewModal} />
      )}
    </>
  );
};
CaseChatFooter.defaultProps = {
  isVCRoom: false,
  isUploadDialogVisible: false,
};
export default CaseChatFooter;
