import { sendResetEmail } from '@brands/services/auth/sendResetEmail';
import React, { FormEventHandler } from 'react';
import { AiFillCloseCircle } from 'react-icons/ai';

import { activateUserById } from '../../../services/identity/activateUserById';
import { displayErrorDetails } from '../../../Utils/displayError';
import styles from './styles.module.scss';

interface Modal {
  setOpenModal: React.Dispatch<
    React.SetStateAction<{
      isOpen: boolean;
      userState: string;
    }>
  >;
  userId: number;
  userEmail: string;
  userName: string;
  disableButton: (val: boolean) => void;
  isDeactivated: boolean;
  userState: string;
}

const ResetPasswordModal = ({
  setOpenModal,
  userId,
  userName,
  userEmail,
  disableButton,
  isDeactivated,
  userState,
}: Modal): JSX.Element => {
  const resetPassword = async (): Promise<void> => {
    try {
      if (userState === 'Active' || userState === 'Pending') await sendResetEmail({ email: userEmail?.toLowerCase() });
      else if (userState === 'Deleted') await activateUserById(userId);
    } catch (error: unknown) {
      displayErrorDetails(error);
    } finally {
      disableButton(true);
      setOpenModal({ isOpen: false, userState: '' });
    }
  };
  const onSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    resetPassword();
  };

  return (
    <div className={styles.modalBackground}>
      <div className={styles.modalContainer}>
        <div className={styles.modalCloseIcon}>
          <button type="button" onClick={() => setOpenModal({ isOpen: false, userState: '' })} aria-label="Close modal">
            <AiFillCloseCircle />
          </button>
        </div>
        <form onSubmit={onSubmit}>
          <div className={styles.inputsContainer}>
            <div className={styles.inputContainer}>
              <span>{userName}</span>
              {!isDeactivated ? (
                <span>Are you sure that you want to reset this user's password?</span>
              ) : (
                <span>Are you sure that you want to activate this user?</span>
              )}
            </div>
          </div>
          <div className={styles.buttonsContainer}>
            <button type="button" onClick={() => setOpenModal({ isOpen: false, userState: '' })}>
              No
            </button>
            <button type="submit">Yes</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ResetPasswordModal;
