import React, { useState } from 'react';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { Popover } from 'react-tiny-popover';

import { CaseType, ICase } from '../../../../services/cases/types/ICase';
import { getFriendlyName } from '../../../../Utils/getFriendlyName';
import styles from '../../styles.module.scss';
import { formatDateWithoutTZ } from '@brands/Utils/formatDate';

type IConsultationHistory = {
  singleCase: ICase;
};

const ConsulationHistory = ({ singleCase }: IConsultationHistory): JSX.Element => {
  const [showDropdown, setShowDropdown] = useState(false);
  const navigate = useNavigate();

  const toggleDropdown = (): void => {
    setShowDropdown(!showDropdown);
  };

  const goToDetails = (): void => {
    const targetUrl =
      singleCase.type !== CaseType.smile_scan
        ? `/consultation-page/${singleCase.id}`
        : `/smile-scan-report/${singleCase.id}`;
    const currentUrl = window.location.pathname;

    if (currentUrl === targetUrl) {
      window.location.reload();
    } else {
      navigate(targetUrl);
    }
  };

  return (
    <div className={`row fs-unmask ${styles.consultationHistory}`}>
      <span className="col-2">
        {singleCase.status === 'completed'
          ? singleCase.type !== CaseType.smile_scan
            ? formatDateWithoutTZ(singleCase.completed_at || '', true)
            : formatDateWithoutTZ(singleCase.updated_at || '', true)
          : ''}
      </span>
      <span className="col-2">{singleCase.id}</span>
      <span className="col-3">{singleCase.status === 'completed' ? 'Completed' : 'In Progress'}</span>
      <span className="col-4">{getFriendlyName(singleCase.type)}</span>
      <span className={`col-1 ${styles.threeDots}`}>
        <Popover
          isOpen={showDropdown}
          positions={['bottom', 'left']}
          onClickOutside={toggleDropdown}
          content={
            <div className={styles.dropdownMenu} onClick={goToDetails}>
              View Consult
            </div>
          }
          reposition={false}
          containerStyle={{ marginTop: '4px' }}
        >
          <button type="button" onClick={toggleDropdown} aria-label="Consultation History Dropdown Button">
            <BsThreeDotsVertical />
          </button>
        </Popover>
      </span>
    </div>
  );
};
export default ConsulationHistory;
