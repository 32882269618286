/* eslint-disable max-len */
/* eslint-disable no-alert */
/* eslint-disable no-nested-ternary */
import imageDrag from '@brands/Dashboard/PatientForms/PatientForm/ImageUpload/assets/drag-image-icon.svg';
import frontSide from '@brands/Dashboard/PatientForms/PatientForm/ImageUpload/assets/front-side.svg';
import leftSide from '@brands/Dashboard/PatientForms/PatientForm/ImageUpload/assets/left-side.svg';
import lowerArch from '@brands/Dashboard/PatientForms/PatientForm/ImageUpload/assets/lower-arch.svg';
import rightSide from '@brands/Dashboard/PatientForms/PatientForm/ImageUpload/assets/right-side.svg';
import upperArch from '@brands/Dashboard/PatientForms/PatientForm/ImageUpload/assets/upper-arch.svg';
import { CaseType } from '@brands/services/cases/types/ICase';
import { selectPatientForm } from '@brands/store/selectors/patientForm';
import { setForm } from '@brands/store/slices/patientFormSlice';
import { yupResolver } from '@hookform/resolvers/yup';
import uniqBy from 'lodash/uniqBy';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { IoClose } from 'react-icons/io5';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import Button from '../../../../Components/Button/Button';
import Container from '../../../../Components/Container/Container';
import ProgressBar from '../../../../Components/ProgressBar/ProgressBar';
import { useAppDispatch, useAppSelector } from '../../../../hooks/useReduxHook';
import useWindowSize from '../../../../hooks/useWindowSize';
import { truncateFilename } from '../../../../Utils/truncateString';
import AddDependentModal from '../../../MyProfile/Patient/Sections/AddDependentModal/AddDependentModal';
import Camera from './Components/Camera';
import styles from './styles.module.scss';
import { heicTo } from 'heic-to';
import { displayErrorDetails } from '@brands/Utils/displayError';

type ImageUploadSubmit = {
  images: UploadedFile[];
};

interface UploadedFile {
  name: string;
  dataURL: string;
}

const ImageUpload = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { values, selectedPatient } = useAppSelector(selectPatientForm);
  const screenSize = useWindowSize();
  const { state } = useLocation();
  const [openCamera, setOpenCamera] = useState(false);
  const [showAddDependentModal, setShowAddDependentModal] = useState<boolean>(false);
  const [isDragging, setIsDragging] = useState(false);
  const [images, setImages] = useState<UploadedFile[]>(
    (values.find((item) => item.form_question_id === 17)?.value.images as UploadedFile[]) || [],
  );

  const onSubmit = (data: ImageUploadSubmit): void => {
    const payload = [...values!];
    const filteredPayload = payload.filter((item) => item.form_question_id !== 17);

    filteredPayload.push({
      form_question_id: 17,
      value: { ...data },
    });

    dispatch(setForm(filteredPayload));
    navigate('/medical-information-summary', {
      state: {
        caseType: state ? (values.find((item) => item.form_question_id === 20)?.value.caseType as string) : '',
        patientId: selectedPatient?.value || '',
        shouldSeeLastModal: false,
        fromPatientIntake: true,
      },
    });
  };

  const onBack = (): void => {
    dispatch(setForm([...values!]));
    navigate(-1);
  };

  const onInvalid = (errors: any): void => {
    const error = Object.values(errors)[0] as any;
    if (error) {
      toast.error(error.message as string);
    }
  };

  const validationSchema = Yup.object().shape({
    images:
      (values.find((item) => item.form_question_id === 20)?.value.caseType as string) === 'one_report'
        ? Yup.array()
            .min(1, 'At least 1 images are required to upload.')
            .max(10, 'Only 10 images are allowed to upload.')
            .required('At least 1 images are required to upload.')
        : (values.find((item) => item.form_question_id === 20)?.value.caseType as string) === 'second_opinion'
        ? Yup.array()
            .min(1, 'At least 1 attachment is required to upload.')
            .max(10, 'Only 10 attachments are allowed to upload.')
            .required('At least 1 attachment is required to upload.')
        : Yup.array().max(10, 'Only 10 images are allowed to upload.').nullable(),
  });

  const { setValue, register, handleSubmit } = useForm<ImageUploadSubmit>({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
  });

  const onCloseCamera = (): void => {
    setOpenCamera(false);
  };

  const onPhotoTaken = (uri: string): void => {
    setImages([...images, { dataURL: uri, name: 'Capture.png' }]);
  };

  const addImages = (files: File[]): void => {
    const newImages = files.filter(
      (file) =>
        file.type.startsWith('image/') ||
        file.type === 'application/pdf' ||
        file.type === 'application/msword' ||
        file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
        file.type === 'application/vnd.ms-powerpoint' ||
        file.type === 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    );
    if (images.length + files.length > 10) {
      alert(
        (values.find((item) => item.form_question_id === 20)?.value.caseType as string) === 'one_report'
          ? 'Only 1-10 files are allowed to upload.'
          : 'Max 10 files are allowed to upload.',
      );
      return;
    }
    const imagesArray: UploadedFile[] = [];
    for (let i = 0; i < newImages.length; i++) {
      const file = newImages[i];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.addEventListener('load', () => {
        imagesArray.push({ name: file.name, dataURL: reader.result as string });
        if (imagesArray.length === files.length) {
          setImages(uniqBy([...images, ...imagesArray], 'dataURL'));
        }
      });
    }
  };

  const handleDragOver = (event: any): void => {
    event.preventDefault();
  };

  const handleDrop = (event: React.DragEvent<HTMLImageElement>): void => {
    event.preventDefault();
    setIsDragging(false);
    const files = Array.from(event.dataTransfer.files);
    addImages(files);
  };

  const handleDragEnter = (): void => {
    setIsDragging(true);
  };

  const handleDragLeave = (): void => {
    setIsDragging(false);
  };

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    const files = Array.from(event.target.files as unknown as File[]);

    const convertedFiles = await Promise.all(
      files.map(async (file) => {
        if (file.type === 'image/heic') {
          try {
            const jpegBlob = await heicTo({
              blob: file,
              type: 'image/jpeg',
              quality: 0.85,
            });

            return new File([jpegBlob], file.name.replace(/\.heic$/i, '.jpeg'), {
              type: 'image/jpeg',
            });
          } catch (error: any) {
            displayErrorDetails(`Error converting HEIC to JPEG: ${error}`);
            return file;
          }
        }

        return file;
      }),
    );

    addImages(convertedFiles);
  };

  const deleteMedia = (mediaName: string): void => {
    const selectedMedia = images.find((image) => image.dataURL === mediaName);
    if (selectedMedia) {
      const idx = images.indexOf(selectedMedia);
      setImages(images.filter((image, index) => index !== idx));
    }
  };

  useEffect(() => {
    if (images.length > 0) {
      setValue('images', images);
      const payload = [...values!];
      const filteredPayload = payload.filter((item) => item.form_question_id !== 17);

      filteredPayload.push({
        form_question_id: 17,
        value: { images },
      });

      dispatch(setForm(filteredPayload));
    }
  }, [images]);

  return (
    <Container
      isBGEclipse
      isVisibleOnMobile
      containerStyle={{
        justifyContent: screenSize.width >= 564 ? 'center' : 'start',
      }}
      eclipseStyle={{ opacity: 0.2, display: screenSize.width <= 768 ? 'none' : '' }}
      childrenStyle={{ zIndex: '2' }}
    >
      <div className={styles.uploadImageContainer}>
        <div className={styles.uploadImageContainerDiv}>
          <ProgressBar currentStep={3} totalSteps={4} onBack={onBack} hideStepNumbers />
          <div className={`${styles.formTitle} d-flex mt-3 fs-unmask`}>
            {(values.find((item) => item.form_question_id === 20)?.value.caseType as string) === 'one_report' ||
            (values.find((item) => item.form_question_id === 20)?.value.caseType as string) === 'second_opinion'
              ? (values.find((item) => item.form_question_id === 20)?.value.caseType as string) === 'one_report'
                ? 'Image upload'
                : 'Attachment Upload *'
              : 'Image upload'}
          </div>
          <form className={styles.formContainer} onSubmit={handleSubmit(onSubmit, onInvalid)}>
            <div className={styles.wrapper}>
              <div className={styles.formContent}>
                <div
                  className={`${styles.draggingArea} ${isDragging ? styles.dragging : ''} fs-unmask`}
                  onDragOver={handleDragOver}
                  onDrop={handleDrop}
                  onDragEnter={handleDragEnter}
                  onDragLeave={handleDragLeave}
                >
                  {images.length > 0 && (
                    <div className={styles.grid}>
                      {images.map((file) => {
                        const isPDF = /^data:application\/pdf/i.test(file.dataURL);
                        const isDoc =
                          /^data:application\/vnd.openxmlformats-officedocument.wordprocessingml.document|^data:application\/msword/i.test(
                            file.dataURL,
                          );
                        const isPpt =
                          /^data:application\/vnd.openxmlformats-officedocument.presentationml.presentation/i.test(
                            file.dataURL,
                          ) || /^data:application\/vnd.ms-powerpoint/i.test(file.dataURL);

                        const isImage = /^data:image/i.test(file.dataURL);
                        return (
                          <div className={`fs-exclude ${styles.imageWrap}`} key={file.dataURL}>
                            <span className={styles.removeImage} onClick={() => deleteMedia(file.dataURL)}>
                              <IoClose />
                            </span>
                            {isPDF || isDoc || isPpt ? (
                              <p>{truncateFilename(file.name, 20)}</p>
                            ) : isImage ? (
                              <img src={file.dataURL} alt={file.name} />
                            ) : null}
                          </div>
                        );
                      })}
                    </div>
                  )}
                  <span className={styles.textDrag}>
                    <img className={styles.dragImage} src={imageDrag} alt="Drap here" />
                    <p>
                      Drag an image here or{' '}
                      <span>
                        <label htmlFor="formId" className={`${styles.uploadImageLabel}`}>
                          <input
                            {...register('images')}
                            name=""
                            type="file"
                            id="formId"
                            className='fs-exclude '
                            onChange={handleChange}
                            multiple
                            hidden
                            accept={
                              (values.find((item) => item.form_question_id === 20)?.value.caseType as string) ===
                              'second_opinion'
                                ? '.pdf, .doc, .docx, .ppt, .pptx, .png, .jpg, .jpeg, .jfif, .pjpeg, .pjp, .heic'
                                : '.png, .jpg, .jpeg, .jfif, .pjpeg, .pjp, .heic'
                            }
                          />

                          <div className={styles.selectImages}>
                            <span>Upload a File</span>
                          </div>
                        </label>
                      </span>
                    </p>
                  </span>
                </div>
                <span className={`fs-unmask ${styles.header}`}>
                  Upload 1-10 images of your mouth and teeth, focusing on any problem areas. Below are examples of
                  images which will help your dentist.{' '}
                  {(values.find((item) => item.form_question_id === 20)?.value.caseType as string) ===
                    CaseType.one_report && <span>At least one image is required for a Photo Review</span>}
                </span>
              </div>
              <div className={styles.btnContainer}>
                <Button
                  type="submit"
                  style={{ width: '245px', height: '46px' }}
                  className='fs-unmask'
                  disabled={
                    ((values.find((item) => item.form_question_id === 20)?.value.caseType as string) === 'one_report' &&
                      (images.length < 1 || images.length > 10)) ||
                    ((values.find((item) => item.form_question_id === 20)?.value.caseType as string) ===
                      'second_opinion' &&
                      (images.length === 0 || images.length > 10))
                  }
                >
                  {(values.find((item) => item.form_question_id === 20)?.value.caseType as string) === 'one_report' ||
                  (values.find((item) => item.form_question_id === 20)?.value.caseType as string) === 'second_opinion'
                    ? 'Next'
                    : images.length > 0
                    ? 'Next'
                    : 'Skip'}
                </Button>
              </div>
              <div className={`fs-unmask ${styles.formContentFooter}`}>
                <div>
                  <span className={styles.formContentFooterImageSpan}>
                    <img src={rightSide} />
                  </span>
                  <span>Right Side</span>
                  <span>
                    Turn your head slightly to the left, and use your index and middle fingers of your right hand to
                    pull back your right cheek.
                  </span>
                </div>
                <div>
                  <span className={styles.formContentFooterImageSpan}>
                    <img src={leftSide} />
                  </span>
                  <span>Left Side</span>
                  <span>
                    Turn your head slightly to the right, and use your index and middle fingers of your left hand to
                    pull back your left cheek.
                  </span>
                </div>
                <div>
                  <span className={styles.formContentFooterImageSpan}>
                    <img src={frontSide} />
                  </span>
                  <span>Front</span>
                  <span>
                    With a broad smile and your teeth slightly parted reveal your front teeth and a portion of your
                    gums. You may find it helpful to use your thumb and forefinger to gently widen your lips.
                  </span>
                </div>
                <div>
                  <span className={styles.formContentFooterImageSpan}>
                    <img src={lowerArch} />
                  </span>
                  <span>Lower Arch</span>
                  <span>
                    With a broad smile and your teeth slightly parted reveal your front teeth and a portion of your
                    gums. You may find it helpful to use your thumb and forefinger to gently widen your lips.
                  </span>
                </div>
                <div>
                  <span className={styles.formContentFooterImageSpan}>
                    <img src={upperArch} />
                  </span>
                  <span>Upper Arch</span>
                  <span>
                    Flip your phone upside down to capture a better angle, and widen your mouth as much as possible to
                    ensure a clear photograph of your upper teeth.
                  </span>
                </div>
              </div>
            </div>
          </form>
          {openCamera && <Camera onClose={onCloseCamera} onPhotoTaken={onPhotoTaken} />}
        </div>
      </div>
      {showAddDependentModal && (
        <AddDependentModal isOpen={showAddDependentModal} setIsOpen={setShowAddDependentModal} isFromSelectNeeds />
      )}
    </Container>
  );
};
export default ImageUpload;
