import Button from '@brands/Components/Button/Button';
import { ICase } from '@brands/services/cases/types/ICase';
import { calculateDueDateAppointmentsPage } from '@brands/Utils/calculateDueDate';
import React, { useEffect, useState } from 'react';
import { HiOutlineVideoCamera } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';

import styles from '../appointments.module.scss';

interface Props {
  appointment: ICase;
}

const syncCaseTypes = ['video_call_scheduled', 'oral_health_coaching'];

const JoinButton = ({ appointment }: Props): JSX.Element => {
  const navigate = useNavigate();
  const [dueDate, setDueDate] = useState(true);
  const [remainingTime, setRemainingTime] = useState('');

  useEffect(() => {
    const interval = setInterval(() => {
      const due = calculateDueDateAppointmentsPage(appointment.video_conference_date as string);
      setDueDate(due !== null);
      if (due) setRemainingTime(due);
    }, 1000);

    return () => clearInterval(interval);
  }, [appointment.video_conference_date]);

  return (
    <Button
      className={`${styles.actionBtn} fs-unmask ${
        appointment.type in syncCaseTypes && dueDate && remainingTime ? styles.actionBtnJoin : ''
      }`}
      type="button"
      onClick={() => {
        navigate(`/video-room/${appointment?.id}`, {
          state: {
            conversationSid:
              appointment.patient_care_advisor_conversation_sid ?? appointment.patient_provider_conversation_sid,
            status: appointment.status,
          },
        });
      }}
    >
      <HiOutlineVideoCamera style={{ fontSize: '20px' }} />
      {appointment.type in syncCaseTypes ? (dueDate && remainingTime ? 'Join' : 'Waiting Room') : 'Join'}
    </Button>
  );
};

export default JoinButton;
