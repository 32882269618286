import { ClinicalDataQuestion } from '@brands/services/clinical_data/types/types';

export const medicalHistoryQuestions: ClinicalDataQuestion[] = [
  {
    id: 'under_care_of_physician',
    title: 'Are you now, or have you been in the past year, under the care of a physician?',
    description: 'If yes, what is/are the condition(s) being treated?',
    descriptionId: 'conditions_treated',
    is_required: false,
    is_conditional: true,
    type: 'textarea',
    options: null,
  },
  {
    id: 'taking_medication',
    title:
      'Are you taking, have you recently taken (within the last month), or are you supposed to be taking any medications? (Prescription, over-the-counter, diet supplements, vitamins, natural, or herbal)',
    description: 'List Medications',
    descriptionId: 'medications',
    is_required: false,
    is_conditional: true,
    type: 'textarea',
    options: null,
  },
  {
    id: 'allergic_to_medications',
    title: 'Are you allergic to any medications, metal, latex, or certain materials?',
    description: 'If so, what are they?',
    descriptionId: 'medications_allergies',
    is_required: false,
    is_conditional: true,
    type: 'textarea',
    options: null,
  },
  {
    id: 'using_substances',
    title: 'Do you use? (Select all that apply)',
    description: null,
    is_required: false,
    is_conditional: false,
    type: 'select',
    options: ['Tobacco Products', 'Recreational Drugs', 'Prescription medication for non-medical reasons'],
  },
  {
    id: 'interested_in_stopping',
    title: 'If yes, are you interested in stopping?',
    description: null,
    is_required: false,
    is_conditional: true,
    type: 'checkbox',
    options: ['Yes', 'No'],
  },
  {
    id: 'height',
    title: 'Height',
    description: null,
    is_required: true,
    is_conditional: false,
    type: 'select',
    options: null,
  },
  {
    id: 'weight',
    title: 'Weight',
    description: null,
    is_required: true,
    is_conditional: false,
    type: 'select',
    options: null,
  },
  {
    id: 'pharmacy_name',
    title: 'Pharmacy Name',
    description: null,
    is_required: false,
    is_conditional: false,
    type: 'textarea',
    options: null,
  },
  {
    id: 'pharmacy_street_address',
    title: 'Pharmacy Street Address',
    description: null,
    is_required: false,
    is_conditional: false,
    type: 'textarea',
    options: null,
  },
  {
    id: 'pharmacy_city',
    title: 'Pharmacy City',
    description: null,
    is_required: false,
    is_conditional: false,
    type: 'textarea',
    options: null,
  },
  {
    id: 'pharmacy_state',
    title: 'Pharmacy State',
    description: null,
    is_required: false,
    is_conditional: false,
    type: 'textarea',
    options: null,
  },
  {
    id: 'pharmacy_zip_code',
    title: 'Pharmacy Zip Code',
    description: null,
    is_required: false,
    is_conditional: true,
    type: 'text',
    options: null,
  },
  {
    id: 'pharmacy_phone_number',
    title: 'Pharmacy Phone Number',
    description: null,
    is_required: false,
    is_conditional: false,
    type: 'phone',
    options: null,
  },
];

export const heightOptions = [
  { value: '1ft', label: '1ft' },
  { value: '2ft', label: '2ft' },
  { value: '3ft', label: '3ft' },
  { value: '4ft', label: '4ft' },
  { value: '5ft', label: '5ft' },
  { value: '6ft', label: '6ft' },
  { value: '7ft', label: '7ft' },
  { value: '8ft', label: '8ft' },
];

export const inchOptions = [
  { value: '0in', label: '0in' },
  { value: '1in', label: '1in' },
  { value: '2in', label: '2in' },
  { value: '3in', label: '3in' },
  { value: '4in', label: '4in' },
  { value: '5in', label: '5in' },
  { value: '6in', label: '6in' },
  { value: '7in', label: '7in' },
  { value: '8in', label: '8in' },
  { value: '9in', label: '9in' },
  { value: '10in', label: '10in' },
  { value: '11in', label: '11in' },
  { value: '12in', label: '12in' },
];
