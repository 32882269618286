import SelectInput from '@brands/Components/Inputs/SelectInput/SelectInput';
import { UnifiedProfileSubmit } from '@brands/Utils/types';
import { validateAddress, ValidationModalInterface } from '@brands/Utils/validateAddress';
import { yupResolver } from '@hookform/resolvers/yup';
import cn from 'classnames';
import { parsePhoneNumber } from 'libphonenumber-js';
import React, { FC, useEffect, useState } from 'react';
import { useForm, UseFormGetValues } from 'react-hook-form';
import Modal, { Styles } from 'react-modal';
import { MultiValue } from 'react-select';
import { toast } from 'react-toastify';

import closeModalIcon from '../../../../../assets/icons/close.svg';
import SuccessAlert from '../../../../../Components/Alert/SuccessAlert';
import Button from '../../../../../Components/Button/Button';
import CustomCheckbox from '../../../../../Components/Inputs/CustomCheckbox/CustomCheckbox';
import MultiselectInput from '../../../../../Components/Inputs/MultiselectInput/MultiselectInput';
import PhoneInput from '../../../../../Components/Inputs/PhoneInput/PhoneInput';
import TextField from '../../../../../Components/Inputs/TextField/TextField';
import ProfileImage from '../../../../../Components/ProfileImage/ProfileImage';
import SelectButtons from '../../../../../Components/ProfileSelectButtons/SelectButtons';
import { RegisterMediaPayload } from '../../../../../services/communication/registersConversationMedia';
import { confirmAvatarMedia } from '../../../../../services/identity/confirmAvatarMedia';
import { createBasicInformation } from '../../../../../services/identity/createBasicInformation';
import { createCareAdvisorProfile } from '../../../../../services/identity/createCareAdvisorProfile';
import { createNewUser } from '../../../../../services/identity/createNewUser';
import { deleteAvatar } from '../../../../../services/identity/deleteAvatar';
import { listAllLanguages } from '../../../../../services/identity/listAllLanguages';
import { registerAvatarMedia } from '../../../../../services/identity/registerAvatarMedia';
import { Address, Language, UserProfile, UserRoleName } from '../../../../../services/identity/types/UserProfile';
import {
  customStylesDashboard,
  multiSelectCommonCustomStylesLarge,
  selectCommonCustomStylesLarge,
} from '../../../../../Utils/customStyles';
import { displayErrorDetails } from '../../../../../Utils/displayError';
import { genderIdentity, genderOptions, pronounsOptions } from '../../../../../Utils/selectButtonOptions';
import { OptionLanguage } from '../../../../../Utils/selectOptions';
import { defaultFormValues } from '../../../../MyProfile/CareAdvisor/utils/defaultFromValues';
import { CAMyProfileSubmit } from '../../../../MyProfile/CareAdvisor/utils/types';
import { updateData } from '../../../../MyProfile/Patient/utils/utilsFunctions';
import AddressSection from '../../Components/AddressSection/AddressSection';
import styles from '../styles.module.scss';
import { validationSchema } from './validationSchema';

interface CustomStylesDashboard {
  overlay: Styles['overlay'];
  content: Styles['content'];
}

interface ProfileCardModalProps {
  isOpen: boolean;
  toggleModal: (bool: boolean) => void;
}

const AddCareAdvisorUserModal: FC<ProfileCardModalProps> = ({ isOpen, toggleModal }) => {
  const [myProfileForm, setMyProfileForm] = useState<CAMyProfileSubmit>(defaultFormValues as CAMyProfileSubmit);
  const [currentAvatarURL, setCurrentAvatarURL] = useState<string>('');
  const [avatarFile, setAvatarFile] = useState<File | null>(null);
  const [avatarPayload, setAvatarPayload] = useState<RegisterMediaPayload | null>(null);
  const [selection, setSelection] = React.useState<[number | null, number | null] | null>(null);
  const ref = React.useRef<HTMLInputElement>(null);
  const [languageOptions, setLanguageOptions] = useState<OptionLanguage[]>([]);
  const [avatarAction, setAvatarAction] = useState<string>('');
  const [image, setImage] = React.useState<string>();
  const [isSendingInvitation, setIsSendingInvitation] = useState(false);
  const [validationResponseId, setValidationResponseId] = useState('');
  const [isSavingProfile, setIsSavingProfile] = useState(false);
  const userRoleCustomStylesDashboard: CustomStylesDashboard = {
    ...customStylesDashboard,
    overlay: {
      ...customStylesDashboard.overlay,
      backgroundColor: '#1f2b38f0',
      overflowY: 'scroll',
    },
    content: {
      ...customStylesDashboard.content,
      width: '1166px',
      height: 'auto',
      backgroundColor: '#F0F0F0',
      borderRadius: '11px',
      opacity: '1',
      padding: '40px 100px',
      transform: 'translate(-50%, -27%)',
    },
  };
  const [showValidationAddressModal, setShowValidationAddressModal] = useState<ValidationModalInterface>({
    isOpen: false,
    title: '',
    whatYouEntered: '',
    recommended: false,
    recommendedAddress: '',
    onClose: undefined,
    onConfirmChange: undefined,
    onKeepCurrentAddress: undefined,
  });
  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('modal-open');
    } else if (document.body.classList.contains('modal-open') && !isOpen) {
      document.body.classList.remove('modal-open');
    }
  }, [isOpen]);
  const closeModal = (): void => {
    toggleModal(false);
  };

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    getValues,
  } = useForm<CAMyProfileSubmit>({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
  });

  const triggerProfileAvatar = React.useCallback((avatarUrl: string) => {
    setValue('profile_img', avatarUrl, { shouldValidate: true });
    setMyProfileForm({
      ...getValues(),
      profile_img: avatarUrl,
    });
  }, []);

  const onCloseConfirmationModal = (): void => {
    setShowValidationAddressModal({
      isOpen: false,
      title: '',
      whatYouEntered: '',
      recommended: false,
      recommendedAddress: '',
      onClose: undefined,
      onConfirmChange: undefined,
      onKeepCurrentAddress: undefined,
    });
  };

  const fetchLanguageOptions = async (): Promise<void> => {
    try {
      const data = await listAllLanguages();
      const transformedData = data.map((language) => ({
        value: language.locale,
        label: language.name,
        id: language.id,
      }));
      setLanguageOptions(transformedData);
    } catch (error: unknown) {
      displayErrorDetails(error);
    }
  };

  useEffect(() => {
    fetchLanguageOptions();
  }, []);

  const setTextValue = (e: React.ChangeEvent<HTMLInputElement>, name: string): void => {
    const { value } = e.target;
    setValue(name as keyof CAMyProfileSubmit, value, { shouldValidate: true });
    setSelection([e.target.selectionStart, e.target.selectionEnd]);
  };

  React.useLayoutEffect(() => {
    if (selection && ref.current) {
      [ref.current.selectionStart, ref.current.selectionEnd] = selection;
    }
  }, [selection]);

  useEffect(() => {
    if (Object.keys(myProfileForm).length > 0) {
      Object.keys(myProfileForm).forEach((key: unknown) => {
        setValue(key as keyof CAMyProfileSubmit, myProfileForm[key as keyof CAMyProfileSubmit]);
      });
    }
  }, [myProfileForm, setValue]);

  const onInvalid = (errorsList: any): void => {
    const error = Object.values(errorsList)[0] as any;
    if (error) {
      toast.error(error.message as string);
    }
  };

  const onCancelEditProfile = (): void => {
    toggleModal(false);
    setImage(currentAvatarURL);
  };

  const callSaveProfile = async (profileData: UnifiedProfileSubmit): Promise<void> => {
    let newUser: UserProfile | undefined;
    setIsSavingProfile(true);
    try {
      newUser = await createNewUser({
        email: profileData.email?.toLowerCase(),
        ...((profileData as CAMyProfileSubmit).phone_number && {
          phone_number: parsePhoneNumber((profileData as CAMyProfileSubmit).phone_number, 'US')?.format(
            'E.164',
          ) as string,
        }),
        ...(profileData.address1 !== '' ||
        profileData.address2 !== '' ||
        profileData.city !== '' ||
        profileData.state !== '' ||
        profileData.zip_code !== ''
          ? {
              address: {
                ...(profileData.address1 !== '' && { address1: profileData.address1 }),
                ...(profileData.address2 !== '' && { address2: profileData.address2 }),
                ...(profileData.city !== '' && { city: profileData.city }),
                ...(profileData.state !== '' && { state: profileData.state }),
                ...(profileData.zip_code !== '' && { zip_code: profileData.zip_code }),
              } as Address,
            }
          : {}),
        type: UserRoleName.CareAdvisor,
        send_invitation: isSendingInvitation,
        full_name: isSendingInvitation
          ? `${(profileData as CAMyProfileSubmit).first_name} ${profileData.last_name}`
          : undefined,
      });
    } catch (error: unknown) {
      displayErrorDetails(error);
      setIsSavingProfile(false);
    }
    if (newUser) {
      try {
        if (avatarPayload && avatarFile && avatarAction === 'Upload') {
          const res = await registerAvatarMedia(avatarPayload);
          const mediaId = res.files[0].upload_id;
          await fetch(res.files[0].upload_url, {
            method: 'PUT',
            body: avatarFile,
            headers: { 'content-type': avatarFile.type },
          });
          await confirmAvatarMedia(mediaId, newUser.id);
          triggerProfileAvatar(res.files[0].upload_url);
        } else if (avatarAction === 'Remove') {
          await deleteAvatar(newUser.id);
          triggerProfileAvatar('');
        }

        const dataWithUpdatedDOB = updateData(profileData);
        if (
          dataWithUpdatedDOB.first_name !== '' ||
          dataWithUpdatedDOB.middle_name !== '' ||
          dataWithUpdatedDOB.last_name !== '' ||
          dataWithUpdatedDOB.gender !== '' ||
          dataWithUpdatedDOB.pronouns !== '' ||
          (Array.isArray(dataWithUpdatedDOB.languages) &&
            dataWithUpdatedDOB.languages.length > 0 &&
            (dataWithUpdatedDOB.languages[0] as Language).name !== '')
        ) {
          await createBasicInformation(
            {
              ...(dataWithUpdatedDOB.first_name && { first_name: dataWithUpdatedDOB.first_name }),
              ...(dataWithUpdatedDOB.middle_name && { middle_name: dataWithUpdatedDOB.middle_name }),
              ...(dataWithUpdatedDOB.last_name && { last_name: dataWithUpdatedDOB.last_name }),
              ...(dataWithUpdatedDOB.gender && { gender: dataWithUpdatedDOB.gender }),
              ...(dataWithUpdatedDOB.pronouns && { pronouns: dataWithUpdatedDOB.pronouns }),
              ...(dataWithUpdatedDOB.gender_identity && { gender_identity: dataWithUpdatedDOB.gender_identity }),
              ...((profileData as CAMyProfileSubmit).languages && {
                languages: (profileData as CAMyProfileSubmit).language?.map((language) => language.id),
              }),
            },
            newUser.id,
          );
        }

        if (
          (profileData as CAMyProfileSubmit).biography ||
          (profileData as CAMyProfileSubmit).phone_number ||
          profileData.address1 !== '' ||
          profileData.address2 !== '' ||
          profileData.city !== '' ||
          profileData.state !== '' ||
          profileData.zip_code !== ''
        ) {
          newUser = await createCareAdvisorProfile(
            {
              ...((profileData as CAMyProfileSubmit).biography && {
                biography: (profileData as CAMyProfileSubmit).biography as string,
              }),
            },
            newUser.id,
          );
        }
        toggleModal(false);
      } catch (error: any) {
        if (error.response) {
          if (error.response.data.message && error.response.data.details) {
            toast.error(`${error.response.data.message} \n${error.response.data.details}`);
          } else if (error.response.data.details) {
            toast.error(error.response.data.details);
          } else {
            toast.error(error.response.data.message);
          }
        } else {
          toast.error(error.message);
        }
      }
    }
    setIsSavingProfile(false);
  };

  const updateCurrentAddress = (standardizedAddress: any, postalAddress: any, addressComponents: any): void => {
    const streetNumber = addressComponents.find(
      (addressComponent: any) => addressComponent.componentType === 'street_number',
    );
    const address1 = addressComponents.find((addressComponent: any) => addressComponent.componentType === 'route');
    if (postalAddress) setValue('city', postalAddress.locality);
    if (streetNumber || address1) {
      setValue('address1', `${streetNumber.componentName.text} ${address1.componentName.text}`);
    }
    if (standardizedAddress?.state) setValue('state', standardizedAddress.state);
    if (postalAddress) setValue('zip_code', postalAddress.postalCode);
  };

  const onSubmitProfile = async (profileData: CAMyProfileSubmit): Promise<void> => {
    if (
      profileData.address1 !== '' ||
      profileData.address2 !== '' ||
      profileData.city !== '' ||
      profileData.state !== '' ||
      profileData.zip_code !== ''
    ) {
      await validateAddress(
        profileData,
        getValues as UseFormGetValues<UnifiedProfileSubmit>,
        validationResponseId,
        callSaveProfile,
        setValidationResponseId,
        setShowValidationAddressModal,
        onCloseConfirmationModal,
        updateCurrentAddress,
      );
    } else callSaveProfile(profileData);
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      style={userRoleCustomStylesDashboard}
      contentLabel="Modal"
      ariaHideApp={false}
      shouldCloseOnOverlayClick={false}
    >
      <button
        type="button"
        className={styles.modalClose}
        aria-label="Close modal"
        onClick={closeModal}
        style={{ backgroundImage: `url(${closeModalIcon})` }}
      />
      <div className={styles.profileContainer}>
        <div className={styles.contentContainer}>
          <div className={styles.contentContainerInner}>
            <div className={styles.editProfileContainer}>
              <div className={styles.titleContainer}>
                <span className={styles.title}>Create Care Advisor Profile</span>
              </div>
              <div className={styles.editContainer}>
                <form className={styles.formContainer} onSubmit={handleSubmit(onSubmitProfile, onInvalid)}>
                  <div className={styles.headerActionDiv}>
                    <ProfileImage
                      reviewProfile
                      setCurrentAvatarURL={setCurrentAvatarURL}
                      setAvatarFile={setAvatarFile}
                      setAvatarPayload={setAvatarPayload}
                      setAvatarAction={setAvatarAction}
                      triggerProfileAvatar={triggerProfileAvatar}
                      setImage={setImage}
                      image={image}
                      errors={errors}
                      size="80px"
                      isNewDependent
                    />
                  </div>
                  <p className={styles.requiredIndicator}>
                    Required <span>*</span>
                  </p>

                  <div className={styles.row}>
                    <div className={cn(styles.rowItem, styles.detailsCA)}>
                      <div className={styles.caption}>First Name</div>
                      <TextField
                        {...register('first_name')}
                        errors={errors}
                        name="first_name"
                        value={getValues('first_name')}
                        onChange={(e) => setTextValue(e, 'first_name')}
                        wrapperClassName={styles.inputWrapper}
                        inputClass={styles.input}
                      />
                    </div>
                    <div className={cn(styles.rowItem, styles.detailsCA)}>
                      <div className={styles.caption}>Middle Name</div>
                      <TextField
                        {...register('middle_name')}
                        errors={errors}
                        name="middle_name"
                        value={getValues('middle_name')}
                        onChange={(e) => setTextValue(e, 'middle_name')}
                        wrapperClassName={styles.inputWrapper}
                        inputClass={styles.input}
                      />
                    </div>
                    <div className={cn(styles.rowItem, styles.detailsCA)}>
                      <div className={styles.caption}>Last Name</div>
                      <TextField
                        {...register('last_name')}
                        errors={errors}
                        name="last_name"
                        value={getValues('last_name')}
                        onChange={(e) => setTextValue(e, 'last_name')}
                        wrapperClassName={styles.inputWrapper}
                        inputClass={styles.input}
                      />
                    </div>
                  </div>
                  <div className={styles.row}>
                    <div className={cn(styles.rowItem, styles.gender)}>
                      <div className={styles.caption}>Gender assigned at birth</div>
                      <SelectButtons
                        errors={errors}
                        setValue={setValue}
                        form={getValues()}
                        value="gender"
                        options={genderOptions}
                      />
                    </div>
                  </div>
                  <div className={styles.row}>
                    <div className={cn('col-md-6', styles.rowItem, styles.details, styles.rowItemHalf)}>
                      <div className={styles.caption}>Gender identity</div>
                      <SelectInput
                        {...register('gender_identity')}
                        name="gender_identity"
                        options={genderIdentity}
                        containerClass={styles.inputWrapper}
                        selectorClass={styles.selector}
                        style={selectCommonCustomStylesLarge}
                        errors={errors}
                        selectedValue={getValues('gender_identity')}
                        onChange={(value) => {
                          if (value) {
                            setValue('gender_identity', value.value, { shouldValidate: true });
                          }
                        }}
                      />
                    </div>
                    <div className={cn('col-md-6', styles.rowItem, styles.details, styles.rowItemHalf)}>
                      <div className={styles.caption}>Pronouns</div>
                      <SelectInput
                        {...register('pronouns')}
                        name="pronouns"
                        options={pronounsOptions}
                        containerClass={styles.inputWrapper}
                        selectorClass={styles.selector}
                        style={selectCommonCustomStylesLarge}
                        errors={errors}
                        selectedValue={getValues('pronouns')}
                        onChange={(value) => {
                          if (value) {
                            setValue('pronouns', value.value, { shouldValidate: true });
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className={styles.row}>
                    <div className={cn(styles.rowItem, styles.rowItemHalf)}>
                      <div className={styles.caption}>
                        Email Address <span>*</span>
                      </div>
                      <TextField
                        {...register('email')}
                        errors={errors}
                        name="email"
                        value={getValues('email')}
                        onChange={(e) => setTextValue(e, 'email')}
                        wrapperClassName={styles.inputWrapper}
                        inputClass={styles.input}
                      />
                    </div>
                    <div className={cn(styles.rowItem, styles.rowItemHalf)}>
                      <div className={styles.caption}>Phone Number</div>
                      <div className={styles.inputWrapper}>
                        <PhoneInput
                          {...register('phone_number')}
                          id="phone_number"
                          value={getValues('phone_number')}
                          onChange={(val) => {
                            setValue('phone_number', val as string, {
                              shouldValidate: true,
                            });
                          }}
                          inputClass={styles.input}
                          errors={errors}
                          wrapperClassName={styles.inputWrapper}
                        />
                      </div>
                    </div>
                  </div>
                  <div className={styles.row}>
                    <div className={cn(styles.rowItem, styles.detailsCA, styles.big, styles.languages)}>
                      <div className={styles.caption}>Languages</div>
                      <MultiselectInput
                        {...register('language')}
                        name="language"
                        placeholder="Please add any languages you are comfortable speaking during the consult"
                        options={languageOptions}
                        style={multiSelectCommonCustomStylesLarge}
                        containerClass={styles.inputWrapper}
                        selectorClass={styles.selector}
                        errors={errors}
                        selectedValue={getValues('language')}
                        onChange={(value) => {
                          if (value) {
                            setValue('language', value as MultiValue<OptionLanguage>, {
                              shouldValidate: true,
                            });
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className={styles.separator} />
                  <div className={styles.row}>
                    <AddressSection
                      register={register}
                      errors={errors}
                      getValues={getValues}
                      setTextValue={setTextValue}
                      setValue={setValue}
                      showValidationAddressModal={showValidationAddressModal}
                      required={false}
                    />
                  </div>
                  <div className={styles.separator} />
                  <div className={styles.row}>
                    <div className={cn(styles.rowItem)}>
                      <div className={styles.caption}>Biography</div>
                      <textarea
                        {...register('biography')}
                        className={cn(styles.formControl, styles.textareaProfile)}
                        style={{ marginLeft: '0px', width: '100%' }}
                        placeholder="Write here"
                        onChange={(e) => {
                          setValue('biography', e.target.value, { shouldValidate: true });
                          setSelection([e.target.selectionStart, e.target.selectionEnd]);
                        }}
                        value={getValues('biography')}
                      />
                      <div
                        className={`${styles.invalidFeedback} invalid-feedback`}
                        style={{ display: errors.biography?.message ? 'block' : 'none' }}
                      >
                        {errors.biography?.message}
                      </div>
                    </div>
                  </div>
                  {false && <SuccessAlert />}
                  <div className={styles.actionBtnContainer} style={{ marginTop: '20px' }}>
                    <CustomCheckbox
                      label="Invite user"
                      checked={isSendingInvitation}
                      onChange={(e) => {
                        setIsSendingInvitation(e.target.checked);
                      }}
                      containerClassName={styles.checkboxContainer}
                    />
                    <div className={styles.btnContainer}>
                      <Button type="button" className={styles.cancel} onClick={onCancelEditProfile}>
                        Cancel
                      </Button>
                      <Button type="submit" className={styles.submit} disabled={isSavingProfile}>
                        Save
                      </Button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

Modal.setAppElement('#root');

export default AddCareAdvisorUserModal;
