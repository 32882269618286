/* eslint-disable react/no-array-index-key */
import Moment from 'moment';
import React from 'react';
import { toast } from 'react-toastify';

import { formatTime } from '../../../SelectDateAndTime/utils/generateTimeSlots';
import { SelectedAppointment } from '../../types';
import styles from './styles.module.scss';

export interface TimeSlot {
  start_time: string;
  end_time: string;
  nextAvailable?: boolean;
}

const TimeSlotComponent: React.FC<{
  id: number;
  timeSlots: TimeSlot[];
  allSlots: string[];
  setSelectedTimeSlot: React.Dispatch<React.SetStateAction<SelectedAppointment>>;
  selectedTimeSlot: SelectedAppointment;
  date: string;
  refreshProviders: () => void;
}> = ({ id, timeSlots, allSlots, selectedTimeSlot, setSelectedTimeSlot, date, refreshProviders }) => {
  const isCurrentDate = (dateString: string): boolean => {
    const currentDate = new Date();
    const givenDate = new Date(dateString);

    return (
      givenDate.getFullYear() === currentDate.getFullYear() &&
      givenDate.getMonth() === currentDate.getMonth() &&
      givenDate.getDate() === currentDate.getDate()
    );
  };

  const handleTimeSlotClick = (slot: string): void => {
    const currentTime = Moment(); // Get the current time
    const startTimeMoment = Moment(slot);

    if ((startTimeMoment.diff(currentTime, 'minutes') >= 30 && isCurrentDate(date)) || !isCurrentDate(date)) {
      setSelectedTimeSlot({
        selectedTimeSlot: formatTime(Moment(slot).toDate()),
        selectedProviderId: id,
      });
    } else {
      toast.error('The time slot you selected is no longer available. Please select a different time slot.');
      refreshProviders();
      setSelectedTimeSlot({
        selectedTimeSlot: '',
        selectedProviderId: undefined,
      });
    }
  };

  return (
    <div className={styles.timeSlotContainer}>
      {allSlots.map((slot, index) => {
        const matchingTimeSlot = timeSlots.find((timeSlot) => timeSlot.start_time === slot);
        const isNextAvailable = timeSlots.some((timeSlot) => timeSlot.nextAvailable === true);
        const nextAvailable = isNextAvailable && matchingTimeSlot?.nextAvailable;

        return (
          <div
            key={index}
            className={`${styles.timeSlotDiv} fs-unmask ${
              formatTime(Moment(slot).toDate()) === selectedTimeSlot.selectedTimeSlot &&
              id === selectedTimeSlot.selectedProviderId
                ? styles.selected
                : ''
            } ${nextAvailable ? styles.nextAvailableSlot : ''}`}
            onClick={() => handleTimeSlotClick(slot)}
          >
            {nextAvailable && 'First Available'}
            {nextAvailable && <span className={styles.separator}> | </span>}
            {formatTime(Moment(slot).toDate())}
          </div>
        );
      })}
    </div>
  );
};

export default TimeSlotComponent;
