import { ClinicalDataQuestion } from '@brands/services/clinical_data/types/types';

export const dentalHistoryQuestions: ClinicalDataQuestion[] = [
  {
    id: 'last_dental_visit',
    title: 'Date of your last dental visit',
    description: null,
    is_required: true,
    is_conditional: false,
    type: 'select',
    options: null,
  },
  {
    id: 'visit_type',
    title: 'Visit Type',
    description: null,
    is_required: false,
    is_conditional: true,
    type: 'select',
    options: ['Emergency', 'Other', 'Exam', 'Procedure', 'Cleaning', 'Consultation'],
  },
  {
    id: 'who_did_you_see',
    title: 'Who did you see?',
    description: null,
    is_required: false,
    is_conditional: false,
    type: 'textarea',
    options: null,
  },
];

export const monthOptions = [
  { value: 'January', label: 'January' },
  { value: 'February', label: 'February' },
  { value: 'March', label: 'March' },
  { value: 'April', label: 'April' },
  { value: 'May', label: 'May' },
  { value: 'June', label: 'June' },
  { value: 'July', label: 'July' },
  { value: 'August', label: 'August' },
  { value: 'September', label: 'September' },
  { value: 'October', label: 'October' },
  { value: 'November', label: 'November' },
  { value: 'December', label: 'December' },
];

export const visitOptions = [
  { value: 'Exam', label: 'Exam' },
  { value: 'Emergency', label: 'Emergency' },
  { value: 'Consultation', label: 'Consultation' },
  { value: 'Procedure', label: 'Procedure' },
  { value: 'Cleaning', label: 'Cleaning' },
  { value: 'Other', label: 'Other' },
];
