/* eslint-disable prefer-destructuring */
import React, { SetStateAction, useEffect, useState } from 'react';
import { FieldErrors, UseFormGetValues, UseFormRegister, UseFormSetValue, UseFormTrigger } from 'react-hook-form';

import { ICase } from '../../../../services/cases/types/ICase';
import { UserProfile } from '../../../../services/identity/types/UserProfile';
import { updateOneReport } from '../../../../services/oneReport/updateOneReport';
import { checkCollapsibleHeaderColor } from '../../../../Utils/getCollapsibleHeader';
import { OralHealthReportSubmit } from '../../assets/types';
import styles from '../../styles.module.scss';

type AssessmentSectionProps = {
  consultationForm: OralHealthReportSubmit;
  setConsultationForm: React.Dispatch<React.SetStateAction<OralHealthReportSubmit>>;
  thisCase: ICase;
  setCase: React.Dispatch<SetStateAction<ICase>>;
  userInfo: UserProfile;
  register: UseFormRegister<OralHealthReportSubmit>;
  setValue: UseFormSetValue<OralHealthReportSubmit>;
  getValues: UseFormGetValues<OralHealthReportSubmit>;
  trigger: UseFormTrigger<OralHealthReportSubmit>;
  errors: FieldErrors<OralHealthReportSubmit>;
};

const AssessmentSection = ({
  consultationForm,
  setConsultationForm,
  thisCase,
  setCase,
  userInfo,
  register,
  setValue,
  getValues,
  trigger,
  errors,
}: AssessmentSectionProps): JSX.Element => {
  const saveCaseAssessment = async (caseAssessment: string): Promise<void> => {
    const { assessments: res } = await updateOneReport(thisCase?.one_report.id || '', {
      assessments: caseAssessment,
    });
    setCase({
      ...thisCase,
      one_report: {
        ...thisCase.one_report,
        assessments: res,
      },
    });
  };

  return (
    <div className={styles.assessmentDiv}>
      <div className={styles.assessment}>
        {checkCollapsibleHeaderColor(thisCase || '', userInfo, 'Assessment') === 'edit' ||
        checkCollapsibleHeaderColor(thisCase || '', userInfo, 'Assessment') === 'editNotRequired' ? (
          <textarea
            className={`fs-exclude ${styles.assessmentInput}`}
            style={{ color: errors.assessment ? '#BA271A' : '' }}
            {...register('assessment')}
            value={consultationForm.assessment || ''}
            onChange={(e) => {
              setConsultationForm({ ...consultationForm, assessment: e.target.value });
              setValue('assessment', e.target.value);
              if (errors?.assessment) {
                trigger('assessment');
              }
            }}
            onBlur={() => {
              saveCaseAssessment(consultationForm.assessment);
            }}
          />
        ) : (
          <textarea
            disabled
            value={thisCase?.one_report?.assessments || ''}
            className={`fs-exclude ${styles.assessmentInput}`}
            style={{ color: errors.assessment ? '#BA271A' : '' }}
          />
        )}
      </div>
    </div>
  );
};

export default React.memo(AssessmentSection);
