import React, { Dispatch, FormEventHandler, SetStateAction, useRef } from 'react';
import { AiFillCloseCircle } from 'react-icons/ai';
import { IoTrashOutline } from 'react-icons/io5';

import { ICase } from '../../../../services/cases/types/ICase';
import { ImageNoteState } from '../../../../services/forms/types/ImageNote';
import { ImageWithNotes } from '../../../../services/forms/types/ImageWithNotes';
import { IOneReport } from '../../../../services/oneReport/types/IOneReport';
import { updateOneReportMedia } from '../../../../services/oneReport/updateOneReportMedia';
import styles from './imageNotesModal.module.scss';

interface Modal {
  setOpenModal: Dispatch<SetStateAction<ImageNoteState>>;
  media_src: string;
  media_id: string;
  images: ImageWithNotes[] | undefined;
  oneReportId: string;
  thisCase: ICase;
  setCase: React.Dispatch<React.SetStateAction<ICase>>;
  updateSelectedImages: (updatedReport: IOneReport) => void;
  readOnly: boolean;
}

const ImageNotesModal = ({
  setOpenModal,
  media_src,
  media_id,
  images,
  oneReportId,
  thisCase,
  setCase,
  updateSelectedImages,
  readOnly,
}: Modal): JSX.Element => {
  const image = images?.find((selectedImage) => selectedImage.src === media_src);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [notes, setNotes] = React.useState(image?.notes ?? '');

  const editMediaNote = async (givenNotes: string, select: boolean): Promise<void> => {
    const updatedCase = await updateOneReportMedia(oneReportId, media_id, {
      note: givenNotes,
      is_selected: select,
    });
    setCase({
      ...thisCase,
      one_report: updatedCase,
    });
    updateSelectedImages(updatedCase);
  };

  const onSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    if (notes.trim() === '') {
      alert('Please enter a note.');
      return;
    }
    editMediaNote(notes, true);
    setOpenModal({ isOpen: false, media_src: '', media_id: '' });
  };

  const deleteNotes = (): void => {
    if (textareaRef.current) textareaRef.current.value = '';
    setNotes('');
    editMediaNote('', false);
    updateOneReportMedia(oneReportId || '', image?.id || '', {
      is_selected: false,
    });
    setOpenModal({ isOpen: false, media_src: '', media_id: '' });
  };

  return (
    <div className={styles.modalBackground}>
      <div className={styles.imageContainer}>
        <div className={styles.image}>
          <img className='fs-exclude' src={image?.src} alt="modal display for notes" />
        </div>
      </div>
      <div className={styles.modalContainer}>
        <div className={styles.modalCloseIcon}>
          <button
            type="button"
            onClick={() => setOpenModal({ isOpen: false, media_src: '', media_id: '' })}
            aria-label="Close Modal"
          >
            <AiFillCloseCircle />
          </button>
        </div>
        <form onSubmit={onSubmit}>
          <div className={styles.inputsContainer}>
            <div className={styles.inputContainer}>
              {!readOnly && <label htmlFor="notes">Add Image Notes</label>}
              <textarea
                name="notes"
                ref={textareaRef}
                className='fs-exclude'
                id="notes"
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
                disabled={readOnly}
              />
            </div>
          </div>
          {!readOnly && (
            <div className={styles.buttonsContainer}>
              <div className={styles.deleteNotes} onClick={deleteNotes}>
                <IoTrashOutline className={styles.icon} />
                Delete Notes
              </div>
              <button type="submit" disabled={notes.trim() === ''}>
                Save and Close
              </button>
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default ImageNotesModal;
