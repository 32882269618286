import { activateDependentById } from '@brands/services/identity/activateDependentById';
import { getUserAvatar } from '@brands/services/identity/getUserAvatar';
import { listDependents } from '@brands/services/identity/listDependents';
import { Dependent } from '@brands/services/identity/types/Dependent';
import { selectAuth } from '@brands/store/selectors/auth';
import { selectPatient } from '@brands/store/selectors/selectedPatient';
import { setDependents } from '@brands/store/slices/dependentsSlice';
import { checkProfileForm } from '@brands/Utils/checkExternalForms';
import React, { useEffect, useState } from 'react';
import { IconType } from 'react-icons';
import { BsToggleOn } from 'react-icons/bs';
import { CgProfile } from 'react-icons/cg';
import { FiEdit } from 'react-icons/fi';
import { IoMdAddCircle } from 'react-icons/io';
import { useParams } from 'react-router-dom';

import Button from '../../../../../Components/Button/Button';
import { Loading } from '../../../../../Components/LoadingSpinner/Loading';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/useReduxHook';
import { UserProfile, UserRoleName } from '../../../../../services/identity/types/UserProfile';
import styles from '../../../styles.module.scss';

type ReadOnlyProfileProps = {
  onEditDependent: (id: number) => void;
  addDependent: () => void;
  reviewProfile: boolean;
  thisUser: UserProfile;
};

const ReadOnlyDependent = ({
  onEditDependent,
  addDependent,
  reviewProfile,
  thisUser,
}: ReadOnlyProfileProps): JSX.Element => {
  const { userId } = useParams();
  const { userInfo } = useAppSelector(selectAuth);
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState(false);
  const { selectedPatient } = useAppSelector(selectPatient);
  const [dependentList, setDependentList] = useState<Dependent[]>([]);
  const [deactivatedDependentList, setDeactivatedDependentList] = useState<Dependent[]>([]);
  const [deactivatedDependentListVisible, setDeactivatedDependentListVisible] = useState(false);

  const setAllDependents = async (): Promise<void> => {
    const listOfDependents = await listDependents(!reviewProfile ? userInfo.id : userId, { include_deleted: true });
    if (listOfDependents.length > 0) {
      setDependentList(listOfDependents);
      dispatch(
        setDependents(
          listOfDependents
            .slice() // Create a shallow copy to avoid mutating the original array
            .sort((a, b) => b.id - a.id) // Sort in descending order based on the 'id' property
            .map((dependent) => ({
              value: dependent.id.toString(),
              label: dependent.full_name,
              status: dependent.status,
            })),
        ),
      );
    } else {
      setDependentList([]);
      dispatch(setDependents([]));
    }
    if (listOfDependents.filter((dependent) => dependent?.status === 'Deactivated').length > 0) {
      setDeactivatedDependentList(listOfDependents);
    } else {
      setDeactivatedDependentList([]);
    }
  };

  const onReactivateDependent = async (id: number): Promise<void> => {
    await activateDependentById(
      userInfo.role.name === UserRoleName.Admin ||
        userInfo.role.name === UserRoleName.SuperAdmin ||
        userInfo.role.name === UserRoleName.OrganizationAdmin
        ? selectedPatient.id
        : userInfo.id,
      id,
    );
    setAllDependents();
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      await setAllDependents();
      setLoading(false);
    })();
  }, []);

  const renderActionButton = (): JSX.Element => {
    return (
      <Button
        className={`fs-unmask ${styles.addBtn}`}
        type="button"
        onClick={
          checkProfileForm(
            userInfo.role.name === UserRoleName.Admin ||
              userInfo.role.name === UserRoleName.SuperAdmin ||
              userInfo.role.name === UserRoleName.OrganizationAdmin
              ? thisUser
              : userInfo,
            null,
          )
            ? addDependent
            : undefined
        }
        disabled={
          !checkProfileForm(
            userInfo.role.name === UserRoleName.Admin ||
              userInfo.role.name === UserRoleName.SuperAdmin ||
              userInfo.role.name === UserRoleName.OrganizationAdmin
              ? thisUser
              : userInfo,
            null,
          )
        }
      >
        Add a Dependent
        <IoMdAddCircle className={styles.addIcon} />
      </Button>
    );
    return <div />;
  };

  const renderDependentList = (
    list: Dependent[],
    status: string,
    actionText: string,
    actionIcon: IconType,
    actionHandler: (id: number) => void,
  ): JSX.Element => {
    if (list.length !== 0) {
      return (
        <ul className={styles.dependentsList}>
          {list
            .filter((dependents) => dependents.status === status)
            .map((dependent) => (
              <li key={dependent?.id} className={styles.dependentItem}>
                {dependent?.picture_media_id ? (
                  <div className={styles.dependentImage}>
                    <img className='fs-exclude' src={getUserAvatar(dependent.id)} alt="profile" />
                  </div>
                ) : (
                  <div className={styles.dependentImage}>
                    <CgProfile className='fs-exclude' />
                  </div>
                )}
                <div className={`fs-exclude ${styles.dependentName}`}>{dependent.full_name || '-'}</div>
                <button className={styles.editBtn} type="button" onClick={() => actionHandler(dependent.id)}>
                  {actionText}
                  {actionIcon && React.createElement(actionIcon, { className: styles.editIcon, size: 16 })}
                </button>
              </li>
            ))}
        </ul>
      );
    }
    return <div className={styles.noDependent}>No Dependents</div>;
  };

  const renderTitle = (): JSX.Element => (
    <>
      <div className={`${styles.titleContainer} ${styles.mobileFlexRevCol} ${styles.mobileAddDependent}`}>
        <span className={`fs-unmask ${styles.title}`}>Dependents</span>
        {renderActionButton()}
      </div>
      {!checkProfileForm(
        userInfo.role.name === UserRoleName.Admin ||
          userInfo.role.name === UserRoleName.SuperAdmin ||
          userInfo.role.name === UserRoleName.OrganizationAdmin
          ? thisUser
          : userInfo,
        null,
      ) && (
        <span>
          You need to complete{' '}
          {userInfo.role.name === UserRoleName.Admin ||
          userInfo.role.name === UserRoleName.SuperAdmin ||
          userInfo.role.name === UserRoleName.OrganizationAdmin
            ? 'this'
            : 'your'}{' '}
          profile to add a dependent.
        </span>
      )}
    </>
  );

  const renderDependentSection = (): JSX.Element => {
    if (loading) {
      return <Loading dotted />;
    }

    if (dependentList.length !== 0) {
      return renderDependentList(dependentList, 'Active', 'Edit', FiEdit, onEditDependent);
    }

    return <div className={styles.noDependent}>No Dependents</div>;
  };

  const renderDeactivatedDependents = (): JSX.Element => {
    if (loading) {
      return <Loading dotted />;
    }

    if (deactivatedDependentList.length !== 0) {
      return (
        <>
          <div
            className={styles.readOnlyProfileShowDeactivatedDependentsButton}
            onClick={() => setDeactivatedDependentListVisible(!deactivatedDependentListVisible)}
          >
            {deactivatedDependentListVisible ? 'Hide Deactivated Dependents' : 'Show Deactivated Dependents'}
          </div>
          {deactivatedDependentListVisible &&
            renderDependentList(
              deactivatedDependentList,
              'Deactivated',
              'Reactivate',
              BsToggleOn,
              onReactivateDependent,
            )}
        </>
      );
    }

    return <div className={styles.noDependent} />;
  };

  return (
    <div className={styles.readOnlyProfileContainer}>
      {renderTitle()}
      {renderDependentSection()}
      <div className={styles.readOnlyProfileDeactivatedDependents}>{renderDeactivatedDependents()}</div>
    </div>
  );
};

export default ReadOnlyDependent;
