/* eslint-disable @typescript-eslint/naming-convention */
import { listClinicalData } from '@brands/services/clinical_data/listClinicalData';
import { DentalHistory, MedicalConditions, MedicalHistory } from '@brands/services/clinical_data/types/types';
import { UserProfile } from '@brands/services/identity/types/UserProfile';

export const checkEssentialProfileForm = (userInfo: UserProfile): boolean => {
  const basicInfo = userInfo?.basic_info;

  return !!(basicInfo?.first_name && basicInfo?.last_name && basicInfo?.date_of_birth);
};

export const checkProfileForm = (userInfo: UserProfile, selectedPatient: UserProfile | null): boolean => {
  let userProfileInfo = userInfo;
  if (selectedPatient) {
    userProfileInfo = selectedPatient;
  }
  const basicInfo = userProfileInfo?.basic_info;
  const address = userProfileInfo?.address;

  return !!(
    basicInfo?.first_name &&
    basicInfo?.last_name &&
    basicInfo?.gender &&
    basicInfo?.gender_identity &&
    basicInfo?.date_of_birth &&
    basicInfo?.languages &&
    basicInfo?.languages?.length > 0 &&
    userInfo?.phone_number &&
    userInfo?.email &&
    address?.address1 &&
    address?.city &&
    address?.state &&
    address?.zip_code
  );
};

export const checkMedicalHistoryForms = (medical_history: MedicalHistory): boolean => {
  if (medical_history && medical_history.height && medical_history.weight) {
    return true;
  }
  return false;
};

export const checkMedicalConditionsForms = (medical_conditions: MedicalConditions): boolean => {
  if (medical_conditions) {
    const containsYesOrNonEmptyString = Object.values(medical_conditions).some(
      (value) => value === 'yes' || (typeof value === 'string' && value.trim() !== '' && value !== 'no'),
    );
    if (containsYesOrNonEmptyString) {
      return true;
    }
  }
  return false;
};

export const checkDentalHistoryForms = (dental_history: DentalHistory): boolean => {
  if (
    dental_history &&
    (dental_history.last_dental_visit === 'dontKnow' ||
      (dental_history.visit_type !== 'no' && dental_history.who_did_you_see !== ''))
  ) {
    return true;
  }
  return false;
};

export const checkExternalFormAnswers = async (
  userInfo: UserProfile,
  selectedPatient: UserProfile | null,
): Promise<boolean[]> => {
  const { medical_history, medical_conditions, dental_history } = await listClinicalData(
    selectedPatient?.id || userInfo.id,
  );
  const checkProfileFormAnswer = await checkProfileForm(userInfo, selectedPatient);
  const checkMedicalHistoryFormAnswer = await checkMedicalHistoryForms(medical_history);
  const checkMedicalConditionsFormAnswer = await checkMedicalConditionsForms(medical_conditions);
  const checkDentalHistoryFormAnswer = await checkDentalHistoryForms(dental_history);
  const checkEssentialProfileFormAnswer = await checkEssentialProfileForm(userInfo);

  return [
    checkProfileFormAnswer,
    checkMedicalHistoryFormAnswer,
    checkMedicalConditionsFormAnswer,
    checkDentalHistoryFormAnswer,
    checkEssentialProfileFormAnswer,
  ];
};
