import { client } from '../client';
import { ClinicalDataPayload, ClinicalDataResponse, ObjectType } from './types/types';

export const addClinicalData = async (
  object_type: ObjectType,
  payload: ClinicalDataPayload,
): Promise<ClinicalDataResponse> => {
  const { data } = await client.post<ClinicalDataResponse>(`/clinical-data/${object_type}`, payload);

  return data;
};
