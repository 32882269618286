import Button from '@brands/Components/Button/Button';
import { useLocalStorageState } from '@brands/hooks';
import useSubscribe, { Subscriber } from '@brands/hooks/useSubscribe';
import { cancelCase } from '@brands/services/cases/cancelCase';
import { getOneCase } from '@brands/services/cases/getCaseById';
import { ICase } from '@brands/services/cases/types/ICase';
import { displayErrorDetails } from '@brands/Utils/displayError';
import React, { memo, useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useNavigate, useParams } from 'react-router-dom';

import { customStylesDashboard } from '../../Utils/customStyles';
import styles from './styles.module.scss';

customStylesDashboard.overlay.backgroundColor = 'rgba(0, 0, 0, 0.9  )';
customStylesDashboard.content.borderRadius = '10px';
customStylesDashboard.content.width = '';

type ProviderNotJoinedForState = {
  caseId: string | number;
  duration: number | null;
  providerId: string | number;
  scheduledTime: string;
};

const ProviderNotJoinedModal: React.FC = () => {
  const navigate = useNavigate();
  const { subscribe, unsubscribe } = useSubscribe();
  const [startCancelFlag, setStartCancelFlag] = useState(false);
  const caseId = useParams().caseId as string;
  const [providerNotJoinedFor, setProviderNotJoinedFor] = useLocalStorageState<ProviderNotJoinedForState>(
    'providerNotJoinedFor',
    {
      caseId: 0,
      duration: null,
      providerId: 0,
      scheduledTime: '',
    },
  );
  const [currentCase, setThisCase] = useState<ICase>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [checkProviderNotJoinedForFiveMinutes, setCheckProviderNotJoinedForFiveMinutes] = useState(false);

  const subscriber: Subscriber = {
    onMessage: (message: Record<string, any>) => {
      if (message.case_id == caseId) {
        if (message.command == 'provider_not_joined_for_five_minutes') {
          setProviderNotJoinedFor({
            caseId: Number(caseId),
            duration: 5,
            providerId: message.providerId,
            scheduledTime: message.scheduledTime,
          });
        } else if (message.command == 'provider_not_joined_for_fifteen_minutes') {
          setProviderNotJoinedFor({
            caseId: Number(caseId),
            duration: 15,
            providerId: message.providerId,
            scheduledTime: message.scheduledTime,
          });
        }
      }
    },
    commands: ['provider_not_joined_for_five_minutes', 'provider_not_joined_for_fifteen_minutes'],
  };

  useEffect(() => {
    const subscriptionId = subscribe(subscriber);
    return () => {
      unsubscribe(subscriptionId);
    };
  }, []);

  useEffect(() => {
    if (
      Boolean(providerNotJoinedFor.caseId) &&
      Boolean(providerNotJoinedFor.providerId) &&
      Boolean(providerNotJoinedFor.scheduledTime) &&
      Boolean(providerNotJoinedFor?.duration) &&
      providerNotJoinedFor.caseId == caseId &&
      providerNotJoinedFor.providerId == currentCase?.provider_id &&
      new Date(providerNotJoinedFor.scheduledTime).getTime() ==
        new Date(currentCase?.video_conference_date || '').getTime() &&
      providerNotJoinedFor?.duration !== null
    ) {
      if (providerNotJoinedFor.duration == 5) {
        setCheckProviderNotJoinedForFiveMinutes(true);
      } else {
        setCheckProviderNotJoinedForFiveMinutes(false);
      }
      setIsModalOpen(true);
    } else {
      setIsModalOpen(false);
      setCheckProviderNotJoinedForFiveMinutes(false);
    }
  }, [
    providerNotJoinedFor.caseId,
    providerNotJoinedFor.providerId,
    providerNotJoinedFor.scheduledTime,
    providerNotJoinedFor.duration,
    caseId,
    currentCase?.provider_id,
    currentCase?.video_conference_date,
  ]);

  useEffect(() => {
    (async () => {
      try {
        const response = await getOneCase(Number(caseId));
        setThisCase(response);
      } catch (error) {
        displayErrorDetails(error);
      }
    })();
  }, [caseId]);

  const onClose = (): void => {
    setProviderNotJoinedFor({ caseId: 0, duration: null, providerId: 0, scheduledTime: '' });
  };

  const reschedule = (): void => {
    navigate('/select-date-time', {
      state: {
        RescheduleCase: true,
        caseId,
        providerId: 0,
        patient_current_state: currentCase?.patient_current_state,
      },
    });
  };

  const cancelAppointment = async (): Promise<void> => {
    setStartCancelFlag(true);
    try {
      await cancelCase(Number(caseId));
      navigate('/dashboard');
    } catch (error: unknown) {
      displayErrorDetails(error);
      setStartCancelFlag(false);
    }
  };

  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={onClose}
      style={customStylesDashboard}
      contentLabel="Modal"
      ariaHideApp={false}
      className={`fs-unmask ${styles.wrapSuccess}`}
      shouldCloseOnOverlayClick={false}
    >
      {checkProviderNotJoinedForFiveMinutes && (
        <button type="button" className={styles.modalClose} aria-label="Close modal" onClick={onClose}>
          Continue to wait
          <span className={styles.closeIconWrapper}>
            <span aria-hidden="true" className={styles.closeIcon}>
              &times;
            </span>
          </span>
        </button>
      )}
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 20,
        }}
      >
        <div
          className={styles.providerNotJoinedContent}
          style={{
            padding: checkProviderNotJoinedForFiveMinutes ? '' : '0 16px',
          }}
        >
          <div
            className={styles.providerNotJoinedHeader}
            style={{
              padding: checkProviderNotJoinedForFiveMinutes ? '' : '0 16px',
            }}
          >
            {checkProviderNotJoinedForFiveMinutes
              ? "It seems your provider hasn't joined the call yet."
              : 'We were unable to reach your provider.'}
          </div>
          {checkProviderNotJoinedForFiveMinutes ? (
            <>
              <div className={styles.providerNotJoinedText}>
                Our team is working to contact the provider. We'll update you in 10 minutes if we can't reach them.
              </div>
              <div className={styles.providerNotJoinedText}>
                If you can't wait, use the option below to find another time or cancel the appointment.{' '}
              </div>
            </>
          ) : (
            <div className={styles.providerNotJoinedText}>
              Dentistry.One is committed to providing the best care and we sincerely apologize. Please select the option
              below to find another suitable time or to cancel this appointment.
            </div>
          )}
          <div className={styles.providerNotJoinedDisclaimer}>No charge for cancelations.</div>
        </div>
        <div className={styles.buttonsContainer}>
          <Button type="button" onClick={reschedule} className={styles.button}>
            Reschedule
          </Button>
          <Button
            type="button"
            onClick={cancelAppointment}
            className={`${styles.button} ${styles.cancelButton}`}
            disabled={startCancelFlag}
          >
            Cancel Appointment
          </Button>
        </div>
      </div>
    </Modal>
  );
};

Modal.setAppElement('#root');

export default memo(ProviderNotJoinedModal);
