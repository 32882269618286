/* eslint-disable @typescript-eslint/no-empty-interface */
import Button from '@brands/Components/Button/Button';
import { getProviderRx } from '@brands/services/cases/getProviderRx';
import React from 'react';

import styles from './styles.module.scss';
import { displayErrorDetails } from '@brands/Utils/displayError';

interface PendingApprovalProps {}

const PendingApproval: React.FC<PendingApprovalProps> = (): JSX.Element => {
  const initiateICore = async (): Promise<void> => {
    try {
      window.open('', 'formpopup', 'width=1500,height=1000,resizeable,scrollbars');
      const icorePayload = await getProviderRx();

      const loginform = document.getElementById('icoreForm');
      const loginInput = document.getElementById('icoreFormData');

      if (loginform && loginInput) {
        (loginform as HTMLFormElement).target = 'formpopup';
        (loginInput as HTMLInputElement).value = JSON.stringify(icorePayload);
        (loginform as HTMLFormElement).submit();
      }
    } catch (error) {
      displayErrorDetails(`Error adding new report objects: ${error}`);
    }
  };

  return (
    <div className={styles.pendingApprovalContainer}>
      <div className={styles.pendingApprovalHeader}>
        <span className={styles.pendingApprovalTitle}>Your status is pending approval</span>
      </div>
      <div className={styles.pendingApprovalContent}>
        <span className={styles.pendingApprovalText}>Please schedule your iCore training to complete the process</span>
      </div>
      <div>
        <Button type="button" onClick={() => initiateICore()} className={styles.pendingApprovalButton}>
          Schedule Now
        </Button>
      </div>
    </div>
  );
};

export default PendingApproval;
