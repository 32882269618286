import { client } from '../client';
import {
  CreateOralHealthCoachingReportEducationParams,
  CreateOralHealthCoachingReportGoalParams,
  CreateOralHealthCoachingReportInternalNoteParams,
  ObjectType,
  OralHealthCoachingReport,
} from './types/types';

export const addOralHealthCoachingReportObject = async (
  oral_health_coaching_report_id: string,
  object_type: ObjectType,
  payload:
    | CreateOralHealthCoachingReportInternalNoteParams
    | CreateOralHealthCoachingReportGoalParams
    | CreateOralHealthCoachingReportEducationParams,
): Promise<OralHealthCoachingReport> => {
  const { data } = await client.post<OralHealthCoachingReport>(
    `/care_advisor/oral_health_coaching_reports/${oral_health_coaching_report_id}/${object_type}`,
    payload,
  );

  return data;
};
