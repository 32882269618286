import { ClinicalDataQuestion } from '@brands/services/clinical_data/types/types';
import { isValidZipCode } from '@brands/Utils/validation';
import * as Yup from 'yup';

const isValidHeight = (value: string): boolean => {
  const heightRegex = /^\d+ft, \d+in$/;
  return heightRegex.test(value);
};

export const validationSchema = (
  questions: ClinicalDataQuestion[] | undefined,
  medicalHistory?: boolean,
  medicalCondition?: boolean,
  dentalHistory?: boolean,
): any => {
  return Yup.object().shape({
    ...questions?.reduce((acc, question, index) => {
      return {
        ...acc,
        [question.id]: Yup.mixed()
          .test('required', (value, context) => {
            if (question.is_required) {
              if (Array.isArray(value)) {
                return value.length > 0;
              }
              return !!value?.trim() && value !== false && (isValidHeight(value) || question.title !== 'Height')
                ? true
                : new Yup.ValidationError(`${question.title} is required`);
            }
            if (question.descriptionId) {
              const val = context.parent[question.descriptionId];
              if (value) {
                return val && val.trim() !== ''
                  ? true
                  : new Yup.ValidationError(`The description is required for "${question.title}"`);
              }
              return true;
            }
            return true;
          })
          .test('medicalHistory', 'If yes, are you interested in stopping?', (value, context) => {
            if (
              medicalHistory &&
              !question.is_required &&
              question.title === 'If yes, are you interested in stopping?'
            ) {
              const selectedOptions = context.parent[questions[index - 1].id];
              if (Array.isArray(selectedOptions) && selectedOptions.length > 0) {
                return selectedOptions.length > 0 && (value === true || value === false);
              }
              return true;
            }
            return true;
          })
          .test('pharmacyZipCode', 'Not a valid pharmacy zip code', (value) => {
            if (medicalHistory && question.title === 'Pharmacy Zip Code' && value && !isValidZipCode(value)) {
              return new Yup.ValidationError('Not a valid zip code');
            }
            return true;
          })
          .test('medicalCondition', 'At least one value must be checked', (value, context) => {
            if (medicalCondition && !question.is_required) {
              const values = Object.values(context.parent);
              return values.some((val: any) => val);
            }

            return true;
          })
          .test('dentalHistory', 'Please enter a valid last dental visit date', (value) => {
            if (dentalHistory && question.is_required) {
              if (value === 'dontKnow') {
                return true;
              }
              const datePattern =
                /^(January|February|March|April|May|June|July|August|September|October|November|December), \d{4}$/;

              if (!datePattern.test(value.trim())) {
                return false;
              }
            }
            return true;
          })
          .test('dentalHistory', `You can't enter future date on last dental visit date`, (value) => {
            if (dentalHistory && question.is_required) {
              const enteredDate = new Date(value.trim());

              const currentDate = new Date();

              if (enteredDate > currentDate) {
                return false;
              }
            }
            return true;
          }),
      };
    }, {}),
  });
};
