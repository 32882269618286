import { ClinicalDataQuestion } from '@brands/services/clinical_data/types/types';

export const medicalConditionQuestions: ClinicalDataQuestion[] = [
  {
    id: 'arthritis',
    title: 'Arthritis',
    description: null,
    is_required: false,
    is_conditional: false,
    type: null,
    options: null,
  },
  {
    id: 'cancer',
    title: 'Cancer',
    description: null,
    is_required: false,
    is_conditional: false,
    type: null,
    options: null,
  },
  {
    id: 'dementia_alzheimers',
    title: 'Dementia/Alzheimer’s',
    description: null,
    is_required: false,
    is_conditional: false,
    type: null,
    options: null,
  },
  {
    id: 'heart_disease',
    title: 'Heart Disease',
    description: null,
    is_required: false,
    is_conditional: false,
    type: null,
    options: null,
  },
  {
    id: 'dry_mouth',
    title: 'Dry Mouth',
    description: null,
    is_required: false,
    is_conditional: false,
    type: null,
    options: null,
  },
  {
    id: 'obesity',
    title: 'Obesity',
    description: null,
    is_required: false,
    is_conditional: false,
    type: null,
    options: null,
  },
  {
    id: 'pregnancy_or_breastfeeding',
    title: 'Pregnancy or Breastfeeding',
    description: null,
    is_required: false,
    is_conditional: false,
    type: null,
    options: null,
  },
  {
    id: 'respiratory_conditions',
    title: 'Respiratory Conditions',
    description: null,
    is_required: false,
    is_conditional: false,
    type: null,
    options: null,
  },
  {
    id: 'acid_reflux',
    title: 'Acid Reflux',
    description: null,
    is_required: false,
    is_conditional: false,
    type: null,
    options: null,
  },
  {
    id: 'aids_hiv',
    title: 'AIDS/HIV',
    description: null,
    is_required: false,
    is_conditional: false,
    type: null,
    options: null,
  },
  {
    id: 'alzheimers_disease',
    title: 'Alzheimer’s Disease',
    description: null,
    is_required: false,
    is_conditional: false,
    type: null,
    options: null,
  },
  {
    id: 'anaphylaxis',
    title: 'Anaphylaxis',
    description: null,
    is_required: false,
    is_conditional: false,
    type: null,
    options: null,
  },
  {
    id: 'angina',
    title: 'Angina',
    description: null,
    is_required: false,
    is_conditional: false,
    type: null,
    options: null,
  },
  {
    id: 'artificial_joints',
    title: 'Artificial Joints',
    description: null,
    is_required: false,
    is_conditional: false,
    type: null,
    options: null,
  },
  {
    id: 'asthma',
    title: 'Asthma',
    description: null,
    is_required: false,
    is_conditional: false,
    type: null,
    options: null,
  },
  {
    id: 'cancer_chemo_radiation',
    title: 'Cancer Chemo/Radiation',
    description: null,
    is_required: false,
    is_conditional: false,
    type: null,
    options: null,
  },
  {
    id: 'copd',
    title: 'COPD',
    description: null,
    is_required: false,
    is_conditional: false,
    type: null,
    options: null,
  },
  {
    id: 'diabetes',
    title: 'Diabetes',
    description: null,
    is_required: false,
    is_conditional: false,
    type: null,
    options: null,
  },
  {
    id: 'drug_addiction',
    title: 'Drug Addiction',
    description: null,
    is_required: false,
    is_conditional: false,
    type: null,
    options: null,
  },
  {
    id: 'eating_disorder',
    title: 'Eating Disorder',
    description: null,
    is_required: false,
    is_conditional: false,
    type: null,
    options: null,
  },
  {
    id: 'emphysema',
    title: 'Emphysema',
    description: null,
    is_required: false,
    is_conditional: false,
    type: null,
    options: null,
  },
  {
    id: 'epilepsy',
    title: 'Epilepsy',
    description: null,
    is_required: false,
    is_conditional: false,
    type: null,
    options: null,
  },
  {
    id: 'excessive_bleeding',
    title: 'Excessive Bleeding',
    description: null,
    is_required: false,
    is_conditional: false,
    type: null,
    options: null,
  },
  {
    id: 'excessive_thirst',
    title: 'Excessive Thirst',
    description: null,
    is_required: false,
    is_conditional: false,
    type: null,
    options: null,
  },
  {
    id: 'fainting_spells',
    title: 'Fainting Spells',
    description: null,
    is_required: false,
    is_conditional: false,
    type: null,
    options: null,
  },
  {
    id: 'glaucoma',
    title: 'Glaucoma',
    description: null,
    is_required: false,
    is_conditional: false,
    type: null,
    options: null,
  },
  {
    id: 'heart_attack',
    title: 'Heart Attack',
    description: null,
    is_required: false,
    is_conditional: false,
    type: null,
    options: null,
  },
  {
    id: 'heart_pacemaker',
    title: 'Heart Pacemaker',
    description: null,
    is_required: false,
    is_conditional: false,
    type: null,
    options: null,
  },
  {
    id: 'hemophilia',
    title: 'Hemophilia',
    description: null,
    is_required: false,
    is_conditional: false,
    type: null,
    options: null,
  },
  {
    id: 'hepatitis_a',
    title: 'Hepatitis A',
    description: null,
    is_required: false,
    is_conditional: false,
    type: null,
    options: null,
  },
  {
    id: 'hepatitis_b',
    title: 'Hepatitis B',
    description: null,
    is_required: false,
    is_conditional: false,
    type: null,
    options: null,
  },
  {
    id: 'hepatitis_c',
    title: 'Hepatitis C',
    description: null,
    is_required: false,
    is_conditional: false,
    type: null,
    options: null,
  },
  {
    id: 'high_blood_pressure',
    title: 'High Blood Pressure',
    description: null,
    is_required: false,
    is_conditional: false,
    type: null,
    options: null,
  },
  {
    id: 'kidney_problems',
    title: 'Kidney Problems',
    description: null,
    is_required: false,
    is_conditional: false,
    type: null,
    options: null,
  },
  {
    id: 'low_blood_pressure',
    title: 'Low Blood Pressure',
    description: null,
    is_required: false,
    is_conditional: false,
    type: null,
    options: null,
  },
  {
    id: 'mitral_valve_prolapse',
    title: 'Mitral Valve Prolapse',
    description: null,
    is_required: false,
    is_conditional: false,
    type: null,
    options: null,
  },
  {
    id: 'osteoporosis',
    title: 'Osteoporosis',
    description: null,
    is_required: false,
    is_conditional: false,
    type: null,
    options: null,
  },
  {
    id: 'psychiatric_care',
    title: 'Psychiatric Care',
    description: null,
    is_required: false,
    is_conditional: false,
    type: null,
    options: null,
  },
  {
    id: 'rheumatic_fever',
    title: 'Rheumatic Fever',
    description: null,
    is_required: false,
    is_conditional: false,
    type: null,
    options: null,
  },
  {
    id: 'rheumatism',
    title: 'Rheumatism',
    description: null,
    is_required: false,
    is_conditional: false,
    type: null,
    options: null,
  },
  {
    id: 'sexually_transmitted_disease',
    title: 'Sexually Transmitted Disease',
    description: null,
    is_required: false,
    is_conditional: false,
    type: null,
    options: null,
  },
  {
    id: 'sickle_cell_disease',
    title: 'Sickle Cell Disease',
    description: null,
    is_required: false,
    is_conditional: false,
    type: null,
    options: null,
  },
  {
    id: 'stroke',
    title: 'Stroke',
    description: null,
    is_required: false,
    is_conditional: false,
    type: null,
    options: null,
  },
  {
    id: 'thyroid_disease',
    title: 'Thyroid Disease',
    description: null,
    is_required: false,
    is_conditional: false,
    type: null,
    options: null,
  },
  {
    id: 'tuberculosis',
    title: 'Tuberculosis',
    description: null,
    is_required: false,
    is_conditional: false,
    type: null,
    options: null,
  },
  {
    id: 'ulcers',
    title: 'Ulcers',
    description: null,
    is_required: false,
    is_conditional: false,
    type: null,
    options: null,
  },
  {
    id: 'conditions_not_listed',
    title: 'Conditions Not Listed',
    description: null,
    is_required: false,
    is_conditional: true,
    type: 'textarea',
    options: null,
  },
  {
    id: 'none_of_the_above',
    title: 'None of the Above',
    description: null,
    is_required: false,
    is_conditional: false,
    type: null,
    options: null,
  },
];

export const medicalConditionQuestionsForTags: ClinicalDataQuestion[] = [
  {
    id: 'arthritis',
    title: 'Arthritis',
    description: null,
    is_required: false,
    is_conditional: false,
    type: null,
    options: null,
  },
  {
    id: 'cancer',
    title: 'Cancer',
    description: null,
    is_required: false,
    is_conditional: false,
    type: null,
    options: null,
  },
  {
    id: 'dementia_alzheimers',
    title: 'Dementia/Alzheimer’s Disease',
    description: null,
    is_required: false,
    is_conditional: false,
    type: null,
    options: null,
  },
  {
    id: 'heart_disease',
    title: 'Heart Disease',
    description: null,
    is_required: false,
    is_conditional: false,
    type: null,
    options: null,
  },
  {
    id: 'dry_mouth',
    title: 'Dry Mouth (Xerostomia)/ Take Medications',
    description: null,
    is_required: false,
    is_conditional: false,
    type: null,
    options: null,
  },
  {
    id: 'obesity',
    title: 'Obesity',
    description: null,
    is_required: false,
    is_conditional: false,
    type: null,
    options: null,
  },
  {
    id: 'pregnancy_or_breastfeeding',
    title: 'Currently Pregnant/Breastfeeding',
    description: null,
    is_required: false,
    is_conditional: false,
    type: null,
    options: null,
  },
  {
    id: 'respiratory_conditions',
    title: 'Respiratory Disease',
    description: null,
    is_required: false,
    is_conditional: false,
    type: null,
    options: null,
  },
  {
    id: 'acid_reflux',
    title: 'Acid Reflux/GERD',
    description: null,
    is_required: false,
    is_conditional: false,
    type: null,
    options: null,
  },
  {
    id: 'aids_hiv',
    title: 'AIDS/HIV Positive',
    description: null,
    is_required: false,
    is_conditional: false,
    type: null,
    options: null,
  },
  {
    id: 'alzheimers_disease',
    title: 'Alzheimer’s Disease',
    description: null,
    is_required: false,
    is_conditional: false,
    type: null,
    options: null,
  },
  {
    id: 'anaphylaxis',
    title: 'Anaphylaxis',
    description: null,
    is_required: false,
    is_conditional: false,
    type: null,
    options: null,
  },
  {
    id: 'angina',
    title: 'Angina/Chest Pain',
    description: null,
    is_required: false,
    is_conditional: false,
    type: null,
    options: null,
  },
  {
    id: 'artificial_joints',
    title: 'Artificial Joint',
    description: null,
    is_required: false,
    is_conditional: false,
    type: null,
    options: null,
  },
  {
    id: 'asthma',
    title: 'Asthma',
    description: null,
    is_required: false,
    is_conditional: false,
    type: null,
    options: null,
  },
  {
    id: 'cancer_chemo_radiation',
    title: 'Cancer/Chemo/Radiation',
    description: null,
    is_required: false,
    is_conditional: false,
    type: null,
    options: null,
  },
  {
    id: 'copd',
    title: 'COPD',
    description: null,
    is_required: false,
    is_conditional: false,
    type: null,
    options: null,
  },
  {
    id: 'diabetes',
    title: 'Diabetes',
    description: null,
    is_required: false,
    is_conditional: false,
    type: null,
    options: null,
  },
  {
    id: 'drug_addiction',
    title: 'Drug Addiction',
    description: null,
    is_required: false,
    is_conditional: false,
    type: null,
    options: null,
  },
  {
    id: 'eating_disorder',
    title: 'Eating Disorder',
    description: null,
    is_required: false,
    is_conditional: false,
    type: null,
    options: null,
  },
  {
    id: 'emphysema',
    title: 'Emphysema/Shortness of Breath',
    description: null,
    is_required: false,
    is_conditional: false,
    type: null,
    options: null,
  },
  {
    id: 'epilepsy',
    title: 'Epilepsy or Seizures/Convulsions',
    description: null,
    is_required: false,
    is_conditional: false,
    type: null,
    options: null,
  },
  {
    id: 'excessive_bleeding',
    title: 'Excessive Bleeding',
    description: null,
    is_required: false,
    is_conditional: false,
    type: null,
    options: null,
  },
  {
    id: 'excessive_thirst',
    title: 'Excessive Thirst',
    description: null,
    is_required: false,
    is_conditional: false,
    type: null,
    options: null,
  },
  {
    id: 'fainting_spells',
    title: 'Fainting spells/Dizziness',
    description: null,
    is_required: false,
    is_conditional: false,
    type: null,
    options: null,
  },
  {
    id: 'glaucoma',
    title: 'Glaucoma',
    description: null,
    is_required: false,
    is_conditional: false,
    type: null,
    options: null,
  },
  {
    id: 'heart_attack',
    title: 'Heart Attack/Heart Failure',
    description: null,
    is_required: false,
    is_conditional: false,
    type: null,
    options: null,
  },
  {
    id: 'heart_pacemaker',
    title: 'Heart Pacemaker/Artificial Heart Valve',
    description: null,
    is_required: false,
    is_conditional: false,
    type: null,
    options: null,
  },
  {
    id: 'hemophilia',
    title: 'Hemophilia',
    description: null,
    is_required: false,
    is_conditional: false,
    type: null,
    options: null,
  },
  {
    id: 'hepatitis_a',
    title: 'Hepatitis A',
    description: null,
    is_required: false,
    is_conditional: false,
    type: null,
    options: null,
  },
  {
    id: 'hepatitis_b',
    title: 'Hepatitis B',
    description: null,
    is_required: false,
    is_conditional: false,
    type: null,
    options: null,
  },
  {
    id: 'hepatitis_c',
    title: 'Hepatitis C',
    description: null,
    is_required: false,
    is_conditional: false,
    type: null,
    options: null,
  },
  {
    id: 'high_blood_pressure',
    title: 'High Blood Pressure',
    description: null,
    is_required: false,
    is_conditional: false,
    type: null,
    options: null,
  },
  {
    id: 'kidney_problems',
    title: 'Kidney Problems/Dialysis Liver Disease',
    description: null,
    is_required: false,
    is_conditional: false,
    type: null,
    options: null,
  },
  {
    id: 'low_blood_pressure',
    title: 'Low Blood Pressure',
    description: null,
    is_required: false,
    is_conditional: false,
    type: null,
    options: null,
  },
  {
    id: 'mitral_valve_prolapse',
    title: 'Mitral Valve Prolapse',
    description: null,
    is_required: false,
    is_conditional: false,
    type: null,
    options: null,
  },
  {
    id: 'osteoporosis',
    title: 'Osteoporosis Active TB',
    description: null,
    is_required: false,
    is_conditional: false,
    type: null,
    options: null,
  },
  {
    id: 'psychiatric_care',
    title: 'Psychiatric Care',
    description: null,
    is_required: false,
    is_conditional: false,
    type: null,
    options: null,
  },
  {
    id: 'rheumatic_fever',
    title: 'Rheumatic Fever',
    description: null,
    is_required: false,
    is_conditional: false,
    type: null,
    options: null,
  },
  {
    id: 'rheumatism',
    title: 'Rheumatism',
    description: null,
    is_required: false,
    is_conditional: false,
    type: null,
    options: null,
  },
  {
    id: 'sexually_transmitted_disease',
    title: 'Sexually Transmitted Disease',
    description: null,
    is_required: false,
    is_conditional: false,
    type: null,
    options: null,
  },
  {
    id: 'sickle_cell_disease',
    title: 'Sickle Cell Disease',
    description: null,
    is_required: false,
    is_conditional: false,
    type: null,
    options: null,
  },
  {
    id: 'stroke',
    title: 'Stroke',
    description: null,
    is_required: false,
    is_conditional: false,
    type: null,
    options: null,
  },
  {
    id: 'thyroid_disease',
    title: 'Thyroid Disease',
    description: null,
    is_required: false,
    is_conditional: false,
    type: null,
    options: null,
  },
  {
    id: 'tuberculosis',
    title: 'Tuberculosis',
    description: null,
    is_required: false,
    is_conditional: false,
    type: null,
    options: null,
  },
  {
    id: 'ulcers',
    title: 'Ulcers/Intestinal Disease',
    description: null,
    is_required: false,
    is_conditional: false,
    type: null,
    options: null,
  },
  {
    id: 'conditions_not_listed',
    title: 'Conditions Not Listed',
    description: null,
    is_required: false,
    is_conditional: true,
    type: 'textarea',
    options: null,
  },
  {
    id: 'none_of_the_above',
    title: 'None of the Above',
    description: null,
    is_required: false,
    is_conditional: false,
    type: null,
    options: null,
  },
];
