/* eslint-disable react/no-array-index-key */
import React, { FC } from 'react';
import { MdKeyboardArrowLeft } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

import styles from './styles.module.scss';

type ProgressBarProps = {
  currentStep: number;
  totalSteps: number;
  containerStyle?: { [key: string]: string };
  onBack?: () => void | undefined;
  hideStepNumbers?: boolean;
};

const ProgressBar: FC<ProgressBarProps> = ({ currentStep, totalSteps, containerStyle, onBack, hideStepNumbers }) => {
  const navigate = useNavigate();
  return (
    <section className={styles.progressContainer} style={{ ...containerStyle }}>
      <div className={styles.textContainer}>
        <button
          type="button"
          className={`fs-unmask ${styles.backButton}`}
          onClick={() => {
            if (onBack) {
              onBack();
            } else {
              navigate(-1);
            }
          }}
        >
          <MdKeyboardArrowLeft />
          Back
        </button>
        {!hideStepNumbers && (
          <div className={styles.pageDisplay}>
            Page {currentStep} of {totalSteps}
          </div>
        )}
      </div>
      {!hideStepNumbers && (
        <div className={styles.barContainer}>
          {[...Array(totalSteps)].map((step, i) => (
            <div
              className={`${styles.barStep} ${currentStep >= i + 1 ? styles.selectedBarStep : ''}`}
              key={`${step}-${i}`}
            >
              {step}
            </div>
          ))}
        </div>
      )}
    </section>
  );
};

ProgressBar.defaultProps = {
  containerStyle: {},
  onBack: undefined,
  hideStepNumbers: false,
};

export default ProgressBar;
