import Button from '@brands/Components/Button/Button';
import Container from '@brands/Components/Container/Container';
import ProgressBar from '@brands/Components/ProgressBar/ProgressBar';
import { useAppDispatch } from '@brands/hooks/useReduxHook';
import useWindowSize from '@brands/hooks/useWindowSize';
import { FormValues, setForm } from '@brands/store/slices/patientFormSlice';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './styles.module.scss';

const SmileScanMobile = (): JSX.Element => {
  const screenSize = useWindowSize();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  return (
    <Container
      isBGEclipse={false}
      patientDashboard
      childClassName={styles.dashboard}
      containerStyle={{
        justifyContent: screenSize.width >= 1289 ? 'center' : 'start',
        overflowY: screenSize.width >= 1289 ? 'hidden' : 'scroll',
      }}
    >
      <div className={styles.smileScanMobile}>
        <ProgressBar currentStep={0} totalSteps={0} hideStepNumbers onBack={() => navigate('/dashboard')} />
        <span className={`fs-unmask ${styles.smileScanWebHeader}`}>AI SmileScan Disclaimer</span>
        <div className={`fs-unmask ${styles.smileScanWebContentMobile}`}>
          <span>
            The information available on the Dentistry.One website and any associated web applications (collectively,
            the “Online Services”) does not create a patient-provider or similar relationship between you and
            Mouthwatch, LLC (“We” or “Us”). Such relationships require your mutual agreement with qualified, licensed
            dental or other healthcare professional (“Provider”).
          </span>
          <span>
            We provide the Online Services as a tool for pre-diagnostic information. They are not medical or diagnostic
            tools, and we do not claim that they diagnose any specific condition. They do not provide any medical advice
            and are not evaluated by a Provider. Due to the many variables involved in their use and potential health
            conditions, we cannot guarantee the accuracy or reliability of the Online Services. We do not warrant that
            the information provided by the Online Services is complete or exhaustive.
          </span>
          <span>
            You understand and acknowledge that reports provided by the Online Services are based on limited information
            gathered from the photos and/or scans that you upload, and those may be generated by technologies such as
            artificial intelligence. These reports are subject to review and verification by a Provider. We strongly
            encourage you to discuss the report provided by the Online Services with your dentist to ensure an
            appropriate course of treatment where warranted.
          </span>
          <span>
            The report generated by the Online Services is not a substitute for medical consultation with a Provider. We
            strongly advise you to consult with a Provider about any aspect of a report, medical advice, or treatment.
          </span>
          <span>
            We provide the Online Services and information they convey on an “as is” and “as available” basis. We use
            our best efforts to provide accurate information but disclaim any express or implied warranties, including
            but not limited to implied warranties of merchantability, fitness for a particular purpose, or
            non-infringement of intellectual property, to the fullest extent permitted by law.
          </span>
          <span>
            Where the Online Services link to other websites or content from third parties, we make no warranty that it
            has investigated, monitored, or checked those sites or that content for adequacy, accuracy, validity,
            reliability, availability, or completeness.
          </span>
          <span>
            Use of the Online Services is at your sole risk and in no event shall our liability under any legal or
            equitable theory exceed the fees actually paid for the Online Services or fifty dollars ($50), whichever is
            lower. We shall not be liable for any incidental, consequential, or indirect damages relating to or arising
            from your use of the Online Services, even if you advise us in advance that there is a possibility of same.
            You agree to defend, indemnify, and hold us harmless from any demands, claims, damages, suits, losses, or
            other expenses of any kind that arise from or relate to your use of the Online Services, including any
            claims brought by you or third parties.
          </span>
        </div>
        <Button
          onClick={() => {
            const formPayload: FormValues[] = [
              {
                form_question_id: 20,
                value: {
                  caseType: 'smile_scan',
                },
              },
            ];

            dispatch(setForm(formPayload));
            navigate('/select-patient', {
              state: {
                caseType: 'smile_scan',
              },
            });
          }}
          className={`fs-unmask ${styles.dashboardBtn}`}
          id="smile_scan_select_patient"
        >
          I Agree
        </Button>
      </div>
    </Container>
  );
};
export default React.memo(SmileScanMobile);
