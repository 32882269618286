import React, { useEffect, useState } from 'react';

import { CaseType, ICase } from '../../../../services/cases/types/ICase';
import { cancelCaseAvailability } from '../../../../Utils/getFriendlyName';
import styles from '../appointments.module.scss';

interface Props {
  appointment: ICase;
  setCase: (value: React.SetStateAction<ICase | undefined>) => void;
  setCancelCaseModal: (value: React.SetStateAction<boolean>) => void;
  cancelAvailability: boolean;
}

const CancelButton = ({ appointment, setCase, setCancelCaseModal, cancelAvailability }: Props): JSX.Element => {
  const [cancelCaseAvailabilityFlag, setCancelCaseAvailabilityFlag] = useState(cancelAvailability);

  useEffect(() => {
    let intervalId: NodeJS.Timeout;
    if (
      (appointment.type === 'video_call_scheduled' || appointment.type === CaseType.phone_call_scheduled) &&
      appointment.video_conference_date &&
      cancelCaseAvailabilityFlag
    ) {
      intervalId = setInterval(() => {
        const dueDate = cancelCaseAvailability(appointment);
        setCancelCaseAvailabilityFlag(!!dueDate);
      }, 10000);
    }
    return () => clearInterval(intervalId);
  }, [appointment]);

  return (
    <span>
      {cancelCaseAvailabilityFlag && (
        <button
          type="button"
          className={`fs-unmask ${styles.cancelBtn}`}
          onClick={() => {
            setCase(appointment);
            setCancelCaseModal(true);
          }}
        >
          Cancel
        </button>
      )}
    </span>
  );
};

export default CancelButton;
