/* eslint-disable no-underscore-dangle */
import { displayErrorDetails } from '@brands/Utils/displayError';
import mqtt, { MqttClient } from 'mqtt';

type MessageCallback = (topic: string, message: Record<string, any>) => void;

export class IotClient {
  private _connecting = false;

  private _mqttWS: MqttClient | null = null;

  private _endpointURL: string | null = null;

  private callbacks: Record<string, MessageCallback> = {};

  private _onConnect: mqtt.OnConnectCallback = () => null;

  private _onError: mqtt.OnErrorCallback = () => null;

  private _onClose: () => void = () => null;

  get hasIoT(): boolean {
    return this._mqttWS !== undefined;
  }

  get connected(): boolean {
    return !!this._mqttWS?.connected;
  }

  get connecting(): boolean {
    return this._connecting;
  }

  set connecting(value: boolean) {
    this._connecting = value;
  }

  set endpointURL(url: string) {
    this._endpointURL = url;
  }

  set onConnect(func: mqtt.OnConnectCallback) {
    this._onConnect = func;
  }

  set onClose(func: () => void) {
    this._onClose = func;
  }

  set onError(func: mqtt.OnErrorCallback) {
    this._onError = func;
  }

  subscribe(topic: string): void {
    if (this._mqttWS) {
      this._mqttWS.subscribe(topic);
    }
  }

  connect(clientId: string): void {
    if (!this._endpointURL) {
      throw new Error('"socketURL" must be set on client using "setEndpointURL" method before connection.');
    }

    this._mqttWS = mqtt.connect(this._endpointURL, {
      clientId,
      reconnectPeriod: 0,
    });

    this._mqttWS.on('connect', this._onConnect);
    this._mqttWS.on('close', this._onClose);
    this._mqttWS.on('error', this._onError);

    this._mqttWS.on('message', (topic: string, payload: Buffer) => {
      try {
        const response: Record<string, any> = JSON.parse(payload.toString('utf8'));
        Object.keys(this.callbacks).forEach((id) => {
          this.callbacks[id](topic, response);
        });
      } catch (e) {
        displayErrorDetails(`Received Error Response ${payload.toString('utf8')}`);
      }
    });
  }

  onMessage(id: string, callback: (topic: string, message: Record<string, any>) => void): string {
    this.callbacks[id] = callback;
    return id;
  }

  offMessage(id: string): void {
    delete this.callbacks[id];
  }

  disconnect(): void {
    this._mqttWS?.end();
  }

  reset(): void {
    this.callbacks = {};
    this._onConnect = () => null;
    this._onError = () => null;
    this._onClose = () => null;
  }
}

const iotClient = new IotClient();

export { iotClient };
