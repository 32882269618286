/* eslint-disable import/no-cycle */
/* eslint-disable no-nested-ternary */
import { MedicalHistorySubmit } from '@brands/Dashboard/MedicalHistory/MedicalHistory/utils/types';
import { addClinicalData } from '@brands/services/clinical_data/addClinicalData';
import { listClinicalData } from '@brands/services/clinical_data/listClinicalData';
import { MedicalHistory } from '@brands/services/clinical_data/types/types';
import { updateClinicalData } from '@brands/services/clinical_data/updateClinicalData';
import { selectPageState } from '@brands/store/selectors/pageState';
import { selectPatientForm } from '@brands/store/selectors/patientForm';
import { setDependentId, setIsFromSelectPatient } from '@brands/store/slices/pageStateSlice';
import { selectCommonCustomStylesLarge } from '@brands/Utils/customStyles';
import { Option, stateOptions } from '@brands/Utils/selectOptions';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import Button from '../../../../../Components/Button/Button';
import PhoneInput from '../../../../../Components/Inputs/PhoneInput/PhoneInput';
import SelectInput from '../../../../../Components/Inputs/SelectInput/SelectInput';
import TextField from '../../../../../Components/Inputs/TextField/TextField';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/useReduxHook';
import { UserProfile } from '../../../../../services/identity/types/UserProfile';
import styles from '../../../styles.module.scss';
import { displayPhoneNumber, isValidPhoneNumberFormat } from '../../MyProfile';
import { pharmacyValidationSchema } from '../../utils/validationSchema';

type EditPharmacyProps = {
  medicalHistoryForm: MedicalHistory;
  thisUser: UserProfile;
  setThisUser: React.Dispatch<React.SetStateAction<UserProfile | undefined>>;
  setIsEditPharmacy: React.Dispatch<React.SetStateAction<boolean>>;
  loading: boolean;
  setPharmacyName: React.Dispatch<React.SetStateAction<string>>;
  setPharmacyAddress: React.Dispatch<React.SetStateAction<string>>;
  setPharmacyPhoneNumber: React.Dispatch<React.SetStateAction<string>>;
  dependentId: number | undefined;
};

const EditPharmacy = ({
  medicalHistoryForm,
  thisUser,
  setThisUser,
  setIsEditPharmacy,
  loading,
  setPharmacyName,
  setPharmacyAddress,
  setPharmacyPhoneNumber,
  dependentId,
}: EditPharmacyProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { values } = useAppSelector(selectPatientForm);
  const { selectedPatient } = useAppSelector(selectPatientForm);

  const { isFromSelectPatient } = useAppSelector(selectPageState);

  const [objectId, setObjectId] = useState<string | null>(null);

  function getEnhancedGivingOptions(options: Option[]): any {
    return [{ value: 'noState', label: 'Select a state' }, ...options];
  }

  const enhancedGivingOptions = getEnhancedGivingOptions(stateOptions);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    getValues,
  } = useForm<MedicalHistorySubmit>({
    mode: 'onChange',
    resolver: yupResolver(pharmacyValidationSchema()),
  });

  const onInvalid = (errorsList: any): void => {
    const error = Object.values(errorsList)[0] as any;
    if (error) {
      toast.error(error.message as string);
    }
  };

  const onSubmitPharmacy = async (profileData: MedicalHistorySubmit): Promise<void> => {
    if (!objectId) {
      await addClinicalData('medical_history', profileData);
    } else {
      await updateClinicalData('medical_history', objectId, profileData);
    }
    setThisUser({
      ...thisUser,
      pharmacy_name: profileData.pharmacy_name ?? undefined,
      pharmacy_street_address: profileData.pharmacy_street_address ?? undefined,
      pharmacy_city: profileData.pharmacy_city ?? undefined,
      pharmacy_state: profileData.pharmacy_state ?? undefined,
      pharmacy_zip_code: profileData.pharmacy_zip_code ?? undefined,
      pharmacy_phone_number: profileData.pharmacy_phone_number ?? undefined,
    });
    setPharmacyName(profileData.pharmacy_name as string);
    setPharmacyPhoneNumber(
      profileData?.pharmacy_phone_number && isValidPhoneNumberFormat(profileData.pharmacy_phone_number)
        ? (displayPhoneNumber(profileData.pharmacy_phone_number) as string)
        : (profileData.pharmacy_phone_number as string),
    );

    setPharmacyAddress(
      `${profileData?.pharmacy_street_address || ''} ${profileData?.pharmacy_city || ''}, ${
        profileData?.pharmacy_state || ''
      }, ${profileData?.pharmacy_zip_code || ''}`,
    );
    setIsEditPharmacy(false);
    if (isFromSelectPatient) {
      dispatch(setIsFromSelectPatient(false));
      dispatch(setDependentId(null));
      navigate('/select-patient', {
        state: { caseType: values.find((item: any) => item.form_question_id === 20)?.value.caseType as string },
      });
    }
  };

  const onCancelEditPharmacy = (): void => {
    setIsEditPharmacy(false);
    if (isFromSelectPatient) {
      dispatch(setIsFromSelectPatient(false));
      dispatch(setDependentId(null));
      navigate('/select-patient', {
        state: { caseType: values.find((item: any) => item.form_question_id === 20)?.value.caseType as string },
      });
    }
  };

  useEffect(() => {
    if (Object.keys(medicalHistoryForm).length > 0) {
      Object.keys(medicalHistoryForm).forEach((key) => {
        setValue(key as keyof MedicalHistorySubmit, medicalHistoryForm[key as keyof MedicalHistorySubmit] as string, {
          shouldValidate: !loading,
        });
      });
    }
  }, [loading, medicalHistoryForm, setValue]);

  useEffect(() => {
    (async () => {
      const res = await listClinicalData(dependentId || Number(selectedPatient?.value));
      if (res.medical_history) {
        setObjectId(res.medical_history.id.toString());
      }
    })();
  }, [thisUser]);

  return (
    <div className={styles.editProfileContainer}>
      <div className={styles.titleContainer}>
        <span className={styles.title}>Pharmacy</span>
      </div>
      <div className={styles.editContainer}>
        <form className={styles.formContainer} onSubmit={handleSubmit(onSubmitPharmacy, onInvalid)}>
          <div className={styles.row}>
            <div className={styles.rowItem}>
              <div className={styles.caption}>Pharmacy Name</div>
              <TextField
                {...register('pharmacy_name')}
                errors={errors}
                name="pharmacy_name"
                value={getValues('pharmacy_name') ?? ''}
                onChange={(e) => {
                  setValue('pharmacy_name', e.target.value, { shouldValidate: true });
                }}
                wrapperClassName={styles.inputWrapper}
                inputClass={styles.input}
              />
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.rowItem}>
              <div className={styles.caption}>Pharmacy Address</div>
              <TextField
                {...register('pharmacy_street_address')}
                errors={errors}
                name="pharmacy_street_address"
                value={getValues('pharmacy_street_address') ?? ''}
                onChange={(e) => {
                  setValue('pharmacy_street_address', e.target.value, { shouldValidate: true });
                }}
                wrapperClassName={styles.inputWrapper}
                inputClass={styles.input}
              />
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.rowItem}>
              <div className={styles.caption}>Pharmacy City</div>
              <TextField
                {...register('pharmacy_city')}
                errors={errors}
                name="pharmacy_city"
                value={getValues('pharmacy_city') ?? ''}
                onChange={(e) => {
                  setValue('pharmacy_city', e.target.value, { shouldValidate: true });
                }}
                wrapperClassName={styles.inputWrapper}
                inputClass={styles.input}
              />
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.rowItem}>
              <div className={styles.caption}>Pharmacy State</div>
              <SelectInput
                {...register('pharmacy_state')}
                name="pharmacy_state"
                options={enhancedGivingOptions}
                containerClass={styles.inputWrapper}
                selectorClass={styles.selector}
                style={selectCommonCustomStylesLarge}
                errors={errors}
                selectedValue={
                  getValues('pharmacy_state') === '' ||
                  getValues('pharmacy_state') === null ||
                  getValues('pharmacy_state') === undefined
                    ? enhancedGivingOptions[0]
                    : getValues('pharmacy_state')
                }
                onChange={(value) => {
                  if (value) {
                    setValue('pharmacy_state', value.value === 'noState' ? '' : value.value, {
                      shouldValidate: true,
                    });
                  }
                }}
              />
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.rowItem}>
              <div className={styles.caption}>Pharmacy Zip Code</div>
              <TextField
                {...register('pharmacy_zip_code')}
                errors={errors}
                name="pharmacy_zip_code"
                value={getValues('pharmacy_zip_code') ?? ''}
                onChange={(e) => {
                  setValue('pharmacy_zip_code', e.target.value, { shouldValidate: true });
                }}
                wrapperClassName={styles.inputWrapper}
                inputClass={styles.input}
                isUnderlined={false}
              />
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.rowItem}>
              <div className={styles.caption}>Pharmacy Phone number</div>
              <PhoneInput
                {...register('pharmacy_phone_number')}
                value={getValues('pharmacy_phone_number') ?? ''}
                id="pharmacy_phone_number"
                onChange={(val) => {
                  setValue('pharmacy_phone_number', (val as string) ?? '', {
                    shouldValidate: true,
                  });
                }}
                inputClass={styles.input}
                errors={errors}
                wrapperClassName={styles.inputWrapper}
                isUnderlined={false}
              />
            </div>
          </div>
          <div className={styles.actionBtnContainer}>
            <Button type="button" className={styles.cancel} onClick={onCancelEditPharmacy}>
              Cancel
            </Button>
            <Button type="submit" className={styles.submit}>
              Save
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditPharmacy;
