import React, { FC } from 'react';
import { MdLensBlur } from 'react-icons/md';

import { useVideoContext } from '../../../hooks';
import styles from './styles.module.scss';

const VirtualBackgroundButton: FC = () => {
  const { setIsBackgroundSelectionOpen } = useVideoContext();

  return (
    <button type="button" className='fs-unmask' onClick={() => setIsBackgroundSelectionOpen(true)} aria-label="Background button">
      <span className={styles.virtualBackgroundIcon}>
        <MdLensBlur />
      </span>
    </button>
  );
};
export default VirtualBackgroundButton;
