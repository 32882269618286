/* eslint-disable react/no-array-index-key */
import { isAlmostEqualWithTolerance } from '@brands/Dashboard/MyProfile/Patient/utils/utilsFunctions';
import { formatDate } from '@brands/Utils/formatDate';
import React, { SetStateAction, useEffect } from 'react';
import { FieldErrors, UseFormRegister, UseFormSetValue, UseFormTrigger } from 'react-hook-form';

import { ICase } from '../../../../services/cases/types/ICase';
import { UserProfile, UserRoleName } from '../../../../services/identity/types/UserProfile';
import { updateOneReport } from '../../../../services/oneReport/updateOneReport';
import { checkCollapsibleHeaderColor } from '../../../../Utils/getCollapsibleHeader';
import { OralHealthReportSubmit } from '../../assets/types';
import ConsultationOHRHeader from '../../Components/ConsultationOHRHeader/ConsultationOHRHeader';
import styles from '../../styles.module.scss';

type SubjectiveSectionProps = {
  consultationForm: OralHealthReportSubmit;
  setConsultationForm: React.Dispatch<React.SetStateAction<OralHealthReportSubmit>>;
  thisCase: ICase;
  setCase: React.Dispatch<SetStateAction<ICase>>;
  userInfo: UserProfile;
  register: UseFormRegister<OralHealthReportSubmit>;
  setValue: UseFormSetValue<OralHealthReportSubmit>;
  trigger: UseFormTrigger<OralHealthReportSubmit>;
  errors: FieldErrors<OralHealthReportSubmit>;
};

const SubjectiveSection = ({
  consultationForm,
  setConsultationForm,
  thisCase,
  setCase,
  userInfo,
  register,
  setValue,
  trigger,
  errors,
}: SubjectiveSectionProps): JSX.Element => {
  const saveCaseAttribute = async (attributeName: string, attributeValue: any): Promise<void> => {
    if (attributeValue) {
      const updateData = {
        [attributeName]: attributeValue,
      };

      const response = await updateOneReport(thisCase?.one_report.id || '', updateData);

      setCase({
        ...thisCase,
        one_report: {
          ...response,
        },
      });
    }
  };

  const updateChiefComplaintProblem = async (): Promise<void> => {
    await updateOneReport(thisCase?.one_report.id || '', {
      chief_complaint_problem: thisCase?.case_intake_questions?.is_dental_emergency
        ? 'I have a Dental Emergency'
        : 'I have a Non-urgent Dental Concern',
    });
  };

  useEffect(() => {
    if (thisCase.one_report.chief_complaint_problem === null && userInfo.role.name === UserRoleName.Provider) {
      updateChiefComplaintProblem();
    }
  }, []);

  return (
    <div className={styles.subjectiveDiv}>
      <div className={styles.subjective}>
        <span className={`fs-unmask ${styles.subjectiveSpan}`}>Chief Submitted Complaint/Problem:</span>
        <span className={`fs-exclude ${styles.subjectiveSpan}`}>
          {thisCase?.case_intake_questions?.is_dental_emergency
            ? 'I have a Dental Emergency'
            : 'I have a Non-urgent Dental Concern'}
        </span>
      </div>
      <div className="row">
        <ConsultationOHRHeader thisCase={thisCase} />
      </div>
      <div className="row">
        <div className={styles.comments}>
          <div className={`fs-unmask ${styles.commentsTitle}`}>Is there anything else you want the dentist to know?</div>
          <div className={`${styles.commentsContent} ${styles.additionalInfoDiv}`}>
            {thisCase?.case_intake_questions?.additional_info
              ? thisCase?.case_intake_questions?.additional_info.map((info, index) => (
                  <div key={index} className={`fs-exclude ${styles.additionalInfoDiv}`}>
                    {info.created_at !== thisCase.created_at &&
                      !isAlmostEqualWithTolerance(info.created_at, thisCase.created_at) && (
                        <span className={`${styles.infoDate} ${styles.subjectiveSpan}`}>
                          {formatDate(info.created_at)}
                        </span>
                      )}
                    <span className={`fs-exclude ${styles.subjectiveSpan}`}>{info.additional_info}</span>
                  </div>
                ))
              : '-'}
          </div>
        </div>
      </div>

      <div className={styles.medicalHistoryReview}>
        <span className={`fs-unmask ${styles.subjectiveSpan}`}>
          Additional Comments from Provider on Patient’s Chief Complaint/Problem
        </span>
        {checkCollapsibleHeaderColor(thisCase || '', userInfo, 'Assessment') === 'edit' ||
        checkCollapsibleHeaderColor(thisCase || '', userInfo, 'Assessment') === 'editNotRequired' ? (
          <textarea
            className={`fs-exclude ${styles.subjectiveInput}`}
            style={{ color: errors.subjective?.medical_history ? '#BA271A' : '' }}
            {...register('subjective.medical_history')}
            value={consultationForm.subjective?.medical_history || ''}
            onChange={(e) => {
              setConsultationForm({
                ...consultationForm,
                subjective: {
                  ...consultationForm.subjective,
                  medical_history: e.target.value,
                },
              });

              setValue('subjective.medical_history', e.target.value);

              if (errors?.subjective?.medical_history) {
                trigger('subjective.medical_history');
              }
            }}
            onBlur={() => {
              saveCaseAttribute('medical_history', consultationForm.subjective.medical_history);
            }}
          />
        ) : (
          <textarea
            disabled
            value={thisCase?.one_report?.medical_history || ''}
            className={`fs-exclude ${styles.subjectiveInput}`}
            style={{ color: errors.subjective?.medical_history ? '#BA271A' : '' }}
          />
        )}
      </div>
    </div>
  );
};

export default React.memo(SubjectiveSection);
